import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import {channelsClientOnlyActions, channelsServerToClientActions} from '@messenger/core/src/Redux/Channels/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectChannelIdsListByFilter} from '@messenger/core/src/Redux/Channels/Selectors/selectChannelIdsListByFilter';
import {selectAreAllChannelsShown} from '@messenger/core/src/Redux/Channels/Selectors/Filter/selectAreAllChannelsShown';
import {selectTotalChannels} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';

export const processCanLoadMoreFlagSaga = function* ({
	meta,
}: ReturnType<typeof channelsServerToClientActions.channelsReceived>) {
	try {
		const totalChannelCount = meta.values.channelTotal;

		if (_.isUndefined(totalChannelCount)) {
			return;
		}

		const isDefaultFilterApplied = yield* select(selectAreAllChannelsShown, undefined);
		const currentlyLoadedCount = isDefaultFilterApplied
			? yield* select(selectTotalChannels)
			: _.size(yield* select(selectChannelIdsListByFilter));

		yield* put(channelsClientOnlyActions.setCanLoadMore(currentlyLoadedCount < _.parseInt(totalChannelCount, 10)));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processCanLoadMoreFlagSaga'});
	}
};
