import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import {selectIsNetworkAvailable} from '@messenger/core/src/Redux/Client/Selectors/selectIsNetworkAvailable';

export function* processOnlineStatusChangeSaga({type}: Event) {
	try {
		const isOnline = type === 'online';
		const isNetworkAvailable = yield* select(selectIsNetworkAvailable);

		if (isNetworkAvailable !== isOnline) {
			yield* put(clientClientOnlyActions.updateNetworkStatus({isOnline, isResumable: true}));
		}
	} catch (e) {
		ServiceFactory.logService.error(e, {saga: 'processOnlineStatusChangeSaga'});
	}
}
