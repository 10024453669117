import LanguageDetector from 'i18next-browser-languagedetector';
import _ from 'lodash';

import SupportedLanguageList from '@messenger/core/src/BusinessLogic/SupportedLanguageList';

const VxLiveLanguageDetector = new LanguageDetector();

VxLiveLanguageDetector.addDetector({
	name: 'VxLiveLanguageDetector',

	lookup() {
		const found = [];

		if (typeof navigator !== 'undefined') {
			if (navigator.languages) {
				for (let i = 0; i < navigator.languages.length; i++) {
					found.push(navigator.languages[i]);
				}
			}

			if (navigator.language) {
				found.push(navigator.language);
			}
		}

		const detectedLang = found.length > 0 ? _.chain(found).head().split('-').head().value() : undefined;

		return _.includes(SupportedLanguageList, detectedLang) ? detectedLang : undefined;
	},
});

export default VxLiveLanguageDetector;
