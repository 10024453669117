import {
  CMDP_SSTARTTICKETSHOW,
  CMDP_SSTARTTICKETSHOW_RESPONSE,
  CMDP_SSTOPTICKETSHOW,
  CMDP_SSTOPTICKETSHOW_RESPONSE,
} from "./live/ticketshow";

export class CMDP_OPEN_LOBBY extends CMDP_SSTARTTICKETSHOW {}

export class CMDP_OPEN_LOBBY_RESPONSE extends CMDP_SSTARTTICKETSHOW_RESPONSE {}

export class CMDP_CLOSE_LOBBY extends CMDP_SSTOPTICKETSHOW {}

export class CMDP_CLOSE_LOBBY_RESPONSE extends CMDP_SSTOPTICKETSHOW_RESPONSE {}
