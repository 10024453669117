import {put} from 'typed-redux-saga';
import {SupportedLanguage} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {modelSettingsClientToServerActions} from '@messenger/core/src/Redux/ModelSettings/Actions/modelSettingsClientToServerActions';
import {modelSettingsClientOnlyActions} from '@messenger/core/src/Redux/ModelSettings/Actions/modelSettingsClientOnlyActions';

export const saveHeatmapSettingsSaga = function* ({
	payload: {heatmapEnabled, heatmapText},
}: ReturnType<typeof modelSettingsClientOnlyActions.saveHeatmapSettings>) {
	try {
		yield* put(modelSettingsClientToServerActions.setHeatmapEnabled(heatmapEnabled));
		yield* put(
			modelSettingsClientToServerActions.setHeatmapDescription([
				{lang: SupportedLanguage.DE, text: heatmapText[SupportedLanguage.DE]},
				{lang: SupportedLanguage.EN, text: heatmapText[SupportedLanguage.EN]},
			]),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'saveHeatmapSettingsSaga'});
	}
};
