import {call, select, put} from 'typed-redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {IMediaWebRtc} from '@messenger/core/src/BusinessLogic/IMediaWebRtc';
import ICam2CamConnectionConfig from '@messenger/core/src/Redux/Cam2CamPreview/Model';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

import {PREVIEW_FORMAT_SUFFIX} from 'src/Redux/Cam2CamPreview/Sagas/openPreviewSocketSaga';

export function* startCam2CamPreviewSaga(action: PayloadAction<ICam2CamConnectionConfig>) {
	try {
		if (action.payload.chatID && action.payload.mediaWebRtc) {
			const api = ServiceFactory.webRtcCam2camPreviewApi;
			const mediaWebRtc: IMediaWebRtc = JSON.parse(action.payload.mediaWebRtc);

			yield* call([api, api.createPreview], action.payload.chatID, mediaWebRtc);
		} else if (action.payload.chatID && action.payload.mediaJpegWsUrl) {
			const api = ServiceFactory.jpegSocketPreviewApi;

			yield* call(
				[api, api.createPreview],
				action.payload.chatID,
				action.payload.mediaJpegWsUrl + PREVIEW_FORMAT_SUFFIX,
			);
		}

		const guestIdentity = yield* select(selectCurrentGuestIdentity);

		if (
			guestIdentity?.guestType === EnumGuestType.CHAT &&
			guestIdentity.chatId === action.payload.chatID &&
			guestIdentity.channelId
		) {
			if (!ServiceFactory.uiContainer.isMobile(false)) {
				yield* put(clientClientOnlyActions.openUserInfoArea({channelId: guestIdentity.channelId}));
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'startCam2CamPreviewSaga'});
	}
}
