import _ from 'lodash';
import {channelIdFilter, EnumChannelFilterValues, getChannelsParamsType} from 'cmd-control-client-lib';

import ChannelVM from '@messenger/core/src/Redux/Channels/ChannelVM';

export default class ChannelFilterMatcher {
	constructor(
		protected readonly filter: getChannelsParamsType & channelIdFilter,
		protected readonly channelIdsWhiteList?: string[],
	) {}

	get filterUnAnswered(): EnumChannelFilterValues {
		return _.get(this, 'filter.filterUnAnswered', EnumChannelFilterValues.ANY);
	}

	isMatches(channelVM: ChannelVM): boolean {
		const isArchived = channelVM.isArchived && !channelVM.isTeamChannel;
		const isRegular = channelVM.isRegular && !channelVM.isTeamChannel;
		const isAdvertised = channelVM.isAdvertised && !channelVM.isTeamChannel;
		const isMuted = channelVM.isMuted && !channelVM.isTeamChannel;
		const isVIP = channelVM.isVIP && !channelVM.isTeamChannel;
		const isBanned = channelVM.isBanned && !channelVM.isTeamChannel;
		const isVideoChat = channelVM.isVideoChat;

		return (
			(this.channelIdsWhiteList && !_.includes(this.channelIdsWhiteList, channelVM.channelId)) ||
			(this.filter.filterArchived === EnumChannelFilterValues.EXCLUDE && isArchived) ||
			(this.filter.filterArchived === EnumChannelFilterValues.ONLY && !isArchived) ||
			(this.filter.filterRegularCustomer === EnumChannelFilterValues.EXCLUDE && isRegular) ||
			(this.filter.filterRegularCustomer === EnumChannelFilterValues.ONLY && !isRegular) ||
			(this.filter.filterVIP === EnumChannelFilterValues.EXCLUDE && isVIP) ||
			(this.filter.filterVIP === EnumChannelFilterValues.ONLY && !isVIP) ||
			(this.filter.filterMuted === EnumChannelFilterValues.EXCLUDE && isMuted) ||
			(this.filter.filterMuted === EnumChannelFilterValues.ONLY && !isMuted) ||
			(this.filter.filterAdvertised === EnumChannelFilterValues.EXCLUDE && isAdvertised) ||
			(this.filter.filterAdvertised === EnumChannelFilterValues.ONLY && !isAdvertised) ||
			(this.filter.filterUnseen === EnumChannelFilterValues.EXCLUDE && channelVM.unseenCount > 0) ||
			(this.filter.filterUnseen === EnumChannelFilterValues.ONLY && channelVM.unseenCount === 0) ||
			(this.filter.filterUnAnswered === EnumChannelFilterValues.EXCLUDE && channelVM.isUnanswered) ||
			(this.filter.filterUnAnswered === EnumChannelFilterValues.ONLY && !channelVM.isUnanswered) ||
			(this.filter.filterBanned === EnumChannelFilterValues.EXCLUDE && isBanned) ||
			(this.filter.filterBanned === EnumChannelFilterValues.ONLY && !isBanned) ||
			(this.filter.filterCanReceiveImage === EnumChannelFilterValues.ONLY && !channelVM.canReceiveImage) ||
			(this.filter.filterCanReceiveVideo === EnumChannelFilterValues.ONLY && !channelVM.canReceiveVideo) ||
			(this.filter.filterCanReceiveAudio === EnumChannelFilterValues.ONLY && !channelVM.canReceiveAudio) ||
			(this.filter.filterCanPurchaseMedia === EnumChannelFilterValues.ONLY && !channelVM.canPurchaseMedia) ||
			(this.filter.filterVideoChat === EnumChannelFilterValues.EXCLUDE && isVideoChat) ||
			(this.filter.filterVideoChat === EnumChannelFilterValues.ONLY && !isVideoChat) ||
			(!_.isEmpty(this.filter.filterContactName) &&
				_.toLower(channelVM.username).indexOf(_.toLower(this.filter.filterContactName)) === -1) ||
			(!_.isUndefined(this.filter.filterProductId) && channelVM.isTeamChannel) ||
			(!_.isUndefined(this.filter.filterProductId) && this.filter.filterProductId !== channelVM.productId)
		);
	}
}
