import {ACTION, groupIdType} from 'cmd-control-client-lib';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';

class ChannelGroupsClientToServerActions extends ClientToServerActions<EnumStore.CHANNEL_GROUPS> {
	readonly scope = EnumStore.CHANNEL_GROUPS;

	getChannelsByGroupId = this.createAction(ACTION.CMDP_SGETGROUPCHANNELS, this.getPrepareAction<groupIdType>());

	getChannelGroups = this.createAction(
		ACTION.CMDP_SGETCHANNELGROUPS,
		({groupId, channelId}: Partial<TChannelGroupRequestPayload>) => {
			let groupIdToRequest = '';

			if (!_.isUndefined(groupId)) {
				groupIdToRequest = _.isString(groupId) ? groupId : groupId.join(',');
			}

			return {
				payload: {
					channelId,
					groupId: groupIdToRequest,
				},
			};
		},
	);

	addChannelsToGroup = this.createAction(
		ACTION.CMDP_SADDCHANNELTOGROUP,
		(payload: Partial<groupIdType> & {channelIds: string[]}) => {
			return {
				payload: {
					groupId: payload.groupId,
					channelId: _.join(payload.channelIds, ','),
				},
			};
		},
	);

	removeChannelsFromGroup = this.createAction(
		ACTION.CMDP_SDELCHANNELFROMGROUP,
		(payload: Partial<groupIdType> & {channelIds: string[]}) => {
			return {
				payload: {
					groupId: payload.groupId,
					channelId: _.join(payload.channelIds, ','),
				},
			};
		},
	);
}

export type TChannelGroupRequestPayload = {
	channelId?: string;
	groupId?: string[] | string;
};

export const channelGroupsClientToServerActions = new ChannelGroupsClientToServerActions();
