import {Dictionary} from '@reduxjs/toolkit';
import _ from 'lodash';
import {call} from 'typed-redux-saga';

import {TCanPlaySound} from '@messenger/core/src/Services/AbstractUiContainer';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';

import {getSound} from 'src/BusinessLogic/SoundTracks';

const cache: Dictionary<TAudioBufferCache> = {};

type TAudioBufferCache = {
	data: Float32Array[];
	length: number;
	sampleRate: number;
	numberOfChannels: number;
};

export const playSoundSaga = function* (
	browser: TCanPlaySound,
	{payload}: ReturnType<typeof clientClientOnlyActions.playSound>,
) {
	try {
		const track = getSound(payload);
		const isSoundDebugEnabled = yield* call([ServiceFactory.store, ServiceFactory.store.get], 'SOUND_DEBUG_ENABLED', {
			storeType: EnumStoreType.LOCAL,
		});

		isSoundDebugEnabled && ServiceFactory.logService.log(new Date().getTime(), ['(0) playing ', track.mp3]);
		const context = yield* call([browser, browser.getAudioContext]);
		const cachedSound = cache[payload];

		if (_.isUndefined(cachedSound)) {
			isSoundDebugEnabled &&
				ServiceFactory.logService.log(new Date().getTime(), [track.mp3, ' (1) not in cache, fetching ...']);

			const response = yield* call(fetch, track.mp3, {cache: 'force-cache'});

			isSoundDebugEnabled && ServiceFactory.logService.log(new Date().getTime(), [track.mp3, ' (2) buffering ...']);
			const buffer = yield* call([response, response.arrayBuffer]);

			isSoundDebugEnabled && ServiceFactory.logService.log(new Date().getTime(), [track.mp3, ' (3) decoding ...']);

			yield* call([context, context.decodeAudioData], buffer, (audioBuffer: AudioBuffer) => {
				isSoundDebugEnabled &&
					ServiceFactory.logService.log(new Date().getTime(), [track.mp3, ' (4) fetched & buffered, caching ...']);

				const channelsData: TAudioBufferCache = {
					data: [],
					length: audioBuffer.length,
					sampleRate: audioBuffer.sampleRate,
					numberOfChannels: audioBuffer.numberOfChannels,
				};

				for (let j = 0; j < audioBuffer.numberOfChannels; j++) {
					const channelData = audioBuffer.getChannelData(j);

					if (_.isFunction(audioBuffer.copyFromChannel)) {
						channelsData.data[j] = new Float32Array(channelData.length);
						audioBuffer.copyFromChannel(channelsData.data[j], j);
					} else {
						channelsData.data[j] = channelData;
					}
				}

				cache[payload] = channelsData;

				isSoundDebugEnabled && ServiceFactory.logService.log(new Date().getTime(), [track.mp3, ' (5) playing ...']);

				browser.playSound(audioBuffer);
			});

			return;
		}

		isSoundDebugEnabled &&
			ServiceFactory.logService.log(new Date().getTime(), [track.mp3, ' (6) sourcing buffer ...', cachedSound]);

		const audioBuffer = yield* call(
			[context, context.createBuffer],
			cachedSound.numberOfChannels,
			cachedSound.length,
			cachedSound.sampleRate,
		);

		isSoundDebugEnabled && ServiceFactory.logService.log(new Date().getTime(), [track.mp3, ' (7) copy buffer ...']);

		for (let i = 0; i < cachedSound.numberOfChannels; i++) {
			yield* call([audioBuffer, audioBuffer.copyToChannel], cachedSound.data[i], i);
		}

		isSoundDebugEnabled && ServiceFactory.logService.log(new Date().getTime(), [track.mp3, ' (8) play ...']);

		yield* call([browser, browser.playSound], audioBuffer);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'playSoundSaga'});
	}
};
