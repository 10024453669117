import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectIsOnline} from '@messenger/core/src/Redux/Session/Selectors/selectIsOnline';
import {sessionClientToServerActions} from '@messenger/core/src/Redux/Session/Actions';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';

export const beforeLogOutSaga = function* () {
	try {
		const isOnline = yield* select(selectIsOnline);

		if (isOnline) {
			yield* put(clientClientOnlyActions.showLogoutWarning());
		} else {
			yield* put(sessionClientToServerActions.logOut());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'beforeLogOutSaga'});
	}
};
