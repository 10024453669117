import { EnumBooleanStringified } from "../@types";
import { ACTION } from "./command/action";
import { baseParamsType } from "./command/baseparams";
import { ICOMMAND, IKeyMaybeValue, IRESPONSE, RESULT } from "./command/icommand";
import { channelIdType, IMayHaveChannelId, partnerIdKeyType } from "./channel";

/** online state of session */
export type OnlineStateType = {
  /* 8230 */
  partnerId: string;
  /** model id */
  partnerKey: string;
  /** is online */
  online: EnumBooleanStringified;
  /** normal video vchat available */
  multi: EnumBooleanStringified;
  /** messaging available */
  messenger: EnumBooleanStringified;
  /** single vchat available */
  single: EnumBooleanStringified;
  /** soft vchat available */
  soft: EnumBooleanStringified;
  /** text over vchat available  */
  text: EnumBooleanStringified;
  /** preview vchat available */
  preview: EnumBooleanStringified;
  /** visbility for Content Partnter */
  visible: EnumBooleanStringified;
  /** voyeur vchat available */
  voyeur: EnumBooleanStringified;
  /** voyeur into single vchat available */
  voyeur2s: EnumBooleanStringified;
  /** lobby vchat available */
  lobby: EnumBooleanStringified;
  /** toy state */
  toy: EnumBooleanStringified;
  /** mobile video */
  mobileVideoCall?: EnumBooleanStringified;
};

/**
 * Transport command for online state
 */
export class CMDC_ONLINESTATE implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_ONLINESTATE;
  public params: OnlineStateType & channelIdType;
}

/**
 * Query online state of contact
 */
export class CMDP_GETONLINESTATE implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_GETONLINESTATE;
  public params: baseParamsType & (IMayHaveChannelId | partnerIdKeyType);
}

/**
 * Response CMDP_GETONLINESTATE
 */
export class CMDP_GETONLINESTATE_RESPONSE extends CMDP_GETONLINESTATE implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_ONLINESTATE[];
  /** unused */
  public values: IKeyMaybeValue;
}

/**
 * get all onlilne 8320 users
 */
export class CMDP_GETONLINESTATES implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_GETONLINESTATES;
  // coma-separated channelIds
  public params: baseParamsType &
    IMayHaveChannelId & {
      onlineOnly?: EnumBooleanStringified;
      mobileVideoCallOnly?: EnumBooleanStringified;
    };
}

/**
 * Response for @see CMDP_GETONLINECONTACTS
 */
export class CMDP_GETONLINESTATES_RESPONSE extends CMDP_GETONLINESTATES implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_ONLINESTATE[];
  public values: IKeyMaybeValue & {
    channelCount: string;
  };
}
