import {call, put, select, take} from 'typed-redux-saga';
import {EnumBooleanStringified, InfoWizard, ResultCode} from 'cmd-control-client-lib';
import _ from 'lodash';
import moment from 'moment';

import {updatePermanentDataSaga} from '@messenger/core/src/Redux/Session/Sagas/PermanentData/updatePermanentDataSaga';
import {selectShownInfoWizards} from '@messenger/core/src/Redux/Session/Selectors/PermanentData/selectShownInfoWizards';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {introductionClientOnlyActions} from '@messenger/core/src/Redux/Introduction/Action/IntroductionClientOnlyActions';
import {processLoginResultSaga as processLoginResultSagaCore} from '@messenger/core/src/Redux/Session/Sagas/processLoginResultSaga';
import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions/sessionServerToClientActions';
import {featureShowcaseClientOnlyActions} from '@messenger/core/src/Redux/FeatureShowcase/Actions/featureShowcaseClientOnlyActions';
import {chatsClientToServerActions} from '@messenger/core/src/Redux/Chats/Actions';
import {onlineSummaryClientToServerActions} from '@messenger/core/src/Redux/OnlineSummary/Actions/onlineSummaryClientToServerActions';
import {onlineSummaryServerToClientActions} from '@messenger/core/src/Redux/OnlineSummary/Actions/onlineSummaryServerToClientActions';
import {loadFontSizeSaga} from '@messenger/core/src/Redux/LoginForm/Sagas/loadFontSizeSaga';
import {selectIsOnline} from '@messenger/core/src/Redux/Session/Selectors/selectIsOnline';
import {selectIsLoading} from '@messenger/core/src/Redux/Session/Selectors/selectIsLoading';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {EnumClientInfoWizard} from '@messenger/core/src/Redux/FeatureShowcase/EnumClientInfoWizard';
import {TInfoWizard} from '@messenger/core/src/Redux/FeatureShowcase/TInfoWizard';
import {EnumStartStreamStep} from '@messenger/core/src/Redux/Stream/streamSlice';
import {EnumPermanentSettings} from '@messenger/core/src/Redux/Session/Model';
import {loadLastLiveSessionSaga} from '@messenger/core/src/Redux/LiveSessions/Sagas/loadLastLiveSessionSaga';
import {sessionClientOnlyActions} from '@messenger/core/src/Redux/Session/Actions';
import {selectIsStreamNeverStarted} from '@messenger/core/src/Redux/OnlineSummary/selectors';

export const processLoginResultSaga = function* (action: ReturnType<typeof sessionServerToClientActions.loggedIn>) {
	try {
		// only send on successful login
		if (action.meta.result.code !== ResultCode.OK) {
			return;
		}

		const modelId: string = action.payload.usrKey as string;
		const isStreamStarted: boolean = yield* select(selectIsOnline);
		const isStreamLoading: boolean = yield* select(selectIsLoading);

		const showWizards = (action.payload.showInfoWizards?.split(',') || []) as TInfoWizard[];
		const lastFeature = _.last(showWizards);

		if (!_.isUndefined(lastFeature)) {
			ServiceFactory.featureShowcase.lastFeature = lastFeature;
		}

		const shownInfoWizards = yield* select(selectShownInfoWizards);

		const isLegacyIntroShown = yield* call(
			[ServiceFactory.featureShowcase, ServiceFactory.featureShowcase.isLegacyIntroShown],
			modelId,
		);

		let feat = showWizards.find(
			(feat) => !_.includes(shownInfoWizards, feat) && (feat !== InfoWizard.FIRSTTIME || !isLegacyIntroShown),
		);

		// Show Momentum download feature modal if
		if (
			// no other modals
			!feat &&
			// and not used Momentum in the last 30 days
			(!_.isString(action.payload.lastMomentumUsage) ||
				moment(action.payload.lastMomentumUsage).isBefore(moment().subtract(30, 'd'))) &&
			// and with 25% chance
			Math.random() > 0.75
		) {
			feat = EnumClientInfoWizard.MOMENTUM_DOWNLOAD;
		}

		if (feat) {
			yield* put(
				feat === InfoWizard.FIRSTTIME
					? introductionClientOnlyActions.showModal()
					: featureShowcaseClientOnlyActions.start({type: feat}),
			);

			yield* call(
				updatePermanentDataSaga,
				EnumPermanentSettings.SHOWN_INFO_WIZARDS,
				_.chain([...shownInfoWizards, feat])
					.compact()
					.uniq()
					.value(),
			);
		} else if (!isStreamStarted && !isStreamLoading) {
			yield* put(streamClientOnlyActions.setStartStreamStep(EnumStartStreamStep.START_STREAM_PROMPT));
		} else if (isStreamLoading) {
			// @TODO: "CMDC_CSTARTSTREAMPUSH" should be requested to show correct message after reload
		}

		yield* call(loadLastLiveSessionSaga, {limit: ServiceFactory.env.getInitialWidgetLiveSessionsTotal()});
		yield* call(processLoginResultSagaCore, action);

		yield* put(chatsClientToServerActions.get());
		yield* put(onlineSummaryClientToServerActions.getOnlineSummary());
		yield* call(loadFontSizeSaga, action.payload.usrKey as string);

		yield* take(onlineSummaryServerToClientActions.getOnlineSummary);
		const isFirstStarted = yield* select(selectIsStreamNeverStarted);

		if (isFirstStarted) {
			yield* put(sessionClientOnlyActions.setTooltipStartOnlineVisibility(EnumBooleanStringified.TRUE));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processLoginResultSaga', action});
	}
};
