import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {TLiveSessionChats} from '@messenger/core/src/Redux/LiveSessionChats/liveSessionChatsEntityAdapter';

enum LiveSessionChatsAction {
	ADD_MANY = 'ADD_MANY',
	RESET_STORE = 'RESET_STORE',
}

class LiveSessionChatsClientOnlyActions extends ClientOnlyActions<EnumStore.LIVE_SESSION_CHATS> {
	readonly scope = EnumStore.LIVE_SESSION_CHATS;

	resetStore = this.createAction(LiveSessionChatsAction.RESET_STORE);

	addMany = this.createAction(LiveSessionChatsAction.ADD_MANY, this.getPrepareAction<TLiveSessionChats[]>());
}

export const liveSessionChatsClientOnlyActions = new LiveSessionChatsClientOnlyActions();
