import {createSlice, EntityState} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {queryClientOnlyActions} from '@messenger/core/src/Redux/QueryUser/Actions/queryClientOnlyActions';
import {queryClientToServerActions} from '@messenger/core/src/Redux/QueryUser/Actions/queryClientToServerActions';
import {queryServerToClientActions} from '@messenger/core/src/Redux/QueryUser/Actions/queryServerToClientActions';
import {queryUserAdapter} from '@messenger/core/src/Redux/QueryUser/entityAdapter';
import {TQueryUserModel} from '@messenger/core/src/Redux/QueryUser/TQueryUserModel';

export const initialState: TQueryUserState = queryUserAdapter.getInitialState({
	isLoading: false,
});

export const queryUserSlice = createSlice({
	name: EnumStore.QUERY_USER,
	initialState: initialState,
	reducers: {
		resetStore: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(queryServerToClientActions.queryReceived, queryUserAdapter.upsertOne);
		builder.addCase(queryClientOnlyActions.resetError, (state) => {
			state.error = undefined;
		});
		builder.addCase(queryClientToServerActions.queryReply, (state) => {
			state.isLoading = true;
		});
		builder.addCase(queryClientOnlyActions.setNotLoading, (state) => {
			state.isLoading = false;
		});
		builder.addCase(queryServerToClientActions.queryAnswered, (state, action) =>
			queryUserAdapter.removeOne(state, action.payload),
		);

		builder.addCase(queryServerToClientActions.queryAnswerResultReceived, (state, action) =>
			queryUserAdapter.removeOne(state, action.payload),
		);

		builder.addCase(queryClientOnlyActions.abortQuery, (state, action) =>
			queryUserAdapter.removeOne(state, action.payload),
		);
	},
});

export type TQueryUserState = EntityState<TQueryUserModel> & {
	error?: string;
	isLoading: boolean;
};
