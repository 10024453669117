import {
  ACTION,
  baseParamsType,
  ChannelMediaLinkState,
  ICOMMAND,
  IKeyMaybeValue,
  IRESPONSE,
  MayHaveChannelId,
  RESULT,
} from "../cmd-protocol";
import { channelIdType } from "./channel";
import { CMDC_CMSG, MediaFile, MediaPrice, MessageId, MessageParams, MessageReadOnlyParams } from "./message";

/**
 * Frontent command for media purchase. Will be forwarded to users pool B2B backend with additional infos
 */
export class CMDP_SMEDIAPURCHASE implements ICOMMAND {
  public action = ACTION.CMDP_SMEDIAPURCHASE;
  public params: baseParamsType &
    channelIdType &
    /** the message id of related media offer */
    MessageId &
    /** MediaPrice is added to proxy call from Cmdontrol to B2B backend */
    MediaPrice &
    /** MediaFile is added to proxy call from CmdControl to B2B backend */
    MediaFile;
}

/**
 * Response command for CMDP_SMEDIAPURCHASE.
 */
export class CMDP_SMEDIAPURCHASE_RESPONSE extends CMDP_SMEDIAPURCHASE implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

/**
 * Inform the Session about purchase
 */
export class CMDC_MEDIAPURCHASED extends CMDC_CMSG {
  public action = ACTION.CMDC_MEDIAPURCHASED;
  public params: MessageParams &
    MessageReadOnlyParams &
    MediaFile &
    MediaPrice &
    MayHaveChannelId & { linkState?: ChannelMediaLinkState } & IKeyMaybeValue;
}
