import {
	ACTION,
	EnumStreamStatusType,
	EnumBooleanStringified,
	CMDP_SDEVICESTATUS,
	ProxyStreamInfo2Type,
} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class MediaDeviceClientToServerActions extends ClientToServerActions<EnumStore.MEDIA_DEVICE> {
	readonly scope = EnumStore.MEDIA_DEVICE;

	setMicStatus = this.createAction(ACTION.CMDP_SSTATUS, (payload: {isMuted: boolean}) => ({
		payload: {
			...payload,
			type: payload.isMuted ? EnumStreamStatusType.AUDIO_DEVICE_MUTED : EnumStreamStatusType.AUDIO_DEVICE_UNMUTED,
			value: EnumBooleanStringified.TRUE,
		},
		meta: {isMicDisabled: payload.isMuted},
	}));

	setStreamInfo = this.createAction(ACTION.CMDP_SSTATUS, (payload: {info: ProxyStreamInfo2Type}) => ({
		payload: {
			info: JSON.stringify(payload.info),
			type: EnumStreamStatusType.PROXY_STREAM_INFO,
		},
	}));

	setDeviceStatus = this.createAction(ACTION.CMDP_SDEVICESTATUS, this.getPrepareAction<CMDP_SDEVICESTATUS['params']>());
}

export const mediaDeviceClientToServerActions = new MediaDeviceClientToServerActions();
