import { ComaSeparatedValues } from "./sessionstate";
import { ICOMMAND } from "./command/icommand";
import { ACTION } from "./command/action";
import { baseParamsType } from "./command/baseparams";

enum EnumFeedbackKey {
  HARASSMENT_THREATS = "harassment-threats",
  UNAUTHORIZED_CONTENT = "unauthorized-content",
  HACKED = "hacked",
  TECH = "tech",
  OTHER = "other",
}

export type Feedback = {
  login: string;
  name: string;
  email: string;
  product?: string;
  version?: string;
  shortDescription?: string;
  longDescription?: string;
  channelID?: string;
  reasons?: ComaSeparatedValues<EnumFeedbackKey> | string;
  details?: string;
};

export class CMDC_FEEDBACK implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_FEEDBACK;
  public params: {
    sessionID?: string;
    clientID?: string;
    uploadFeedbackUrl?: string;
  };
}
