import {CMDC_SEDCARDPICTURE} from 'cmd-control-client-lib';
import _ from 'lodash';

import {createEntityAdapter} from '@messenger/core/src/Utils/Redux/createEntityAdapter';

export const sedCardPictureAdapter = createEntityAdapter<TSedCardPicture>({
	selectId: (image) => (image.pictureId ? image.pictureId : _.uniqueId('old-sed-card-picture')),
});

export type TSedCardPicture = CMDC_SEDCARDPICTURE['params'];
