import {ACTION, EnumBooleanDigitized} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class QueryClientToServerActions extends ClientToServerActions<EnumStore.QUERY_USER> {
	readonly scope = EnumStore.QUERY_USER;

	queryReply = this.createAction(
		ACTION.CMDP_QUERYUSER,
		(
			key: string,
			button: EnumBooleanDigitized,
			chatIds: string[],
			queryId?: string,
			isTimeout: EnumBooleanDigitized = EnumBooleanDigitized.FALSE,
			channelId?: string,
		) => ({
			payload: {
				key,
				button,
				chatIDs: chatIds.join(','),
				queryId,
				isTimeout,
				channelId,
			},
		}),
	);
}

export const queryClientToServerActions = new QueryClientToServerActions();
