import { EnumBooleanStringified } from "../../@types";
import { ACTION } from "../command/action";
import { baseParamsType, SupportedLanguage } from "../command/baseparams";
import { ICOMMAND, IKeyMaybeValue, IRESPONSE, RESULT } from "../command/icommand";

export enum EnumService0900State {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum EnumService0900Operation {
  ENABLE = "turnOn",
  DISABLE = "turnOff",
}

/**
 * Update session state or control features
 */
export class CMDP_SUPDATE implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SUPDATE;
  public params: baseParamsType & {
    language?: SupportedLanguage;
    broadcastData?: string;
    permanentData?: string;
    service0900State?: EnumService0900State;
    livePreviewState?: EnumBooleanStringified;
    babeStation24State?: EnumBooleanStringified;
    mobileVideoCallState?: EnumBooleanStringified;
    webSIPState?: EnumBooleanStringified;
  };
}

/**
 * Response for CMDP_UPDATE
 */
export class CMDP_SUPDATE_RESPONSE extends CMDP_SUPDATE implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}
