import {createSelectorCreator, defaultMemoize} from 'reselect';
import _ from 'lodash';

import IRootState from '@messenger/core/src/Redux/IRootState';
import {selectSession} from '@messenger/core/src/Redux/Session/Selectors/selectSession';
import {default as SessionViewModel} from '@messenger/core/src/Redux/Session/ViewModel';

const selectSessionVmSelectorCreator = createSelectorCreator(defaultMemoize, _.isEqual);

/**
 * @warning: to add dependency, you should customize comparator in createSelectorCreator
 */
export const selectSessionVm = selectSessionVmSelectorCreator<
	IRootState,
	ReturnType<typeof selectSession>,
	SessionViewModel
>([selectSession], (sessionState) => new SessionViewModel(sessionState));
