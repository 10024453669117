import _ from 'lodash';
import {EnumMessageDirection, EnumMessageType} from 'cmd-control-client-lib';

import IRootState from '@messenger/core/src/Redux/IRootState';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectLiveChatMessages} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/LiveChatMessages/selectLiveChatMessages';

const getProps = (_: IRootState, props: {messageId: string}) => props;

export const selectUnseenMessagesCount = createSelector([selectLiveChatMessages, getProps], (messageVms, props) => {
	const fromIndex = _.findLastIndex(messageVms, {
		messageId: props.messageId,
	});

	return fromIndex !== -1 && fromIndex + 1 < _.size(messageVms)
		? _.chain(messageVms)
				.slice(fromIndex + 1)
				.filter(
					({direction, messageType}) => direction === EnumMessageDirection.IN && messageType === EnumMessageType.CHAT,
				)
				.size()
				.value()
		: 0;
});
