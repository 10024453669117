import {select, put, delay} from 'typed-redux-saga';
import _ from 'lodash';
import {IS_TYPING_NOTIFICATION_INTERVAL} from 'cmd-control-client-lib';

import {selectReplyMessageVm} from '@messenger/core/src/Redux/Messages/Selectors/selectReplyMessageVm';
import {channelsClientToServerActions} from '@messenger/core/src/Redux/Channels/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectAllGuestChatIds} from '@messenger/core/src/Redux/Chats/Selectors/selectAllGuestChatIds';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

export function* notifyIsTypingSaga() {
	try {
		const guestIdentity = yield* select(selectCurrentGuestIdentity);

		let payload: Parameters<typeof channelsClientToServerActions.notifyIsTyping>[0] | undefined = undefined;

		if (guestIdentity?.guestType === EnumGuestType.CHANNEL) {
			payload = {channelId: guestIdentity.channelId};
		} else if (guestIdentity?.guestType === EnumGuestType.ALL) {
			const replyMessageVM = yield* select(selectReplyMessageVm);

			if (!replyMessageVM) {
				const chatIds = yield* select(selectAllGuestChatIds);

				if (_.size(chatIds)) {
					payload = {chatIDs: _.join(chatIds, ',')};
				}
			} else if (replyMessageVM.chatId) {
				payload = {chatIDs: replyMessageVM.chatId};
			}
		} else if (guestIdentity?.guestType === EnumGuestType.CHAT) {
			payload = {chatIDs: guestIdentity.chatId};
		}

		if (payload) {
			yield* put(channelsClientToServerActions.notifyIsTyping(payload));
		}

		yield* delay(IS_TYPING_NOTIFICATION_INTERVAL);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'notifyIsTypingSaga'});
	}
}
