import {CMDC_LIVESESSION} from 'cmd-control-client-lib';
import {createSelector} from '@reduxjs/toolkit';

import IRootState from '@messenger/core/src/Redux/IRootState';
import {TDefaultSelectors} from '@messenger/core/src/Redux/TDefaultSelectors';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {liveSessionEntityAdapter} from '@messenger/core/src/Redux/LiveSessions/liveSessionEntityAdapter';
import getLiveSessionVMCached from '@messenger/core/src/Redux/LiveSessions/getLiveSessionVMCached';

export type TLiveSessionEntityId = string & {__liveSessionEntityIdBrand: any};
export const getLiveSessionIdForSelector = (liveSessionId?: string) => liveSessionId as TLiveSessionEntityId;

const {selectIds, selectById} = liveSessionEntityAdapter.getSelectors<IRootState>(
	(state) => state[EnumStore.LIVE_SESSIONS],
) as TDefaultSelectors<CMDC_LIVESESSION['params'], TLiveSessionEntityId>;

export const selectLiveSessionIds = selectIds;
export const selectLiveSessionVMById = createSelector([selectById], getLiveSessionVMCached);
