export enum EnumMediaSource {
	GALLERY = 'gallery',
	FILE_SYSTEM = 'fileSystem',
	CATALOG = 'catalog',
	MICROPHONE = 'microphone',
	CAMERA_ALL = 'cameraAll',
	CAMERA_PHOTO = 'cameraPhoto',
	CAMERA_VIDEO = 'cameraVideo',
}

export const CAMERA_MEDIA_SOURCES = [
	EnumMediaSource.CAMERA_ALL,
	EnumMediaSource.CAMERA_PHOTO,
	EnumMediaSource.CAMERA_VIDEO,
];

export type TCameraMediaSource =
	| EnumMediaSource.CAMERA_ALL
	| EnumMediaSource.CAMERA_PHOTO
	| EnumMediaSource.CAMERA_VIDEO;
export type TImageCameraMediaSource = TCameraMediaSource | EnumMediaSource.GALLERY;
export type TDeviceStorageMediaSource = TImageCameraMediaSource | EnumMediaSource.FILE_SYSTEM;
