import {takeEvery} from 'typed-redux-saga';

import {processMediaLinksReceivedSaga} from '@messenger/core/src/Redux/MediaLinks/Sagas/processMediaLinksReceivedSaga';
import {mediaServerToClientActions} from '@messenger/core/src/Redux/Media/Actions/mediaServerToClientActions';
import {mediaLinksServerToClientActions} from '@messenger/core/src/Redux/MediaLinks/Actions/mediaLinksServerToClientActions';
import {mediaClientOnlyActions} from '@messenger/core/src/Redux/Media/Actions/mediaClientOnlyActions';
import {processMediaUpsertManySaga} from '@messenger/core/src/Redux/MediaLinks/Sagas/processMediaUpsertManySaga';

import {showMediaPurchasedNotificationSaga} from 'src/Redux/MediaLinks/Sagas/showMediaPurchasedNotificationSaga';

export default function* mediaLinksSagaWatcher() {
	yield* takeEvery(mediaLinksServerToClientActions.mediaPurchased, showMediaPurchasedNotificationSaga);
	yield* takeEvery(mediaClientOnlyActions.upsertMany, processMediaUpsertManySaga);
	yield* takeEvery(mediaServerToClientActions.mediaHistoryReceived, processMediaLinksReceivedSaga);
}
