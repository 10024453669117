import {ACTION} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class TestVideoChatClientToServerActions extends ClientToServerActions<EnumStore.TEST_VIDEO_CHAT> {
	readonly scope = EnumStore.TEST_VIDEO_CHAT;

	startTestVideoChat = this.createAction(ACTION.CMDP_SSTARTTESTVIDEOCHAT);
}

export const testVideoChatClientToServerActions = new TestVideoChatClientToServerActions();
