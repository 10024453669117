import {ACTION, CMDP_GETSETTINGS_RESPONSE, CMDP_SETSETTINGS_RESPONSE} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class PushNotificationsServerToClientActions extends ServerToClientActions<EnumStore.PUSH_NOTIFICATIONS> {
	readonly scope = EnumStore.PUSH_NOTIFICATIONS;

	settingsReceived = this.createAction(
		ACTION.CMDP_GETSETTINGS,
		this.getPrepareAction<CMDP_GETSETTINGS_RESPONSE['values'], CMDP_GETSETTINGS_RESPONSE, boolean | string>(),
	);

	settingsSaved = this.createAction(
		ACTION.CMDP_SETSETTINGS,
		(payload: CMDP_SETSETTINGS_RESPONSE['values'], meta: CMDP_SETSETTINGS_RESPONSE, error?: boolean | string) => ({
			payload: {pushNotificationFilter: meta.params.pushNotificationFilter},
			meta,
			error,
		}),
	);
}

export const pushNotificationsServerToClientActions = new PushNotificationsServerToClientActions();
