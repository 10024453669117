import IRootState from '@messenger/core/src/Redux/IRootState';
import {channelGroupsEntityAdapter} from '@messenger/core/src/Redux/ChannelGroups/channelGroupsEntityAdapter';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

export const {
	selectEntities: selectChannelGroupsEntities,
	selectAll: selectAllChannelGroups,
	selectIds: selectChannelGroupsIds,
	selectById: selectChannelGroupsById,
} = channelGroupsEntityAdapter.getSelectors<IRootState>((state) => state[EnumStore.CHANNEL_GROUPS]);
