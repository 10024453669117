import {call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {sipClientOnlyActions} from '@messenger/core/src/Redux/Sip/Actions/sipClientOnlyActions';

export const hangUpCallSaga = function* (_action: ReturnType<typeof sipClientOnlyActions.hangUp>) {
	try {
		yield* call([ServiceFactory.sip, ServiceFactory.sip.hangUp]);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'hangUpCallSaga'});
	}
};
