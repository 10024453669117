import {SupportedLanguage} from 'cmd-control-client-lib';

export const getCountryByLanguage = (lang: SupportedLanguage = SupportedLanguage.DE) => {
	switch (lang) {
		case SupportedLanguage.EN:
			return 'us';

		default:
		case SupportedLanguage.ES:
		case SupportedLanguage.DE:
			return lang;
	}
};
