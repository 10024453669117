import {createSlice} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

import {channelGroupsPurchaseAbilityServerToClientActions} from './Actions/channelGroupsPurchaseAbilityServerToClientActions';
import {channelGroupsPurchaseAbilityClientToServerActions} from './Actions/channelGroupsPurchaseAbilityClientToServerActions';
import {channelGroupsPurchaseAbilityClientOnlyActions} from './Actions/channelGroupsPurchaseAbilityClientOnlyActions';

export enum EnumCanPurchase {
	ALL = 'ALL',
	SOME = 'SOME',
	NO_ONE = 'NO_ONE',
}
export const initialState: TChannelGroupsPurchaseAbilityState = {
	isLoading: false,
	canReceiveAttachment: EnumCanPurchase.ALL,
	canPurchase: EnumCanPurchase.ALL,
	hasPurchased: EnumCanPurchase.NO_ONE,
	canNotPurchaseChannels: [],
	canNotReceiveChannels: [],
	channelCount: 0,
	canReceiveChannels: [],
	canPurchaseChannels: [],
};

export const channelGroupsPurchaseAbilitySlice = createSlice({
	name: EnumStore.CHANNEL_GROUPS_PURCHASE_ABILITY,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(channelGroupsPurchaseAbilityClientToServerActions.checkCanReceiveAttachment, (state) => {
			state.isLoading = true;
		});
		builder.addCase(channelGroupsPurchaseAbilityServerToClientActions.checkCanReceiveAttachmentReceived, (state) => {
			state.isLoading = false;
		});
		builder.addCase(channelGroupsPurchaseAbilityClientOnlyActions.resetStore, () => {
			return initialState;
		});
		builder.addCase(channelGroupsPurchaseAbilityClientOnlyActions.change, (state, action) => {
			state.canNotPurchaseChannels = action.payload.canNotPurchaseChannels;
			state.canNotReceiveChannels = action.payload.canNotReceiveChannels;
			state.hasPurchased = action.payload.hasPurchased;
			state.canPurchase = action.payload.canPurchase;
			state.canReceiveAttachment = action.payload.canReceiveAttachment;
			state.channelCount = action.payload.channelCount;
			state.canReceiveChannels = action.payload.canReceiveChannels;
			state.canPurchaseChannels = action.payload.canPurchaseChannels;
		});
	},
});

export type TChannelGroupsPurchaseAbility = {
	canNotPurchaseChannels: string[];
	canNotReceiveChannels: string[];
	hasPurchased: EnumCanPurchase;
	canPurchase: EnumCanPurchase;
	canReceiveAttachment: EnumCanPurchase;
};

export type TChannelGroupsPurchaseAbilityState = TChannelGroupsPurchaseAbility & {
	isLoading: boolean;
	channelCount: number;
	canReceiveChannels: string[];
	canPurchaseChannels: string[];
};
