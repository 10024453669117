import {delay, put, race, select, take} from 'typed-redux-saga';
import _ from 'lodash';
import {Action} from 'redux';
import {IS_TYPING_WAIT_INTERVAL} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TSessionUpdatedAction} from '@messenger/core/src/Redux/Session/Actions';
import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions';
import {chatsClientOnlyActions} from '@messenger/core/src/Redux/Chats/Actions';
import {selectIsChannelTypingById} from '@messenger/core/src/Redux/Channels/Selectors/selectIsChannelTypingById';
import {selectIsChatTypingById} from '@messenger/core/src/Redux/Chats/Selectors/selectIsChatTypingById';

export const processIsTypingSaga = function* (hasChats: boolean, action: TSessionUpdatedAction) {
	try {
		if (!action.payload.proxyStateInfo?.isTyping) {
			return;
		}

		const channelId = _.get(action.payload, 'channelId');
		const chatId = _.get(action.payload, 'chatID');

		if (!channelId && !hasChats) {
			return;
		}

		if (chatId) {
			const isChatTyping = yield* select(selectIsChatTypingById, {chatId});

			if (!isChatTyping) {
				yield* put(chatsClientOnlyActions.addTypingChat(chatId));
			}
		} else if (channelId) {
			const isChannelTyping = yield* select(selectIsChannelTypingById, {channelId});

			if (!isChannelTyping) {
				yield* put(channelsClientOnlyActions.addTypingChannel(channelId));
			}
		}

		const [finishedTyping] = yield* race([
			delay(IS_TYPING_WAIT_INTERVAL),
			take((newAction: Action) => _.isMatch(newAction, action)),
		]);

		if (_.isUndefined(finishedTyping)) {
			return;
		}

		if (chatId) {
			yield* put(chatsClientOnlyActions.removeTypingChat(chatId));
		} else if (channelId) {
			yield* put(channelsClientOnlyActions.removeTypingChannel(channelId));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processIsTypingSaga'});
	}
};
