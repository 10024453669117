import {put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';

export const resetSupportedCodecsSaga = function* () {
	try {
		yield* put(mediaDeviceClientOnlyActions.resetSupportedCodecs());
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'resetSupportedCodecsSaga'});
	}
};
