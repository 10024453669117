import {createEntityAdapter} from '@messenger/core/src/Utils/Redux/createEntityAdapter';

export const mediaUploadProgressAdapter = createEntityAdapter<TMediaUploadProgressEntity>({
	selectId: (mediaUploadProgress: TMediaUploadProgressEntity) => mediaUploadProgress.messageId,
});

export type TMediaUploadProgressEntity = {
	progress: number;
	messageId: string;
};
