import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {
	TAvailableMediaDevices,
	TChosenMediaDevices,
	TChosenMediaDevicesInfo,
	TRequestMediaDevicePermissionsPayload,
	TRequestMediaDevicePermissionsReceived,
	TSetChosenMediaDevicesPayload,
	TSupportedCodecs,
} from '@messenger/core/src/Redux/MediaDevice/mediaDeviceSlice';

enum EnumActions {
	REQUEST_DEVICES_LIST = 'REQUEST_DEVICES_LIST',
	SET_SHOULD_RESTART_STREAM = 'SET_SHOULD_RESTART_STREAM',
	REQUEST_MICROPHONE_PERMISSION = 'REQUEST_MICROPHONE_PERMISSION',
	REQUEST_MEDIA_DEVICE_PERMISSIONS = 'REQUEST_MEDIA_DEVICE_PERMISSIONS',
	REQUEST_MEDIA_DEVICE_PERMISSIONS_RECEIVED = 'REQUEST_MEDIA_DEVICE_PERMISSIONS_RECEIVED',
	REQUEST_MEDIA_DEVICE_PERMISSIONS_FAILED = 'REQUEST_MEDIA_DEVICE_PERMISSIONS_FAILED',
	REQUEST_MIC_PERMISSION_FAILED = 'REQUEST_MIC_PERMISSION_FAILED',
	RELEASE_MEDIA_DEVICE_PERMISSIONS = 'RELEASE_MEDIA_DEVICE_PERMISSIONS',
	SET_CHOSEN_MEDIA_DEVICES = 'SET_CHOSEN_MEDIA_DEVICES',
	SET_CHOSEN_MEDIA_DEVICES_TO_STORE = 'SET_CHOSEN_MEDIA_DEVICES_TO_STORE',
	SET_CHOSEN_MEDIA_DEVICES_FROM_STORE = 'SET_CHOSEN_MEDIA_DEVICES_FROM_STORE',
	SET_CHOSEN_MEDIA_DEVICES_INFO_FROM_STORE = 'SET_CHOSEN_MEDIA_DEVICES_INFO_FROM_STORE',
	SET_AVAILABLE_MEDIA_DEVICES = 'SET_AVAILABLE_MEDIA_DEVICES',
	RESET_AVAILABLE_MEDIA_DEVICES = 'RESET_AVAILABLE_MEDIA_DEVICES',
	SET_IS_MIC_DISABLED = 'SET_IS_MIC_DISABLED',
	SET_SUPPORTED_CODECS = 'SET_SUPPORTED_CODECS',
	RESET_SUPPORTED_CODECS = 'RESET_SUPPORTED_CODECS',
	RESET_CHOSEN_MEDIA_DEVICES_INFO = 'RESET_CHOSEN_MEDIA_DEVICES_INFO',
}

class MediaDeviceClientOnlyActions extends ClientOnlyActions<EnumStore.MEDIA_DEVICE> {
	readonly scope = EnumStore.MEDIA_DEVICE;

	requestMediaDevicesList = this.createAction(EnumActions.REQUEST_DEVICES_LIST);
	requestMicrophonePermission = this.createAction(
		EnumActions.REQUEST_MICROPHONE_PERMISSION,
		this.getPrepareAction<{forVideo?: boolean} | undefined>(),
	);
	setShouldRestartStream = this.createAction(EnumActions.SET_SHOULD_RESTART_STREAM, this.getPrepareAction<boolean>());
	requestMediaDevicePermissions = this.createAction(
		EnumActions.REQUEST_MEDIA_DEVICE_PERMISSIONS,
		this.getPrepareAction<TRequestMediaDevicePermissionsPayload>(),
	);
	requestMediaDevicePermissionsReceived = this.createAction(
		EnumActions.REQUEST_MEDIA_DEVICE_PERMISSIONS_RECEIVED,
		this.getPrepareAction<TRequestMediaDevicePermissionsReceived>(),
	);
	requestMediaDevicePermissionsFailed = this.createAction(
		EnumActions.REQUEST_MEDIA_DEVICE_PERMISSIONS_FAILED,
		this.getPrepareAction<string>(),
	);
	requestMicPermissionFailed = this.createAction(
		EnumActions.REQUEST_MIC_PERMISSION_FAILED,
		this.getPrepareAction<string>(),
	);
	releaseMediaDevicePermissions = this.createAction(EnumActions.RELEASE_MEDIA_DEVICE_PERMISSIONS);
	setChosenMediaDevices = this.createAction(
		EnumActions.SET_CHOSEN_MEDIA_DEVICES,
		this.getPrepareAction<TSetChosenMediaDevicesPayload>(),
	);
	setChosenMediaDevicesFromStore = this.createAction(
		EnumActions.SET_CHOSEN_MEDIA_DEVICES_FROM_STORE,
		this.getPrepareAction<TChosenMediaDevices>(),
	);
	setChosenMediaDevicesInfoFromStore = this.createAction(
		EnumActions.SET_CHOSEN_MEDIA_DEVICES_INFO_FROM_STORE,
		this.getPrepareAction<TChosenMediaDevicesInfo>(),
	);
	setChosenMediaDevicesToStore = this.createAction(EnumActions.SET_CHOSEN_MEDIA_DEVICES_TO_STORE);
	setAvailableMediaDevices = this.createAction(
		EnumActions.SET_AVAILABLE_MEDIA_DEVICES,
		this.getPrepareAction<TAvailableMediaDevices>(),
	);
	resetAvailableMediaDevices = this.createAction(EnumActions.RESET_AVAILABLE_MEDIA_DEVICES);
	setIsMicDisabled = this.createAction(EnumActions.SET_IS_MIC_DISABLED, this.getPrepareAction<boolean>());
	setSupportedCodecs = this.createAction(EnumActions.SET_SUPPORTED_CODECS, this.getPrepareAction<TSupportedCodecs>());
	resetSupportedCodecs = this.createAction(EnumActions.RESET_SUPPORTED_CODECS);
	resetChosenMediaDevicesInfo = this.createAction(EnumActions.RESET_CHOSEN_MEDIA_DEVICES_INFO);
}

export const mediaDeviceClientOnlyActions = new MediaDeviceClientOnlyActions();
