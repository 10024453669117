import cuid from 'cuid';
import _ from 'lodash';
import {Channel, channel, END} from 'redux-saga';
import {call, take} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {
	EnumAbstractNotificationCloseReason,
	EnumAbstractNotificationVariant,
} from '@messenger/core/src/Services/UINotification';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';

enum EnumToastEvents {
	CLOSED = 'toast-closed',
}

export const notifyModelAboutSendingReplyToChannelSaga = function* ({
	payload: replyMessageVM,
}: ReturnType<typeof messagesClientOnlyActions.notifyModelAboutSendingReply>) {
	const toastChannel = (yield* call(channel)) as Channel<
		{type: EnumToastEvents; payload: EnumAbstractNotificationCloseReason} | END
	>;

	ServiceFactory.notifications.enqueue({
		key: cuid.slug(),
		buttonText: ServiceFactory.i18n.t('notification:show-btn'),
		variant: EnumAbstractNotificationVariant.INFO,
		duration: ServiceFactory.env.getSnackTimeout(),
		text: ServiceFactory.i18n.t('notification:reply-is-sent-to-channel', {
			userName: replyMessageVM.userName,
		}),
		onClose: (event, reason) => {
			if (!_.isUndefined(toastChannel)) {
				toastChannel.put({type: EnumToastEvents.CLOSED, payload: reason});
				toastChannel.put(END);
			}
		},
	});

	const closedAction = yield* take(toastChannel, EnumToastEvents.CLOSED);

	if (replyMessageVM.channelId && closedAction.payload === EnumAbstractNotificationCloseReason.USER) {
		const {goToChannelMessages} = yield* getNavigationContext();

		yield* call(goToChannelMessages, replyMessageVM.channelId);
	}
};
