import _ from 'lodash';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import {TChannelsMap, TChannelsMeta} from '@messenger/core/src/Redux/ChannelsMap/types';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

enum ChannelsMapActions {
	REQUEST_CHANNELS_RANGE = 'REQUEST_CHANNELS_RANGE',
	REQUEST_CHANNELS_MAP_BY_WEIGHT = 'REQUEST_CHANNELS_MAP_BY_WEIGHT',
	SET_CURRENT_RANGE = 'SET_CURRENT_RANGE',
	INCREASE_LOADING_COUNTER = 'INCREASE_LOADING_COUNTER',
	DECREASE_LOADING_COUNTER = 'DECREASE_LOADING_COUNTER',
	SET_TOTAL = 'SET_TOTAL',
	SET_CHANNELS_META = 'SET_CHANNELS_META',
	SET_SELECTED_INDEX = 'SET_SELECTED_INDEX',
	PROCESS_CHANNEL_SELECTION = 'PROCESS_CHANNEL_SELECTION',
	PROCESS_CHAT_SELECTION = 'PROCESS_CHAT_SELECTION',
	START_CHANNELS_MAP_UPDATE = 'START_CHANNELS_MAP_UPDATE',
	END_CHANNELS_MAP_UPDATE = 'END_CHANNELS_MAP_UPDATE',
	CLEAR_CHANNELS_MAP_UPDATE = 'CLEAR_CHANNELS_MAP_UPDATE',
	REFRESH_CURRENT_RANGE = 'REFRESH_CURRENT_RANGE',
	CHANNELS_MAP_SAVED = 'CHANNELS_MAP_SAVED',
	ADD_PENDING_CHANNEL_IDS = 'ADD_PENDING_CHANNEL_IDS',
	RESET_PENDING_CHANNEL_IDS = 'RESET_PENDING_CHANNEL_IDS',
	UPSERT_MANY = 'UPSERT_MANY',
	REMOVE_MANY = 'REMOVE_MANY',
	REMOVE_ONE = 'REMOVE_ONE',
	REMOVE_ALL = 'REMOVE_ALL',
	ADD_MANY = 'ADD_MANY',
}

class ChannelsMapClientOnlyActions extends ClientOnlyActions<EnumStore.CHANNELS_MAP> {
	readonly scope = EnumStore.CHANNELS_MAP;

	refreshCurrentRange = this.createAction(ChannelsMapActions.REFRESH_CURRENT_RANGE);

	setCurrentRange = this.createAction(
		ChannelsMapActions.SET_CURRENT_RANGE,
		this.getPrepareAction<{startIndex: number; endIndex: number}>(),
	);

	processChannelSelection = this.createAction(
		ChannelsMapActions.PROCESS_CHANNEL_SELECTION,
		this.getPrepareAction<{channelId: string}>(),
	);

	processChatSelection = this.createAction(
		ChannelsMapActions.PROCESS_CHAT_SELECTION,
		this.getPrepareAction<{chatId: string}>(),
	);

	startChannelsMapUpdate = this.createAction(
		ChannelsMapActions.START_CHANNELS_MAP_UPDATE,
		({channelIds}: {channelIds: string[]}) => {
			return {
				payload: {
					channelIds,
					updateUuid: _.uniqueId(),
				},
			};
		},
	);

	endChannelsMapUpdate = this.createAction(
		ChannelsMapActions.END_CHANNELS_MAP_UPDATE,
		this.getPrepareAction<{updateUuid: string}>(),
	);

	clearChannelsMapUpdate = this.createAction(ChannelsMapActions.CLEAR_CHANNELS_MAP_UPDATE);

	channelsMapSaved = this.createAction(ChannelsMapActions.CHANNELS_MAP_SAVED);

	increaseLoadingCounter = this.createAction(ChannelsMapActions.INCREASE_LOADING_COUNTER);

	decreaseLoadingCounter = this.createAction(ChannelsMapActions.DECREASE_LOADING_COUNTER);

	setSelectedIndex = this.createAction(
		ChannelsMapActions.SET_SELECTED_INDEX,
		this.getPrepareAction<{index: number; type: EnumGuestType} | undefined>(),
	);

	setTotal = this.createAction(ChannelsMapActions.SET_TOTAL, this.getPrepareAction<{total?: number}>());

	requestChannelsMap = this.createAction(
		ChannelsMapActions.REQUEST_CHANNELS_RANGE,
		({startIndex, endIndex}: {startIndex: number; endIndex: number}) => {
			return {
				payload: {
					skip: String(startIndex),
					limit: String(endIndex - startIndex + 1),
				},
			};
		},
	);

	setChannelsMeta = this.createAction(
		ChannelsMapActions.SET_CHANNELS_META,
		this.getPrepareAction<{channelsMeta: TChannelsMeta}>(),
	);

	requestChannelsMapByWeight = this.createAction(
		ChannelsMapActions.REQUEST_CHANNELS_MAP_BY_WEIGHT,
		({maxWeight, limit = 1}: {maxWeight: string; limit?: number}, meta?: {selectChannelId?: string}) => {
			return {
				payload: {
					filterMaxWeight: maxWeight,
					limit: String(limit),
				},
				meta: meta,
			};
		},
	);

	addPendingChannelIds = this.createAction(
		ChannelsMapActions.ADD_PENDING_CHANNEL_IDS,
		this.getPrepareAction<{channelIds: string[]}>(),
	);

	resetPendingChannelIds = this.createAction(ChannelsMapActions.RESET_PENDING_CHANNEL_IDS);

	upsertMany = this.createAction(ChannelsMapActions.UPSERT_MANY, this.getPrepareAction<TChannelsMap[]>());

	removeMany = this.createAction(ChannelsMapActions.REMOVE_MANY, this.getPrepareAction<number[]>());

	removeOne = this.createAction(ChannelsMapActions.REMOVE_ONE, this.getPrepareAction<number>());

	removeAll = this.createAction(ChannelsMapActions.REMOVE_ALL);

	addMany = this.createAction(ChannelsMapActions.ADD_MANY, this.getPrepareAction<TChannelsMap[]>());
}

export const channelsMapClientOnlyActions = new ChannelsMapClientOnlyActions();
