import {select, call} from 'typed-redux-saga';
import md5 from 'md5';

import {selectLoginCredentials} from '@messenger/core/src/Redux/LoginForm/Selectors/selectLoginCredentials';
import {selectShouldRemember} from '@messenger/core/src/Redux/LoginForm/Selectors/selectShouldRemember';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';
import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';

export const rememberCredentialsIfNeededSaga = function* () {
	try {
		// do we need to remember?
		const shouldRemember: boolean = yield* select(selectShouldRemember);
		const store = ServiceFactory.store;

		yield* call([store, store.set], EnumLocalStorageKeys.REMEMBER_ME, shouldRemember);

		const {login, password} = yield* select(selectLoginCredentials);

		const credentialStorage = shouldRemember ? EnumStoreType.LOCAL : EnumStoreType.SESSION;

		yield* call([store, store.set], EnumLocalStorageKeys.LOGIN, login, {storeType: credentialStorage});

		if (password) {
			yield* call([store, store.set], EnumLocalStorageKeys.PASSWORD_HASH, md5(password), {
				storeType: credentialStorage,
			});
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'rememberCredentialsIfNeededSaga'});
	}
};
