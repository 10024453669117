import {CMDC_SEDCARDPICTURE} from 'cmd-control-client-lib';

import {ClientOnlyActions, TMultiEntityActionMeta} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum EnumSedCardPictureActions {
	ADD_MANY = 'ADD_MANY',
	RESET_STORE = 'RESET_STORE',
}

class SedCardPictureClientOnlyActions extends ClientOnlyActions<EnumStore.SED_CARD_PICTURE> {
	readonly scope = EnumStore.SED_CARD_PICTURE;

	addMany = this.createAction(
		EnumSedCardPictureActions.ADD_MANY,
		this.getPrepareAction<CMDC_SEDCARDPICTURE['params'][], TMultiEntityActionMeta<CMDC_SEDCARDPICTURE>>(),
	);

	resetStore = this.createAction(EnumSedCardPictureActions.RESET_STORE);
}

export const sedCardPictureClientOnlyActions = new SedCardPictureClientOnlyActions();
