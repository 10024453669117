import {createSelector} from '@messenger/core/src/Utils/Redux';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {TGuestIdentity} from '@messenger/core/src/Types/IGuest';

import {_selectCurrentGuestType} from './_selectCurrentGuestType';
import {_selectCurrentGuestChannelId} from './_selectCurrentGuestChannelId';
import {_selectCurrentGuestChatId} from './_selectCurrentGuestChatId';

export const selectCurrentGuestIdentity = createSelector(
	[_selectCurrentGuestType, _selectCurrentGuestChatId, _selectCurrentGuestChannelId],
	(guestType, chatId, channelId): TGuestIdentity | undefined => {
		if (guestType === EnumGuestType.ALL) {
			return {guestType};
		} else if (guestType === EnumGuestType.BULK) {
			return {guestType};
		} else if (guestType === EnumGuestType.CHAT && chatId) {
			return {guestType, chatId, channelId};
		} else if (guestType === EnumGuestType.CHANNEL && channelId) {
			return {guestType, channelId};
		}

		return undefined;
	},
);
