import {ACTION} from 'cmd-control-client-lib';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';

class SedCardPictureClientToServerActions extends ClientToServerActions<EnumStore.SED_CARD_PICTURE> {
	readonly scope = EnumStore.SED_CARD_PICTURE;

	getSedCardPicture = this.createAction(ACTION.CMDP_SGETSEDCARDPICTURES);
}

export const sedCardPictureClientToServerActions = new SedCardPictureClientToServerActions();
