import _ from 'lodash';
import {call, put, select} from 'typed-redux-saga';

import {loginFormClientOnlyActions} from '@messenger/core/src/Redux/LoginForm/Actions/loginFormClientOnlyActions';
import {currencyClientOnlyActions} from '@messenger/core/src/Redux/Currency/Actions/currencyClientOnlyActions';
import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {processTranslationsSaga} from '@messenger/core/src/Redux/Session/Sagas/processTranslationsSaga';
import {processAutoLoginSaga} from '@messenger/core/src/Redux/Session/Sagas/processAutoLoginSaga';
import {setDefaultCredentialsSaga} from '@messenger/core/src/Redux/LoginForm/Sagas/setDefaultCredentialsSaga';
import {mediaClientOnlyActions} from '@messenger/core/src/Redux/Media/Actions/mediaClientOnlyActions';
import {feedbackClientOnlyActions} from '@messenger/core/src/Redux/Feedback/Actions/feedbackClientOnlyActions';
import {reactionsClientOnlyActions} from '@messenger/core/src/Redux/Reactions/Actions/reactionsClientOnlyActions';
import {resetAllStores} from '@messenger/core/src/Redux/Reducers';
import {selectShouldAutoLogin} from '@messenger/core/src/Redux/LoginForm/Selectors/selectShouldAutoLogin';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';

export const processInitResultsSaga = function* (action: ReturnType<typeof sessionServerToClientActions.initialized>) {
	try {
		yield* put(clientClientOnlyActions.setIsConnected());
		const isAutoLogin = yield* select(selectShouldAutoLogin);

		if (isAutoLogin) {
			// @NOTE After auto login we reset whole redux store except data that are not received from backend
			// as we don't know what data could be updated during the time the session was down
			yield* put(resetAllStores({keepLocalStores: true}));
		}

		if (_.has(action, 'payload.gtcUrl')) {
			yield* put(loginFormClientOnlyActions.setGtcUrl(_.get(action, 'payload.gtcUrl')));
		} else {
			ServiceFactory.logService.error('Gts url is not set', {saga: 'processInitResultsSaga'});
		}

		yield* call(setDefaultCredentialsSaga);
		yield* call(processAutoLoginSaga);

		if (!_.isUndefined(action.payload.uploadMediaUrl)) {
			yield* put(mediaClientOnlyActions.setMediaUploadUrl(action.payload.uploadMediaUrl));
		} else {
			ServiceFactory.logService.error('Upload media url is not set', {saga: 'processInitResultsSaga'});
		}

		if (_.has(action, 'payload.reactions')) {
			try {
				yield* put(reactionsClientOnlyActions.setReactions(JSON.parse(_.get(action, 'payload.reactions'))));
			} catch (error) {
				ServiceFactory.logService.error(error, {saga: 'processInitResultsSaga'});
			}
		} else {
			ServiceFactory.logService.error('Reactions list is not set', {saga: 'processInitResultsSaga'});
		}

		if (_.has(action, 'payload.uploadFeedbackUrl')) {
			yield* put(
				feedbackClientOnlyActions.setUploadFeedbackUrl({uploadFeedbackUrl: String(action.payload.uploadFeedbackUrl)}),
			);
		} else {
			ServiceFactory.logService.error('Upload feedback url is not set', {saga: 'processInitResultsSaga'});
		}

		if (_.has(action, 'payload.vxc_factor')) {
			yield* put(currencyClientOnlyActions.setCoins2EuroFactor(Number(_.get(action, 'payload.vxc_factor'))));
		} else {
			ServiceFactory.logService.error('Upload feedback url is not set', {saga: 'processInitResultsSaga'});
		}

		yield* call(processTranslationsSaga, action);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processInitResultsSaga'});
	}
};
