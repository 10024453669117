import {call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export const reInitVControlSaga = function* () {
	try {
		const api = ServiceFactory.getVControlApi();

		yield* call([api, api.closeConnection]);
		yield* call([api, api.connect]);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'reInitVControlSaga'});
	}
};
