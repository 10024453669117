import {takeEvery} from 'typed-redux-saga';

import {feedbackClientOnlyActions} from '@messenger/core/src/Redux/Feedback/Actions/feedbackClientOnlyActions';
import {routingClientOnlyActions} from '@messenger/core/src/Redux/Routing/Actions/routingClientOnlyActions';

import {updateStateOnChatNavigationSaga} from 'src/Redux/Routing/Sagas/updateStateOnChatNavigationSaga';
import {closeModalSaga} from 'src/Redux/Routing/Sagas/closeModalSaga';
import {handleNotFoundSaga} from 'src/Redux/Routing/Sagas/handleNotFoundSaga';
import {navigateToChannelOrChatSaga} from 'src/Redux/Routing/Sagas/navigateToChannelOrChatSaga';

import {navigateToChannelSaga} from './Sagas/navigateToChannelSaga';

function* routingSagaWatcher() {
	yield* takeEvery(routingClientOnlyActions.updateStateAfterNavigation, updateStateOnChatNavigationSaga);
	yield* takeEvery(routingClientOnlyActions.navigateChatOrChannel, navigateToChannelOrChatSaga);
	yield* takeEvery(routingClientOnlyActions.navigateToChannel, navigateToChannelSaga);
	yield* takeEvery(feedbackClientOnlyActions.success, closeModalSaga);
	yield* takeEvery('*', handleNotFoundSaga);
}

export default routingSagaWatcher;
