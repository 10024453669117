export const EDITOR_MAX_LENGTH = 1000;

export const UPLOAD_FILESIZE_LIMIT = 500 * 1024 * 1024;

export const TV_ADMIN_CHANNEL_ID_PARTIAL = '8083.552542';

export const MAX_PART_LENGTH_REPLY_MESSAGE = 400;

export const FIELD_SAVING_INTERVAL = 1000;

export const SPEEDTEST_FAST_CONNECTION = 3000;

export const SPEEDTEST_SLOW_CONNECTION = 1000;

// used for mobile only
export const MAX_RECENT_EMOJI = 40;

export const USER_MENU_ONLINE_SUMMARY_REFRESH_INTERVAL = 60000;

export const LIVE_SESSIONS_PAGE_SIZE = 25;

export const PRICES_PRECISION = 2;
