import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {selectChannelGroupEntityVms} from '@messenger/core/src/Redux/ChannelGroups/Selectors/selectChannelGroupEntityVms';
import ChannelGroupVM from '@messenger/core/src/Redux/ChannelGroups/channelGroupVM';

export const selectChannelGroupVms = createSelector(
	selectChannelGroupEntityVms,
	(channelGroupEntityVMs): ChannelGroupVM[] => _.chain(channelGroupEntityVMs).values().compact().value(),
);
