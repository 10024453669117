import _ from 'lodash';
import {call, put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';
import EnumChatFontSize from '@messenger/core/src/BusinessLogic/EnumChatFontSize';

export function* loadFontSizeSaga(modelId: string) {
	try {
		const api = ServiceFactory.store;
		const storageKey = `${EnumLocalStorageKeys.CHAT_FONT_SIZE}:${modelId}`;
		const storedChatFontSize = yield* call([api, api.get], storageKey, {storeType: EnumStoreType.LOCAL});

		if (_.includes(_.values(EnumChatFontSize), storedChatFontSize)) {
			yield* put(clientClientOnlyActions.loadChatFontSizeFromStore(storedChatFontSize));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'loadFontSizeSaga'});
	}
}
