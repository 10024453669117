import _ from 'lodash';
import {Action, Middleware} from 'redux';
import {PayloadAction} from '@reduxjs/toolkit';
import {ACTION, ICOMMAND} from 'cmd-control-client-lib';

import EnumActionRole from '@messenger/core/src/BusinessLogic/EnumActionRole';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectIsServerDisabled} from '@messenger/core/src/Redux/Client/Selectors/selectIsServerDisabled';

type TWithSocketAction<T> = PayloadAction<T, ACTION, {originalType: ACTION}> & {
	socket?: boolean;
};

const middleware: Middleware =
	(store) => (next) => (action: TWithSocketAction<ICOMMAND['params']> | (Action<ACTION> & ICOMMAND['params'])) => {
		const isClientToServerAction = _.get(action, 'meta.role') === EnumActionRole.CLIENT_TO_SERVER;
		const disabled = selectIsServerDisabled(store.getState());
		const api = ServiceFactory.getVControlApi(disabled);

		if (!!action.socket && !isClientToServerAction) {
			api.send({
				action: action.type,
				...(_.omit(action, ['socket', '_iid', 'type']) as Omit<ICOMMAND, 'action'>),
			});
		}

		if (isClientToServerAction) {
			api.send({
				action: _.get(action, 'meta.originalType'),
				params: _.isUndefined(action.payload) ? {} : (action.payload as ICOMMAND['params']),
			});
		}

		next(action);
	};

export default middleware;
