import {call, put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import {notificationsClientOnlyActions} from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';

export const notifyOnUploadFailedSaga = function* () {
	yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
		text: ServiceFactory.i18n.t('messages:upload-failed'),
		variant: EnumAbstractNotificationVariant.ERROR,
		key: EnumSnackbarNotificationKeys.UPLOAD_ERROR,
	});

	yield* put(notificationsClientOnlyActions.closeSnackbar(EnumSnackbarNotificationKeys.PROCESSING_BULK_MESSAGE_SENT));
};
