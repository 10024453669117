import { ICOMMAND, ACTION, IRESPONSE, RESULT, IKeyMaybeValue } from "../../cmd-protocol";
import { baseParamsType } from "../command/baseparams";

/**
 * update media device infos
 */
export class CMDP_SDEVICESTATUS implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SDEVICESTATUS;
  public params: baseParamsType & {
    camName?: string;
    width?: number;
    height?: number;
    fps?: number;
    microName?: string;
    muted?: boolean;
    volume?: number;
    channels?: number;
    sampleRate?: number;
    sampleSize?: number;
    bandWidth?: number;
    cpuLoad?: number;
  };
}

/**
 * reponse.
 */
export class CMDP_SDEVICESTATUS_RESPONSE extends CMDP_SDEVICESTATUS implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}
