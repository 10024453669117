import 'reflect-metadata';
import {call} from 'typed-redux-saga';
import {container} from 'tsyringe';

import DIToken from '@messenger/core/src/BusinessLogic/DIToken';

import {ShortcutsService} from 'src/Services/ShortcutsService';

export const restartShortcutsProcessingSaga = function* () {
	const shortcuts = (yield* call([container, 'resolve'], DIToken.shortcutsService)) as ShortcutsService;

	yield* call([shortcuts, 'start']);
};
