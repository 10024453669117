import {ACTION, CMDP_SGETUPLOADEDMEDIA2_RESPONSE} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class MediaServerToClientActions extends ServerToClientActions<EnumStore.MEDIA> {
	readonly scope = EnumStore.MEDIA;

	mediaHistoryReceived = this.createAction(
		ACTION.CMDP_SGETUPLOADEDMEDIA2,
		(
			payload: CMDP_SGETUPLOADEDMEDIA2_RESPONSE['values'],
			meta: CMDP_SGETUPLOADEDMEDIA2_RESPONSE,
			error?: string | boolean,
		) => ({
			payload,
			meta,
			error,
		}),
	);
}

export const mediaServerToClientActions = new MediaServerToClientActions();
