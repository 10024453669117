import _ from 'lodash';
import {EnumCurrency, EnumMediaType, InitialCurrencies, PriceInstruction} from 'cmd-control-client-lib';

import {TEntityPrice} from '@messenger/core/src/Redux/Currency/Selectors/selectPriceFormatted';
import {IMediaPrice} from '@messenger/core/src/Redux/MediaPrice/IMediaPrice';
import AbstractMediaViewModel from '@messenger/core/src/Redux/MediaPrice/AbstractMediaViewModel';

class MediaPricesViewModel extends AbstractMediaViewModel {
	protected mediaPrice: Record<EnumMediaType, PriceInstruction | undefined>;
	readonly currency: EnumCurrency;
	readonly currencySymbol: string;
	readonly precision: number;

	constructor(
		protected mediaInfo: Partial<IMediaPrice>,
		protected currenciesList: InitialCurrencies,
		protected mediaType?: EnumMediaType,
	) {
		super();
		this.mediaPrice = {
			[EnumMediaType.AUDIO]: mediaInfo.audioPrices,
			[EnumMediaType.VIDEO]: mediaInfo.videoPrices,
			[EnumMediaType.BITMAP]: mediaInfo.imagePrices,
			[EnumMediaType.TICKET_SHOW]: mediaInfo.ticketPrices?.ticketShow.price,
		};

		this.currency = mediaInfo.currency ?? EnumCurrency.VX_COIN;
		this.currencySymbol = _.get(currenciesList, [`${this.currency}`, 'symbol'], '');
		this.precision =
			_.chain(currenciesList)
				.get([`${this.currency}`, 'formatJS', 'maximumFractionDigits'])
				.value() || 0;
	}

	getMin(mediaType = EnumMediaType.BITMAP, fallback = 1): TEntityPrice {
		const value = this.mediaPrice[mediaType]?.min || fallback;

		return {
			price: _.isString(value) ? parseFloat(value) : value,
			currency: this.currency,
		};
	}

	getMax(mediaType = EnumMediaType.BITMAP, fallback = 150): TEntityPrice {
		const value = this.mediaPrice[mediaType]?.max || fallback;

		return {
			price: _.isString(value) ? parseFloat(value) : value,
			currency: this.currency,
		};
	}

	getDefault(mediaType = EnumMediaType.BITMAP, fallback = 0): TEntityPrice {
		const value = this.mediaPrice[mediaType]?.default || fallback;

		return {
			price: _.isString(value) ? parseFloat(value) : value,
			currency: this.currency,
		};
	}

	getPredefinedList(mediaType: EnumMediaType): number[] {
		return _.chain(this.mediaPrice[mediaType]?.pick)
			.map((value) => _.toNumber(value || 0))
			.unshift(0)
			.value();
	}

	isValid(price: number, mediaType: EnumMediaType = EnumMediaType.BITMAP) {
		if (_.isNaN(price) || !_.isFinite(price)) {
			return false;
		}

		const isInRage = price >= this.getMin(mediaType).price && price <= this.getMax(mediaType).price;

		return isInRage || price === 0;
	}
}

export default MediaPricesViewModel;
