import { ChatState } from "./chatstate";
import { SessionState } from "./sessionstate";
import { ACTION } from "./command/action";
import { ICOMMAND } from "./command/icommand";
import { JSONString } from "../@types";

export enum EnumStreamOrientation {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export enum EnumStreamingPlatform {
  ANDROID = "android",
  IOS = "ios",
}

export enum EnumCameraType {
  FRONT = "front",
  BACK = "back",
}

export type ProxyStreamInfo2Type = {
  orientation: EnumStreamOrientation;
  angle?: number;
  platform?: EnumStreamingPlatform;
  cameraType?: EnumCameraType;
};

export type proxyStreamInfo = {
  proxyStreamInfo?: "horizontal" | "vertical" | string;
  proxyStreamInfo2?: JSONString<ProxyStreamInfo2Type>;
};

export type proxyStateInfo = {
  proxyStateInfo?: string;
  channelId?: string;
  chatID?: string;
};

export const IS_TYPING_NOTIFICATION_INTERVAL = 5 * 1000;
export const IS_TYPING_WAIT_INTERVAL = IS_TYPING_NOTIFICATION_INTERVAL + 1000;
/**
 * Update command
 */
export class CMDC_UPDATE implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_UPDATE;
  public params: ChatState | SessionState | proxyStreamInfo | proxyStateInfo;
}
