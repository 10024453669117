import {call} from 'typed-redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';

import {TSswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {startTicketShowSaga} from '@messenger/web/src/Redux/TicketShow/Sagas/startTicketShowSaga';
import {soonStartTicketShowSaga} from '@messenger/web/src/Redux/TicketShow/Sagas/soonStartTicketShowSaga';

const h_ticket_show_start = 'h_ticket_show_start';
const h_ticket_show_reminder_text = 'h_ticket_show_reminder_text';

export const checkStartTicketShowSaga = function* (action: PayloadAction<TSswMessageType>) {
	try {
		const messageKey = action.payload.messageKey;

		switch (messageKey) {
			case h_ticket_show_start:
				yield* call(startTicketShowSaga);
				break;
			case h_ticket_show_reminder_text:
				yield* call(soonStartTicketShowSaga);
				break;
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'checkStartTicketShowSaga', action});
	}
};
