import {call, put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';
import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';
import {toyClientOnlyActions} from '@messenger/core/src/Redux/Toy/Actions/toyClientOnlyActions';

export const loadMaxIntensitySaga = function* () {
	try {
		const store = ServiceFactory.store;
		const maxIntensity = yield* call([store, store.get], EnumLocalStorageKeys.VIBRATE_MAX_INTENSITY, {
			storeType: EnumStoreType.LOCAL,
		});

		if (maxIntensity && typeof maxIntensity === 'number' && maxIntensity >= 0 && maxIntensity <= 1) {
			yield* put(toyClientOnlyActions.setMaxIntensity(maxIntensity * 100));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'saveMaxIntensitySaga'});
	}
};
