import moment from 'moment';
import {END, eventChannel} from 'redux-saga';
import {call, put, select, spawn, take} from 'typed-redux-saga';

import {selectSpeedTestMeasured} from '@messenger/core/src/Redux/SpeedTest/Selectors/selectSpeedTestMeasured';
import {selectSpeedTestStartTime} from '@messenger/core/src/Redux/SpeedTest/Selectors/selectSpeedTestStartTime';
import {selectSpeedTestTime} from '@messenger/core/src/Redux/SpeedTest/Selectors/selectSpeedTestTime';
import {speedTestClientOnlyActions} from '@messenger/core/src/Redux/SpeedTest/Actions/speedTestClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {processTestRequestSaga} from '@messenger/core/src/Redux/SpeedTest/Sagas/processTestRequestSaga';
import {selectSpeedTestUrl} from '@messenger/core/src/Redux/SpeedTest/Selectors/selectSpeedTestUrl';

/**
 * @link https://stackoverflow.com/a/38574266/1847769
 * @link https://redux-saga.js.org/docs/advanced/Channels.html
 */
export const startSpeedTestSaga = function* () {
	try {
		const speedTestUrl = yield* select(selectSpeedTestUrl);

		if (!speedTestUrl) {
			yield* put(speedTestClientOnlyActions.setError(ServiceFactory.i18n.t('notification:speedtest-failed')));

			return;
		}

		const started = yield* select(selectSpeedTestStartTime);
		const seconds = yield* select(selectSpeedTestTime);
		const speedTestCountDownChannel = yield* call(speedTestCountDownChannelCreator, seconds, started);

		while (true) {
			// take(END) will cause the saga to terminate by jumping to the finally block
			const remainingMs = yield* take(speedTestCountDownChannel);

			yield* spawn(processTestRequestSaga, remainingMs);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'SaveTestMeta'});
	} finally {
		// mark complete
		const measured = yield* select(selectSpeedTestMeasured);

		yield* put(speedTestClientOnlyActions.markProgress({progress: 100, measured}));
		yield* put(speedTestClientOnlyActions.markEnd());
	}
};

function speedTestCountDownChannelCreator(secs: number, start: number) {
	const end = moment(start).add(secs, 'seconds');

	return eventChannel<number | END>((emitter) => {
		const iv = setInterval(() => {
			const now = moment();

			if (now < end) {
				emitter(end.diff(now, 'milliseconds'));
			} else {
				emitter(END); // terminate
			}
		}, ServiceFactory.env.getSpeedTestInterval());

		// The subscriber must return an unsubscribe function
		return () => clearInterval(iv);
	});
}
