import {PrepareAction, Update} from '@reduxjs/toolkit';

import IChat from '@messenger/core/src/Redux/Chats/Model';

export const prepareChatUpdatePayload: PrepareAction<Update<IChat>> = (payload: IChat, meta, error) => ({
	payload: {
		id: payload.chatID,
		changes: payload,
	},
	meta: meta,
	error: error,
});
