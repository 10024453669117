import {ChannelMediaLinkState, EnumCurrency, EnumMediaType, UploadedMediaDirection} from 'cmd-control-client-lib';

import {TEntityPrice} from '@messenger/core/src/Redux/Currency/Selectors/selectPriceFormatted';

import {TMediaLink} from './mediaLinkEntityAdapter';

export const MEDIA_LINK_FIELDS = [
	'channelId',
	'currency',
	'linkDate',
	'linkState',
	'mediaPrice',
	'mediaMd5',
	'mediaType',
];

export class MediaLinkVm implements TEntityPrice {
	channelId: string;
	mediaMd5: string;
	mediaType: EnumMediaType;
	linkState: ChannelMediaLinkState;
	linkDate: string;
	direction?: UploadedMediaDirection;
	currency: EnumCurrency;
	price: number;
	isSent: boolean;
	isSold: boolean;

	constructor({mediaPrice, mediaType, mediaMd5, linkState, currency, direction, channelId, linkDate}: TMediaLink) {
		this.channelId = channelId;
		this.mediaMd5 = String(mediaMd5);
		this.mediaType = mediaType as EnumMediaType.BITMAP;
		this.linkState = linkState;
		this.linkDate = linkDate;
		this.direction = direction || UploadedMediaDirection.OUT;
		this.currency = currency || EnumCurrency.VX_COIN;
		this.price = mediaPrice ? parseFloat(mediaPrice) : 0;

		this.isSent = this.linkState === ChannelMediaLinkState.FREE;
		this.isSold = this.linkState === ChannelMediaLinkState.PAID;
	}
}
