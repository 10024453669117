import {EnumCurrency, EnumTicketShowStatusType, ticketShow} from 'cmd-control-client-lib';

class TicketShowVM {
	readonly showId: string;
	readonly showImageUrl?: string;
	readonly pictureId?: string;
	readonly showPrice: number;
	readonly showStart: Date;
	readonly showEnd: Date;
	readonly showName: string;
	readonly showSales: number;
	readonly titleDE: string;
	readonly titleEN: string;
	readonly descriptionDE: string;
	readonly descriptionEN: string;
	readonly showCurrency: EnumCurrency;
	readonly status: EnumTicketShowStatusType | string;
	readonly isDeleted: boolean;
	readonly isActive: boolean;
	readonly isCancelled: boolean;
	readonly hasNoSales: boolean;

	constructor(liveTicketShow: ticketShow) {
		this.showImageUrl = liveTicketShow.showImageUrl;
		this.pictureId = liveTicketShow.pictureId;
		this.showPrice = parseFloat(liveTicketShow.showPrice.replace(',', '.'));
		this.showStart = new Date(liveTicketShow.showStart);
		this.showEnd = new Date(liveTicketShow.showEnd);
		this.showId = liveTicketShow.showId;
		this.showName = liveTicketShow.showName;
		this.showSales = parseInt(liveTicketShow.showSales, 10);
		this.titleDE = liveTicketShow.titleDE;
		this.titleEN = liveTicketShow.titleEN;
		this.descriptionDE = liveTicketShow.descriptionDE;
		this.descriptionEN = liveTicketShow.descriptionEN;
		this.showCurrency = liveTicketShow.showCurrency;
		this.showImageUrl = liveTicketShow.showImageUrl;
		this.status = liveTicketShow.status;
		this.isDeleted = this.status === EnumTicketShowStatusType.DELETED;
		this.isActive = this.status === EnumTicketShowStatusType.OK;
		this.isCancelled = this.status === EnumTicketShowStatusType.CANCELLED;
		this.hasNoSales = this.showSales === 0;
	}
}

export default TicketShowVM;
