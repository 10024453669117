import {call, put, select} from 'typed-redux-saga';

import {selectSpeedTestRequests} from '@messenger/core/src/Redux/SpeedTest/Selectors/selectSpeedTestRequests';
import {selectSpeedTestProgress} from '@messenger/core/src/Redux/SpeedTest/Selectors/selectSpeedTestProgress';
import {selectSpeedTestTime} from '@messenger/core/src/Redux/SpeedTest/Selectors/selectSpeedTestTime';
import {speedTestClientOnlyActions} from '@messenger/core/src/Redux/SpeedTest/Actions/speedTestClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectSpeedTestUrl} from '@messenger/core/src/Redux/SpeedTest/Selectors/selectSpeedTestUrl';

export const processTestRequestSaga = function* (remainingMs: number) {
	try {
		const speedTestUrl = yield* select(selectSpeedTestUrl);

		if (!speedTestUrl) {
			return;
		}

		const speedTestApi = ServiceFactory.speedTestApi;

		yield* call([speedTestApi, speedTestApi.sendRequest], speedTestUrl);

		// calculate new progress value
		const totalSeconds: number = yield* select(selectSpeedTestTime);
		const totalTimeMs = totalSeconds * 1000;
		const progress: number = yield* select(selectSpeedTestProgress);
		const currentMs = totalTimeMs - remainingMs;
		const newProgress = (currentMs / totalTimeMs) * 100;

		// calculate measured
		const requests: number = yield* select(selectSpeedTestRequests);
		const measured = ((((requests * speedTestApi.payloadSize) / currentMs) * 1000) / 1024) * 8;

		// only change if grows
		if (newProgress > progress) {
			yield* put(speedTestClientOnlyActions.markProgress({progress: newProgress, measured}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processTestRequestSaga'});
	}
};
