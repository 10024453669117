import { EnumBooleanStringifiedExtended } from "../@types";
import { channelIdType } from "./channel";
import { ACTION } from "./command/action";
import { baseParamsType } from "./command/baseparams";
import { ICOMMAND, IKeyMaybeValue, IRESPONSE, RESULT } from "./command/icommand";
import { MediaFile, MediaMd5, MediaPrice, EnumMediaType, EnumMediaState } from "./message";
import { ComaSeparatedValues } from "./sessionstate";

export type MayHaveChannelId = {
  channelId?: string;
};

export enum ChannelMediaLinkState {
  NONE = "NONE",
  FREE = "FREE",
  OFFER = "OFFER",
  PAID = "PAID",
  EXPIRED = "EXPIRED",
  CLOSED = "CLOSED",
}

export enum UploadedMediaDirection {
  IN = "in",
  OUT = "out",
  ANY = "any",
}

/**
 * media info, may have a channel id
 */
export class CMDC_CMEDIA implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_CMEDIA;
  public params: MediaFile &
    MayHaveChannelId & {
      linkState?: ChannelMediaLinkState;
      linkDate?: string;
      direction?: UploadedMediaDirection;
    } & MediaPrice;
}

/**
 * check for media already was uploaded.
 * mediaMd5 - comaseparated media md5
 * channelId - comaseparated channelId
 */
export class CMDP_SCHECKUPLOADEDMEDIA implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SCHECKUPLOADEDMEDIA;
  public params: baseParamsType & MediaMd5 & MayHaveChannelId;
}

/**
 *  media infos, if any found in cache
 */
export class CMDP_SCHECKUPLOADEDMEDIA_RESPONSE extends CMDP_SCHECKUPLOADEDMEDIA implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_CMEDIA[];
  public values: IKeyMaybeValue;
}

/** get list of all uploaded media ordered by upliad date descending, may have comaseparated channel id for filter */
export class CMDP_SGETUPLOADEDMEDIA implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SGETUPLOADEDMEDIA;
  public params: baseParamsType &
    MayHaveChannelId & {
      mediaState?: EnumMediaState;
      mediaType?: ComaSeparatedValues<EnumMediaType> | EnumMediaType;
      linkState?: ComaSeparatedValues<ChannelMediaLinkState> | ChannelMediaLinkState;
      skip?: string;
      limit?: string;
      direction?: UploadedMediaDirection;
      /** get only mediaMd5, channelId, linkState, linkDate, and cut off all mediaXXX fields, default is false */
      dropMediaFields?: EnumBooleanStringifiedExtended;
      countOnly?: EnumBooleanStringifiedExtended;
    };
}

export class CMDP_SGETUPLOADEDMEDIA_RESPONSE extends CMDP_SGETUPLOADEDMEDIA implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_CMEDIA[];
  public values: IKeyMaybeValue;
}

/** get list of all uploaded media ordered by upliad date descending, may have one channel id for filter */
export class CMDP_SGETUPLOADEDMEDIA2 implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SGETUPLOADEDMEDIA2;
  public params: baseParamsType &
    MayHaveChannelId & {
      mediaState?: EnumMediaState;
      mediaType?: ComaSeparatedValues<EnumMediaType> | EnumMediaType;
      linkState?: ComaSeparatedValues<ChannelMediaLinkState> | ChannelMediaLinkState;
      skip?: string; // default 0
      limit?: string; // default 50
      direction?: UploadedMediaDirection;
      /** get only mediaMd5, channelId, linkState, linkDate and cut off all mediaXXX fields, default is false */
      dropMediaFields?: EnumBooleanStringifiedExtended;
      sortBy?: "linkDate" | "mediaCreated" | string;
    };
}

export class CMDP_SGETUPLOADEDMEDIA2_RESPONSE extends CMDP_SGETUPLOADEDMEDIA2 implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_CMEDIA[];
  public values: IKeyMaybeValue;
}

//** summary channel media */
export class CMDC_CHANNELMEDIASUMMARY implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_CHANNELMEDIASUMMARY;
  public params: channelIdType & {
    outAll: string;
    outSold: string;
    outOffer: string;
    outVideo: string;
    outAudio: string;
    outBitmap: string;
    inAll: string;
    inSold: string;
    inOffer: string;
    inVideo: string;
    inAudio: string;
    inBitmap: string;
  };
}

/** query media summary for channels  */
export class CMDP_SGETCHANNELDMEDIASUMMARY implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SGETCHANNELDMEDIASUMMARY;
  public params: baseParamsType & channelIdType;
}

/**
 * response
 */
export class CMDP_SGETCHANNELDMEDIASUMMARY_RESPONSE extends CMDP_SGETCHANNELDMEDIASUMMARY implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_CHANNELMEDIASUMMARY[];
  public values: IKeyMaybeValue;
}
