import {put, select, delay} from 'typed-redux-saga';

import {selectIsWebRtcOutputStreamActive} from '@messenger/core/src/Redux/Stream/Selectors/selectIsWebRtcOutputStreamActive';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumStreamOrientation} from '@messenger/core/src/Services/WebRtc/EnumStreamOrientation';
import {mediaDeviceClientToServerActions} from '@messenger/core/src/Redux/MediaDevice/Actions';

export const ORIENTATION_CHANGE_DELAY = 500;

export const deviceOrientationSaga = function* () {
	try {
		const isWebRtcStream = yield* select(selectIsWebRtcOutputStreamActive);

		if (!isWebRtcStream) {
			return;
		}

		//there is a some delay between orientationchange event and stream orientation change on ipad
		yield* delay(ORIENTATION_CHANGE_DELAY);

		const {videoTrackSettings} = ServiceFactory.webRtcApi;

		const streamOrientation =
			videoTrackSettings &&
			videoTrackSettings.width &&
			videoTrackSettings.height &&
			videoTrackSettings.width <= videoTrackSettings.height
				? EnumStreamOrientation.VERTICAL
				: EnumStreamOrientation.HORIZONTAL;

		yield* put(mediaDeviceClientToServerActions.setStreamInfo({info: {orientation: streamOrientation}}));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'deviceOrientationSaga'});
	}
};
