import cuid from 'cuid';
import {call, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import {selectPinnedFromSummary} from '@messenger/core/src/Redux/Channels/Selectors/selectPinnedFromSummary';

export const notifyIfPinnedLimitReachedSaga = function* () {
	try {
		const pinnedCountFromSummary = yield* select(selectPinnedFromSummary);

		if (pinnedCountFromSummary > ServiceFactory.env.maxPinnedChannelLimit()) {
			yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
				text: ServiceFactory.i18n.t('notification:pins-limit', {pin: ServiceFactory.env.maxPinnedChannelLimit()}),
				variant: EnumAbstractNotificationVariant.WARNING,
				key: cuid(),
			});
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'notifyIfPinnedLimitReachedSaga'});
	}
};
