import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum Cam2camPreviewAction {
	SET_PRIMARY_CAM_2_CAM_ID = 'SET_PRIMARY_CAM_2_CAM_ID',
	RESET_STORE = 'RESET_STORE',
}

class Cam2camPreviewClientOnlyActions extends ClientOnlyActions<EnumStore.CAM_2_CAM_PREVIEW> {
	readonly scope = EnumStore.CAM_2_CAM_PREVIEW;

	setPrimaryCam2CamId = this.createAction(
		Cam2camPreviewAction.SET_PRIMARY_CAM_2_CAM_ID,
		this.getPrepareAction<string | undefined>(),
	);

	resetStore = this.createAction(Cam2camPreviewAction.RESET_STORE);
}

export const cam2camPreviewClientOnlyActions = new Cam2camPreviewClientOnlyActions();
