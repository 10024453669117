import {call} from 'typed-redux-saga';

import {chatsClientOnlyActions} from '@messenger/core/src/Redux/Chats/Actions';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';

export function* processGroupChatToggleSaga({
	payload,
}: ReturnType<typeof chatsClientOnlyActions.setIsGroupChatExpanded>) {
	if (!payload) {
		const {goToChatMessages} = yield* getNavigationContext();

		yield* call(goToChatMessages, ALL_CHATS);
	}
}
