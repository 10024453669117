import {select, put} from 'typed-redux-saga';

import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectUnseenMessagesCount} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/ChatMessages/selectUnseenMessagesCount';

export function* markChatMessageSeenSaga({
	payload: messageId,
}: ReturnType<typeof messagesClientOnlyActions.markChatMessageSeen>) {
	try {
		const unseenMessagesCount = yield* select(selectUnseenMessagesCount, {messageId});

		yield* put(messagesClientOnlyActions.setUnseenChatMessagesCount(unseenMessagesCount));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'markChatMessageSeenSaga'});
	}
}
