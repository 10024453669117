import 'reflect-metadata';
import {ICOMMAND, IRESPONSE, SupportedLanguage, UploadMediaTus} from 'cmd-control-client-lib';
import {container, inject, singleton} from 'tsyringe';
import type {Dispatch} from 'redux';

import AbstractVControlApi, {TSendMediaParams} from '@messenger/core/src/Services/VControl/AbstractVControlApi';
import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import ILocalFile from '@messenger/core/src/Redux/Media/ILocalFile';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

@singleton()
class VControlFakerApi extends AbstractVControlApi {
	constructor(
		@inject(DIToken.reduxDispatch) dispatch: Dispatch,
		@inject(DIToken.currentLanguage) private _language: SupportedLanguage,
	) {
		super(dispatch);
	}

	changeLanguage(language: SupportedLanguage) {
		this._language = language;
	}

	isConnected(): boolean {
		return true;
	}

	isOpened(): boolean {
		return true;
	}

	connect() {
		ServiceFactory.logService.debug('FAKE CONNECT');

		return this;
	}

	resume() {
		ServiceFactory.logService.debug('FAKE RESUME');
	}

	pause() {
		ServiceFactory.logService.debug('FAKE PAUSE');
	}

	get language() {
		return this._language;
	}

	send(command: ICOMMAND) {
		ServiceFactory.logService.debug('FAKE COMMAND: ', command);

		return this;
	}

	dispatchResponse(response: IRESPONSE): void {
		ServiceFactory.logService.debug(response);
	}

	sendMedia(uploadUrl: string, file: File | ILocalFile, params: TSendMediaParams, tusConfig?: UploadMediaTus): void {
		new Error('Not supported in faker implementation.');
	}

	submitFeedbackForm(uploadFeedbackUrl: string, formData: FormData) {
		return new Promise<{success?: boolean; error?: unknown}>((_resolve, reject) =>
			reject('Not implemented in faker. Form data: ' + JSON.stringify(formData)),
		);
	}

	closeConnection(): void {
		ServiceFactory.logService.debug('closeConnection');
	}
}

container.register(DIToken.VControlFakerApi, {useToken: VControlFakerApi});

export default VControlFakerApi;
