import {MSettingNameEnum, MSettings, ResultCode} from 'cmd-control-client-lib';
import _ from 'lodash';
import {call, put, select, take} from 'typed-redux-saga';

import {
	modelSettingsClientOnlyActions,
	TVideoChatSettings,
} from '@messenger/core/src/Redux/ModelSettings/Actions/modelSettingsClientOnlyActions';
import {modelSettingsClientToServerActions} from '@messenger/core/src/Redux/ModelSettings/Actions/modelSettingsClientToServerActions';
import {selectModelSettingsViewModel} from '@messenger/core/src/Redux/ModelSettings/Selectors/selectModelSettingsViewModel';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {modelSettingsServerToClientActions} from '@messenger/core/src/Redux/ModelSettings/Actions/modelSettingsServerToClientActions';
import {
	EnumAbstractNotificationVariant,
	TAbstractNotificationOptions,
} from '@messenger/core/src/Services/UINotification';

export const saveVideoChatSettingsSaga = function* ({
	payload,
}: ReturnType<typeof modelSettingsClientOnlyActions.saveVideoChatSettings>) {
	const priceDict: Record<keyof TVideoChatSettings, MSettingNameEnum> = {
		messengerPrice: MSettingNameEnum.MESSENGER_PRICE,
		videoChatPrice: MSettingNameEnum.VIDEO_CHAT_PRICE,
		privateChatPrice: MSettingNameEnum.PRIVATE_CHAT_PRICE,
		voyeurChatPrice: MSettingNameEnum.VOYEUR_CHAT_PRICE,
		singleC2CChatPrice: MSettingNameEnum.PRIVATE_C2C_CHAT_PRICE,
	};

	try {
		const mSettingsVm = yield* select(selectModelSettingsViewModel);
		const mSettingsParsedFields = mSettingsVm.parsedFields;

		if (_.isUndefined(mSettingsParsedFields)) {
			return;
		}

		const overrides = _.toPairs(payload).reduce((changedPrices: MSettings['fields'], [key, value]) => {
			const priceType = priceDict[key as keyof TVideoChatSettings];

			const initialConfig = mSettingsParsedFields[priceType];

			if (!_.isUndefined(initialConfig)) {
				return {...changedPrices, [priceType]: {...initialConfig, value}};
			}

			return changedPrices;
		}, {});

		yield* put(modelSettingsClientToServerActions.saveModelSettings({...mSettingsParsedFields, ...overrides}));

		const {meta} = yield* take(modelSettingsServerToClientActions.setModelSettingsResponseReceived);

		let notification: TAbstractNotificationOptions | undefined;

		if (meta.result.code !== ResultCode.OK) {
			notification = {
				text: ServiceFactory.i18n.t('notification:prices-updated-error'),
				variant: EnumAbstractNotificationVariant.ERROR,
				key: 'notification:prices-updated-error',
			};
		} else {
			notification = {
				text: ServiceFactory.i18n.t('notification:prices-updated-success'),
				variant: EnumAbstractNotificationVariant.SUCCESS,
				key: 'notification:prices-updated-success',
			};
		}

		yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], notification);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'saveVideoChatSettingsSaga'});
	}
};
