import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectReplyMessageId} from '@messenger/core/src/Redux/Client/Selectors/selectReplyMessageId';
import {selectMessageVms} from '@messenger/core/src/Redux/Messages/Selectors/messagesDefaultSelectors';

export const selectReplyMessageVm = createSelector(
	[selectMessageVms, selectReplyMessageId],
	(messageVMs, messageId) => {
		if (_.isNull(messageId)) {
			return undefined;
		}

		return _.find(messageVMs, {messageId});
	},
);
