import { IProductConfig } from "./live/products-config";
import { EnumService0900State } from "./live/supdate";
import { IKeyMaybeValue } from "./command/icommand";
import { SupportedLanguage } from "./command/baseparams";
import { EnumBooleanStringified, JSONString, EnumBooleanStringifiedExtended } from "../@types";

export enum EnumSetTp {
  OFFLINE = "0",
  ONLINE = "1",
  GOING_ONLINE = "2",
}

export enum EnumFsk {
  SIXTEEN = "16",
  EIGHTEEN = "18",
}

export enum SessionFlag {
  MASTER = "MASTER",
  B2B = "B2B",
  FSK16 = "FSK16",
  FSK18 = "FSK18",
  BLOCKFSK16 = "BLOCKFSK16",
  FSKMASK = "FSKMASK", // FSK16 | FSK18,
  JWT = "JWT",
  MSN = "MSN",
  SHARED = "SHARED",
  ADMIN = "ADMIN",
  HASFSK18CHAT = "HASFSK18CHAT",
  /**
   * used only for avs moderator sessions
   */
  WEBCAMAVS = "WEBCAMAVS",
  FREEMODE = "FREEMODE", // SEXOLE only feature
  PARTYMODE = "PARTYMODE", // SEXOLE only feature
  BBS24 = "BBS24", // Exclusive babestation24 modus
  LOBBY_OPENED = "LOBBY_OPENED", // when lobby is started
  MOBILEVIDEOCALL = "MOBILEVIDEOCALL", // mobile video call is enabled
  WEBSIP = "WEBSIP", // websip is enabled
}

export enum InfoWizard {
  FIRSTTIME = "FIRST_TIME",
  CONTENTSELL = "CONTENT_SELL",
  CONTENTSELLMULTI = "CONTENT_SELL_MULTI",
  TICKETSHOW = "TICKET_SHOW",
  CUSTOMTAGS = "CUSTOM_TAGS",
}
/**
 * @remarks String having <T>'s separated by ','
 */
export type ComaSeparatedValues<T extends string> = T | string;

/* session state */
export interface SessionState extends IKeyMaybeValue {
  /** the session mnumber */
  sessionID: string;
  /** loginname, deprecated, use CMDP_GETUSERINFO */
  login: string;
  /** start time */
  startTime: string;
  /* deprecated */
  online?: EnumBooleanStringified;
  /* 0 offline, 1 online, 2 going online */
  setTp?: EnumSetTp;
  flags?: ComaSeparatedValues<SessionFlag> | SessionFlag;
  /** en de */
  language?: SupportedLanguage;
  /** unused */
  broadcastData?: string;
  /** Used for client-side short-cuts (live) */
  permanentData?: string;
  /** avatar  */
  imgSrc?: string;
  /** FSKSTATE */
  fsk?: EnumFsk;
  /** SoftChat */
  fsk16?: EnumBooleanStringifiedExtended;
  /** deprecated, use service0900State*/
  service0900state?: EnumService0900State;
  /** service 0900 state, if missing in LOGIN values, the service is not available */
  service0900State?: EnumService0900State;
  /** the live preview*/
  livePreviewState?: EnumBooleanStringified;
  /** only exclusive stream available*/
  showBabeStation24Tab?: EnumBooleanStringified;
  babeStation24State?: EnumBooleanStringified;
  /* the mobile video calls*/
  showMobileVideoCallTab?: EnumBooleanStringified;
  mobileVideoCallState?: EnumBooleanStringified;
  mobileVideoCallRun?: EnumBooleanStringified;
  mobileVideoCallChannelId?: string;
  /** ticket show allowed*/
  canOpenLobby?: EnumBooleanStringified;
  /** web sip allowed*/
  webSIPState?: EnumBooleanStringified;
  userLinks?: JSONString<IProductConfig["links"]>;
  chatMotto?: string;
  showInfoWizards?: ComaSeparatedValues<InfoWizard> | string;
  /** last MomentumChat usage Date*/
  lastMomentumUsage?: string;
}
