import {createSelector, Dictionary} from '@reduxjs/toolkit';
import _ from 'lodash';

import ChannelGroupVM from '@messenger/core/src/Redux/ChannelGroups/channelGroupVM';
import {selectChannelGroupsEntities} from '@messenger/core/src/Redux/ChannelGroups/Selectors/channelGroupsDefaultSelectors';
import {selectChannelInfoEntityVms} from '@messenger/core/src/Redux/ChannelInfos/Selectors/channelInfosDefaultSelectors';

export const selectChannelGroupEntityVms = createSelector(
	[selectChannelGroupsEntities, selectChannelInfoEntityVms],
	(channelGroupEntities, channelInfoVMEntities): Dictionary<ChannelGroupVM> =>
		_.mapValues(channelGroupEntities, (channelGroupEntity) =>
			_.isUndefined(channelGroupEntity)
				? undefined
				: new ChannelGroupVM(
						channelGroupEntity,
						_.get(channelInfoVMEntities, [channelGroupEntity.channelId, 'username']),
				  ),
		),
);
