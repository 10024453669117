import { ACTION } from "./command/action";
import { ICOMMAND } from "./command/icommand";

/**
 * internal commando used for maintenance of CmdControl-Server-Farm
 */
export class CMDC_DSRELOAD implements ICOMMAND {
  public action = ACTION.CMDC_DSRELOAD;
  public params: {
    id: string;
    host: string;
    PlatformUrl: string;
    wsshost: string;
    wssport: string;
    wsspath: string;
  };
}
