import {call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {sipClientOnlyActions} from '@messenger/core/src/Redux/Sip/Actions/sipClientOnlyActions';

export const sendToneSaga = function* ({payload}: ReturnType<typeof sipClientOnlyActions.sendTone>) {
	try {
		yield* call([ServiceFactory.sip, ServiceFactory.sip.sendTone], payload);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'sendToneSaga'});
	}
};
