import {takeEvery} from 'typed-redux-saga';

import {chatSummaryClientOnlyActions} from '@messenger/core/src/Redux/ChatSummary/Actions/chatSummaryClientOnlyActions';
import {fetchChatSummarySaga} from '@messenger/core/src/Redux/ChatSummary/Sagas/fetchChatSummarySaga';

function* chatSummarySagaWatcher() {
	yield* takeEvery(chatSummaryClientOnlyActions.getChatSummary, fetchChatSummarySaga);
}

export default chatSummarySagaWatcher;
