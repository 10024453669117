import { EnumBooleanStringifiedExtended } from "../../@types";
import { ACTION } from "../command/action";
import { ICOMMAND } from "../command/icommand";

export enum EnumStreamQuality {
  NONE = "NONE",
  EXCELLENT = "EXCELLENT",
  GOOD = "GOOD",
  BAD = "BAD",
}

/**
 * Update incoming stream state.
 * Server inform you about the state of incoming video audio stream.
 */
export class CMDC_STREAMSTATE implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_STREAMSTATE;
  public params: {
    /*json datetime */
    startTime?: string;
    bandWith?: string;
    quality?: EnumStreamQuality;
    videoWidth?: string;
    videoHeight?: string;
    videoFps?: string;
    hasAudio?: EnumBooleanStringifiedExtended;
    audioMuted?: EnumBooleanStringifiedExtended;
    /* deprecated*/
    bandwith?: string;
    /* deprecated*/
    videoHeigth?: string;
  };
}
