import {put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {liveSessionsClientToServerActions} from '@messenger/core/src/Redux/LiveSessions/Actions/liveSessionsClientToServerActions';
import {liveSessionsClientOnlyActions} from '@messenger/core/src/Redux/LiveSessions/Actions/liveSessionsClientOnlyActions';

export const loadLiveSessionsByDateSaga = function* ({
	payload: {date},
}: ReturnType<typeof liveSessionsClientOnlyActions.loadByDate>) {
	try {
		const dateTo = date.endOf('day');
		const dateFrom = dateTo.clone().add(-1, 'day');

		yield* put(
			liveSessionsClientToServerActions.getLiveSessions({
				dateFrom: dateFrom.format(DATE_TIME_FORMAT),
				dateTo: dateTo.format(DATE_TIME_FORMAT),
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'loadLiveSessionsByDateSaga'});
	}
};

const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
