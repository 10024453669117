import _ from 'lodash';
import {CMDP_INIT_RESPONSE, InitialTranslations} from 'cmd-control-client-lib';
import {PayloadAction} from '@reduxjs/toolkit';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export const processTranslationsSaga = function* (action: PayloadAction<CMDP_INIT_RESPONSE['values']>) {
	try {
		if (action.payload.translations) {
			const serverTranslations = (yield _.attempt(JSON.parse, action.payload.translations)) as
				| Error
				| InitialTranslations;

			if (!_.isError(serverTranslations)) {
				_.forEach(serverTranslations, (langResources, langUpperCase) => {
					const lang = _.lowerCase(langUpperCase);

					_.forEach(langResources, (nsResources, ns) => {
						_.forEach(joinKeys('', nsResources as unknown as TResources), (value, key) => {
							const resource = ServiceFactory.i18n.getResource(lang, ns, key);

							if (!_.isString(resource)) {
								_.unset(nsResources, key);

								if (ServiceFactory.env.notProduction()) {
									ServiceFactory.logService.error(
										`[${lang}] ${ns}:${key} Wrong translation key in backend translations`,
									);
								}
							} else if (!_.isEmpty(resource) && ServiceFactory.env.notProduction()) {
								ServiceFactory.logService.error(`[${lang}] ${ns}:${key} exists in backend translations`);
							}
						});

						if (!_.isEmpty(nsResources)) {
							ServiceFactory.i18n.addResourceBundle(lang, ns, nsResources, true, true);
						}
					});
				});
			} else {
				ServiceFactory.logService.error('Failed to parse translations from server: ' + action.payload.translations);
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processTranslationsSaga'});
	}
};

type TKeyValue = {
	[key: string]: string;
};

type TResources = {
	[key: string]: string | TKeyValue;
};

function joinKeys(prefix: string, obj: TResources) {
	return _.reduce(
		obj,
		(acc: TKeyValue, value, key) => {
			if (_.isObject(value)) {
				acc = {
					...acc,
					...joinKeys(`${prefix}${key}.`, value),
				};
			} else {
				acc[`${prefix}${key}`] = value;
			}

			return acc;
		},
		{},
	);
}
