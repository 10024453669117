import { CMDP_LOGIN, CMDP_LOGIN_RESPONSE } from "../login";
import { ACTION } from "../command/action";

/**
 *  8320 only
 */
export class CMDP_SLOGIN extends CMDP_LOGIN {
  public action = ACTION.CMDP_SLOGIN;
}

/**
 * response for CMDP_SLOGIN, 8320 only
 */
export class CMDP_SLOGIN_RESPONSE extends CMDP_LOGIN_RESPONSE {
  public action = ACTION.CMDP_SLOGIN;
}
