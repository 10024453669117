import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {messagesClientOnlyActions, messagesClientToServerActions} from '@messenger/core/src/Redux/Messages/Actions';
import {selectChannelIdFromChat} from '@messenger/core/src/Redux/Chats/Selectors/selectChannelIdFromChat';
import {getChatIdForSelector} from '@messenger/core/src/Redux/Chats/Selectors/chatsDefaultSelectors';

export const performMessageOptimisticAppendSaga = function* ({
	payload,
}: ReturnType<typeof messagesClientToServerActions.sendMessage>) {
	try {
		const parsedClientData = _.attempt(JSON.parse, payload.clientData);

		if (!_.isError(parsedClientData)) {
			payload.clientData = parsedClientData;
		}

		const {chatIDs, channelId} = payload;
		const message = {...payload};

		if (chatIDs && !channelId && chatIDs.indexOf(',') === -1) {
			const chatChannelId = yield* select(selectChannelIdFromChat, getChatIdForSelector(chatIDs));

			if (chatChannelId) {
				message.channelId = chatChannelId;
			}
		}

		yield* put(messagesClientOnlyActions.appendMessage(message));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'preformMessageOptimisticAppendSaga'});
	}
};
