import {call} from 'typed-redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';
import {ACTION, CMDP_OPEN_LOBBY_RESPONSE, ResultCode} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';

export const showErrorIfLobbyStartFailedSaga = function* (action: TStartLobbyResultAction) {
	try {
		if (action.meta.result.code !== ResultCode.OK) {
			yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
				text: action.meta.result.reason,
				variant: EnumAbstractNotificationVariant.ERROR,
				key: EnumSnackbarNotificationKeys.NOTIFICATION_KEY_LOBBY_FAILED,
			});
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'showErrorIfLobbyStartFailedSaga', action});
	}
};

export type TStartLobbyResultAction = PayloadAction<never, ACTION.CMDP_OPEN_LOBBY, CMDP_OPEN_LOBBY_RESPONSE>;
