import {takeEvery} from 'typed-redux-saga';

import {cam2camPreviewServerToClientActions} from '@messenger/core/src/Redux/Cam2CamPreview/Actions/cam2camPreviewServerToClientActions';

import {closePreviewSocketSaga} from 'src/Redux/Cam2CamPreview/Sagas/closePreviewSocketSaga';
import {startCam2CamPreviewSaga} from 'src/Redux/Cam2CamPreview/Sagas/startCam2CamPreviewSaga';
import {closePrimaryCam2CamSaga} from 'src/Redux/Cam2CamPreview/Sagas/closePrimaryCam2CamSaga';

function* cam2CamPreviewSagaWatcher() {
	yield* takeEvery(cam2camPreviewServerToClientActions.guestStartsCam2Cam.type, startCam2CamPreviewSaga);
	yield* takeEvery(cam2camPreviewServerToClientActions.guestStopsCam2Cam.type, closePreviewSocketSaga);
	yield* takeEvery(cam2camPreviewServerToClientActions.guestStopsCam2Cam, closePrimaryCam2CamSaga);
}

export default cam2CamPreviewSagaWatcher;
