import { channelIdType, partnerIdKeyType } from "../channel";
import { ACTION } from "../command/action";
import { baseParamsType } from "../command/baseparams";
import { ICOMMAND, IKeyMaybeValue, IRESPONSE, RESULT } from "../command/icommand";
import { IB2BUserInfo } from "./b2buserinfo";

// B2B only.

/**
 *  the information about partner user. overrides information set by partnter to his user and will be shown to your users.
 */
export type IB2BContactInfo = IB2BUserInfo;

/**
 * Transport command for Contact Info
 */

export class CMDC_CONTACTINFO implements ICOMMAND {
  public action = ACTION.CMDC_CONTACTINFO;
  public params: partnerIdKeyType & IB2BContactInfo;
}

/**
 * Set the contact information.
 */
export class CMDP_SETCONTACTINFO implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SETCONTACTINFO;
  public params: baseParamsType & partnerIdKeyType & IB2BContactInfo;
}

/**
 * Response for CMDP_SETCONTACTINFO
 */

export class CMDP_SETCONTACTINFO_RESPONSE extends CMDP_SETCONTACTINFO implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  /** unused */
  public values: IKeyMaybeValue;
}

/**
 * get the contact information
 */
export class CMDP_GETCONTACTINFO implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_GETCONTACTINFO;
  public params: baseParamsType & (channelIdType | partnerIdKeyType);
}

/**
 * Response for CMDP_GETCONTACTINFO
 */
export class CMDP_GETCONTACTINFO_RESPONSE extends CMDP_GETCONTACTINFO implements IRESPONSE {
  public result: RESULT;
  /** ContactInfo */
  public commands: CMDC_CONTACTINFO[];
  /** unused */
  public values: IKeyMaybeValue;
}

export class CMDP_SGETCONTACTINFO extends CMDP_GETCONTACTINFO {
  public action: ACTION = ACTION.CMDP_SGETCONTACTINFO;
}

/**
 * @deprecated
 */
export class CMDP_SGETCONTACTINFO_RESPONSE extends CMDP_GETCONTACTINFO_RESPONSE {
  public action: ACTION = ACTION.CMDP_SGETCONTACTINFO;
}
