import { EnumBooleanStringified, EnumCurrency, JSONString } from "../@types";
import { IBACKENDPAYLOAD } from "./backend";
import { ACTION } from "./command/action";
import { baseParamsType } from "./command/baseparams";
import { ICOMMAND, IKeyMaybeValue, IKeyValue, IRESPONSE, RESULT } from "./command/icommand";
import { channelIdType, EnumProductId } from "./channel";
import { IB2BUserInfo, IB2BUserInfoVOne } from "./b2b/b2buserinfo";
import { ComaSeparatedValues } from "./sessionstate";

export enum EnumBadge {
  MSG = "MSG",
  TOY = "TOY",
  MEDIA = "MEDIA",
  GIFT = "GIFT",
}
/**
 *  product code
 */
export interface IProduct {
  productId: EnumProductId;
}

export interface IChannelInfoBackEndPayload {
  freeChatDuration: number;
  freeMessagesCount: number;
  currency: EnumCurrency;
  messagePrice: number;
  videoChatPrice: number;
  voyeurChatPrice: number;
  singleChatPrice: number;
  singleC2CChatPrice: number;
}

/**
 * channel information and abilities
 */
export interface IChanelInfo extends IKeyMaybeValue {
  canBeArchived?: EnumBooleanStringified;
  canBeBanned?: EnumBooleanStringified;
}

/**
 * User channel information and abilities
 */
export interface IChanelInfoV extends IKeyMaybeValue {
  /** the VOne channel(user) was advertised by user, readonly*/
  advertised?: EnumBooleanStringified;
  /** json-encoded string. discount information about video chat discount, readonly */
  discount?: JSONString<any>;
  /** the user follew me (mirrored regularCustomer), readonly */
  followMe?: EnumBooleanStringified;
  /**  Customer value badges */
  badges?: ComaSeparatedValues<EnumBadge>;
  /** no messages can be sent, too many messages without answer. readonly*/
  blockedOutbound?: EnumBooleanStringified;
  /** no messages can come in*/
  blockedInbound?: EnumBooleanStringified;
}

/**
 * channel note
 */
export default interface IChannelNote extends IKeyValue {
  firstName?: string;
  lastName?: string;
  age?: string;
  city?: string;
  regularCustomer?: string;
  notes?: string; // Live
  note?: string; // Messenger
}

export interface ChannelInfo
  extends channelIdType,
    Partial<IProduct>,
    IChanelInfo,
    IChanelInfoV,
    Partial<IB2BUserInfoVOne>,
    IChannelNote {}

export type ChannelInfoType = channelIdType &
  IProduct &
  IChanelInfo &
  IChanelInfoV &
  (IB2BUserInfo | IB2BUserInfoVOne) &
  IChannelNote;

/**
 * transport command for channel information
 */
export class CMDC_CHANNELINFO implements ICOMMAND {
  public action = ACTION.CMDC_CHANNELINFO;
  public params: ChannelInfoType;
}

/**
 * get channel information
 */
export class CMDP_GETCHANNELINFO implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_GETCHANNELINFO;
  public params: baseParamsType & channelIdType;
}

/**
 * Response for CMDP_CHANNELINFO
 */
export class CMDP_GETCHANNELINFO_RESPONSE extends CMDP_GETCHANNELINFO implements IRESPONSE, IBACKENDPAYLOAD {
  public result: RESULT;
  /** ContactInfo */
  public commands: CMDC_CHANNELINFO[];
  /** unused */
  public values: IKeyMaybeValue;
  /** the backend info for the channel */
  public payload?: IChannelInfoBackEndPayload;
}

/**
 * set channel note.
 */
export class CMDP_SETCHANNELINFO implements ICOMMAND, IBACKENDPAYLOAD {
  public action: ACTION = ACTION.CMDP_SETCHANNELINFO;
  public params: baseParamsType & channelIdType & IChannelNote;
  /** the backend info for the channel */
  public payload?: unknown;
}

/**
 * Response for CMDP_SETCHANNELINFO
 */
export class CMDP_SETCHANNELINFO_RESPONSE extends CMDP_SETCHANNELINFO implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  /** unused */
  public values: IKeyMaybeValue;
  /** the backend info for the channel */
  public payload?: unknown;
}
