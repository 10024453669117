import WebRtcConfigVM from '@messenger/core/src/Redux/Stream/WebRtcConfigVM';

export type OnErrorCallback = (e: Error) => void;
export type OnStopCallback = () => void;

export interface IStreamConnectionProps {
	audioCodec?: string;
	videoCodec?: string;
	webRTCConfig: WebRtcConfigVM;
	onStop: OnStopCallback;
	onError: OnErrorCallback;
}

export enum EnumMediaStreamTrackKind {
	AUDIO = 'audio',
	VIDEO = 'video',
}
