/** messageKey for msgTyp="sys" */
export enum _SystemMessageKey {}

export enum SystemMessageKey {
  // custom Keys
  bonusCodeRedemption = "bonusCodeRedemption", // triggered when guest uses model's bonus code on Visit-X
  h_vcall_code = "h_vcall_code", // manual vcall code
  feedPostBought = "feedPostBought",
  feedPostTipped = "feedPostTipped",

  // standart keys
  categories = "categories", //Categories
  category = "category", //Category
  error_code_6010 = "error_code_6010", //Missing ticket for show.
  error_code_6011 = "error_code_6011", //The show hasn't started yet.
  exit_code_1 = "exit_code_1", //
  exit_code_100 = "exit_code_100", //You have ended this chat.
  exit_code_101 = "exit_code_101", //You have ended this chat.
  exit_code_102 = "exit_code_102", //You have ended this chat.
  exit_code_103 = "exit_code_103", //You have ended this chat.
  exit_code_104 = "exit_code_104", //This chat has been closed to allow a chat software update.
  exit_code_105 = "exit_code_105", //This chat has been closed due to an internal error. Oops.
  exit_code_106 = "exit_code_106", //Not enough money!
  exit_code_107 = "exit_code_107", //
  exit_code_108 = "exit_code_108", //
  exit_code_201 = "exit_code_201", //You were kicked by the Model!
  exit_code_202 = "exit_code_202", //{modelname} just went offline. Please try again later.
  exit_code_203 = "exit_code_203", //{modelname} just went offline. Please try again later.
  exit_code_204 = "exit_code_204", //{modelname} just went offline. Please try again later.
  exit_code_205 = "exit_code_205", //{modelname} just went offline. Please try again later.
  exit_code_206 = "exit_code_206", //{modelname} just went offline. Please try again later.
  exit_code_207 = "exit_code_207", //{modelname} just went offline. Please try again later.
  exit_code_208 = "exit_code_208", //{modelname} just went offline. Please try again later.
  exit_code_210 = "exit_code_210", //{modelname} just went offline. Please try again later.
  exit_code_211 = "exit_code_211", //{modelname} just went offline. Please try again later.
  exit_code_212 = "exit_code_212", //{modelname} just went offline. Please try again later.
  exit_code_213 = "exit_code_213", //{modelname} just went offline. Please try again later.
  exit_code_214 = "exit_code_214", //{modelname} just went offline. Please try again later.
  exit_code_215 = "exit_code_215", //{modelname} just went offline. Please try again later.
  exit_code_216 = "exit_code_216", //{modelname} just went offline. Please try again later.
  exit_code_301 = "exit_code_301", //You were kicked by an admin!
  exit_code_302 = "exit_code_302", //Age verification is successful
  exit_code_303 = "exit_code_303", //Sorry, you are too young
  exit_code_304 = "exit_code_304", //Age verification was not successful. No picture
  exit_code_305 = "exit_code_305", //Webcam AVS process was canceled by guest
  exit_code_306 = "exit_code_306", //Webcam AVS process failed with technical reason
  exit_code_401 = "exit_code_401", //Sorry, you exceeded your preview limit!
  exit_code_402 = "exit_code_402", //Sorry, you exceeded your preview limit!
  exit_code_403 = "exit_code_403", //{modelname} is offline
  exit_code_404 = "exit_code_404", //The model is in a private chat
  exit_code_405 = "exit_code_405", //
  exit_code_406 = "exit_code_406", //
  exit_code_407 = "exit_code_407", //The model has preview disabled
  exit_code_501 = "exit_code_501", //You have exceeded your Videotime-Limit!
  exit_code_502 = "exit_code_502", //You have exceeded your Free-Preview-Limit!
  exit_code_503 = "exit_code_503", //This chat has been closed because the amateur has rejected the user's age status.
  exit_code_601 = "exit_code_601", //The model did not accept your Softchat request!
  exit_code_602 = "exit_code_602", //This chat has been closed because you have commenced an age verification check.
  exit_code_901 = "exit_code_901", //This chat has been closed because en error has occurred. Error code:
  exit_code_902 = "exit_code_902", //This chat has been closed because you have started a new chat.
  exit_code_903 = "exit_code_903", //This chat has been closed because Voice Call has been ended.
  exit_code_904 = "exit_code_904", //This chat has been closed because you have started a new chat.
  exit_code_905 = "exit_code_905", //This chat has been closed because en error has occurred. Error code:
  exit_code_906 = "exit_code_906", //The model entered a Singlechat!
  exit_code_907 = "exit_code_907", //{modelname} just went offline. Please try again later.
  exit_code_908 = "exit_code_908", //The model entered a Show!
  exit_code_910 = "exit_code_910", //The video connection was interrupted. Please try again later.
  exit_code_911 = "exit_code_911", //Free mode was cancelled.
  exit_code_913 = "exit_code_913", //Kicked by server
  exit_code_914 = "exit_code_914", //Chat was terminated due to a technical error.
  exit_code_920 = "exit_code_920", //This chat has been closed because the amateur has been blocked.
  exit_code_921 = "exit_code_921", //{modelname} just went offline. Please try again later.
  exit_code_990 = "exit_code_990", //This chat has been closed or could not proceed because a time-out has occurred.
  exit_code_991 = "exit_code_991", //This chat has been closed because an error has occurred. Error code:
  exit_code_995 = "exit_code_995", //This chat has been closed because an error has occurred. Error code:
  exit_code_996 = "exit_code_996", //This chat has been closed because an error has occurred. Error code:
  exit_code_997 = "exit_code_997", //This chat has been closed because an error has occurred. Error code:
  exit_code_998 = "exit_code_998", //This chat has been closed because an error has occurred. Error code:
  exit_code_999 = "exit_code_999", //This chat has been closed because the amateur has gone offline. This may have occurred unexpectedly.
  exit_code_lobby = "exit_code_lobby", //Show has ended. Thank you for participating.
  exit_code_private_show_end = "exit_code_private_show_end", //Show has ended. Thank you for participating.
  g_chat_auto_charged = "g_chat_auto_charged", //Your account was automatically charged.
  g_chat_host_micro_off = "g_chat_host_micro_off", //%s's microphone is OFF.
  g_chat_host_micro_on = "g_chat_host_micro_on", //%s's microphone is now ON.
  g_discount_client = "g_discount_client", //%s gives you discount of %s%% for this chat and for the next %s days
  g_discount_client_once = "g_discount_client_once", //%s gives you discount of %s%% for this chat
  g_single_not_started = "g_single_not_started", //Private Chat is not started.
  g_single_query = "g_single_query", //%s invites you to a Private chat and wants to give you a private-only pleasure without other observers. The new price for the Video-Chat will be: %s
  g_single_query_contenpartner = "g_single_query_contenpartner", //Do you want to start the private-chat with the adopted price?
  g_single_query_start = "g_single_query_start", //Invite %s to a Private chat? (Price %s per minute).
  g_single_query_start1 = "g_single_query_start1", //Woud you like to switch to Private Chat (%s per minute)?
  g_single_rejected = "g_single_rejected", //Private chat has been rejected by %s.
  g_single_started = "g_single_started", //Private chat started.
  g_video_cancel = "g_video_cancel", //video interrupted, billing stopped. you may try to restart video
  g_video_delayed = "g_video_delayed", //Your player is downloading components for high quality video chat. This is done only once for your computer and is free of charge.
  g_video_delerror = "g_video_delerror", //The high quality components could not be installed. The player will fall back to standard quality. You can contact support for help to install the failed components
  g_video_reconnect = "g_video_reconnect", //video interrupted, reconnecting (Important: video downtime is not billed)
  host_speaks = "host_speaks", //chats in
  h_chat_admin_start = "h_chat_admin_start", //A staff member of VISIT has entered your chat.
  h_chat_admin_stop = "h_chat_admin_stop", //A staff member of VISIT-X has left your chat.
  h_chat_audio_start = "h_chat_audio_start", //%s can listen to your voice, when he turns on the speakers.
  h_chat_ban_failed = "h_chat_ban_failed", //A ban is not available.
  h_chat_ban_ok = "h_chat_ban_ok", //A ban is successful.
  h_chat_button_yes_close = "h_chat_button_yes_close", //Yes, close the chat
  h_chat_cam2cam_running = "h_chat_cam2cam_running", //Cam2Cam active
  h_chat_cam2cam_start = "h_chat_cam2cam_start", //%s turned on his cam (cam2cam chat)
  h_chat_cam2cam_stop = "h_chat_cam2cam_stop", //%s turned off his cam
  h_chat_category_from = "h_chat_category_from", //started from your category: %s
  h_chat_chat_closed = "h_chat_chat_closed", //Live chat closed.
  h_chat_chat_created = "h_chat_chat_created", //%s starts
  h_chat_client_software = "h_chat_client_software", //Client Connection with %s
  h_chat_conversion_info_has_top_up = "h_chat_conversion_info_has_top_up", //%s did already top up money. Try to get him into your video chat.
  h_chat_conversion_info_no_top_up = "h_chat_conversion_info_no_top_up", //If %s tops up money in the chat or up to 30 minutes afterwards (phone access excluded), you will receive a conversion bonus.
  h_chat_conversion_start = "h_chat_conversion_start", //%s has entered your conversion chat. He can see you, write with you, but can't hear you.
  h_chat_duration = "h_chat_duration", //duration
  h_chat_freeuserconv = "h_chat_freeuserconv", //This guest can chat %s minutes with you for free, but you don't get commission on this. So, excite him and try to persuade him to recharge his prepaid account. We will reward you with a 10 EUR conversion bonus!
  h_chat_free_mode_converted = "h_chat_free_mode_converted", //Free Chat with %s converted.
  h_chat_fsk_attention = "h_chat_fsk_attention", //Attention Soft-Chat!. You can only make a flirt and soft erotic show.
  h_chat_fsk_denied = "h_chat_fsk_denied", //You have denied the Soft-Chat.
  h_chat_fsk_text_warn = "h_chat_fsk_text_warn", //You are in a softchat session, please watch your language (offending message not delivered)
  h_chat_fsk_wait = "h_chat_fsk_wait", //Please wait until your soft chat is accepted!
  h_chat_fsk_accepted = "h_chat_fsk_accepted",
  h_chat_guest_mobile = "h_chat_guest_mobile", //This Guest visits you via a mobile device. He cannot write as fast as usual.
  h_chat_guest_preview_start = "h_chat_guest_preview_start", //VIP Live Preview started
  h_chat_guest_preview_start2 = "h_chat_guest_preview_start2", //%s started
  h_chat_guest_sound_off = "h_chat_guest_sound_off", //%s cannot listen to your voice.
  h_chat_guest_sound_on = "h_chat_guest_sound_on", //%s can listen to your voice.
  h_chat_guest_video_start = "h_chat_guest_video_start", //Livechat started
  h_chat_guest_video_start_price = "h_chat_guest_video_start_price", //Livechat started (%s / min)
  h_chat_guest_visit = "h_chat_guest_visit", //This is the %d visit in your chat (first time: %s, last time: %s)
  h_chat_guest_visit_first = "h_chat_guest_visit_first", //This is the first visit in your chat
  h_chat_guest_voyeur_shown = "h_chat_guest_voyeur_shown", //You have left the Voyeurchat and in a regular video chat now.
  h_chat_guest_voyeur_start = "h_chat_guest_voyeur_start", //Voyeurcam started
  h_chat_guest_voyuer_start_info = "h_chat_guest_voyuer_start_info", //You are invisible until you start typing!
  h_chat_guest_voyuer_start_info_price = "h_chat_guest_voyuer_start_info_price", //You are invisible until you start typing for %s / min!
  h_chat_guest_voyuer_start_price = "h_chat_guest_voyuer_start_price", //Voyeurcam started (%s / min)
  h_chat_info_about = "h_chat_info_about", //Info about chat with %s
  h_chat_kicked = "h_chat_kicked", //You kicked %s from your chat.
  h_chat_lobby_start = "h_chat_lobby_start", //%s started
  h_chat_lobby_stop = "h_chat_lobby_stop", //%s has end.
  h_chat_lovense_not_available = "h_chat_lovense_not_available", //For this user the Toy Control function is not available - please do not mention it!
  h_chat_name_preview = "h_chat_name_preview", //Livechat Preview
  h_chat_name_video = "h_chat_name_video", //Livechat
  h_chat_name_voyeur = "h_chat_name_voyeur", //Voyeur Livechat
  h_chat_note_caption = "h_chat_note_caption", //Your notes
  h_chat_note_create = "h_chat_note_create", //Create a note
  h_chat_note_edit = "h_chat_note_edit", //Details/Edit
  h_chat_note_not_available = "h_chat_note_not_available", //No notes can be created for this guest
  h_chat_not_visitx_guest = "h_chat_not_visitx_guest", //This user is logged on a partner site, please never mention VISIT!
  h_chat_no_history = "h_chat_no_history", //No chat histoy found.
  h_chat_party_mode_converted = "h_chat_party_mode_converted", //Party Chat with %s converted.
  h_chat_paused_4107 = "h_chat_paused_4107", //%s credits are exhausted. He has the chance to refill some money immediately. Meanwhile he is not able to see you or to answer you. Encourage him to refill his credits.
  h_chat_please_micro_on = "h_chat_please_micro_on", //Please turn ON your microphone.
  h_chat_preview_chat = "h_chat_preview_chat", //Livechat
  h_chat_preview_live = "h_chat_preview_live", //Livechat Preview
  h_chat_preview_start = "h_chat_preview_start", //%s is watching your free live preview. You are able to chat.
  h_chat_preview_start_voucher = "h_chat_preview_start_voucher", //%s has entered a chat he already paid via voucher
  h_chat_preview_stop = "h_chat_preview_stop", //Free preview for %s is closed.
  h_chat_preview_vip = "h_chat_preview_vip", //VIP Live Preview
  h_chat_pseudo_user = "h_chat_pseudo_user", //No Mailbox (no VIP codes)
  h_chat_rectuited_guest = "h_chat_rectuited_guest", //Recruited guest:
  h_chat_single_available = "h_chat_single_available", //Private chat available, but inactive.
  h_chat_single_not_available = "h_chat_single_not_available", //Private chat not available
  h_chat_single_running = "h_chat_single_running", //Private chat active
  h_chat_stop = "h_chat_stop", //%s has left your chat
  h_chat_stop_guest_error = "h_chat_stop_guest_error", //%s has technical issues and left your chat
  h_chat_test_chat = "h_chat_test_chat", //This is a test chat with your test account.
  h_chat_text_chat_goal_video = "h_chat_text_chat_goal_video", //This user is in your Text-Chat and cannot see you. Goal: Win him for the video chat.
  h_chat_text_muted = "h_chat_text_muted", //You blocked %s from writing messages.
  h_chat_text_start = "h_chat_text_start", //%s has entered your Text-Chat.
  h_chat_text_to_video = "h_chat_text_to_video", //%s has switched to Video-Chat
  h_chat_text_unmuted = "h_chat_text_unmuted", //%s is allowed to write text messages again.
  h_chat_tip = "h_chat_tip", //You have just received a chat bonus from %s over %s Cent!
  h_chat_tip_no_price = "h_chat_tip_no_price", //You have just received a chat bonus from %s!
  h_chat_unban_ok = "h_chat_unban_ok", //The video chat block has been removed.
  h_chat_url_blocked = "h_chat_url_blocked", //X-URL-blocked-X
  h_chat_userisnovip = "h_chat_userisnovip", //VIP member.No
  h_chat_userisvip = "h_chat_userisvip", //VIP membership
  h_chat_video_start = "h_chat_video_start", //%s has entered your Video-Chat
  h_chat_video_start_voyeur = "h_chat_video_start_voyeur", //A voyeur is watching your cam. As long as he stays anonymous he can neither see what you are typing nor chat with you.
  h_chat_voyer2single_video_disabled = "h_chat_voyer2single_video_disabled", //Please remember that you do not have allowed voyeurs while private chat.
  h_chat_voyeur2single = "h_chat_voyeur2single", //%s is currently busy in a Private Chat. You cannot type at the moment!
  h_chat_voyeur2single_off = "h_chat_voyeur2single_off", //%s has left the Private Chat. You are now able to start the text chat!
  h_chat_voyeur2single_video_audio_warn = "h_chat_voyeur2single_video_audio_warn", //Please remember that you have allowed voyeurs while private chat. Those voyeurs are also able to hear you.
  h_chat_voyeur2single_video_warn = "h_chat_voyeur2single_video_warn", //Please remember that you have allowed voyeurs while private chat. Those voyeurs are not able to hear you.
  h_chat_voyeur_shown = "h_chat_voyeur_shown", //%s first watched you as voyeur and has now entered a regular Chat with you
  h_chat_voyuer = "h_chat_voyuer", //A voyeur
  h_code_4002 = "h_code_4002", //Wrong user name or password
  h_code_4003 = "h_code_4003", //Access denied
  h_code_4004 = "h_code_4004", //Session expiered. Please login again.
  h_code_4006 = "h_code_4006", //Login failed. Incorrect username or password.
  h_discount_available = "h_discount_available", //Discount available but not granted
  h_discount_current = "h_discount_current", //Current Discount is %s% (until %s GMT)
  h_discount_invalid = "h_discount_invalid", //It is not possible to set a discount that is less than the current discount or expires sooner.
  h_discount_not_available = "h_discount_not_available", //Discount is not available
  h_discount_setforinfo = "h_discount_setforinfo", //With this function you grant a %s discount on all videochats and private chats until a certain time.
  h_discount_set_ok = "h_discount_set_ok", //You gave %s a %s%% discount.
  h_error_2000 = "h_error_2000", //OK
  h_error_4002 = "h_error_4002", //Login failed. Incorrect username or password.
  h_error_4003 = "h_error_4003", //This login was locked and cannot currently be used for video chats. For further information please contact support team.
  h_error_4004 = "h_error_4004", //Your session has expired. Please log in.
  h_error_4006 = "h_error_4006", //Login failed. Incorrect username or password.
  h_error_4008 = "h_error_4008", //Too many login attempts
  h_error_4009 = "h_error_4009", //missing or incomplete session settings
  h_error_4010 = "h_error_4010", //account holder already online in this categorie with another profile
  h_error_4011 = "h_error_4011", //too many profiles of this account holder online.
  h_error_4012 = "h_error_4012", //maximum number of profiles for this categorie reached
  h_error_4013 = "h_error_4013", //ATTENTION: This software is outdated and can''t be used anymore. Please go to https://www.vxlive.net. If you need help, please contact your support.
  h_error_4014 = "h_error_4014", //Please confirm your email address in order to go online.
  h_error_4015 = "h_error_4015", //No Audio is available
  h_error_4016 = "h_error_4016", //
  h_error_4020 = "h_error_4020", //running chats, cannot stop sending
  h_error_4104 = "h_error_4104", //chat not found
  h_error_4141 = "h_error_4141", //Not enough credit.
  h_error_5001 = "h_error_5001", //Invalid Parameter
  h_error_5004 = "h_error_5004", //An unexpected error has occurred.
  h_error_5005 = "h_error_5005", //Not implemented
  h_error_dberror = "h_error_dberror", //An unexpected error has occurred.
  h_error_login4002 = "h_error_login4002", //Login failed. Incorrect username or password.
  h_error_login4003 = "h_error_login4003", //This login was locked and cannot currently be used for video chats. For further information please contact support team.
  h_error_login4006 = "h_error_login4006", //Login failed. Incorrect username or password.
  h_error_login4007 = "h_error_login4007", //duplicate session found, already logged in
  h_error_login4008 = "h_error_login4008", //force logout duplicate session failed
  h_error_video_send_failed = "h_error_video_send_failed", //The videoconnection was lost.
  h_instant_start = "h_instant_start", //A user is watching your Live Preview.
  h_instant_stop = "h_instant_stop", //A user is not watching your Live Preview anymore.
  h_media_audio_error = "h_media_audio_error", //Audio message could not be delivered
  h_media_audio_informuploaded = "h_media_audio_informuploaded", //Audio message is being recorded ....
  h_media_audio_purchased = "h_media_audio_purchased", //Your audio was bought by %s for %s %s.
  h_media_bitmap_error = "h_media_bitmap_error", //the upload is failed
  h_media_bitmap_purchased = "h_media_bitmap_purchased", //Your Picture was bought by %s for %s %s.
  h_media_cam2cam_purchased = "h_media_cam2cam_purchased", //Your private videocall was bought by %s for %s %s.
  h_media_sold = "h_media_sold", //Sold!
  h_media_video_error = "h_media_video_error", //Video message could not be delivered
  h_media_video_informuploaded = "h_media_video_informuploaded", //Video message is being recorded ....
  h_media_video_purchased = "h_media_video_purchased", //Your video was bought by %s for %s %s.
  h_queryfsk_btn0 = "h_queryfsk_btn0", //Refuse
  h_queryfsk_btn1 = "h_queryfsk_btn1", //Accept
  h_queryfsk_button_caption = "h_queryfsk_button_caption", //Deactivate Soft-Chat.
  h_queryfsk_caption = "h_queryfsk_caption", //Soft-Chat request.
  h_queryfsk_text = "h_queryfsk_text", //Soft-Chat request from %s. If you accept, you are not allowed to show more than topless. Accept?
  h_queryfsk_text_kick = "h_queryfsk_text_kick", //All current Soft-Chats will be terminated and all users will be kicked out immediately. Are you sure you want to disable the Softchat?
  h_session_appear_offline = "h_session_appear_offline", //Your stream is arrived and can be made available for guests. ATTENTION! You have to deactivate "Appear offline" in order to be displayed as ONLINE for guests!
  h_session_audio_device_error = "h_session_audio_device_error", //Your microphone doesn't properly work. Please check.
  h_session_audio_device_frozen = "h_session_audio_device_frozen", //The software detected, that the current Micro is mute. Please check that the micro device is properly positioned, switched on, connected to the pc and -if applicable- that required software is running.
  h_session_audio_loudness_ok = "h_session_audio_loudness_ok", //Your sound is working correctly again. Enjoy your live chat!
  h_session_audio_no_loudness = "h_session_audio_no_loudness", //The microphone is not reacting. Please check your settings to make sure that it has power and is turned on.
  h_session_audio_off = "h_session_audio_off", //Your Microphone is OFF.
  h_session_audio_on = "h_session_audio_on", //Your Microphone is ON
  h_session_button_cancel = "h_session_button_cancel", //Cancel
  h_session_button_no = "h_session_button_no", //No
  h_session_button_yes = "h_session_button_yes", //Yes
  h_session_cpu_overload = "h_session_cpu_overload", //Your computer is overloaded and can´t stream a live picture! Cancel running programs that affect the performance (CPU) of your computer.
  h_session_error = "h_session_error", //An unexpected error has occurred.
  h_session_free_mode_off = "h_session_free_mode_off", //==============ATENCIÓN: FREE CHAT DESACTIVADO================
  h_session_free_mode_on = "h_session_free_mode_on", //==========ATENCIÓN: EL MODO FREE CHAT ESTÁ ACTIVADO==========
  h_session_fsk_mixed_profile = "h_session_fsk_mixed_profile", //This profile is also available for guests without age verification. You will be asked for each chat if you´d like to allow this guest to enter your chat.
  h_session_guest_offline = "h_session_guest_offline", //%s is currently offline and cannot accept your invitation
  h_session_image_too_small = "h_session_image_too_small", //The image is too small
  h_session_livepreview_off = "h_session_livepreview_off", //The live chat preview is OFF
  h_session_livepreview_on = "h_session_livepreview_on", //The live chat preview is ON
  h_session_lobby_not_available = "h_session_lobby_not_available", //The ticket live show is not available or "Appear offline" is activated!
  h_session_lobby_not_planed = "h_session_lobby_not_planed", //No scheduled ticket show in upcoming 30 minutes
  h_session_logout_by_you = "h_session_logout_by_you", //You have logged off.
  h_session_media_purchased = "h_session_media_purchased", //The Media was purchased
  h_session_mixed_profile_auto_off = "h_session_mixed_profile_auto_off", //We just disabled your participation in softchats. When you participate in softchats you have to accept softchat-requests of users.
  h_session_mixed_profile_no = "h_session_mixed_profile_no", //You haven't been unlocked for soft chat yet. Please contact our support.
  h_session_mixed_profile_not_available = "h_session_mixed_profile_not_available", //Softchat is not possible in the category 'video'.
  h_session_mixed_profile_off = "h_session_mixed_profile_off", //Softchat is OFF. Turn it on again, if you have ended your hardcore show (in softchat you are not allowed to show more than topless).
  h_session_mixed_profile_on = "h_session_mixed_profile_on", //Soft-Chat is ON. VERY IMPORTANT: You are only allowed to accept a softchat user, If you are not showing more than topless in this moment.
  h_session_mixed_profile_reminder = "h_session_mixed_profile_reminder", //No users in chat? Activate the Soft-Chat and become available for more users.
  h_session_msg_blocked = "h_session_msg_blocked", //Your message could not be sent.
  h_session_no_video_allowed = "h_session_no_video_allowed", //You are offline now. Your cannot send just a vido anymore. This option is extinguished.
  h_session_offline = "h_session_offline", //You are now OFFLINE. See you soon!
  h_session_offline_title = "h_session_offline_title", //Your Videochat is offline.
  h_session_old_ssw = "h_session_old_ssw", //ATTENTION: In a few days it won’t be possible to go online with this version of VXsoftware. Please download the newest version of VXsoftware; this update does only take a few minutes. DOWNLOAD HERE.
  h_session_online = "h_session_online", //You are now ONLINE, have fun!
  h_session_online_today = "h_session_online_today", //Your total online time today: %s
  h_session_partner_blocked = "h_session_partner_blocked", //Your message could not be sent. Partner does not exist.
  h_session_party_mode_off = "h_session_party_mode_off", //========ATENCIÓN: EL MODO PARTY CHAT ESTÁ DESACTIVADO========
  h_session_party_mode_on = "h_session_party_mode_on", //==========ATENCIÓN: EL MODO PARTY CHAT ESTÁ ACTIVADO==========
  h_session_preview_picture_missed = "h_session_preview_picture_missed", //ATTENTION: YOU DO NOT HAVE A PREVIEW PICTURE IN YOUR PROFILE. WITHOUT A PREVIEW PICTURE YOUR PROFILE WONT BE DISPLAYED
  h_session_preview_picture_upload = "h_session_preview_picture_upload", //Upload a preview picture.
  h_session_service0900state_turnoff = "h_session_service0900state_turnoff", //Your service number is deactivated
  h_session_service0900state_turnon = "h_session_service0900state_turnon", //Your service number is now active
  h_session_singlec2c_query_declined = "h_session_singlec2c_query_declined", //The invitation to private video chat was declined from %s.
  h_session_singlec2c_query_sent = "h_session_singlec2c_query_sent", //The invitation to private video chat has been sent to %s.
  h_session_singlec2c_query_timeout = "h_session_singlec2c_query_timeout", //%s did not respond to your private video chat invitation.
  h_session_stream_error = "h_session_stream_error", //Your stream is corrupt. Please restart your streaming tool and check the settings.
  h_session_stream_missed = "h_session_stream_missed", //Your stream is not arriving. Please check the settings or restart the streamtool
  h_session_stream_not_found = "h_session_stream_not_found", //Your stream is not found. Please check the Software.
  h_session_stream_restart = "h_session_stream_restart", //Your video stream will restart...
  h_session_stream_server_down = "h_session_stream_server_down", //The videoconnection was lost. Please start your stream again.
  h_session_stream_stop = "h_session_stream_stop", //The videostream was lost.
  h_session_stream_wait = "h_session_stream_wait", //Please wait until your videostream is accepted...
  h_session_too_many_pinned_channels = "h_session_too_many_pinned_channels", //You have too many pinned contacts
  h_session_toy_start = "h_session_toy_start", //Lovense started
  h_session_toy_stop = "h_session_toy_stop", //Lovense finished
  h_session_user_locked = "h_session_user_locked", //Your account is temporarily locked. Please try again later or call our support.
  h_session_video_device_error = "h_session_video_device_error", //Sorry. You have been taken offline, because your Webcam transmitted a still picture.lease check your Webcam.
  h_session_video_device_frozen = "h_session_video_device_frozen", //ATTENTION: Please check your Webcam, you are sending a still picture.
  h_single_error = "h_single_error", //The Private chat could not be initiated due to an unexpected error
  h_single_init_failed = "h_single_init_failed", //The invitation for a Private chat could not be delivered.
  h_single_init_ok = "h_single_init_ok", //An invitation has been sent. Waiting for answer.
  h_single_not_available = "h_single_not_available", //Private chat are not available.
  h_single_query = "h_single_query", //%s invites you to a Private chat. Accept?
  h_single_rejected = "h_single_rejected", //Your guest actively denied your Private chat invitation
  h_single_request_90s = "h_single_request_90s", //Private chat can only be started after a minimum of 90 seconds. This is a security feature to prevent abuse.
  h_single_started = "h_single_started", //Private chat started.
  h_single_timeout = "h_single_timeout", //Your guest did not decide on the Request for a certain time, so the request was cancelled automatically
  h_test_0 = "h_test_0", //Test successful! Click <OK> to go online. Now you can start chatting.
  h_test_100 = "h_test_100", //An unknown error occured, please try later again. You can use the "feedback" button to contact our support.
  h_test_101 = "h_test_101", //Your webcam doesn´t broadcast any video. You only broadcast video to a guest if you can see your preview.
  h_test_102 = "h_test_102", //Your camera is broadcasting a freeze image right now, please check your webcam settings!
  h_test_103 = "h_test_103", //An error occured while using your microphone, please check it.
  h_test_104 = "h_test_104", //An error occured while using your microphone, please check it. Is it muted?
  h_test_105 = "h_test_105", //The connection to server failed.1. Please check your internet connection (are you online?) 2. Please check your firewall adjustment: Is VX-Software allowed to send data to the internet? Please ask your firewall provider regarding the settings you have to make to allow programs sending data to the internet.
  h_test_106 = "h_test_106", //encoder error. try later.
  h_test_107 = "h_test_107", //The connection to the video server has been interrupted. Please check your internet connection.
  h_test_109 = "h_test_109", //Your computer is overloaded.
  h_test_110 = "h_test_110", //Your camera resolution is too high. The maximum resolution for your bandwidth is %s. Please go back and set a smaller resolution.
  h_test_200 = "h_test_200", //This test is outdated. Please repeat it.
  h_test_201 = "h_test_201", //Your internet connection is too slow to broadcast sound and guests will receive just a defictive sound. Please click <back> and turn off the microphone.
  h_test_202 = "h_test_202", //Your internet connection is too slow for broadcasting video with a high resolution. Click <back> and choose the format "320x240".
  h_test_203 = "h_test_203", //Please check your camera settings and your internet connection because you are not broadcasting any video. Tip: If you are using a firewall, please allow VX-Software to broadcast the video to the internet.
  h_test_204 = "h_test_204", //Your CPU is too slow to encode video with a high resolution. Click <back> and choose the format max 848x360.
  h_test_205 = "h_test_205", //Bad aspect ratio. Please set another 16:9 or 4:3 Format(±10%).
  h_test_206 = "h_test_206", //Your Videoformat is too small.
  h_test_207 = "h_test_207", //The FPS is too small. Please set minimum 25 FPS.
  h_test_50 = "h_test_50", //Test successful, click <OK> to go online. Now you can start chatting. Attention: your computer is overloaded!
  h_ticket_show_cancelled = "h_ticket_show_cancelled", //Your show has been canceled, since no ticket was sold up to 10 minutes before showstart.
  h_ticket_show_query_caption = "h_ticket_show_query_caption", //Ticket show
  h_ticket_show_query_overtime_text = "h_ticket_show_query_overtime_text", //Tip: The time you defined for your ticket show has expired. Do you want to stop the show now?
  h_ticket_show_query_text = "h_ticket_show_query_text", //Your scheduled ticket show starts in %s mins, do you want to swith to showmode now?
  h_ticket_show_reminder_text = "h_ticket_show_reminder_text", //Your ticket show starts in %s minits.
  h_ticket_show_reminder_text_in_delay = "h_ticket_show_reminder_text_in_delay", //Your ticket show started %s minutes ago. Please come online, the guests are waiting for you!
  h_ticket_show_reminder_title = "h_ticket_show_reminder_title", //Ticket Show Remeinder
  h_ticket_show_start = "h_ticket_show_start", //Ticket Show start now!
  h_video_cancel = "h_video_cancel", //client lost video, chat continues as text-chat only
  h_video_delayed = "h_video_delayed", //Hint: client is preparing for videochat and currently downloading a component for high quality video chat.
  h_video_reconnect = "h_video_reconnect", //client lost video, trying to reconnect.
  h_chat_mobilevideocall_missed = "h_chat_mobilevideocall_missed",
  h_chat_mobilevideocall_started = "h_chat_mobilevideocall_started",
  h_chat_mobilevideocall_ended = "h_chat_mobilevideocall_ended",
  h_chat_mobilevideocall_started_price = "h_chat_mobilevideocall_started_price",
  h_chat_mobilevideocall_init = "h_chat_mobilevideocall_init",
}
/*
SELECT varName, ' = ', CONCAT('"',varName, '", //',  replace(replace(translation, "\r", " "), "\n", "")) descr from (
SELECT
    ll.l_name_short "language",
  lower(CASE IFNULL(v.v_array_index,'')
    WHEN '' THEN CONCAT(v.v_name)
    ELSE CONCAT(v.v_name, '_', v.v_array_index)
  END) "varName",
  t.t_text "translation"
  FROM LangMan_categories c
  JOIN LangMan_var_names v ON c.c_id = v.c_id AND v.p_id = '1'
  LEFT JOIN LangMan_translation t ON t.v_id = v.v_id and t.t_history_flag = 0
  LEFT JOIN LangMan_languages ll ON  ll.l_id = t.t_language
  WHERE c.c_id IN (103, 128) AND ll.l_name_short IN ('EN')

  ORDER BY 2,3
  ) x where varName not like 'foo%'
order BY 1
 */
