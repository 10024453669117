import {select, put} from 'typed-redux-saga';

import {sessionClientOnlyActions} from '@messenger/core/src/Redux/Session/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {messageInputClientOnlyActions} from '@messenger/core/src/Redux/MessageInput/Actions/messageInputClientOnlyActions';
import {selectCurrentMessageInputId} from '@messenger/core/src/Redux/MessageInput/Selectors/selectCurrentMessageInputId';

export const processUseShortcutSaga = function* ({
	payload: {shortcut},
}: ReturnType<typeof sessionClientOnlyActions.useShortcut>) {
	try {
		if (shortcut.autoSend) {
			yield* put(messagesClientOnlyActions.sendMessage({message: shortcut.text}));
		} else {
			const editorStateId = yield* select(selectCurrentMessageInputId);

			yield* put(
				messageInputClientOnlyActions.upsertOne({
					id: editorStateId,
					value: shortcut.text,
					caretPosition: shortcut.text.length,
				}),
			);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processUseShortcutSaga'});
	}
};
