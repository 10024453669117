import {takeEvery} from 'typed-redux-saga';

import {abuseReportClientOnlyActions} from '@messenger/core/src/Redux/AbuseReport/Actions/abuseReportClientOnlyActions';
import {sendAbuseReportSaga} from '@messenger/core/src/Redux/AbuseReport/Sagas/sendAbuseReportSaga';

function* feedbackSagaWatcher() {
	yield* takeEvery(abuseReportClientOnlyActions.send.type, sendAbuseReportSaga);
}

export default feedbackSagaWatcher;
