import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectTypingChats} from '@messenger/core/src/Redux/Chats/Selectors/selectTypingChats';
import IRootState from '@messenger/core/src/Redux/IRootState';

const getPropsChatId = (_: IRootState, props: {chatId?: string}) => props.chatId;

export const selectIsChatTypingById = createSelector(
	[selectTypingChats, getPropsChatId],
	(typingChats, chatId) => !!chatId && _.includes(typingChats, chatId),
);
