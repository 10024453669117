import _ from 'lodash';
import {EnumMediaType} from 'cmd-control-client-lib';

export const getMediaType = (file?: {type: string}): EnumMediaType | undefined => {
	if (_.isUndefined(file)) {
		return undefined;
	}

	switch (_.head(_.split(file.type, '/'))) {
		case 'image':
			return EnumMediaType.BITMAP;
		case 'video':
			return EnumMediaType.VIDEO;
		case 'audio':
			return EnumMediaType.AUDIO;
		default:
			return undefined;
	}
};
