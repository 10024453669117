import {container, inject, singleton} from 'tsyringe';
import ReactGA from 'react-ga4';

import AbstractAnalyticsService, {
	EnumAnalyticsCategories,
	TLogEventOptions,
} from '@messenger/core/src/Services/AbstractAnalyticsService';
import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import EnvInterfaceService from '@messenger/core/src/Services/EnvInterfaceService';

@singleton()
class AnalyticsService extends AbstractAnalyticsService {
	protected isEnabled: boolean;
	protected trackerCode: string;
	protected isDebug: boolean;

	constructor(@inject(DIToken.EnvInterfaceService) protected env: EnvInterfaceService) {
		super();
		this.isEnabled = this.env.isAnalyticsEnabled() && this.env.hasGoogleAnalyticsTrackerNo();
		this.trackerCode = this.env.getGoogleAnalyticsTrackerNo();
		this.isDebug = this.env.isAnalyticsDebugEnabled();
		this.initialize();
	}

	protected initialize() {
		if (!this.isEnabled) {
			return;
		}

		ReactGA.initialize(this.trackerCode);
	}

	logEvent(action: string, {category}: TLogEventOptions) {
		ReactGA.event({
			action,
			category,
		});
	}

	logPage(page: string, title?: string) {
		ReactGA.send({hitType: 'pageview', page, title});
	}

	logSignUp(_method: string) {
		this.logEvent('sign_up', {category: EnumAnalyticsCategories.USER_ACTIVITY});
	}

	logSignIn(_method: string) {
		this.logEvent('sign_in', {category: EnumAnalyticsCategories.USER_ACTIVITY});
	}
}

container.register(DIToken.AnalyticsService, {useToken: AnalyticsService});

export default AnalyticsService;
