/**
 * @see CMDP_SSTATUS
 */
export enum EnumStreamStatusType {
  AUDIO_DEVICE_ERROR = "AUDIO_DEVICE_ERROR", // deprecated, unused
  AUDIO_DEVICE_FROZEN = "AUDIO_DEVICE_FROZEN", // deprecated, unused
  AUDIO_DEVICE_MUTED = "AUDIO_DEVICE_MUTED", // "value" ignored
  AUDIO_DEVICE_UNMUTED = "AUDIO_DEVICE_UNMUTED", // "value" ignored
  BITRATE = "BITRATE", // deprecated, unused
  CPU_OVERLOAD = "CPU_OVERLOAD", // deprecated, unused
  DROPPED_AUDIO_FRAMES = "DROPPED_AUDIO_FRAMES", // deprecated, unused
  DROPPED_VIDEO_FRAMES = "DROPPED_VIDEO_FRAMES", // deprecated, unused
  VIDEO_DEVICE_ERROR = "VIDEO_DEVICE_ERROR", // deprecated, unused
  VIDEO_DEVICE_FROZEN = "VIDEO_DEVICE_FROZEN", // deprecated, unused
  PROXY_STREAM_INFO = "PROXY_STREAM_INFO", // client provided info
  PROXY_STATE_INFO = "PROXY_STATE_INFO", // client provided info, JSON.stringify({isTyping: true})
  WEBRTC_STATS = "WEBRTC_STATS", // value is browser related JSON.stringify(RTCStatsReport()) return of RTCPeerConnection.getStats()
}
