import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import GiftVM from '@messenger/core/src/Redux/Gifts/GiftVM';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum GiftsAction {
	SET_GIFTS = 'SET_GIFTS',
}

class GiftsClientOnlyActions extends ClientOnlyActions {
	scope = EnumStore.GIFTS;
	setGifts = this.createAction(GiftsAction.SET_GIFTS, this.getPrepareAction<GiftVM[]>());
}

export const giftsClientOnlyActions = new GiftsClientOnlyActions();
