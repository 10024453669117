import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {selectHasActiveNoAdminChats} from '@messenger/core/src/Redux/Chats/Selectors/selectHasActiveNonAdminChats';
import {
	clientClientOnlyActions,
	EnumStopStreamWarningAction,
} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';

export const processMaybeGoOfflineSaga = function* () {
	try {
		const hasActiveChats = yield* select(selectHasActiveNoAdminChats);

		if (hasActiveChats) {
			yield* put(clientClientOnlyActions.showLogoutWarning(EnumStopStreamWarningAction.STOP_STREAM));

			return;
		}

		yield* put(streamClientOnlyActions.goOffline());
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processMaybeGoOfflineSaga'});
	}
};
