import _ from 'lodash';

import {TChatSummaryEntity} from '@messenger/core/src/Redux/ChatSummary/entityAdapter';
import {EnumChatTimes, EnumProfileFieldDataType, TProfileFields} from '@messenger/core/src/Redux/ChannelInfos/Model';

export class ChatSummaryVM {
	firstChatTime?: string;
	lastChatTime?: string;
	chatsNumber?: number;
	channelId: string;
	stats: TProfileFields[];

	constructor(chatSummary: TChatSummaryEntity) {
		this.channelId = chatSummary.channelId;
		this.firstChatTime = chatSummary.FIRST;
		this.lastChatTime = chatSummary.LAST;
		this.chatsNumber = _.get(chatSummary, 'CHATS');
		this.stats = [];

		if (!_.isUndefined(this.firstChatTime)) {
			this.stats.push({
				dataType: EnumProfileFieldDataType.DATE,
				key: EnumChatTimes.firstChat,
				value: this.firstChatTime,
			});
		}

		if (!_.isUndefined(this.lastChatTime)) {
			this.stats.push({
				dataType: EnumProfileFieldDataType.DATE,
				key: EnumChatTimes.lastChat,
				value: this.lastChatTime,
			});
		}

		if (!_.isUndefined(this.chatsNumber)) {
			this.stats.push({
				dataType: EnumProfileFieldDataType.STRING,
				key: EnumChatTimes.totalChats,
				value: this.chatsNumber.toString(),
			});
		}
	}
}
