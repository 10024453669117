import {call, put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TCanGetCapabilities} from '@messenger/core/src/Services/AbstractUiContainer';
import {EnumRTCRtpCapabilitiesKind} from '@messenger/core/src/BusinessLogic/EnumRTCRtpCapabilitiesKind';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';

export const gatherSupportedCodecsSaga = function* (browser: TCanGetCapabilities) {
	try {
		const video = yield* call([browser, browser.getCapabilities], EnumRTCRtpCapabilitiesKind.VIDEO);
		const audio = yield* call([browser, browser.getCapabilities], EnumRTCRtpCapabilitiesKind.AUDIO);

		yield* put(
			streamClientOnlyActions.setBrowserCapabilities({
				videoCodecs: video?.codecs,
				audioCodecs: audio?.codecs,
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'gatherSupportedCodecsSaga'});
	}
};
