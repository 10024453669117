import {createSlice} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {liveChatEntityAdapter} from '@messenger/core/src/Redux/LiveChats/liveChatEntityAdapter';
import {liveChatsClientOnlyActions} from '@messenger/core/src/Redux/LiveChats/Actions/liveChatsClientOnlyActions';

export const initialLiveChatState = liveChatEntityAdapter.getInitialState();

export const liveChatsSlice = createSlice({
	name: EnumStore.LIVE_CHATS,
	initialState: initialLiveChatState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(liveChatsClientOnlyActions.addMany, liveChatEntityAdapter.addMany);
	},
});
