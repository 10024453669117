import {ACTION} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class ChatsClientToServerActions extends ClientToServerActions<EnumStore.CHATS> {
	readonly scope = EnumStore.CHATS;

	get = this.createAction(ACTION.CMDP_SGETCHATS, (chatIds: string[] = []) => ({
		payload: {
			chatID: chatIds.join(','),
		},
	}));

	setChatDiscount = this.createAction(ACTION.CMDP_SET_DISCOUNT, (chatID: string, rate: number, time: number) => ({
		payload: {
			chatID,
			rate,
			time,
		},
	}));

	chatKick = this.createAction(ACTION.CMDP_KICK, (chatID: string) => ({
		payload: {
			chatID,
		},
	}));
}

export const chatsClientToServerActions = new ChatsClientToServerActions();
