import {delay, put} from 'typed-redux-saga';
import {SessionState} from 'sip.js';

import {sipClientOnlyActions} from '@messenger/core/src/Redux/Sip/Actions/sipClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export const processChangeCallStateSaga = function* ({
	payload,
}: ReturnType<typeof sipClientOnlyActions.changeCallState>) {
	try {
		if (payload === SessionState.Terminated) {
			yield* delay(3000);
			yield* put(sipClientOnlyActions.resetCall());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processChangeCallStateSaga'});
	}
};
