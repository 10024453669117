import {EntityId} from '@reduxjs/toolkit';
import _, {Dictionary} from 'lodash';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {IVibrationEvent} from '@messenger/core/src/Redux/Toy/IVibrationEvent';
import {IDevice} from '@messenger/core/src/Services/Device/IDevice';
import EnumResponseCode from '@messenger/core/src/Services/Device/EnumResponseCode';
import {IDeviceToy} from '@messenger/core/src/Redux/Toy/IDeviceToy';

enum EnumToyActions {
	APP_OFFLINE = 'APP_OFFLINE',
	APP_ONLINE = 'APP_ONLINE',
	IS_LOADED = 'IS_LOADED',
	REQUEST_TOYS = 'REQUEST_TOYS',
	REQUEST_TOYS_FAILED = 'REQUEST_FAILED',
	SET_LOADING = 'SET_LOADING',
	SET_MAX_INTENSITY = 'SET_MAX_INTENSITY',
	SET_SELECTED = 'SELECTED',
	ADD_TO_QUEUE = 'ADD_TO_QUEUE',
	REMOVE_FROM_QUEUE = 'REMOVE_FROM_QUEUE',
	QUEUE_PROCESSING = 'QUEUE_PROCESSING',
	START_VIBRATE = 'START_VIBRATE',
	STOP_VIBRATE = 'STOP_VIBRATE',
	TEST_VIBRATE = 'TEST_VIBRATE',
	STOP_TEST_VIBRATION = 'STOP_TEST_VIBRATION',
	TOYS_RECEIVED = 'TOYS_RECEIVED',
	UNSET_SELECTED = 'UNSET_SELECTED',
	MARK_PROFILE_ENABLED = 'MARK_PROFILE_ENABLED',
}

class ToyClientOnlyActions extends ClientOnlyActions<EnumStore.TOY> {
	readonly scope = EnumStore.TOY;

	markAppOffline = this.createAction(EnumToyActions.APP_OFFLINE);
	markAppOnline = this.createAction(EnumToyActions.APP_ONLINE);
	markLoaded = this.createAction(EnumToyActions.IS_LOADED);
	markLoading = this.createAction(EnumToyActions.SET_LOADING);
	requestToys = this.createAction(EnumToyActions.REQUEST_TOYS);
	receiveToys = this.createAction(EnumToyActions.TOYS_RECEIVED, (payload: Dictionary<IDevice>) => ({
		payload: _.reduce<Dictionary<IDevice>, Dictionary<IDeviceToy>>(
			payload,
			(result, toy, key) => {
				result[key] = {
					id: toy.id,
					name: toy.name,
					nickName: toy.nickName || '',
					status: toy.status ? 1 : 0,
				};

				return result;
			},
			{},
		),
	}));
	requestToysFailed = this.createAction(
		EnumToyActions.REQUEST_TOYS_FAILED,
		this.getPrepareAction<EnumResponseCode | undefined>(),
	);
	setMaxIntensity = this.createAction(EnumToyActions.SET_MAX_INTENSITY, (payload: number) => ({
		payload: Math.max(5, Math.min(payload, 100)) / 100,
	}));
	selectToy = this.createAction(EnumToyActions.SET_SELECTED, this.getPrepareAction<EntityId>());
	deselectToy = this.createAction(EnumToyActions.UNSET_SELECTED);
	addToQueue = this.createAction(EnumToyActions.ADD_TO_QUEUE, this.getPrepareAction<IVibrationEvent>());
	removeFromQueue = this.createAction(EnumToyActions.REMOVE_FROM_QUEUE);
	queueProcessing = this.createAction(EnumToyActions.QUEUE_PROCESSING);
	startVibrate = this.createAction(EnumToyActions.START_VIBRATE, this.getPrepareAction<IVibrationEvent>());
	stopVibrate = this.createAction(EnumToyActions.STOP_VIBRATE);
	testVibrate = this.createAction(EnumToyActions.TEST_VIBRATE, (payload: IVibrationEvent) => ({
		payload: {
			id: payload.id,
			intensity: payload.intensity,
			duration: payload.duration * 1000, // convert to ms,
			isTest: true,
		},
	}));
	stopTestVibration = this.createAction(EnumToyActions.STOP_TEST_VIBRATION);
	markProfileEnabled = this.createAction(EnumToyActions.MARK_PROFILE_ENABLED, this.getPrepareAction<boolean>());
}

export const toyClientOnlyActions = new ToyClientOnlyActions();
