/**
 * @link http://phabricator.y/T1190#26024
 */

enum EnumChatType {
	ADMIN_CHAT = '18', // admin chat?
	CONTENT_CHAT = '15', // general content model chat, since v8 ssw
	CONTROL_CHAT = '101', // text and picture control chat
	FREE_SHOW = '20', // FreeShow Chat/ Lobby Chat
	PREPAID_CHAT = '16', // general prepayed chat, since v8 ssw release
	PREPARE_CHAT = '0', // Just to prepare a chat for later use
	TEXT_CHAT = '1', // only text
}

export default EnumChatType;
