import { EnumBooleanStringified, JSONString } from "../../@types";
import { EnumProductId, usrKeyType } from "../channel";
import { ACTION } from "../command/action";
import { baseParamsType } from "../command/baseparams";
import { ICOMMAND, IKeyMaybeValue, IKeyValue, IRESPONSE, RESULT } from "../command/icommand";

/**
 *  user information and abilities
 */

interface IUserInfo extends IKeyValue {
  /** product id readonly*/
  productId?: EnumProductId;
  /** user Login/Name to show */
  ualias?: string;
  /** user language, 2-symbol code lower case. "de", "en" */
  language?: string;
  /** avatar url */
  imgSrc?: string;
  /** avatar url sourceSet,  ignored in SET-Commands */
  imgSrcSet?: string;
  /** any json encoded string for client software */
  payload?: JSONString<unknown>;
  /** age verification status */
  avs?: EnumBooleanStringified;
  /** abilities */
  canSendMessage?: EnumBooleanStringified;
  canReceiveMessage?: EnumBooleanStringified;
  canSendImage?: EnumBooleanStringified;
  canReceiveImage?: EnumBooleanStringified;
  canSendAudio?: EnumBooleanStringified;
  canReceiveAudio?: EnumBooleanStringified;
  canSendVideo?: EnumBooleanStringified;
  canReceiveVideo?: EnumBooleanStringified;
  canReceiveTicket?: EnumBooleanStringified;
  canOfferMedia?: EnumBooleanStringified;
  canPurchaseMedia?: EnumBooleanStringified;
}

/**
 * product "VOne" extra information and abilities
 */
export interface IUserInfoVOne extends IUserInfo {
  isVIP?: EnumBooleanStringified;
  canCall0900?: EnumBooleanStringified;
  canSingleSession?: EnumBooleanStringified;
  canBuyShopContent: EnumBooleanStringified;
  canBeDiscounted: EnumBooleanStringified;
  canSendGifts: EnumBooleanStringified;
  canControlToy: EnumBooleanStringified;
  canBeBan: EnumBooleanStringified;
}

/**
 * B2B basic user information
 */
export type IB2BUserInfo = IUserInfo;

/**
 * B2B Vone user information
 */
export type IB2BUserInfoVOne = IUserInfoVOne;

/**
 * user information transport object
 */
export class CMDC_USERINFO implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_USERINFO;
  public params: {
    usrId: string;
    usrKey: string;
  } & (IB2BUserInfo | IB2BUserInfoVOne);
}

/**
 * B2B only
 * Create/Update user information in messenger system
 */
export class CMDP_SETUSERINFO implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SETUSERINFO;
  public params: baseParamsType & usrKeyType & (IUserInfo | IUserInfoVOne);
}

/**
 * Response for CMDP_SETUSERINFO
 */
export class CMDP_SETUSERINFO_RESPONSE extends CMDP_SETUSERINFO implements IRESPONSE {
  public result: RESULT;
  /** unused */
  public values: IKeyMaybeValue;
  /** unused */
  public commands: ICOMMAND[];
}

/**
 * B2B only
 * Get user information in messenger system
 */
export class CMDP_GETUSERINFO implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_GETUSERINFO;
  public params: baseParamsType & {
    /** userKey */
    usrKey: string;
  };
}

/**
 * Response CMDP_GETUSERINFO
 */
export class CMDP_GETUSERINFO_RESPONSE extends CMDP_GETUSERINFO implements IRESPONSE {
  public result: RESULT;
  /** unused */
  public values: IKeyMaybeValue;
  /** user info */
  public commands: CMDC_USERINFO[];
}
