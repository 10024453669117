import {container} from 'tsyringe';
import {Upload} from 'tus-js-client';
import {EnumBooleanStringified, EnumCurrency} from 'cmd-control-client-lib';
import {v5 as uuidv5} from 'uuid';

import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import {TSendMediaTusMeta} from '@messenger/core/src/Services/VControl/AbstractVControlApi';

const FINGERPRINT_HASHING_NAMESPACE = '0ab2c3ea-195b-4247-8bc3-5c1b6b9af1a5';

export default class TusService {
	tusFileReader: TFileReader;
	urlStorage: TUrlStorage;

	async getAllUploads(): Promise<{metadata: TSendMediaTusMeta; size: number; key: string}[]> {
		throw new Error('This method implemented only in Momentum');
	}

	async getFingerprint(_file: File, uploadOptions: TUploadOptions) {
		return `${uploadOptions.endpoint}${uuidv5(
			new URLSearchParams(uploadOptions.metadata).toString(),
			FINGERPRINT_HASHING_NAMESPACE,
		)}`;
	}
}

container.register(DIToken.Tus, {useToken: TusService});

export type TTusMetadata = {
	text?: string;
	messageKey?: string;
	isBulk?: EnumBooleanStringified;
	isChargeable?: EnumBooleanStringified;
	mediaPrice?: string;
	currency: EnumCurrency;
	sessionID: string;
	channelId: string;
	filename: string;
	filetype: string;
	uri?: string;
	imgPixelated?: string;
	videoPoster?: string;
};
export type TUploadOptions = Upload['options'];
type TFileReader = TUploadOptions['fileReader'];
type TUrlStorage = TUploadOptions['urlStorage'];
