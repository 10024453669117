import 'reflect-metadata';
import {container} from 'tsyringe';
import {EventChannel} from '@redux-saga/core';
import {PayloadAction} from '@reduxjs/toolkit';
import {END} from 'redux-saga';
import {call, fork, select, take} from 'typed-redux-saga';
import {ResultCode} from 'cmd-control-client-lib';
import _ from 'lodash';

import {selectKeyboardShortcuts} from '@messenger/core/src/Redux/Session/Selectors/PermanentData/selectKeyboardShortcuts';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import {TKeyboardShortcut} from '@messenger/core/src/Redux/Session/Model';
import {TShortcutAppliedPayload} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions/sessionServerToClientActions';

import {ShortcutsService} from 'src/Services/ShortcutsService';
import {createShortcutsChannelSaga} from 'src/Redux/Client/Sagas/Shortcuts/createShortcutsChannelSaga';
import {processShortcutAppliedSaga} from 'src/Redux/Client/Sagas/Shortcuts/processShortcutAppliedSaga';
import {closeShortcutsChannelSaga} from 'src/Redux/Client/Sagas/Shortcuts/closeShortcutsChannelSaga';

export const keyboardShortcutsSaga = function* (action: ReturnType<typeof sessionServerToClientActions.loggedIn>) {
	// only send on successful login
	if (_.has(action, 'meta.result.code') && action.meta.result.code !== ResultCode.OK) {
		return;
	}

	const list: TKeyboardShortcut[] = yield* select(selectKeyboardShortcuts);
	const shortcuts = (yield* call([container, 'resolve'], DIToken.shortcutsService)) as ShortcutsService;
	const shortcutsChannel: EventChannel<PayloadAction<TShortcutAppliedPayload> | END> = yield* call(
		createShortcutsChannelSaga,
		shortcuts,
		list,
	);

	// wait for logout or session termination
	yield* fork(closeShortcutsChannelSaga, shortcutsChannel);

	while (true) {
		try {
			const action: PayloadAction<TShortcutAppliedPayload> = yield* take(shortcutsChannel);

			yield* fork(processShortcutAppliedSaga, action);
		} catch (err) {
			ServiceFactory.logService.error(err as Error, {saga: 'keyboardShortcutsSaga'});
		}
	}
};
