import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {cam2camPreviewAdapter} from '@messenger/core/src/Redux/Cam2CamPreview/entityAdapter';
import IRootState from '@messenger/core/src/Redux/IRootState';

export const {
	selectIds: selectCam2CamPreviewIds,
	selectEntities: selectCam2CamPreviewEntities,
	selectAll: selectAllCam2CamPreviews,
	selectTotal: selectTotalCam2CamPreviews,
	selectById: _selectCam2CamPreviewById,
} = cam2camPreviewAdapter.getSelectors<IRootState>((state) => state[EnumStore.CAM_2_CAM_PREVIEW]);
