import _ from 'lodash';
import {Comparer} from '@reduxjs/toolkit';

import IChat from '@messenger/core/src/Redux/Chats/Model';
import ChannelHelper from '@messenger/core/src/Redux/Channels/ChannelHelper';

export const chatSortComparer: Comparer<ISortSufficientChatData | IChat> = (chat1, chat2): number => {
	if (ChannelHelper.isTVChannel(chat1.channelId) && !ChannelHelper.isTVChannel(chat2.channelId)) {
		return -1;
	}

	const time1 = parseInt(_.get(chat1, 'startTime', '').toString(), 10);
	const time2 = parseInt(_.get(chat2, 'startTime', '').toString(), 10);

	if (time1 !== time2) {
		return time1 < time2 ? 1 : -1;
	}

	return 0;
};

export type ISortSufficientChatData = Pick<IChat, 'startTime' | 'channelId'>;
