import React from 'react';
import {PayloadAction} from '@reduxjs/toolkit';
import {put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {notificationsClientOnlyActions} from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';
import {TQueryUserModel} from '@messenger/core/src/Redux/QueryUser/TQueryUserModel';
import QueryUserViewModel from '@messenger/core/src/Redux/QueryUser/QueryUserViewModel';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';

import ContentWithTimerContainer from 'src/Components/Notifications/QueryUser/ContentWithTimerContainer';

export const showNotificationWithTimerSaga = function* (action: PayloadAction<TQueryUserModel>) {
	try {
		const notificationId = `${EnumSnackbarNotificationKeys.QUERY_REQUEST}${action.payload.queryId}`;

		yield* put(
			notificationsClientOnlyActions.enqueuePersistent(
				action.payload.text,
				EnumAbstractNotificationVariant.INFO,
				{
					key: notificationId,
					content: <ContentWithTimerContainer query={new QueryUserViewModel(action.payload)} />,
				},
				true,
			),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'showNotificationWithTimerSaga', action});
	}
};
