import {put} from 'typed-redux-saga';
import _ from 'lodash';

import {mediaLinksClientOnlyActions} from '@messenger/core/src/Redux/MediaLinks/Actions/mediaLinksClientOnlyActions';
import {mediaServerToClientActions} from '@messenger/core/src/Redux/Media/Actions/mediaServerToClientActions';
import {TMediaLink} from '@messenger/core/src/Redux/MediaLinks/mediaLinkEntityAdapter';
import {MEDIA_LINKS_HISTORY_SUBJECT_ID} from '@messenger/core/src/Redux/MediaLinks/Actions/mediaLinksClientToServerActions';

export const processMediaLinksReceivedSaga = function* ({
	payload,
	meta,
	error,
}: ReturnType<typeof mediaServerToClientActions.mediaHistoryReceived>) {
	const {commands, params} = meta;

	const mediaLinks = _.chain(commands)
		.map('params')
		.filter((item) => Boolean(item.channelId) && Boolean(item.linkState))
		.value() as TMediaLink[];

	if (!_.isEmpty(mediaLinks)) {
		yield* put(mediaLinksClientOnlyActions.setMany(mediaLinks));
	}

	if (_.get(params, MEDIA_LINKS_HISTORY_SUBJECT_ID)) {
		yield* put(mediaLinksClientOnlyActions.mediaLinksHistoryReceived({payload, meta, error}));
	}
};
