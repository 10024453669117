import {put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {liveSessionsClientToServerActions} from '@messenger/core/src/Redux/LiveSessions/Actions/liveSessionsClientToServerActions';

const LIVE_SESSIONS_LOAD_LAST = 1;

export const loadLastLiveSessionSaga = function* (payload?: {limit: number}) {
	try {
		yield* put(
			liveSessionsClientToServerActions.getLiveSessions({
				limit: String(payload?.limit || LIVE_SESSIONS_LOAD_LAST),
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'loadLastLiveSessionSaga'});
	}
};
