import {put} from 'typed-redux-saga';
import _ from 'lodash';
import {EnumBooleanStringifiedExtended} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {channelsClientOnlyActions, channelsClientToServerActions} from '@messenger/core/src/Redux/Channels/Actions';

export const requestChannelsByIdsSaga = function* ({
	payload: {channelIds, requestUuid},
}: ReturnType<typeof channelsClientOnlyActions.requestChannels>) {
	try {
		if (_.isEmpty(channelIds)) {
			return;
		}

		yield* put(
			channelsClientToServerActions.requestChannelsByFilter(
				_.omitBy(
					{
						channelId: _.join(channelIds, ','),
						onlineState: EnumBooleanStringifiedExtended.TRUE,
						contactNote: EnumBooleanStringifiedExtended.TRUE,
						channelInfo: EnumBooleanStringifiedExtended.TRUE,
						requestUuid,
					},
					_.isNil,
				),
			),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'requestChannelsByIdsSaga'});
	}
};
