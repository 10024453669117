import {IStreamConnectionProps} from '@messenger/core/src/Services/WebRtc/CommonConnectionTypes';
import WebRtcConfigVM from '@messenger/core/src/Redux/Stream/WebRtcConfigVM';

abstract class AbstractWebRtcApi {
	abstract get mediaStream(): MediaStream | undefined;

	abstract checkBrowserSupport(): boolean;

	abstract devicesToConstraints(
		webRtcConfigVM: WebRtcConfigVM,
		isMicrophoneRequired?: boolean,
		videoSource?: string,
		audioSource?: string,
	): MediaStreamConstraints;

	abstract requestPermissions(constraints: MediaStreamConstraints): Promise<void>;

	abstract externalDevices(): Promise<MediaDeviceInfo[]>;

	abstract releasePermissions(): void;

	abstract get isVideoCameraAvailable(): boolean;

	abstract get isMicrophoneAvailable(): boolean;

	abstract get audioTrack(): MediaStreamTrack | undefined;

	abstract get videoTrack(): MediaStreamTrack | undefined;

	abstract get videoTrackSettings(): MediaTrackSettings | undefined;

	abstract get audioTrackSettings(): MediaTrackSettings | undefined;

	abstract muteAudioTrack(): void;

	abstract unMuteAudioTrack(): void;

	abstract isAudioTrackEnabled(): boolean;

	abstract startOutputStream(props: IStreamConnectionProps): void;

	abstract stopOutputStream(): void;

	abstract getStreamStats(): Promise<RTCStatsReport | undefined>;
}

export default AbstractWebRtcApi;
