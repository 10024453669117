export enum EnumChannelFlags {
  ARCHIVED = "archived",
  REGULAR = "regularCustomer",
  ADVERTISED = "advertised",
  DELETED = "deleted",
  LOCKED = "locked",
  UNANSWERED = "unanswered",
  PINNED = "pinned",
  MUTED = "muted",
  BOUGHTMEDIA = "boughtMedia",
  FOLLOWME = "followMe",
  BANNED = "banned",
  BANNEDME = "bannedMe",
  ARCHIVEDME = "archivedMe",
  MUTEDME = "mutedMe",
  BLOCKEDOUTBOUND = "blockedOutbound",
  BLOCKEDINBOUND = "blockedInbound",
  VIDEOCHAT = "videoChat",
  GIFT = "gift",
}
