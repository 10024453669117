import ILocalFile from '@messenger/core/src/Redux/Media/ILocalFile';

export interface IRecordingStatus {
	durationMillis: number;
	metering?: number;
}

abstract class AbstractVoiceMessageService {
	abstract start(selectedDevice?: string): void;
	abstract stop(onStop: (file: File | ILocalFile | null, duration: number) => void): void;
	abstract addRecordingStatusUpdateCallback(callback: (status: IRecordingStatus) => void): void;
	abstract removeRecordingStatusUpdateCallback(callback: (status: IRecordingStatus) => void): void;
	abstract cancelRecording(): void;
}

export default AbstractVoiceMessageService;
