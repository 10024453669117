import {put, select} from 'typed-redux-saga';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {channelGroupsClientOnlyActions} from '@messenger/core/src/Redux/ChannelGroups/Actions/channelGroupsClientOnlyActions';
import {selectLoadedChannelIds} from '@messenger/core/src/Redux/ChannelGroups/Selectors/selectLoadedChannelIds';
import {channelGroupsClientToServerActions} from '@messenger/core/src/Redux/ChannelGroups/Actions/channelGroupsClientToServerActions';

export const requestChannelGroupsOnceSaga = function* ({
	payload: {channelId},
}: ReturnType<typeof channelGroupsClientOnlyActions.getChannelGroupsOnce>) {
	try {
		const channelIds = yield* select(selectLoadedChannelIds);

		if (!_.includes(channelIds, channelId)) {
			yield* put(channelGroupsClientToServerActions.getChannelGroups({channelId}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'requestChannelGroupsOnceSaga'});
	}
};
