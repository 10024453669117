import {takeEvery} from 'typed-redux-saga';

import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions/attachmentClientOnlyActions';
import {releaseMediaDevicePermissionsSaga} from '@messenger/core/src/Redux/MediaDevice/Sagas/releaseMediaDevicePermissionsSaga';
import {checkCanReceiveAttachmentSaga} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Sagas/checkCanReceiveAttachmentSaga';
import {updateAttachmentPriceSaga} from '@messenger/core/src/Redux/Attachment/Sagas/updateAttachmentPriceSaga';
import {voiceRecordingSaga} from '@messenger/core/src/Redux/Attachment/Sagas/voiceRecordingSaga';
import {processDeleteAttachmentSaga} from '@messenger/core/src/Redux/Attachment/Sagas/processDeleteAttachmentSaga';

import {detachCurrentGuestAttachmentSaga} from './Sagas/detachCurrentGuestAttachmentSaga';
import {processAddFileToAttachmentSaga} from './Sagas/processAddFileToAttachmentSaga';
import {processAddEmptyAudioAttachmentSaga} from './Sagas/processAddEmptyAudioAttachmentSaga';
import {processRemoveEmptyAudioAttachmentSaga} from './Sagas/processRemoveEmptyAudioAttachmentSaga';
import {clearAttachmentSaga} from './Sagas/clearAttachmentSaga';

function* attachmentSagaWatcher() {
	yield* takeEvery(attachmentClientOnlyActions.requestRecordingAudio, voiceRecordingSaga);
	yield* takeEvery(attachmentClientOnlyActions.startRecordingAudio, processAddEmptyAudioAttachmentSaga);
	yield* takeEvery(attachmentClientOnlyActions.addFileToAttachment, processAddFileToAttachmentSaga);
	yield* takeEvery(attachmentClientOnlyActions.attach, checkCanReceiveAttachmentSaga);
	yield* takeEvery(attachmentClientOnlyActions.updateAttachmentPrice, updateAttachmentPriceSaga);
	yield* takeEvery(attachmentClientOnlyActions.detachCurrentGuestAttachment, detachCurrentGuestAttachmentSaga);
	yield* takeEvery(attachmentClientOnlyActions.detach, processDeleteAttachmentSaga);
	yield* takeEvery(attachmentClientOnlyActions.clear, clearAttachmentSaga);
	yield* takeEvery(attachmentClientOnlyActions.stopRecordingAudio.type, releaseMediaDevicePermissionsSaga);
	yield* takeEvery(attachmentClientOnlyActions.audioRecordingFailed, processRemoveEmptyAudioAttachmentSaga);
}

export default attachmentSagaWatcher;
