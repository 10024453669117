import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {TMediaUploadProgressEntity} from '@messenger/core/src/Redux/MediaUploadProgress/entityAdapter';

enum EnumActions {
	SET_MEDIA_UPLOAD_PROGRESS = 'SET_MEDIA_UPLOAD_PROGRESS',
}

class MediaUploadProgressClientOnlyActions extends ClientOnlyActions {
	readonly scope = EnumStore.MEDIA_UPLOAD_PROGRESS;

	setMediaUploadProgress = this.createAction(
		EnumActions.SET_MEDIA_UPLOAD_PROGRESS,
		this.getPrepareAction<TMediaUploadProgressEntity>(),
	);
}

export const mediaUploadProgressClientOnlyActions = new MediaUploadProgressClientOnlyActions();
