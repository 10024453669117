import {EntityId} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';

enum ChatSummaryActions {
	RESET_STORE = 'RESET_STORE',
	GET_CHAT_SUMMARY = 'GET_CHAT_SUMMARY',
	REMOVE_MANY = 'REMOVE_MANY',
}

class ChatSummaryClientOnlyActions extends ClientOnlyActions<EnumStore.CHAT_SUMMARY> {
	readonly scope = EnumStore.CHAT_SUMMARY;
	resetStore = this.createAction(ChatSummaryActions.RESET_STORE);
	removeMany = this.createAction(ChatSummaryActions.REMOVE_MANY, this.getPrepareAction<EntityId[]>());
	getChatSummary = this.createAction(ChatSummaryActions.GET_CHAT_SUMMARY, this.getPrepareAction<{channelId: string}>());
}

export const chatSummaryClientOnlyActions = new ChatSummaryClientOnlyActions();
