import {put} from 'typed-redux-saga';
import {ResultCode} from 'cmd-control-client-lib';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';
import {currencyClientOnlyActions} from '@messenger/core/src/Redux/Currency/Actions/currencyClientOnlyActions';

export const processCurrencySaga = function* (action: ReturnType<typeof sessionServerToClientActions.initialized>) {
	try {
		if (action.meta.result.code === ResultCode.OK) {
			const currency = _.get(action.payload, 'currency', '');

			yield* put(currencyClientOnlyActions.setCurrencies(JSON.parse(JSON.parse(currency))));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processCurrencySaga'});
	}
};
