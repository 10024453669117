import {call, put} from 'typed-redux-saga';

import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';
import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions/sessionServerToClientActions';
import {feedbackClientOnlyActions} from '@messenger/core/src/Redux/Feedback/Actions/feedbackClientOnlyActions';

export const getFeedbackDataFromStorageSaga = function* (
	action: ReturnType<typeof sessionServerToClientActions.loggedIn>,
) {
	try {
		const api = ServiceFactory.store;
		const storageKey = `${EnumLocalStorageKeys.FEEDBACK_DATA}:${action.payload.usrKey}`;
		const dataFromStorage = yield* call([api, api.get], storageKey, {
			storeType: EnumStoreType.LOCAL,
		});

		if (dataFromStorage?.data) {
			yield* put(feedbackClientOnlyActions.saveFormFields(dataFromStorage.data));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'getFeedbackDataFromStorageSaga'});
	}
};
