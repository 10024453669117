import { ACTION } from "./command/action";
import { baseParamsType } from "./command/baseparams";
import { ICOMMAND, IKeyMaybeValue, IRESPONSE, RESULT } from "./command/icommand";

/*noop is the heartbeat and the main delivery command from server side*/
export class CMDP_NOOP implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_NOOP;
  public params: baseParamsType;
}

/*noop_response arrives soon as one command available for you.*/
export class CMDP_NOOP_RESPONSE extends CMDP_NOOP implements IRESPONSE {
  public result: RESULT;
  /** server commands for your session/user */
  public commands: ICOMMAND[];
  /** unused */
  public values: IKeyMaybeValue;
}
