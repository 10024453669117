import {createSlice, EntityState, SliceCaseReducers} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {
	TMediaUploadProgressEntity,
	mediaUploadProgressAdapter,
} from '@messenger/core/src/Redux/MediaUploadProgress/entityAdapter';
import {mediaUploadProgressClientOnlyActions} from '@messenger/core/src/Redux/MediaUploadProgress/Actions/mediaUploadProgressClientOnlyActions';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';

export type TMediaUploadProgressState = EntityState<TMediaUploadProgressEntity>;
const initialState = mediaUploadProgressAdapter.getInitialState({});

export const mediaUploadProgressSlice = createSlice<
	TMediaUploadProgressState,
	SliceCaseReducers<TMediaUploadProgressState>,
	EnumStore
>({
	name: EnumStore.MEDIA_UPLOAD_PROGRESS,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(mediaUploadProgressClientOnlyActions.setMediaUploadProgress, mediaUploadProgressAdapter.upsertOne);
		builder.addCase(messagesClientOnlyActions.markUploaded, mediaUploadProgressAdapter.removeOne);
		builder.addCase(messagesClientOnlyActions.setUploadError, (state, {payload: {messageId}}) =>
			mediaUploadProgressAdapter.removeOne(state, messageId),
		);
	},
});
