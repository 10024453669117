import {call, put} from 'typed-redux-saga';

import RequestFailedResponse from '@messenger/core/src/Services/Device/RequestFailedResponse';
import EnumResponseCode from '@messenger/core/src/Services/Device/EnumResponseCode';
import DevicesListResponse from '@messenger/core/src/Services/Device/DevicesListResponse';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {toyClientOnlyActions} from '@messenger/core/src/Redux/Toy/Actions/toyClientOnlyActions';

/**
 * @return {Generator|void}
 */
export const requestToysSaga = function* () {
	try {
		// figure out the API to use
		const api = ServiceFactory.deviceApi;

		// mark start
		yield* put(toyClientOnlyActions.markLoading());

		const response = yield* call([api, api.requestToysList]);

		// handle errors
		if (response instanceof RequestFailedResponse) {
			yield* put(toyClientOnlyActions.requestToysFailed(response.code));

			// check error codes
			if (response.code === EnumResponseCode.NOT_STARTED) {
				yield* put(toyClientOnlyActions.markAppOffline());
			}
		}

		// handle success
		if (response instanceof DevicesListResponse) {
			yield* put(toyClientOnlyActions.markAppOnline());
			yield* put(toyClientOnlyActions.receiveToys(response.list));
		}

		// mark end
		yield* put(toyClientOnlyActions.markLoaded());
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'requestToysSaga'});
	}
};
