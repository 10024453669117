import {Dictionary} from 'lodash';

import {IDevice} from '@messenger/core/src/Services/Device/IDevice';

export default class DevicesListResponse {
	readonly _list: Dictionary<IDevice>;

	constructor(list: Dictionary<IDevice>) {
		this._list = list;
	}

	get list(): Dictionary<IDevice> {
		return this._list;
	}
}
