import {EnumProductId} from 'cmd-control-client-lib';

import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

export interface IGuest<I> {
	guestIdentity: I;
	username: string;
	isVoyeur: boolean;
	isTeamChannel: boolean;
	isTvAdmin: boolean;
	isAdmin: boolean;
	isPartner: boolean;
	isRegular: boolean;
	isLobbyChat: boolean;
	isGroupChat: boolean;
	isPrivateChat: boolean;
	canPurchaseMedia: boolean;
	canReceiveAudio: boolean;
	canReceiveVideo: boolean;
	canReceiveImage: boolean;
	isAvs?: boolean;
	productId: EnumProductId;
	isOnline: boolean;
	avatar?: string;
	lang?: string;
	isBanned: boolean;
	isArchived: boolean;
	isMuted: boolean;
	isPinned: boolean;
	isPreview: boolean;
	isAdvertised: boolean;
	hasDiscount: boolean;
	isFreeChatAfterPreview: boolean;
	unseenCount: number;
	discount?: number;
	lobbyUsersCount?: number;
	userNote?: string;
}

export type TGuestIdentityChannel = {
	guestType: EnumGuestType.CHANNEL;
	channelId: string;
	chatId?: undefined;
};
export type TGuestIdentityChatWithChannel = {
	guestType: EnumGuestType.CHAT;
	chatId: string;
	channelId: string;
};
export type TGuestIdentityChatWithNoChannel = {
	guestType: EnumGuestType.CHAT;
	chatId: string;
	channelId: undefined;
};
export type TGuestIdentityAll = {
	guestType: EnumGuestType.ALL;
	chatId?: undefined;
	channelId?: undefined;
};
export type TGuestIdentityBulk = {
	guestType: EnumGuestType.BULK;
	chatId?: undefined;
	channelId?: undefined;
};

export type TGuestIdentityChat = TGuestIdentityChatWithChannel | TGuestIdentityChatWithNoChannel;

export type TGuestIdentityChatOrChannel = TGuestIdentityChat | TGuestIdentityChannel;

export type TGuestIdentity =
	| TGuestIdentityChannel
	| TGuestIdentityAll
	| TGuestIdentityBulk
	| TGuestIdentityChatWithChannel
	| TGuestIdentityChatWithNoChannel;

export const bulkGuest: IGuest<TGuestIdentityBulk> = {
	guestIdentity: {guestType: EnumGuestType.BULK},
	canReceiveImage: true,
	canReceiveVideo: true,
	canReceiveAudio: true,
	canPurchaseMedia: true,
	isTeamChannel: false,
	isVoyeur: false,
	isAdmin: false,
	isTvAdmin: false,
	isBanned: false,
	isArchived: false,
	isOnline: false,
	isGroupChat: false,
	isLobbyChat: false,
	isPrivateChat: false,
	isPartner: false,
	productId: EnumProductId.Default,
	username: '',
	isRegular: false,
	isMuted: false,
	isPinned: false,
	isPreview: false,
	isAdvertised: false,
	isFreeChatAfterPreview: false,
	unseenCount: 0,
	hasDiscount: false,
};

export const allChatsGuest: IGuest<TGuestIdentityAll> = {
	guestIdentity: {guestType: EnumGuestType.ALL},
	canReceiveImage: false,
	canReceiveVideo: false,
	canReceiveAudio: false,
	canPurchaseMedia: false,
	isTeamChannel: false,
	isVoyeur: false,
	isAdmin: false,
	isTvAdmin: false,
	isBanned: false,
	isArchived: false,
	isOnline: false,
	isGroupChat: false,
	isLobbyChat: false,
	isPrivateChat: false,
	isPartner: false,
	productId: EnumProductId.Default,
	username: '',
	isRegular: false,
	isMuted: false,
	isPinned: false,
	isPreview: false,
	isAdvertised: false,
	isFreeChatAfterPreview: false,
	unseenCount: 0,
	hasDiscount: false,
};
