import {call, select} from 'typed-redux-saga';
import _ from 'lodash';

import {sessionClientOnlyActions} from '@messenger/core/src/Redux/Session/Actions';
import {selectKeyboardShortcuts} from '@messenger/core/src/Redux/Session/Selectors/PermanentData/selectKeyboardShortcuts';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {updatePermanentDataSaga} from '@messenger/core/src/Redux/Session/Sagas/PermanentData/updatePermanentDataSaga';
import {EnumPermanentSettings} from '@messenger/core/src/Redux/Session/Model';

export const processSaveShortcutSaga = function* ({
	payload: {shortcut, initialKeyStatus},
}: ReturnType<typeof sessionClientOnlyActions.saveShortcut>) {
	try {
		const allShortcuts = yield* select(selectKeyboardShortcuts);
		let updatedShortcuts;

		if (!initialKeyStatus) {
			updatedShortcuts = _.unionBy([shortcut], allShortcuts, 'keyStatus');
		} else {
			updatedShortcuts = _.map(allShortcuts, (storedShortcut) =>
				storedShortcut.keyStatus === initialKeyStatus ? shortcut : storedShortcut,
			);
		}

		yield* call(updatePermanentDataSaga, EnumPermanentSettings.KEYBOARD_SHORTCUTS, updatedShortcuts);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processSaveShortcutSaga'});
	}
};
