import _ from 'lodash';
import {call, put, select} from 'typed-redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';

import {selectIsSoundMuted} from '@messenger/core/src/Redux/Client/Selectors/selectIsSoundMuted';
import {IWithTime} from '@messenger/core/src/Redux/IWithTime';
import {TSswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import {selectChannelIdFromChat} from '@messenger/core/src/Redux/Chats/Selectors/selectChannelIdFromChat';
import {twoMinutes} from '@messenger/core/src/BusinessLogic/TimeIntervals';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import AbstractUiContainer from '@messenger/core/src/Services/AbstractUiContainer';
import {selectIsChannelArchivedById} from '@messenger/core/src/Redux/Channels/Selectors/selectIsChannelArchivedById';
import {getChannelIdForSelector} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import {getChatIdForSelector} from '@messenger/core/src/Redux/Chats/Selectors/chatsDefaultSelectors';
import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';

export const isOldMessageEvent = (event: IWithTime): boolean => {
	let isOld = false;

	if (event.time) {
		const nowTime = new Date().getTime();
		const time = parseInt(event.time, 10);

		isOld = time > 0 ? nowTime - time >= twoMinutes : false;
	}

	return isOld;
};

export const isMessageForArchivedChannel = function* (message: TSswMessageType) {
	try {
		let isSpam = false;

		// messenger messages + system
		if (message.channelId) {
			isSpam = yield* select(selectIsChannelArchivedById, getChannelIdForSelector(message.channelId));
		}
		// chat messages
		else if (message.chatID) {
			const chatChannelId = yield* select(selectChannelIdFromChat, getChatIdForSelector(message.chatID));

			if (!chatChannelId) {
				ServiceFactory.logService.debug(`chatChannelId is falsy: ${chatChannelId}`);
				isSpam = false;
			} else {
				isSpam = yield* select(selectIsChannelArchivedById, getChannelIdForSelector(chatChannelId));
			}
		}

		return isSpam;
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'isMessageForArchivedChannel'});
	}

	return false;
};

export const playSoundIfNotSpamOrMutedSaga = function* (
	_browser: AbstractUiContainer,
	action: PayloadAction<TSswMessageType | TSswMessageType[]>,
) {
	try {
		const isSoundMuted = yield* select(selectIsSoundMuted);

		if (isSoundMuted) {
			return;
		}

		const messages = _.isArray(action.payload) ? action.payload : [action.payload];
		const messagesWithSound = _.filter(messages, 'sound');

		const isSoundDebugEnabled = yield* call([ServiceFactory.store, ServiceFactory.store.get], 'SOUND_DEBUG_ENABLED', {
			storeType: EnumStoreType.LOCAL,
		});

		if (isSoundDebugEnabled) {
			ServiceFactory.logService.log('messagesWithSound', messages);
		}

		for (let i = 0; i <= messagesWithSound.length; i++) {
			const message = messagesWithSound[i];

			if (!_.isUndefined(message)) {
				const isSpam = yield* isMessageForArchivedChannel(message);

				if (message.sound && message.sound.length > 0 && !isSpam) {
					yield* put(clientClientOnlyActions.playSound(message.sound));
				}
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'playSoundIfNotSpamOrMutedSaga', action});
	}
};
