import React, {Suspense} from 'react';
import HttpsRedirect from 'react-https-redirect';
import {Provider} from 'react-redux';
import loadable from '@loadable/component';
import {isMobile} from 'react-device-detect';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import Spinner from '@messenger/uikit/src/Spinner/Spinner';
import EnumChatPageTestIds from '@messenger/uikit/src/TestIds/EnumChatPageTestIds';

import store from 'src/Redux/store';
import 'src/Redux/sagaRunner';
import GlobalErrorBoundary from 'src/Components/Error/GlobalErrorBoundary';

const AppMobile = loadable(() => import('src/AppMobile'));
const AppDesktop = loadable(() => import('src/AppDesktop'));

const App = () => (
	<HttpsRedirect disabled={ServiceFactory.env.notProduction()}>
		<Suspense fallback={<Spinner isGlobal data-test-id={EnumChatPageTestIds.LOADING_SPINNER} />}>
			<Provider store={store}>
				<GlobalErrorBoundary>
					{isMobile && ServiceFactory.env.isAppMobileEnabled() ? <AppMobile /> : <AppDesktop />}
				</GlobalErrorBoundary>
			</Provider>
		</Suspense>
	</HttpsRedirect>
);

export default App;
