import {ACTION, CMDP_CHANNELSEEN, EnumStreamStatusType, CMDP_GETCHANNELS} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

import {TBooleanChannelFlags} from './channelsServerToClientActions';

class ChannelsClientToServerActions extends ClientToServerActions<EnumStore.CHANNELS> {
	readonly scope = EnumStore.CHANNELS;

	markChannelSeen = this.createAction(ACTION.CMDP_CHANNELSEEN, this.getPrepareAction<CMDP_CHANNELSEEN['params']>());
	notifyIsTyping = this.createAction(ACTION.CMDP_SSTATUS, (payload: {channelId?: string; chatIDs?: string}) => ({
		payload: {
			...payload,
			value: JSON.stringify({isTyping: true}),
			type: EnumStreamStatusType.PROXY_STATE_INFO,
		},
	}));
	requestSummary = this.createAction(ACTION.CMDP_CHANNELSSUMMARY, this.getPrepareAction<{usrKey: string}>());
	requestChannelsByFilter = this.createAction(
		ACTION.CMDP_GETCHANNELS,
		this.getPrepareAction<CMDP_GETCHANNELS['params'] & {requestUuid?: string}>(),
	);
	setChannelFlags = this.createAction(
		ACTION.CMDP_SETCHANNELINFO,
		({channelIds, ...data}: TUpdateChannelFlagsPayload, meta?: {withoutNotification?: boolean}) => ({
			payload: {
				...data,
				channelId: channelIds.join(','),
			},
			meta,
		}),
	);
}

export type TUpdateChannelFlagsPayload = TBooleanChannelFlags & {
	channelIds: string[];
};

export const channelsClientToServerActions = new ChannelsClientToServerActions();
