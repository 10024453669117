import { IBACKENDPAYLOAD } from "./backend";
import { ACTION } from "./command/action";
import { baseParamsType } from "./command/baseparams";
import { ICOMMAND, IKeyMaybeValue, IRESPONSE, RESULT } from "./command/icommand";
import { EnumBooleanStringified } from "../@types";

export class CMDP_LOGOUT implements ICOMMAND, IBACKENDPAYLOAD {
  public action: ACTION = ACTION.CMDP_LOGOUT;
  public params: baseParamsType & {
    code?: number;
    allDevices?: EnumBooleanStringified;
  };
  public payload?: unknown;
}

export class CMDP_LOGOUT_RESPONSE extends CMDP_LOGOUT implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

// alias to CMDP_LOGOUT
export class CMDP_SLOGOUT extends CMDP_LOGOUT {
  public action: ACTION = ACTION.CMDP_SLOGOUT;
}

export class CMDP_SLOGOUT_RESPONSE extends CMDP_LOGOUT_RESPONSE {
  public action: ACTION = ACTION.CMDP_SLOGOUT;
}
