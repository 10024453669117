import * as cmdcontrol from "./cmd-protocol";
import { ConnectionConfig, CmdConnection } from "./cmd-connection";

/**
 * this is minimal implementation of wraper for cmd-control-connection
 */
export class CmdControlSession {
  private readonly _connection: CmdConnection;

  public constructor(settings: ConnectionConfig) {
    this._connection = new CmdConnection(settings);
    this._connection.connect();
  }

  // noinspection JSUnusedGlobalSymbols
  public LoginWithUsernamePassword(login: string, password: string): void {
    const command = Object.assign(new cmdcontrol.CMDP_SLOGIN(), {
      params: { login, password, msn: true },
    });
    this._connection.send(command);
  }

  // noinspection JSUnusedGlobalSymbols
  public LoginWithSession(foreignSID: string): void {
    const command = Object.assign(new cmdcontrol.CMDP_SLOGIN(), {
      params: { foreignSID, msn: true },
    });
    this._connection.send(command);
  }

  // noinspection JSUnusedGlobalSymbols
  public LoginWithWebtoken(webtoken: string): void {
    const command = Object.assign(new cmdcontrol.CMDP_SLOGIN(), {
      params: { webtoken, msn: true },
    });
    this._connection.send(command);
  }

  // noinspection JSUnusedGlobalSymbols
  public sendCommand(command: cmdcontrol.ICOMMAND): void {
    this._connection.send(command);
  }

  public logout(exitCode: number): void {
    const command = Object.assign(new cmdcontrol.CMDP_LOGOUT(), {
      params: { code: exitCode },
    });
    this._connection.send(command);
  }

  public close(): void {
    this._connection.close();
  }

  public reconnect(): void {
    this._connection.reconnect();
  }

  public pause(isResumable = true): void {
    this._connection.pause(isResumable);
  }

  public resume(): boolean {
    return this._connection.resume();
  }

  public get isOpened(): boolean {
    return this._connection.isOpened;
  }
}
