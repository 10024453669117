// tslint:disable-next-line:interface-name
export interface Logger {
  log: (message?: any, ...optionalParams: any[]) => void;
  warn: (message?: any, ...optionalParams: any[]) => void;
  error: (message?: any, ...optionalParams: any[]) => void;
}

export let logger: Logger = {
  log: (message?: any, ...optionalParams: any[]) => {
    /**NOOP */
  },
  warn: (message?: any, ...optionalParams: any[]) => {
    /**NOOP */
  },
  // tslint:disable-next-line:object-literal-sort-keys
  error: (message?: any, ...optionalParams: any[]) => {
    /**NOOP */
  },
};

export function setLogger(value: Logger): void {
  logger = value;
}

// tslint:disable-next-line:interface-name
export interface LogEntry {
  level: number;
  time: number;
  message: string;
  params: any[];
}

export class LogCollector implements Logger {
  public static readonly LOG = 10;
  public static readonly WARN = 20;
  public static readonly ERROR = 30;

  // tslint:disable-next-line:variable-name
  private _logs: LogEntry[] = [];
  // tslint:disable-next-line:variable-name
  private _logger: Logger;

  // tslint:disable-next-line:no-shadowed-variable
  public constructor(logger?: Logger) {
    this._logger = logger;
  }

  public clear(): void {
    this._logs = [];
  }

  public get logs(): LogEntry[] {
    return this._logs;
  }

  public exportToStr(level = 0, entries = 0): string {
    let out = "";
    const len = this._logs.length;
    for (let i = entries === 0 ? 0 : Math.max(len - entries, 0); i < len; i++) {
      const entry = this._logs[i];
      if (entry.level < level) {
        continue;
      }
      const levelStr = entry.level >= LogCollector.ERROR ? "ERROR" : entry.level >= LogCollector.WARN ? "WARN" : "INFO";
      const d = new Date(entry.time);
      out +=
        (d.getHours() < 10 ? "0" : "") +
        d.getHours() +
        (d.getMinutes() < 10 ? ":0" : ":") +
        d.getMinutes() +
        (d.getSeconds() < 10 ? ":0" : ":") +
        d.getSeconds() +
        (d.getMilliseconds() < 100 ? (d.getMilliseconds() < 10 ? ".00" : ".0") : ".") +
        d.getMilliseconds() +
        " " +
        levelStr +
        " " +
        entry.message +
        (entry.params ? " " + JSON.stringify(entry.params) : "") +
        "\n";
    }
    return out;
  }

  public log(message?: any, ...params: any[]): void {
    this._push(LogCollector.LOG, message, params);
  }

  public warn(message?: any, ...params: any[]): void {
    this._push(LogCollector.WARN, message, params);
  }

  public error(message?: any, ...params: any[]): void {
    this._push(LogCollector.ERROR, message, params);
  }

  private _push(level: number, message: any, params: any[]): void {
    this._logs.push({ level, time: Date.now(), message, params });
    if (this._logger) {
      const method =
        level >= LogCollector.WARN
          ? this._logger.warn
          : level >= LogCollector.ERROR
          ? this._logger.error
          : this._logger.log;
      if (params) {
        const p = [message, ...params];
        try {
          // TODO edge-mobile doesn't like it
          method(...p);
        } catch {
          //
        }
      } else {
        method(message);
      }
    }
  }
}
