import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {sessionClientToServerActions} from '@messenger/core/src/Redux/Session/Actions';
import {selectStreamMode} from '@messenger/core/src/Redux/Stream/Selectors/selectStreamMode';
import {EnumChatMode} from '@messenger/core/src/BusinessLogic/EnumChatMode';

export const switchToLobbyModeIfIntendedSaga = function* () {
	try {
		const intendedChatMode = yield* select(selectStreamMode);

		if (intendedChatMode === EnumChatMode.LOBBY) {
			yield* put(sessionClientToServerActions.setLiveChatPreview(false));
			yield* put(sessionClientToServerActions.setAppearOffline(false));
			yield* put(sessionClientToServerActions.enableLobbyMode());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'switchToLobbyModeIfIntendedSaga'});
	}
};
