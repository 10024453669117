import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectChatVms} from '@messenger/core/src/Redux/Chats/Selectors/chatsDefaultSelectors';

export const selectHasActiveNoAdminChats = createSelector([selectChatVms], (chatVms) =>
	_.some(
		chatVms,
		(chatVm) =>
			_.isMatch(chatVm, {
				isExited: false,
				isTestChat: false,
				isAdmin: false,
				isAllChatsAggregate: false,
				isLobbyChat: false,
			}) || chatVm.lobbyUsersCount > 0,
	),
);
