import {select, put} from 'typed-redux-saga';
import {CMDP_SDEVICESTATUS} from 'cmd-control-client-lib';
import {PayloadAction} from '@reduxjs/toolkit';

import {selectSpeedTestMeasured} from '@messenger/core/src/Redux/SpeedTest/Selectors/selectSpeedTestMeasured';
import {selectIsWebRtcOutputStreamActive} from '@messenger/core/src/Redux/Stream/Selectors/selectIsWebRtcOutputStreamActive';
import {mediaDeviceClientToServerActions} from '@messenger/core/src/Redux/MediaDevice/Actions';
import {selectIsMicDisabled} from '@messenger/core/src/Redux/MediaDevice/Selectors/selectIsMicDisabled';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectChosenMediaDevices} from '@messenger/core/src/Redux/MediaDevice/Selectors/selectChosenMediaDevices';
import {selectChosenMediaDevicesInfo} from '@messenger/core/src/Redux/MediaDevice/Selectors/selectChosenMediaDevicesInfo';

export const setStreamingDeviceStatusSaga = function* (_action?: PayloadAction<boolean>): Generator<any, any, any> {
	try {
		const isWebRtcStream = yield* select(selectIsWebRtcOutputStreamActive);

		if (!isWebRtcStream) {
			return;
		}

		const {videoTrackSettings, isMicrophoneAvailable} = ServiceFactory.webRtcApi;
		const audioTrackSettings = ServiceFactory.webRtcApi.audioTrackSettings as IAudioTrackSettings;

		const mediaDevicesInfo = yield* select(selectChosenMediaDevicesInfo);
		const bandWidth = yield* select(selectSpeedTestMeasured);
		const mediaDevices = yield* select(selectChosenMediaDevices);
		const isMicDisabled = yield* select(selectIsMicDisabled);
		const deviceStatus = {
			muted: isMicDisabled || !mediaDevices.audioinput,
		} as CMDP_SDEVICESTATUS['params'];

		if (mediaDevicesInfo?.videoDevice?.label) {
			deviceStatus.camName = mediaDevicesInfo.videoDevice.label;
		}

		if (mediaDevicesInfo?.audioDevice?.label) {
			deviceStatus.microName = mediaDevicesInfo.audioDevice.label;
		}

		if (audioTrackSettings?.volume) {
			deviceStatus.volume = audioTrackSettings.volume;
		}

		if (audioTrackSettings?.channelCount) {
			deviceStatus.channels = audioTrackSettings.channelCount;
		}

		if (audioTrackSettings?.sampleRate) {
			deviceStatus.sampleRate = audioTrackSettings.sampleRate;
		}

		if (audioTrackSettings?.sampleSize) {
			deviceStatus.sampleSize = audioTrackSettings.sampleSize;
		}

		if (videoTrackSettings?.height) {
			deviceStatus.height = videoTrackSettings.height;
		}

		if (videoTrackSettings?.width) {
			deviceStatus.width = videoTrackSettings.width;
		}

		if (bandWidth) {
			deviceStatus.bandWidth = Math.round(bandWidth);
		}

		yield* put(mediaDeviceClientToServerActions.setDeviceStatus(deviceStatus));

		/**
		 * @note After discussion with @vl, we're about to send mic status on stream start for WebRTC only streams
		 *       in case we have the mic and audio stream.
		 *
		 * @link http://phabricator.y/T1583
		 */
		if (isMicrophoneAvailable) {
			yield* put(mediaDeviceClientToServerActions.setMicStatus({isMuted: isMicDisabled}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'setStreamingDeviceStatusSaga'});
	}
};

interface IAudioTrackSettings extends MediaTrackSettings {
	volume: number;
	channelCount?: number;
}
