import _ from 'lodash';
import {all, call} from 'typed-redux-saga';

import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {removeOrModifyTemporaryMessageSaga} from '@messenger/core/src/Redux/Messages/Sagas/removeOrModifyTemporaryMessageSaga';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export const removeTemporaryMessagesSaga = function* (action: ReturnType<typeof messagesClientOnlyActions.setMany>) {
	try {
		const messagesByKeys = _.chain(action.payload).groupBy('messageKey').value();

		yield* all(
			_.map(messagesByKeys, (messages, messageKey) => {
				return call(
					removeOrModifyTemporaryMessageSaga,
					messageKey,
					_.chain(messages).map('channelId').compact().value(),
				);
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'removeTemporaryMessagesSaga'});
	}
};
