import {createSlice} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

import {channelInfosClientOnlyActions} from './Actions/channelInfosClientOnlyActions';
import {channelInfoEntityAdapter} from './channelInfoEntityAdapter';

export const channelInfoSlice = createSlice({
	name: EnumStore.CHANNEL_INFOS,
	initialState: channelInfoEntityAdapter.getInitialState<{
		isLoading: boolean;
		isError: boolean;
		requestedIds: string[];
	}>({
		isLoading: false,
		isError: false,
		requestedIds: [],
	}),
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(channelInfosClientOnlyActions.upsertMany, channelInfoEntityAdapter.upsertMany);
		builder.addCase(channelInfosClientOnlyActions.addMany, channelInfoEntityAdapter.addMany);
		builder.addCase(channelInfosClientOnlyActions.upsertOne, channelInfoEntityAdapter.upsertOne);
		builder.addCase(channelInfosClientOnlyActions.removeOne, channelInfoEntityAdapter.removeOne);
		builder.addCase(channelInfosClientOnlyActions.updateStore, (state, {payload: {isLoading, requestedIds}}) => {
			state.requestedIds = requestedIds;
			state.isLoading = isLoading;
		});
	},
});
