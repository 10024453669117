import {takeEvery} from 'typed-redux-saga';

import {processLoginFormSubmitSaga} from '@messenger/core/src/Redux/LoginForm/Sagas/processLoginFormSubmitSaga';
import {processLoginFormResultSaga} from '@messenger/core/src/Redux/LoginForm/Sagas/processLoginFormResultSaga';
import {rememberCredentialsIfNeededSaga} from '@messenger/core/src/Redux/LoginForm/Sagas/rememberCredentialsIfNeededSaga';
import {loginFormClientOnlyActions} from '@messenger/core/src/Redux/LoginForm/Actions/loginFormClientOnlyActions';
import {logSuccessLoginSaga} from '@messenger/core/src/Redux/LoginForm/Sagas/logSuccessLoginSaga';

import {showGtsNotConfirmedWarning} from 'src/Redux/LoginForm/Sagas/showGtsNotConfirmedWarningSaga';

function* loginFormSagaWatcher() {
	yield* takeEvery(loginFormClientOnlyActions.submit.type, processLoginFormSubmitSaga);
	yield* takeEvery(loginFormClientOnlyActions.submitResponseReceived.type, processLoginFormResultSaga);
	yield* takeEvery(loginFormClientOnlyActions.showGtsNotConfirmedWarning, showGtsNotConfirmedWarning);
	yield* takeEvery(loginFormClientOnlyActions.submitSuccess.type, rememberCredentialsIfNeededSaga);
	yield* takeEvery(loginFormClientOnlyActions.submitSuccess.type, logSuccessLoginSaga);
}

export default loginFormSagaWatcher;
