import _ from 'lodash';
import type {WebRtcInputConnection, IWebRtcInputConnection} from '@campoint/vxwebrtc';

import {IMediaWebRtc} from '@messenger/core/src/BusinessLogic/IMediaWebRtc';
import IWebRtcPreviewApi from '@messenger/core/src/Services/WebRtcPreviewApi/IWebRtcPreviewApi';
import {dynamicImport} from '@messenger/core/src/Utils/dynamicImport';

class WebRtcPreviewApi implements IWebRtcPreviewApi {
	private _subscribers: HTMLVideoElement[] = [];
	private _connection?: WebRtcInputConnection;
	private _connectionOption?: IWebRtcInputConnection;

	setConnectionOption(mediaWebRtc: IMediaWebRtc) {
		this._connectionOption = {
			wsUrl: mediaWebRtc.signalingUrl,
			streamInfo: {
				applicationName: mediaWebRtc.appName,
				streamName: mediaWebRtc.streamName,
				sessionId: '',
			},
			debounceTime: 1000,
			onStop: this.stop,
			onError: _.noop,
			onTrack: this.onTrack.bind(this),
		};
	}

	play() {
		const connectionOptions = this._connectionOption;

		// TODO: remove after backend will be ready
		if (!connectionOptions) {
			return;
		}

		dynamicImport(() => import('@campoint/vxwebrtc')).then(({WebRtcInputConnection}) => {
			this._connection = new WebRtcInputConnection(connectionOptions);
			this._connection.start();
		});
	}

	stop() {
		this._connection?.stop();
		this._connection = undefined;
		this._subscribers = [];
	}

	addSubscription(sub: HTMLVideoElement) {
		if (this._subscribers.indexOf(sub) === -1) {
			this._subscribers.push(sub);
			const stream = this._connection?.getStream();

			sub.srcObject = stream || null;
		}
	}

	onTrack() {
		const stream = this._connection?.getStream();

		this._subscribers.forEach((sub) => {
			sub.srcObject = stream || null;
		});
	}

	hasSound() {
		if (!this._connection) {
			return false;
		}

		const stream = this._connection.getStream();

		if (!stream) {
			return false;
		}

		return stream.getAudioTracks().length > 0;
	}

	removeSubscription(sub: HTMLVideoElement) {
		_.remove(this._subscribers, sub);
	}
}

export default WebRtcPreviewApi;
