import {ChannelInfoType, EnumBooleanStringified, EnumProductId} from 'cmd-control-client-lib';
import _, {Dictionary} from 'lodash';

import {EnumUserAvatarSize} from '@messenger/core/src/BusinessLogic/EnumUserAvatarSize';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {parseBoolean} from '@messenger/core/src/Utils/Strings/parseBoolean';

import {TChannelInfoPayload} from './Model';

export const USERNAME_FALLBACK = '...';

export default class ChannelInfoVM {
	protected readonly avatars: Dictionary<string> = {
		[EnumUserAvatarSize.SMALL]: '',
		[EnumUserAvatarSize.MEDIUM]: '',
		[EnumUserAvatarSize.LARGE]: '',
	};
	readonly isAvs?: boolean;
	readonly channelId: string;
	readonly canSingleSession: boolean;
	readonly canSendGifts: boolean;
	readonly canControlToy: boolean;
	readonly canCall0900: boolean;
	readonly canBuyShopContent: boolean;
	readonly canSendVideo: boolean;
	readonly canBeDiscounted: boolean;
	readonly canReceiveImages: boolean;
	readonly isVIP: boolean;
	readonly canReceiveAudio: boolean;
	readonly canReceiveVideo: boolean;
	readonly canPurchaseMedia: boolean;
	readonly canReceiveTicket: boolean;
	readonly productId: EnumProductId;
	readonly isTvAdmin: boolean;
	readonly username: string;
	readonly avatar: string;
	readonly payload: TChannelInfoPayload;

	constructor(channelInfoEntity?: ChannelInfoType) {
		const sizes = _.chain(channelInfoEntity).get('imgSrcSet', '').split(',').value();

		_.forEach(sizes, (line: string) => {
			if (_.isEmpty(line)) {
				return;
			}

			const parts = _.split(line, ' ');

			this.avatars[parseInt(parts[1], 10)] = parts[0];
		});

		if (!_.isUndefined(channelInfoEntity?.avs)) {
			this.isAvs = parseBoolean(channelInfoEntity?.avs);
		}

		this.channelId = _.get(channelInfoEntity, 'channelId', '');
		this.canSingleSession = parseBoolean(_.get(channelInfoEntity, 'canSingleSession', EnumBooleanStringified.FALSE));
		this.canSendGifts = parseBoolean(_.get(channelInfoEntity, 'canSendGifts', EnumBooleanStringified.FALSE));
		this.canControlToy = parseBoolean(_.get(channelInfoEntity, 'canControlToy', EnumBooleanStringified.FALSE));
		this.canCall0900 = parseBoolean(_.get(channelInfoEntity, 'canCall0900', EnumBooleanStringified.FALSE));
		this.canBuyShopContent = parseBoolean(_.get(channelInfoEntity, 'canBuyShopContent', EnumBooleanStringified.FALSE));
		this.canSendVideo = parseBoolean(_.get(channelInfoEntity, 'canSendVideo', EnumBooleanStringified.FALSE));
		this.canBeDiscounted = parseBoolean(_.get(channelInfoEntity, 'canBeDiscounted', EnumBooleanStringified.FALSE));
		this.canReceiveImages = parseBoolean(_.get(channelInfoEntity, 'canReceiveImage', EnumBooleanStringified.FALSE));
		this.isVIP = parseBoolean(_.get(channelInfoEntity, 'isVIP', EnumBooleanStringified.FALSE) as string);
		this.canReceiveAudio = parseBoolean(_.get(channelInfoEntity, 'canReceiveAudio', EnumBooleanStringified.FALSE));
		this.canReceiveVideo = parseBoolean(_.get(channelInfoEntity, 'canReceiveVideo', EnumBooleanStringified.FALSE));
		this.canPurchaseMedia = parseBoolean(_.get(channelInfoEntity, 'canPurchaseMedia', EnumBooleanStringified.FALSE));
		this.canReceiveTicket = parseBoolean(
			_.get(channelInfoEntity, 'canReceiveTicket', ServiceFactory.env.getDefaultCanReceiveTicket()),
		);
		this.productId = _.get(channelInfoEntity, 'productId', EnumProductId.Default);
		this.isTvAdmin = this.productId === EnumProductId.TV;
		this.username = _.get(channelInfoEntity, 'ualias', USERNAME_FALLBACK);
		this.avatar = _.get(channelInfoEntity, 'imgSrc', '');

		const parsedPayload = _.attempt(JSON.parse, channelInfoEntity?.payload);

		this.payload = _.isError(parsedPayload) ? {} : parsedPayload;
	}

	getAvatar(size = EnumUserAvatarSize.MEDIUM): string {
		return _.get(this.avatars, size) || this.avatar;
	}
}

export type ChannelAbilities = Pick<
	ChannelInfoVM,
	'canReceiveVideo' | 'canReceiveImages' | 'canReceiveAudio' | 'canPurchaseMedia'
>;
