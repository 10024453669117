import {PayloadAction} from '@reduxjs/toolkit';
import {delay, put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {VStep} from '@messenger/core/src/Redux/Introduction';
import {introductionClientOnlyActions} from '@messenger/core/src/Redux/Introduction/Action/IntroductionClientOnlyActions';
import EnumIntroductionStep from '@messenger/core/src/Redux/Introduction/EnumIntroductionStep';
import {getIsIntroRunning} from '@messenger/core/src/Redux/Introduction/Selectors';

const switchStepToReplyMessage = function* (action: PayloadAction<VStep>) {
	try {
		const isRunning = yield* select(getIsIntroRunning);

		if (!isRunning) {
			return;
		}

		if (action.payload.step === EnumIntroductionStep.MESSAGE_AREA_REPLY_MESSAGE) {
			yield* delay(500);
			yield* put(introductionClientOnlyActions.goToStep(action.payload.step));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'switchStepToReplyMessage'});
	}
};

export default switchStepToReplyMessage;
