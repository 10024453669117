import {ACTION, CMDP_GETCHANNELINFO_RESPONSE} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class ChannelInfosServerToClientActions extends ServerToClientActions<EnumStore.CHANNEL_INFOS> {
	readonly scope = EnumStore.CHANNEL_INFOS;

	channelInfosRequested = this.createAction(
		ACTION.CMDP_GETCHANNELINFO,
		this.getPrepareAction<CMDP_GETCHANNELINFO_RESPONSE['values'], CMDP_GETCHANNELINFO_RESPONSE, false>(),
	);
}

export const channelInfosServerToClientActions = new ChannelInfosServerToClientActions();
