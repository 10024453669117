import {createSelector} from '@reduxjs/toolkit';
import _ from 'lodash';

import {selectAllTicketShowEntitiesVms} from '@messenger/core/src/Redux/TicketShow/Selectors/selectAllTicketShowEntitiesVms';
import {_selectTicketIds} from '@messenger/core/src/Redux/TicketShow/Selectors/ticketShowDefaultSelectors';

export const selectAllTicketShowVms = createSelector(
	[selectAllTicketShowEntitiesVms, _selectTicketIds],
	(ticketShowEntityVMs, ticketShowIds) =>
		_.compact(_.map(ticketShowIds, (ticketShowId) => ticketShowEntityVMs[ticketShowId])),
);
