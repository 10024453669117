import _ from 'lodash';
import ResizeObserver from 'resize-observer-polyfill';
import {RefObject, useEffect, useRef} from 'react';

import {useStableCallback} from '@messenger/core/src/Hooks/useStableCallback';

export const useKeepScrollBottomOnResize = (scrollerRef: RefObject<HTMLElement>, isAtBottom: boolean) => {
	const el = scrollerRef.current;
	const resizeTimer = useRef<number | undefined>();
	const isAtBottomBeforeResize = useRef(true);

	const onResize = useStableCallback((x) => {
		if (resizeTimer.current) {
			clearTimeout(resizeTimer.current);
			resizeTimer.current = undefined;
		} else {
			isAtBottomBeforeResize.current = isAtBottom;
		}

		resizeTimer.current = setTimeout(() => {
			resizeTimer.current = undefined;

			const scrollHeight = _.get(scrollerRef, 'current.scrollHeight');

			if (isAtBottomBeforeResize.current && scrollHeight && scrollerRef.current) {
				scrollerRef.current.scrollTop = scrollHeight;
			}
		}, 200);
	});

	useEffect(() => {
		if (el) {
			const observer = new ResizeObserver(onResize);

			observer.observe(el);

			return () => {
				clearTimeout(resizeTimer.current);
				observer.disconnect();
			};
		}

		return;
	}, [onResize, el]);
};
