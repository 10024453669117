import {Trans} from 'react-i18next';
import {call, select} from 'typed-redux-saga';
import React from 'react';

import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';
import {selectGtcUrl} from '@messenger/core/src/Redux/LoginForm/Selectors/selectGtcUrl';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';

export const showGtsNotConfirmedWarning = function* () {
	try {
		const url = yield* select(selectGtcUrl) || '';

		yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
			text: (
				<Trans
					i18nKey="login:gtcNotConfirmed"
					// eslint-disable-next-line jsx-a11y/anchor-has-content, react/jsx-key
					components={[<br />, <a target="_blank" rel="noopener noreferrer" href={url} />]}
				/>
			),
			variant: EnumAbstractNotificationVariant.WARNING,
			key: EnumSnackbarNotificationKeys.GTC_WARNING,
			isNonClosable: true,
			persist: true,
		});
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'showGtsNotConfirmedWarning'});
	}
};
