import {PayloadAction} from '@reduxjs/toolkit';
import {call, select} from 'typed-redux-saga';
import {SystemMessageKey} from 'cmd-control-client-lib';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import MessageHelper from '@messenger/core/src/BusinessLogic/MessagesDisplayFilters/MessageHelper';
import {TSswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import {selectIsOnline} from '@messenger/core/src/Redux/Session/Selectors/selectIsOnline';
import {selectChatVmById, getChatIdForSelector} from '@messenger/core/src/Redux/Chats/Selectors/chatsDefaultSelectors';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

export function* switchActiveChannelToChatSaga(action: PayloadAction<TSswMessageType>) {
	try {
		const isStreaming = yield* select(selectIsOnline);

		if (
			!isStreaming ||
			MessageHelper.isOldMessage(action.payload) ||
			SystemMessageKey.h_chat_video_start !== action.payload.messageKey
		) {
			return;
		}

		const guestIdentity = yield* select(selectCurrentGuestIdentity);

		if (
			guestIdentity?.guestType === EnumGuestType.CHANNEL &&
			action.payload.channelId === guestIdentity.channelId &&
			!_.isUndefined(action.payload.chatID)
		) {
			const chat = yield* select(selectChatVmById, getChatIdForSelector(action.payload.chatID));

			if (chat && !chat.isExited) {
				const {goToChatMessages} = yield* getNavigationContext();

				yield* call(goToChatMessages, chat.chatId);
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'switchActiveChannelToChatSaga'});
	}
}
