import {delay, put, select} from 'typed-redux-saga';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {contactNoteClientOnlyActions} from '@messenger/core/src/Redux/ContactNote/Actions/contactNoteClientOnlyActions';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {FIELD_SAVING_INTERVAL} from '@messenger/core/src/BusinessLogic/Constants/Constants';
import {contactNoteClientToServerActions} from '@messenger/core/src/Redux/ContactNote/Actions/contactNoteClientToServerAction';
import {selectContactNoteVmById} from '@messenger/core/src/Redux/ContactNote/Selectors/contactNoteDefaultSelectors';
import {getChannelIdForSelector} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';

export function* processSaveContactNoteFieldSaga({
	payload,
}: ReturnType<typeof contactNoteClientOnlyActions.updateContactNoteField>) {
	try {
		if (!payload.forceSave) {
			yield* delay(FIELD_SAVING_INTERVAL);
		}

		const currentGuestIdentity = yield* select(selectCurrentGuestIdentity);

		if (!currentGuestIdentity?.channelId) {
			return;
		}

		const oldContactNote = yield* select(
			selectContactNoteVmById,
			getChannelIdForSelector(currentGuestIdentity.channelId),
		);

		const fieldsData = oldContactNote
			? {...oldContactNote, channelId: currentGuestIdentity.channelId, ..._.omit(payload, ['forceSave'])}
			: {channelId: currentGuestIdentity.channelId, ..._.omit(payload, ['forceSave'])};

		yield* put(contactNoteClientOnlyActions.upsertMany([fieldsData]));

		yield* put(contactNoteClientToServerActions.saveContactNote(fieldsData));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processSaveContactNoteFieldSaga'});
	}
}
