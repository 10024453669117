import { ACTION, channelIdType, ICOMMAND, IKeyMaybeValue, IRESPONSE, RESULT } from "../../cmd-protocol";
import { baseParamsType, deviceIdType } from "../command/baseparams";
import { VideoSdkConfig } from "./videosdk";
import { EnumBooleanStringified, JSONString } from "../../@types";

export enum EnumMobileTokenType {
  FCM = "fcm",
  APN = "apn",
  VOIP = "voip",
}

export enum EnumMobileVideoCallState {
  // caller or receiver action
  Cancelled = "CANCELLED",
  // receiver action
  Answered = "ANSWERED",
  // receiver action
  Declined = "DECLINED",
  /* avs denied*/
  FskDenied = "FSKDENIED",
  // others
  Busy = "BUSY",
  NoAnswer = "NOANSWER",
  Failed = "FAILED",
  Unknown = "UNKNOWN",
}

export class CMDP_SETMOBILETOKEN implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SETMOBILETOKEN;
  public params: baseParamsType & {
    type: EnumMobileTokenType;
    token: string;
    bundleId?: string;
  };
}

export class CMDP_SETMOBILETOKEN_RESPONSE extends CMDP_SETMOBILETOKEN implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

export class CMDP_STARTMOBILEVIDEOCALL implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_STARTMOBILEVIDEOCALL;
  public params: baseParamsType & channelIdType;
}

export class CMDP_STARTMOBILEVIDEOCALL_RESPONSE extends CMDP_STARTMOBILEVIDEOCALL implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: { videoSdkConfig: JSONString<VideoSdkConfig> };
}

export class CMDC_STARTMOBILEVIDEOCALL implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_STARTMOBILEVIDEOCALL;
  public params: channelIdType & {
    sessionID: string;
    ualias: string;
    imgSrc: string;
    videoSdkConfig: JSONString<VideoSdkConfig>;
    isSoftChat: EnumBooleanStringified;
  };
}

export class CMDP_UPDATEMOBILEVIDEOCALL implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_UPDATEMOBILEVIDEOCALL;
  public params: baseParamsType & {
    videoSdkConfig: string;
    state: EnumMobileVideoCallState;
  };
}

export class CMDP_UPDATEMOBILEVIDEOCALL_RESPONSE extends CMDP_UPDATEMOBILEVIDEOCALL implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

export class CMDC_UPDATEMOBILEVIDEOCALL implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_UPDATEMOBILEVIDEOCALL;
  public params: channelIdType &
    deviceIdType & {
      sessionID: string;
      videoSdkConfig: JSONString<VideoSdkConfig>;
      state: EnumMobileVideoCallState;
    };
}

export class CMDP_LOGOUTOTHERMOBILE implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_LOGOUTOTHERMOBILE;
  public params: baseParamsType;
}

export class CMDP_LOGOUTOTHERMOBILE_RESPONSE extends CMDP_LOGOUTOTHERMOBILE implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}
