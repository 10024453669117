import {CMDC_LIVECHAT} from 'cmd-control-client-lib';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum EnumLiveChatsAction {
	ADD_MANY = 'ADD_MANY',
}

class LiveChatsClientOnlyActions extends ClientOnlyActions<EnumStore.LIVE_CHATS> {
	readonly scope = EnumStore.LIVE_CHATS;

	addMany = this.createAction(EnumLiveChatsAction.ADD_MANY, this.getPrepareAction<CMDC_LIVECHAT['params'][]>());
}

export const liveChatsClientOnlyActions = new LiveChatsClientOnlyActions();
