enum EnumChatFontSize {
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large',
	EXTRA_LARGE = 'extraLarge',
}

export const availableFontSizes = [
	EnumChatFontSize.SMALL,
	EnumChatFontSize.MEDIUM,
	EnumChatFontSize.LARGE,
	EnumChatFontSize.EXTRA_LARGE,
];

export default EnumChatFontSize;
