import {call, select, take, put, race, delay} from 'typed-redux-saga';
import _ from 'lodash';
import type {GroupedVirtuosoHandle} from 'react-virtuoso';
import {Action} from 'redux';

import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {selectRunningChatVmByChannelId} from '@messenger/core/src/Redux/Chats/Selectors/selectRunningChatVmByChannelId';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {selectChannelMessagesIds} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/ChannelMessages/selectChannelMessagesIds';
import {ObjectStoreService} from '@messenger/core/src/Services/ObjectStore/ObjectStoreService';
import {routingClientOnlyActions} from '@messenger/core/src/Redux/Routing/Actions/routingClientOnlyActions';
import {findMessageIndexInHistorySaga} from '@messenger/core/src/Redux/Messages/Sagas/findMessageIndexInHistorySaga';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {getMessageHistoryRefId} from '@messenger/uikit/src/MessagesHistory/MessagesHistory';

export function* navigateToMessageSaga(action: ReturnType<typeof messagesClientOnlyActions.navigateToMessage>) {
	try {
		const {
			payload: {channelId, messageId},
		} = action;

		if (!channelId) {
			return;
		}

		const messageIds = yield* select(selectChannelMessagesIds, {channelId});
		let waitForFirstItemIndexChange = false;
		let messageIndex = _.indexOf(messageIds, messageId);

		if (messageIndex === -1) {
			messageIndex = yield* call(findMessageIndexInHistorySaga, action);

			if (messageIndex === -1) {
				return;
			}

			waitForFirstItemIndexChange = true;
		}

		const {goToChatMessages, goToChannelMessages, isChatPage, isChannelPage} = yield* getNavigationContext();

		const runningChat = yield* select(selectRunningChatVmByChannelId, {channelId});

		if (runningChat) {
			if (!isChatPage(runningChat.chatId)) {
				yield* call(goToChatMessages, runningChat.chatId);

				yield* take(routingClientOnlyActions.updateStateAfterNavigation);
				waitForFirstItemIndexChange = true;
			}
		} else if (!isChannelPage(channelId)) {
			yield* call(goToChannelMessages, channelId);

			yield* take(routingClientOnlyActions.updateStateAfterNavigation);
			waitForFirstItemIndexChange = true;
		}

		if (waitForFirstItemIndexChange) {
			yield* race([
				delay(500),
				take((action: Action) => {
					if (action.type !== messagesClientOnlyActions.virtuosoChangeFirstItemIndex.type) {
						return false;
					}

					const {payload} = action as ReturnType<typeof messagesClientOnlyActions.virtuosoChangeFirstItemIndex>;

					return payload === channelId;
				}),
			]);

			yield* delay(500);
		}

		const virtuosoHandle = ObjectStoreService.get<GroupedVirtuosoHandle>(getMessageHistoryRefId(channelId));

		if (virtuosoHandle) {
			const messageIds = yield* select(selectChannelMessagesIds, {channelId});

			messageIndex = _.indexOf(messageIds, messageId);

			virtuosoHandle.scrollToIndex(messageIndex);
			yield* put(messagesClientOnlyActions.highlightMessage({messageId}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'navigateToMessageSaga'});
	}
}
