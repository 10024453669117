import {InitialCurrencies} from 'cmd-control-client-lib';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum EnumActions {
	SET_CURRENCIES = 'SET_CURRENCIES',
	COINS_2_EURO_FACTOR = 'COINS_2_EURO_FACTOR',
}

class CurrencyClientOnlyActions extends ClientOnlyActions {
	scope = EnumStore.CURRENCY;
	setCurrencies = this.createAction(EnumActions.SET_CURRENCIES, this.getPrepareAction<InitialCurrencies>());
	setCoins2EuroFactor = this.createAction(EnumActions.COINS_2_EURO_FACTOR, this.getPrepareAction<number>());
}

export const currencyClientOnlyActions = new CurrencyClientOnlyActions();
