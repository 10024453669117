import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';

enum EnumMediaClientOnlyActions {
	REQUEST_PRICES = 'REQUEST_PRICES',
}

class MediaPricesClientOnlyActions extends ClientOnlyActions<EnumStore.MEDIA_PRICES> {
	readonly scope = EnumStore.MEDIA_PRICES;

	requestPrices = this.createAction(EnumMediaClientOnlyActions.REQUEST_PRICES);
}

export const mediaPricesClientOnlyActions = new MediaPricesClientOnlyActions();
