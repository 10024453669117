import {ACTION, CMDP_SGETCHATSSUMMARY} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class ChatSummaryClientToServerActions extends ClientToServerActions<EnumStore.CHAT_SUMMARY> {
	readonly scope = EnumStore.CHAT_SUMMARY;

	fetchChatSummary = this.createAction(
		ACTION.CMDP_SGETCHATSSUMMARY,
		this.getPrepareAction<CMDP_SGETCHATSSUMMARY['params']>(),
	);
}

export const chatSummaryClientToServerActions = new ChatSummaryClientToServerActions();
