import {call, put} from 'typed-redux-saga';

import {streamPreviewClientOnlyActions} from '@messenger/core/src/Redux/StreamPreview/Actions/streamPreviewClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {MAIN_PREVIEW_ID} from '@messenger/core/src/Services/JpegSocketPreviewApi/AbstractJpegSocketPreviewApi';

export function* closeMainPreviewSocketSaga() {
	try {
		const api = ServiceFactory.jpegSocketPreviewApi;

		yield* call([api, api.removePreview], MAIN_PREVIEW_ID);
		yield* put(streamPreviewClientOnlyActions.resetSources());
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'closeMainPreviewSocketSaga'});
	}
}
