import _ from 'lodash';
import {ACTION, CMDP_SGETPRICESMEDIA_RESPONSE, ResultCode} from 'cmd-control-client-lib';
import type {TFunction} from 'react-i18next';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {ServerToClientActions, TServerMeta} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class MediaPricesServerToClientActions extends ServerToClientActions<EnumStore.MEDIA_PRICES> {
	readonly scope = EnumStore.MEDIA_PRICES;

	receivedByChannelId = this.createAction(
		ACTION.CMDP_SMEDIAGETPRICES,
		(
			_payload: CMDP_SGETPRICESMEDIA_RESPONSE['values'] | undefined,
			meta: TServerMeta<CMDP_SGETPRICESMEDIA_RESPONSE>,
		) => {
			const reason =
				_.size(meta.result.reason) !== 0
					? meta.result.reason
					: getErrorTranslated(String(meta.result.code), ServiceFactory.i18n.t);

			const isOk = meta.result.code === ResultCode.OK;
			const channelIds = meta.params.channelId?.split(',');

			return isOk
				? {
						payload: {
							prices: meta.params.channelId
								? meta.params.channelId.split(',').map((channelId: string) => ({...meta.payload, channelId}))
								: [],
							defaultPrices: meta.params.channelId ? undefined : meta.payload,
							channelIds,
						},
				  }
				: {
						payload: {
							channelIds,
							defaultPricesError: meta.params.channelId ? undefined : reason,
						},
						error: reason,
				  };
		},
	);
}

const getErrorTranslated = (errorCode: string, t: TFunction) => {
	switch (errorCode) {
		case '4003':
			return t('common:server-error_4003');

		case '4004':
			return t('common:server-error_4004');

		case '4010':
			return t('common:server-error_4010');

		case '4011':
			return t('common:server-error_4011');

		case '4012':
			return t('common:server-error_4012');

		case '4013':
			return t('common:server-error_4013');

		case '5002':
			return t('common:server-error_5002');

		case '5004':
			return t('common:server-error_5004');

		case '5005':
			return t('common:server-error_5005');

		case '5096':
			return t('common:server-error_5096');

		case '5097':
			return t('common:server-error_5097');

		case '5098':
			return t('common:server-error_5098');

		case '5099':
			return t('common:server-error_5099');

		default:
			return errorCode;
	}
};

export const mediaPricesServerToClientActions = new MediaPricesServerToClientActions();
