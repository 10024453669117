import _ from 'lodash';
import {put} from 'typed-redux-saga';
import {EnumMediaType, EnumMessageDirection, UploadedMediaDirection} from 'cmd-control-client-lib';

import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {mediaClientOnlyActions} from '@messenger/core/src/Redux/Media/Actions/mediaClientOnlyActions';
import {MEDIA_FIELDS} from '@messenger/core/src/Redux/Media/MediaVm';
import {MEDIA_LINK_FIELDS} from '@messenger/core/src/Redux/MediaLinks/MediaLinkVm';

export const updateMediaAfterSendMessageWithMediaSaga = function* ({
	payload,
}: ReturnType<typeof messagesClientOnlyActions.setMany>) {
	try {
		const prepareData = _.chain(payload)
			.filter(
				({mediaType, channelId, mediaMd5, direction}) =>
					direction === EnumMessageDirection.OUT &&
					!_.isEmpty(channelId) &&
					!_.isEmpty(mediaMd5) &&
					_.includes([EnumMediaType.BITMAP, EnumMediaType.VIDEO, EnumMediaType.AUDIO], mediaType),
			)
			.groupBy((groupBy) => `${groupBy.mediaMd5}+${groupBy.channelId}`)
			.map((messages) => {
				return {
					..._.chain(messages)
						.last()
						.pick([...MEDIA_FIELDS, ...MEDIA_LINK_FIELDS])
						.value(),
					direction: UploadedMediaDirection.OUT,
				};
			})
			.flatten()
			.value();

		if (!_.isUndefined(prepareData) && !_.isEmpty(prepareData)) {
			yield* put(mediaClientOnlyActions.upsertMany(prepareData));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'updateMediaAfterSendMessageWithMediaSaga'});
	}
};
