import {ACTION, CMDP_SETMOBILETOKEN, EnumPushNotificationFilter} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class PushNotificationsClientToServerActions extends ClientToServerActions<EnumStore.PUSH_NOTIFICATIONS> {
	readonly scope = EnumStore.PUSH_NOTIFICATIONS;

	setNotificationToken = this.createAction(
		ACTION.CMDP_SETMOBILETOKEN,
		this.getPrepareAction<CMDP_SETMOBILETOKEN['params']>(),
	);

	saveSettings = this.createAction(ACTION.CMDP_SETSETTINGS, (pushNotificationFilter: EnumPushNotificationFilter) => ({
		payload: {pushNotificationFilter},
	}));

	requestSettings = this.createAction(ACTION.CMDP_GETSETTINGS);
}

export const pushNotificationsClientToServerActions = new PushNotificationsClientToServerActions();
