export enum EnumQueryBackendSubAction {
  BUY_GIFT_BUNDLE = "BUYGIFTBUNDLE",
  START_VIDEOCHAT = "STARTVIDEOCHAT",
  START_FREE_CHAT = "STARTFREECHAT",
  START_LIVE_PREVIEW = "STARTLIVEPREVIEW",
  START_VOYEUR_CHAT = "STARTVOYEURCHAT",
  START_VOYEUR2S_CHAT = "STARTVOYEUR2SCHAT",
  START_PRIVATE_SHOW = "STARTSINGLECAM2CAMCHAT",
  START_MOBILECALL_CHAT = "STARTMOBILECALLCHAT",
}
