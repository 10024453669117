import { ICOMMAND, ACTION, IRESPONSE, RESULT, IKeyMaybeValue } from "../cmd-protocol";
import { IBACKENDPAYLOAD } from "./backend";
import { baseParamsType } from "./command/baseparams";
import { channelIdOrChatId } from "./channel";
import IChannelNote from "./channelinfo";

/**
 * Transport for Contact note
 */
export class CMDC_CONTACTNOTE implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_CONTACTNOTE;
  public params: channelIdOrChatId & IChannelNote;
}

/**
 * Set Contact note
 */
export class CMDP_SETCONTACTNOTE implements ICOMMAND, IBACKENDPAYLOAD {
  public action: ACTION = ACTION.CMDP_SETCONTACTNOTE;
  public params: baseParamsType & channelIdOrChatId & IChannelNote;
  public payload?: unknown;
}

/**
 * Response for CMDP_SETCONTACTNOTE
 */
export class CMDP_SETCONTACTNOTE_RESPONSE extends CMDP_SETCONTACTNOTE implements IRESPONSE, IBACKENDPAYLOAD {
  public result: RESULT;
  public commands: ICOMMAND[];
  /** unused */
  public values: IKeyMaybeValue;
}

/**
 * Get Contact Note
 */
export class CMDP_GETCONTACTNOTE implements ICOMMAND, IBACKENDPAYLOAD {
  public action: ACTION = ACTION.CMDP_GETCONTACTNOTE;
  // channelId may be CSV
  public params: baseParamsType & channelIdOrChatId;
  public payload?: unknown;
}

/**
 * Response for CMDP_GETCONTACTNOTE
 */
export class CMDP_GETCONTACTNOTE_RESPONSE extends CMDP_GETCONTACTNOTE implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_CONTACTNOTE[];
  /** unused */
  public values: IKeyMaybeValue;
}

/** depricated, 8320 only  */
export class CMDP_SSETCONTACTNOTE extends CMDP_SETCONTACTNOTE {
  public action: ACTION = ACTION.CMDP_SSETCONTACTNOTE;
}

/** depricated, 8320 only  */
export class CMDP_SSETCONTACTNOTE_RESPONSE extends CMDP_SETCONTACTNOTE_RESPONSE {
  public action: ACTION = ACTION.CMDP_SSETCONTACTNOTE;
}

/** depricated, 8320 only  */
export class CMDP_SGETCONTACTNOTE extends CMDP_GETCONTACTNOTE {
  public action: ACTION = ACTION.CMDP_SGETCONTACTNOTE;
}

/** depricated, 8320 only  */
export class CMDP_SGETCONTACTNOTE_RESPONSE extends CMDP_GETCONTACTNOTE_RESPONSE {
  public action: ACTION = ACTION.CMDP_SGETCONTACTNOTE;
}
