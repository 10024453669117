import {createSelector} from '@reduxjs/toolkit';
import _ from 'lodash';

import {selectChatSummaryEntitiesVms} from '@messenger/core/src/Redux/ChatSummary/Selectors/selectChatSummaryEntitiesVms';
import {getPropsChannelId} from '@messenger/core/src/Redux/Channels/Selectors/getPropsChannelId';

export const selectChatSummaryVmById = createSelector(
	[selectChatSummaryEntitiesVms, getPropsChannelId],
	(chatSummaryEntitiesVMs, channelId) => _.get(chatSummaryEntitiesVMs, String(channelId)),
);
