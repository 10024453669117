import 'reflect-metadata';
import {call, select} from 'typed-redux-saga';
import {container} from 'tsyringe';

import {selectKeyboardShortcuts} from '@messenger/core/src/Redux/Session/Selectors/PermanentData/selectKeyboardShortcuts';
import {TKeyboardShortcut} from '@messenger/core/src/Redux/Session/Model';
import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import {TSessionUpdatedAction} from '@messenger/core/src/Redux/Session/Actions';
import {shortcutToStringTransform} from '@messenger/core/src/Utils/shortcutToStringTransform';

import {ShortcutsService} from 'src/Services/ShortcutsService';

export const syncShortcutsListenerSaga = function* (_action: TSessionUpdatedAction) {
	const list = yield* select(selectKeyboardShortcuts);
	const shortcuts = (yield* call([container, 'resolve'], DIToken.shortcutsService)) as ShortcutsService;

	yield* call([shortcuts, 'reset']);
	yield* call(
		[shortcuts, 'addMany'],
		list.map((shortcut: TKeyboardShortcut) => ({
			label: shortcutToStringTransform(shortcut.keyStatus),
			text: shortcut.text,
			autoSend: shortcut.autoSend,
		})),
	);
};
