import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import {messagesClientToServerActions} from '@messenger/core/src/Redux/Messages/Actions';
import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions';
import {
	selectChannelVMById,
	getChannelIdForSelector,
} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';
import {selectTeamChannelId} from '@messenger/core/src/Redux/Channels/Selectors/selectTeamChannelId';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export const requestTeamChannelSaga = function* () {
	try {
		const teamChannelId = yield* select(selectTeamChannelId);

		if (!ServiceFactory.env.shouldShowInfoChannel() || !teamChannelId) {
			return;
		}

		const teamChannelVm = yield* select(selectChannelVMById, getChannelIdForSelector(teamChannelId));

		yield* put(channelsClientOnlyActions.requestChannels({channelIds: [teamChannelId]}));

		if (_.isUndefined(teamChannelVm)) {
			yield* put(messagesClientToServerActions.requestHistory({channelId: teamChannelId, limit: 1}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'requestTeamChannelSaga'});
	}
};
