import {ACTION} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class ChannelMediaSummaryClientToServerActions extends ClientToServerActions {
	readonly scope = EnumStore.CHANNEL_MEDIA_SUMMARY;

	getChannelMediaSummary = this.createAction(
		ACTION.CMDP_SGETCHANNELDMEDIASUMMARY,
		this.getPrepareAction<{channelId: string}>(),
	);
}

export const channelMediaSummaryClientToServerActions = new ChannelMediaSummaryClientToServerActions();
