import {ACTION} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class OnlineSummaryClientToServerActions extends ClientToServerActions<EnumStore.ONLINE_SUMMARY> {
	readonly scope = EnumStore.ONLINE_SUMMARY;

	getOnlineSummary = this.createAction(ACTION.CMDP_GETONLINESUMMARY);
}

export const onlineSummaryClientToServerActions = new OnlineSummaryClientToServerActions();
