import {call, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {updatePermanentDataSaga} from '@messenger/core/src/Redux/Session/Sagas/PermanentData/updatePermanentDataSaga';
import {EnumPermanentSettings} from '@messenger/core/src/Redux/Session/Model';
import {selectIsKeepSelectedChannelInViewEnabled} from '@messenger/core/src/Redux/Session/Selectors/PermanentData/selectIsKeepSelectedChannelInViewEnabled';

export const toggleKeepSelectedChannelInViewEnabledSaga = function* () {
	try {
		const currentValue = yield* select(selectIsKeepSelectedChannelInViewEnabled);

		yield* call(updatePermanentDataSaga, EnumPermanentSettings.KEEP_SELECTED_CHANNEL_IN_VIEW, !currentValue);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'toggleKeepSelectedChannelInViewEnabledSaga'});
	}
};
