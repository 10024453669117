import {call} from 'typed-redux-saga';
import React from 'react';

import {Icon, IconColor, IconSize, IconType} from '@messenger/uikit/src/Icon';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';
import {sessionClientOnlyActions} from '@messenger/core/src/Redux/Session/Actions';

export const showCurrentMobileVideoCallNotificationSaga = function* ({
	payload: isVisible,
}: ReturnType<typeof sessionClientOnlyActions.toggleCurrentMobileVideoCallNotification>) {
	try {
		if (isVisible) {
			yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
				text: ServiceFactory.i18n.t('play:mobileVideoCall.inProgress.description'),
				variant: EnumAbstractNotificationVariant.WARNING,
				key: EnumSnackbarNotificationKeys.CURRENT_MOBILE_VIDEO_CALL,
				icon: <Icon type={IconType.info} size={IconSize.small} color={IconColor.black} />,
				isOnlyMobile: true,
			});
		} else {
			yield* call(
				[ServiceFactory.notifications, ServiceFactory.notifications.close],
				EnumSnackbarNotificationKeys.CURRENT_MOBILE_VIDEO_CALL,
			);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'showCurrentMobileVideoCallNotificationSaga'});
	}
};
