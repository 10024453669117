import {PayloadAction} from '@reduxjs/toolkit';
import {call, put, select} from 'typed-redux-saga';

import RequestFailedResponse from '@messenger/core/src/Services/Device/RequestFailedResponse';
import EnumResponseCode from '@messenger/core/src/Services/Device/EnumResponseCode';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {toyClientOnlyActions} from '@messenger/core/src/Redux/Toy/Actions/toyClientOnlyActions';
import {IVibrationEvent} from '@messenger/core/src/Redux/Toy/IVibrationEvent';
import {selectSelectedToy} from '@messenger/core/src/Redux/Toy/Selectors/selectSelectedToy';
import {selectMaxIntensity} from '@messenger/core/src/Redux/Toy/Selectors/selectMaxIntensity';

/**
 * @param {Object} action
 * @return {Generator|void}
 */
export const startVibrateSaga = function* (action: PayloadAction<IVibrationEvent>) {
	try {
		// figure out the API to use
		const api = ServiceFactory.deviceApi;

		const selectedToy = yield* select(selectSelectedToy);

		if (!selectedToy) {
			return;
		}

		// fire a new vibration
		const maxIntensity = yield* select(selectMaxIntensity);
		const response = yield* call(
			[api, api.sendVibrate],
			selectedToy,
			// apply intensity settings
			action.payload.intensity > maxIntensity ? maxIntensity : action.payload.intensity,
		);

		// handle errors
		if (response instanceof RequestFailedResponse && response.code === EnumResponseCode.NOT_STARTED) {
			yield* put(toyClientOnlyActions.markAppOffline());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'startVibrateSaga', action});
	}
};
