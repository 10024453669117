import {put, select} from 'typed-redux-saga';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {mediaPricesClientToServerActions} from '@messenger/core/src/Redux/MediaPrice/Actions/mediaPricesClientToServerActions';
import {selectTargetChannelIdsWithNoPrices} from '@messenger/core/src/Redux/MediaPrice/Selectors/selectTargetChannelIdsWithNoPrices';
import {selectTargetChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetChannelIds';
import {selectDefaultPrices} from '@messenger/core/src/Redux/MediaPrice/Selectors/selectDefaultPrices';

export const requestMediaPricesForTargetChannelsSaga = function* (requestPricesConfig?: {isDefault: boolean}) {
	try {
		const targetChannelIds = yield* select(selectTargetChannelIds);

		if (_.isEmpty(targetChannelIds) || requestPricesConfig?.isDefault) {
			const defaultPrices = yield* select(selectDefaultPrices);

			if (_.isUndefined(defaultPrices)) {
				//Request default prices
				yield* put(mediaPricesClientToServerActions.getByTargetChannelIds({targetChannelIds: []}));
			}

			return;
		}

		//@NOTE maybe we don't need to request prices as they are always the same now
		const channelIdsToRequestPrices = yield* select(selectTargetChannelIdsWithNoPrices);

		if (_.isEmpty(channelIdsToRequestPrices)) {
			return;
		}

		yield* put(mediaPricesClientToServerActions.getByTargetChannelIds({targetChannelIds: channelIdsToRequestPrices}));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'requestMediaPricesForTargetChannelsSaga'});
	}
};
