enum EnumPreviewType {
	LOCAL = 'local',
	SERVER = 'server',
	JPEG = 'jpeg',
}

/** @note order matters */
export const getPreviewTypes = (isWebRTCStreamActive = false): EnumPreviewType[] => {
	const types = [];

	if (isWebRTCStreamActive) {
		types.push(EnumPreviewType.LOCAL);
	} else {
		types.push(EnumPreviewType.JPEG);
	}

	types.push(EnumPreviewType.SERVER);

	return types;
};

export default EnumPreviewType;
