import { ICOMMAND, ACTION, IRESPONSE, RESULT, IKeyMaybeValue } from "../../cmd-protocol";
import { baseParamsType } from "../command/baseparams";

export class CMDP_SETEXPOTOKEN implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SETEXPOTOKEN;
  public params: baseParamsType & {
    token?: string;
  };
}

/**
 * reponse.
 */
export class CMDP_SETEXPOTOKEN_RESPONSE extends CMDP_SETEXPOTOKEN implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}
