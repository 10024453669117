import {CMDC_CHANNELGROUP} from 'cmd-control-client-lib';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum ChannelGroupsAction {
	UPDATE_GROUP = 'UPDATE_GROUP',
	UPSERT_MANY = 'UPSERT_MANY',
	RESET_STORE = 'RESET_STORE',
	GET_CHANNEL_GROUPS_ONCE = 'GET_CHANNEL_GROUPS_ONCE',
}

class ChannelGroupsClientOnlyActions extends ClientOnlyActions<EnumStore.CHANNEL_GROUPS> {
	readonly scope = EnumStore.CHANNEL_GROUPS;

	updateGroup = this.createAction(
		ChannelGroupsAction.UPDATE_GROUP,
		({groupId, channelId, isDeleted}: CMDC_CHANNELGROUP['params']) => {
			return {
				payload: {
					groupId,
					channelId,
					isDeleted,
				},
			};
		},
	);

	upsertMany = this.createAction(
		ChannelGroupsAction.UPSERT_MANY,
		this.getPrepareAction<CMDC_CHANNELGROUP['params'][]>(),
	);

	getChannelGroupsOnce = this.createAction(
		ChannelGroupsAction.GET_CHANNEL_GROUPS_ONCE,
		this.getPrepareAction<{channelId: string}>(),
	);

	resetStore = this.createAction(ChannelGroupsAction.RESET_STORE);
}

export const channelGroupsClientOnlyActions = new ChannelGroupsClientOnlyActions();
