import {call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {sipClientOnlyActions} from '@messenger/core/src/Redux/Sip/Actions/sipClientOnlyActions';

export const processCallToSaga = function* ({payload}: ReturnType<typeof sipClientOnlyActions.callTo>) {
	try {
		yield* call([ServiceFactory.sip, ServiceFactory.sip.callTo], payload);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processCallToSaga'});
	}
};
