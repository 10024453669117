import PropertyHandler from '@messenger/core/src/Types/PropertyHandler';

export enum EnumMessageInGroupPosition {
	first,
	last,
	single,
	middle,
}

export enum EnumScrollToUnseenBehavior {
	firstUnseen,
	lastUnseen,
}

export type TMessageComponentProps = {
	messageId: string;
	isLiveChat?: boolean;
	isShowReactions?: boolean;
	isShowHeaderMessage?: boolean;
	inGroupPosition?: EnumMessageInGroupPosition;
	onMessageInView?: PropertyHandler<string>;
};
