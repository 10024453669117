import _ from 'lodash';

import AbstractJpegSocketPreviewApi, {
	startJpegPreviewSocketCommand,
} from '@messenger/core/src/Services/JpegSocketPreviewApi/AbstractJpegSocketPreviewApi';

class JpegSocketPreviewApi extends AbstractJpegSocketPreviewApi {
	private _subscribers: HTMLImageElement[] = [];
	private _socket: WebSocket | undefined;

	setSocketUrl(url: string) {
		this._socket?.close();

		const websocket = new WebSocket(url);

		websocket.onopen = () => websocket.send(JSON.stringify(startJpegPreviewSocketCommand()));
		websocket.onmessage = (evt) => this.notifyAll('data:image/jpeg;base64,' + evt.data);
		this._socket = websocket;
	}

	stopSocket() {
		this._socket?.close();
		this._socket = undefined;
		this._subscribers = [];
	}

	addSubscription(sub: HTMLImageElement) {
		if (this._subscribers.indexOf(sub) === -1) {
			this._subscribers.push(sub);
		}
	}

	removeSubscription(sub: HTMLImageElement) {
		_.remove(this._subscribers, sub);
	}

	notifyAll(imageBytes: string) {
		this._subscribers.forEach((sub) => {
			if (!sub) {
				return;
			}

			sub.src = imageBytes;

			if (sub.classList.contains('-hidden')) {
				sub.classList.remove('-hidden');
			}

			if (sub.clientWidth !== 0 && sub.clientHeight !== 0) {
				if (sub.clientWidth > sub.clientHeight && !sub.classList.contains('-is-landscape')) {
					sub.classList.add('-is-landscape');
					sub.classList.remove('-is-portrait');
				} else if (sub.clientHeight > sub.clientWidth && !sub.classList.contains('-is-portrait')) {
					sub.classList.add('-is-portrait');
					sub.classList.remove('-is-landscape');
				}
			}
		});
	}
}

export default JpegSocketPreviewApi;
