import 'reflect-metadata';
import React from 'react';
import cuid from 'cuid';
import _ from 'lodash';
import {container, singleton} from 'tsyringe';
import {OptionsObject, SnackbarOrigin, TransitionHandlerProps, VariantType} from 'notistack';

import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import AbstractUINotificationService from '@messenger/core/src/Services/AbstractUINotificationService';
import {
	EnumAbstractNotificationVariant,
	TAbstractNotificationOptions,
} from '@messenger/core/src/Services/UINotification';

import ActionButton from 'src/Components/Notifications/ActionButton';

const supportedWebUINotificationVariants = [
	EnumAbstractNotificationVariant.DEFAULT,
	EnumAbstractNotificationVariant.ERROR,
	EnumAbstractNotificationVariant.SUCCESS,
	EnumAbstractNotificationVariant.WARNING,
	EnumAbstractNotificationVariant.INFO,
];

@singleton()
class UINotificationService extends AbstractUINotificationService {
	protected prepareOptions(options: TAbstractNotificationOptions): OptionsObject {
		if (!_.isUndefined(options.variant) && !_.includes(supportedWebUINotificationVariants, options.variant)) {
			throw new Error(
				`Variant ${options.variant} not supported. Can be one of: ${_.join(supportedWebUINotificationVariants, ', ')}`,
			);
		}

		const key = _.get(options, 'key', cuid.slug());
		const prepared: OptionsObject = {
			key: key,
			variant: options.variant as VariantType,
			autoHideDuration: _.get(options, 'duration', this.env.getSnackTimeout()),
			anchorOrigin: options.position as SnackbarOrigin,
			onClose: options.onClose as TransitionHandlerProps['onClose'],
			persist: options.persist,
			color: options.color,
		};

		if (!_.isUndefined(options.buttonText)) {
			prepared.action = (
				<ActionButton
					endIcon={options.buttonEndIcon}
					notificationKey={key}
					buttonRoute={options.buttonRoute}
					onClick={options.onClose}
					text={options.buttonText}
					isNonClosable={options.isNonClosable}
				/>
			);
		}

		return prepared;
	}
}

container.register(DIToken.UINotificationService, {useToken: UINotificationService});

export default UINotificationService;
