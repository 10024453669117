import {all, takeEvery, takeLatest} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {toyClientOnlyActions} from '@messenger/core/src/Redux/Toy/Actions/toyClientOnlyActions';
import {toyServerToClientActions} from '@messenger/core/src/Redux/Toy/Actions/toyServerToClientActions';

import {requestToysSaga} from 'src/Redux/Toy/Sagas/requestToysSaga';
import {startVibrateSaga} from 'src/Redux/Toy/Sagas/startVibrateSaga';
import {stopVibrateSaga} from 'src/Redux/Toy/Sagas/stopVibrateSaga';
import {deselectMissingToySaga} from 'src/Redux/Toy/Sagas/deselectMissingToySaga';
import {deselectInactiveToySaga} from 'src/Redux/Toy/Sagas/deselectInactiveToySaga';
import {selectOnlyToyIfNotYetSaga} from 'src/Redux/Toy/Sagas/selectOnlyToyIfNotYetSaga';
import {notifyControlOfStatusSaga} from 'src/Redux/Toy/Sagas/notifyControlOfStatusSaga';
import {checkDeviceStatusSaga} from 'src/Redux/Toy/Sagas/checkDeviceStatusSaga';
import {processVibrateQueueSaga} from 'src/Redux/Toy/Sagas/processVibrateQueueSaga';
import {saveMaxIntensitySaga} from 'src/Redux/Toy/Sagas/saveMaxIntensitySaga';

function* toyControlSagaWatcher() {
	yield* takeEvery(toyClientOnlyActions.markProfileEnabled, checkDeviceStatusSaga);
	yield* takeEvery(
		[toyClientOnlyActions.testVibrate, toyServerToClientActions.queueVibration, toyClientOnlyActions.queueProcessing],
		processVibrateQueueSaga,
	);

	//@NOTE saga starts processing the next vibration from the queue, needed for badges
	yield* takeEvery(toyClientOnlyActions.stopVibrate.type, stopVibrateSaga);

	yield* takeEvery(toyClientOnlyActions.setMaxIntensity.type, saveMaxIntensitySaga);

	if (!ServiceFactory.uiContainer.isNotDesktop()) {
		yield* takeEvery(toyClientOnlyActions.startVibrate.type, startVibrateSaga);
		yield* takeEvery(toyClientOnlyActions.requestToys.type, requestToysSaga);

		yield* all([
			takeLatest(toyClientOnlyActions.receiveToys.type, deselectMissingToySaga),
			takeLatest(toyClientOnlyActions.receiveToys.type, deselectInactiveToySaga),
			takeLatest(toyClientOnlyActions.receiveToys.type, selectOnlyToyIfNotYetSaga),
			takeLatest(toyClientOnlyActions.receiveToys.type, notifyControlOfStatusSaga),
		]);
	}
}

export default toyControlSagaWatcher;
