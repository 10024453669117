import {LinkProps} from 'react-router-dom';
import {useMemo} from 'react';
import {useLocation} from 'react-router';

import getRouterLinkForMui from 'src/Utils/Navigation/getRouterLinkForMui';
import {Routes} from 'src/Routes/Routes';
import {excludeModalRoute} from 'src/Utils/Navigation/excludeModalRoute';

export const useRouterLinkModalForMui = (
	to: LinkProps['to'],
	replace: LinkProps['replace'] = false,
	onClick?: LinkProps['onClick'],
	state?: LinkProps['state'],
) => {
	const {pathname} = useLocation();
	const fixedTo = Routes.isModal(String(to)) ? `${excludeModalRoute(pathname)}/${to}` : to;

	return useMemo(() => getRouterLinkForMui({to: fixedTo, replace, onClick, state}), [fixedTo, replace, onClick, state]);
};
