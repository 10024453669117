import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import ChannelFilterMatcher from '@messenger/core/src/BusinessLogic/ChannelFilterMatcher';
import {selectChannelGroupVms} from '@messenger/core/src/Redux/ChannelGroups/Selectors/selectChannelGroupVms';

import {selectChannelsFilter} from './selectChannelsFilter';

export const selectChannelFilterMatcher = createSelector(
	[selectChannelsFilter, selectChannelGroupVms],
	(channelsFilter, groupVMs) => {
		const filterGroups = _.chain(channelsFilter).get('filterGroupId').split(',').compact().value();

		const channelIdsWhiteList = _.isEmpty(filterGroups)
			? undefined
			: _.filter(groupVMs, ({isDeleted, groupId}) => !isDeleted && _.includes(filterGroups, groupId));

		const result = new ChannelFilterMatcher(
			channelsFilter,
			channelIdsWhiteList && _.map(channelIdsWhiteList, 'channelId'),
		);

		return result;
	},
);
