import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectChatSummaryVmById} from '@messenger/core/src/Redux/ChatSummary/Selectors/selectChatSummaryVmById';
import {ChatSummaryVM} from '@messenger/core/src/Redux/ChatSummary/ChatSummaryVM';
import {chatSummaryClientToServerActions} from '@messenger/core/src/Redux/ChatSummary/Actions/chatSummaryClientToServerActions';
import {chatSummaryClientOnlyActions} from '@messenger/core/src/Redux/ChatSummary/Actions/chatSummaryClientOnlyActions';

export const fetchChatSummarySaga = function* (action: ReturnType<typeof chatSummaryClientOnlyActions.getChatSummary>) {
	try {
		const chatSummary: ChatSummaryVM | undefined = yield* select(selectChatSummaryVmById, {
			channelId: action.payload.channelId,
		});

		if (_.isUndefined(chatSummary)) {
			yield* put(chatSummaryClientToServerActions.fetchChatSummary({channelId: action.payload.channelId}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'fetchChatSummarySaga'});
	}
};
