export default class SpeedTestResultAdapter {
	private readonly _testId: string;
	private readonly _dataId: string;
	private readonly _seconds: number;
	private readonly _bitrate: number;

	constructor(testId: string, dataId: string, seconds: number, bitrate: number) {
		this._testId = testId;
		this._dataId = dataId;
		this._seconds = seconds;
		this._bitrate = bitrate;
	}

	public toSocket(): Record<string, any> {
		return {
			testID: this._testId,
			dataID: this._dataId,
			seconds: this._seconds,
			bitrate: Math.floor(this._bitrate),
			cpuUsage: -1, // not supported :(
		};
	}
}
