import _ from 'lodash';
import {ParametricSelector} from '@reduxjs/toolkit';

import IRootState from '@messenger/core/src/Redux/IRootState';

export const getPropsAttachmentId: ParametricSelector<
	IRootState,
	{attachmentId?: string} | undefined,
	string | undefined
> = (_state: IRootState, props?: {attachmentId?: string}) => (_.isUndefined(props) ? undefined : props.attachmentId);
