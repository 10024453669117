import type {TFunction} from 'react-i18next';

import {VStep} from '@messenger/core/src/Redux/Introduction';
import EnumIntroductionStep from '@messenger/core/src/Redux/Introduction/EnumIntroductionStep';

import {
	EnumIntroStepSelectors,
	MESSAGE_AREA_CHAT_MESSAGE,
	MESSAGE_AREA_MSN_MESSAGE,
} from 'src/Components/Introduction/EnumIntroStepSelectors';

export const vStepToNumber = (step: VStep): EnumIntroductionStep => step.step;

export const getJohnDoeChannelStep = (
	t: TFunction,
	element = `.${EnumIntroStepSelectors.CHANNELS_JOHN_DOE}`,
): VStep => ({
	step: EnumIntroductionStep.CHANNELS_JOHN_DOE,
	element: element,
	title: t('intro:channels_john_doe-title'),
	intro: t('intro:channels_john_doe'),
	position: 'right',
	disableInteraction: true,
});

export const getOpenedUserMenuStep = (
	t: TFunction,
	element = `.${EnumIntroStepSelectors.USER_MENU_AREA_OPENED}`,
): VStep => ({
	step: EnumIntroductionStep.USER_MENU_AREA_OPENED,
	element: element,
	title: t('intro:user_menu_area_opened-title'),
	intro: t('intro:user_menu_area_opened'),
	position: 'left',
});

export const getDoeMsnMessageStep = (t: TFunction, element = `.${MESSAGE_AREA_MSN_MESSAGE}`): VStep => ({
	step: EnumIntroductionStep.MESSAGE_AREA_MSN_MESSAGE,
	element: element,
	title: t('intro:channels_john_doe_msn_message-title'),
	intro: t('intro:channels_john_doe_msn_message'),
	position: 'bottom',
	disableInteraction: true,
});

export const getDoeChatMessageStep = (t: TFunction, element = `.${MESSAGE_AREA_CHAT_MESSAGE}`): VStep => ({
	step: EnumIntroductionStep.MESSAGE_AREA_CHAT_MESSAGE,
	element: element,
	title: t('intro:channels_john_doe_chat_message-title'),
	intro: t('intro:channels_john_doe_chat_message'),
	position: 'bottom',
	disableInteraction: true,
});

export const getDoeReplyMessageStep = (t: TFunction, element: string): VStep => ({
	step: EnumIntroductionStep.MESSAGE_AREA_REPLY_MESSAGE,
	element: element,
	title: t('intro:channels_john_doe_reply_message-title'),
	intro: t('intro:channels_john_doe_reply_message'),
	position: 'bottom',
	disableInteraction: true,
});

/**
 * @link https://github.com/usablica/intro.js/blob/master/intro.js#L260
 */
export const getStepsDefinitions = (t: TFunction): VStep[] => {
	return [
		{
			step: EnumIntroductionStep.GO_ONLINE_BUTTON,
			element: `.${EnumIntroStepSelectors.GO_ONLINE_BUTTON}`,
			title: t('intro:go_online-title'),
			intro: t('intro:go_online'),
			position: 'right',
			disableInteraction: true,
		},
		{
			step: EnumIntroductionStep.CHANNELS_FILTER,
			element: `.${EnumIntroStepSelectors.CHANNELS_FILTER}`,
			title: t('intro:channels_filter-title'),
			intro: t('intro:channels_filter'),
			position: 'right',
			disableInteraction: true,
		},
		{
			step: EnumIntroductionStep.CHANNELS_ALL_MESSAGES,
			element: `[data-intro-id=${EnumIntroStepSelectors.CHANNELS_ALL_MESSAGES}]`,
			title: t('intro:channel_all_messages-title'),
			intro: t('intro:channel_all_messages'),
			position: 'right',
			disableInteraction: true,
		},
		{
			step: EnumIntroductionStep.CHANNELS_LIST,
			element: `.${EnumIntroStepSelectors.CHANNELS_LIST}`,
			title: t('intro:channels_list-title'),
			intro: t('intro:channels_list'),
			position: 'right',
			disableInteraction: true,
		},
		{
			step: EnumIntroductionStep.MESSAGE_AREA,
			element: `.${EnumIntroStepSelectors.MESSAGE_AREA}`,
			title: t('intro:message_area-title'),
			intro: t('intro:message_area'),
			position: 'right',
			disableInteraction: true,
		},
		{
			step: EnumIntroductionStep.MESSAGE_INPUT,
			element: `.${EnumIntroStepSelectors.MESSAGE_INPUT}`,
			title: t('intro:message_input-title'),
			intro: t('intro:message_input'),
			position: 'top',
		},
		{
			step: EnumIntroductionStep.USER_MENU_AREA,
			element: `.${EnumIntroStepSelectors.USER_MENU_AREA}`,
			title: t('intro:user_menu_area-title'),
			intro: t('intro:user_menu_area'),
			position: 'bottom',
			disableInteraction: true,
		},
		{
			step: EnumIntroductionStep.ICONS_MEANING,
			element: `.${EnumIntroStepSelectors.ICONS_MEANING}`,
			title: t('intro:icons-modal.step-label-title'),
			intro: t('intro:icons-modal.step-label'),
			position: 'top',
		},
	];
};
