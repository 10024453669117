import {createSlice, EntityState} from '@reduxjs/toolkit';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {
	MEDIA_LINKS_HISTORY_SUBJECT_ID,
	mediaLinksClientToServerActions,
} from '@messenger/core/src/Redux/MediaLinks/Actions/mediaLinksClientToServerActions';
import {mediaLinksServerToClientActions} from '@messenger/core/src/Redux/MediaLinks/Actions/mediaLinksServerToClientActions';
import {TMediaHistoryStatuses} from '@messenger/core/src/Redux/Media/mediaSlice';

import {mediaLinkEntityAdapter, TMediaLink} from './mediaLinkEntityAdapter';
import {mediaLinksClientOnlyActions} from './Actions/mediaLinksClientOnlyActions';

export type TMediaLinksState = EntityState<TMediaLink> & {
	checkingChannelIds: string[];
	mediaLinksHistoryStatuses: TMediaHistoryStatuses;
};

const initialMediaLinksState: TMediaLinksState = mediaLinkEntityAdapter.getInitialState({
	checkingChannelIds: [],
	mediaLinksHistoryStatuses: {},
});

export const mediaLinksSlice = createSlice({
	name: EnumStore.MEDIA_LINKS,
	initialState: initialMediaLinksState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(mediaLinksClientToServerActions.checkUploadedMedia, (state, action) => {
			state.checkingChannelIds = _.uniq([...state.checkingChannelIds, ..._.split(action.payload.channelId, ',')]);
		});
		builder.addCase(mediaLinksServerToClientActions.checkUploadedMediaReceived, (state, action) => {
			state.checkingChannelIds = _.difference(state.checkingChannelIds, _.split(action.meta.params.channelId, ','));
		});
		builder.addCase(mediaLinksServerToClientActions.mediaPurchased, mediaLinkEntityAdapter.setOne);
		builder.addCase(mediaLinksClientOnlyActions.addMany, mediaLinkEntityAdapter.addMany);
		builder.addCase(mediaLinksClientOnlyActions.setMany, mediaLinkEntityAdapter.setMany);
		builder.addCase(mediaLinksClientToServerActions.requestMediaLinksHistory, (state, {payload}) => {
			const subjectId = payload[MEDIA_LINKS_HISTORY_SUBJECT_ID];

			if (subjectId) {
				const status = state.mediaLinksHistoryStatuses[subjectId] || {};

				if (!status.isLoading) {
					state.mediaLinksHistoryStatuses[subjectId] = {...status, isLoading: true};
				}
			}
		});
		builder.addCase(mediaLinksClientOnlyActions.mediaLinksHistoryReceived, (state, {meta: {params, commands}}) => {
			const mediaLinksHistorySubjectId: string = _.get(params, MEDIA_LINKS_HISTORY_SUBJECT_ID);

			if (mediaLinksHistorySubjectId) {
				const limit = params.limit ? _.parseInt(String(params.limit), 10) : 0;
				const skip = params.skip ? _.parseInt(String(params.skip), 10) : 0;

				state.mediaLinksHistoryStatuses = {
					...state.mediaLinksHistoryStatuses,
					[mediaLinksHistorySubjectId]: {
						skip: skip + limit,
						endReached: _.size(commands) < limit,
						isReady: true,
						isLoading: false,
					},
				};
			}
		});
	},
});
