import {put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {cam2camPreviewClientOnlyActions} from '@messenger/core/src/Redux/Cam2CamPreview/Actions/cam2camPreviewClientOnlyActions';

export const resetCam2CamStoreSaga = function* () {
	try {
		yield* put(cam2camPreviewClientOnlyActions.resetStore());
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'resetCam2CamStoreSaga'});
	}
};
