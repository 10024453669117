import {
	ACTION,
	CMDC_UPDATE,
	CMDP_FSK16,
	CMDP_INIT_RESPONSE,
	CMDP_LOGOUT_RESPONSE,
	CMDP_OPEN_LOBBY_RESPONSE,
	CMDP_RESETPASSWORD_RESPONSE,
	CMDP_SDELETE_USER_RESPONSE,
	CMDP_SIGNUP_RESPONSE,
	CMDP_SUPDATE_RESPONSE,
	EnumService0900State,
	ResultCode,
	CMDP_SLOGIN_RESPONSE,
} from 'cmd-control-client-lib';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {ServerToClientActions, TServerMeta} from '@messenger/core/src/Actions/ActionCreator';

/**
 * @WARNING
 * After adding new Server Action add it to "serverToClientActionsMap" object
 * @see serverToClientActionsMap
 */
class SessionServerToClientActions extends ServerToClientActions<EnumStore.SESSION> {
	readonly scope = EnumStore.SESSION;

	initialized = this.createAction(
		ACTION.CMDP_INIT,
		this.getPrepareAction<CMDP_INIT_RESPONSE['values'], CMDP_INIT_RESPONSE>(),
	);

	loggedIn = this.createAction(
		ACTION.CMDP_SLOGIN,
		this.getPrepareAction<CMDP_SLOGIN_RESPONSE['values'], CMDP_SLOGIN_RESPONSE>(),
	);

	signedUp = this.createAction(
		ACTION.CMDP_SIGNUP,
		this.getPrepareAction<CMDP_SIGNUP_RESPONSE['params'], CMDP_SIGNUP_RESPONSE>(),
	);

	resetPasswordResponseReceived = this.createAction(
		ACTION.CMDP_RESETPASSWORD,
		this.getPrepareAction<CMDP_RESETPASSWORD_RESPONSE['values'], CMDP_RESETPASSWORD_RESPONSE>(),
	);

	loggedOut = this.createAction(
		ACTION.CMDP_LOGOUT,
		this.getPrepareAction<CMDP_LOGOUT_RESPONSE['params'], CMDP_LOGOUT_RESPONSE>(),
	);

	loggedOutAllDevices = this.createAction(ACTION.CMDP_SLOGOUT);

	// @TODO: insert proper type after adding CMDC_SLOGOUT to cmd-control-client-lib
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	sessionRemotelyTerminated = this.createAction(ACTION.CMDC_SLOGOUT, (payload: any, meta: any) => ({
		payload,
		meta,
	}));

	fsk16Applied = this.createAction(ACTION.CMDP_FSK16, this.getPrepareAction<CMDP_FSK16['params'], CMDP_FSK16>());

	service0900StatusChanged = this.createAction(
		ACTION.CMDP_SERVICE0900,
		(
			payload: CMDP_SUPDATE_RESPONSE['params'] & {service0900state: EnumService0900State},
			meta: CMDP_SUPDATE_RESPONSE,
		) => ({
			payload: {
				service0900State: payload.service0900state ?? payload.service0900State,
			},
			meta,
			error: meta.result.code !== ResultCode.OK ? meta.result.reason : false,
		}),
	);

	feedbackRequested = this.createAction(ACTION.CMDC_FEEDBACK, this.getPrepareAction<TFeedbackRequestedPayload>());

	sessionCMDPUpdated = this.createAction(
		ACTION.CMDP_SUPDATE,
		this.getPrepareAction<CMDP_SUPDATE_RESPONSE['params'], CMDP_SUPDATE_RESPONSE>(),
	);

	sessionUpdated = this.createAction(
		ACTION.CMDC_UPDATE,
		(payload: CMDC_UPDATE['params'], meta?: CMDC_UPDATE, error?: false) => {
			const preparedPayload = {
				...payload,
				proxyStateInfo:
					'proxyStateInfo' in payload
						? (_.attempt(JSON.parse, payload.proxyStateInfo) as {isTyping?: boolean})
						: undefined,
				...('service0900state' in payload || 'service0900State' in payload
					? {service0900State: payload.service0900state ?? payload.service0900State}
					: {}),
			};

			return {
				payload: preparedPayload,
				meta,
				error,
			};
		},
	);

	enableLobbyModeResult = this.createAction(
		ACTION.CMDP_OPEN_LOBBY,
		this.getPrepareAction<CMDP_OPEN_LOBBY_RESPONSE['values'], CMDP_OPEN_LOBBY_RESPONSE>(),
	);

	accountDeleted = this.createAction(
		ACTION.CMDP_SDELETE_USER,
		this.getPrepareAction<CMDP_SDELETE_USER_RESPONSE['values'], TServerMeta<CMDP_SDELETE_USER_RESPONSE>>(),
	);
}

type TFeedbackRequestedPayload = {
	namefrom?: string;
	emailfrom?: string;
	subj?: string;
	text?: string;
};

export type TSessionCMDPUpdatedAction = ReturnType<typeof SessionServerToClientActions.prototype.sessionCMDPUpdated>;

export type TSessionUpdatedAction = ReturnType<typeof SessionServerToClientActions.prototype.sessionUpdated>;

export const sessionServerToClientActions = new SessionServerToClientActions();
