import {call, put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {sessionClientOnlyActions} from '@messenger/core/src/Redux/Session/Actions';
import {loginFormClientOnlyActions} from '@messenger/core/src/Redux/LoginForm/Actions/loginFormClientOnlyActions';
import AbstractUiContainer from '@messenger/core/src/Services/AbstractUiContainer';

export const processStartApplicationSaga = function* (browser: AbstractUiContainer) {
	try {
		const vControlApi = ServiceFactory.getVControlApi();
		const searchParams = new URLSearchParams(browser.getSearchString());

		if (searchParams.get('foreignSID')) {
			yield* put(loginFormClientOnlyActions.enableAutoLogin());
		}

		yield* put(sessionClientOnlyActions.setForeignSID(searchParams.get('foreignSID') || undefined));
		yield* call([browser, browser.removeSearchParam], 'foreignSID');
		yield* call([browser, browser.redirectIfNeeded]);
		yield* call([vControlApi, vControlApi.connect]);
		yield* call([browser, browser.preventZooming]);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processStartApplicationSaga'});
	}
};
