import {createSlice} from '@reduxjs/toolkit';
import {EnumPushNotificationFilter} from 'cmd-control-client-lib';

import {
	pushNotificationsClientToServerActions,
	pushNotificationsServerToClientActions,
} from '@messenger/core/src/Redux/PushNotifications/Actions';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

export const initialState: TPushNotificationState = {
	isLoading: true,
	filter: EnumPushNotificationFilter.ALL,
};

export const pushNotificationsSlice = createSlice({
	name: EnumStore.QUERY_USER,
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(pushNotificationsServerToClientActions.settingsReceived, (state, action) => {
			state.filter = action.payload.pushNotificationFilter;
			state.isLoading = false;
		});
		builder.addCase(pushNotificationsClientToServerActions.saveSettings, (state) => {
			state.isLoading = true;
		});
		builder.addCase(pushNotificationsServerToClientActions.settingsSaved, (state, action) => {
			state.isLoading = false;
			state.filter = action.payload.pushNotificationFilter;
		});
	},
});

export type TPushNotificationState = {
	isLoading: boolean;
	filter?: EnumPushNotificationFilter;
};
