// @ts-ignore
import HeicImageConvertWorker from 'worker-loader!src/Utils/Browser/heicConvertWorker';
import {container, inject, singleton} from 'tsyringe';
import _ from 'lodash';
import SparkMD5 from 'spark-md5';

import AbstractFileProcessingService from '@messenger/core/src/Services/AbstractFileProcessingService';
import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import ILocalFile from '@messenger/core/src/Redux/Media/ILocalFile';
import AbstractUiContainer from '@messenger/core/src/Services/AbstractUiContainer';
import {TDimensionObject} from '@messenger/core/src/Redux/Attachment/Sagas/processSelectAttachmentSaga';
import type ILogService from '@messenger/core/src/Services/ILogService';

@singleton()
class FileProcessingService extends AbstractFileProcessingService {
	constructor(
		@inject(DIToken.LogService) protected logService: ILogService,
		@inject(DIToken.UiContainer) protected uiContainer: AbstractUiContainer,
	) {
		super();
	}

	private heicImageConvertWorkerInstance: Worker | undefined = undefined;

	isImageConversionRequired(file: File | ILocalFile): boolean {
		return _.includes(['image/heic', 'image/heif'], file.type);
	}

	async getImageDimension(file: File | ILocalFile): Promise<TDimensionObject | undefined> {
		const reader = new FileReader();

		return new Promise<TDimensionObject | undefined>((resolve, reject) => {
			reader.onload = ({target}: ProgressEvent<FileReader>) => {
				const image = new Image();

				const fileContent = _.get(target, 'result') as string;

				if (fileContent) {
					image.src = fileContent;

					image.onload = function (e) {
						const {height, width} = image;

						resolve({width, height});
					};
				} else {
					reject(null);
				}
			};

			if (file.type !== 'image/heic' && file.type !== 'image/heif') {
				reader.readAsDataURL(file as File);
			} else {
				if (_.isUndefined(this.heicImageConvertWorkerInstance)) {
					this.heicImageConvertWorkerInstance = new HeicImageConvertWorker() as Worker;

					this.heicImageConvertWorkerInstance.onerror = (e: ErrorEvent) => {
						this.logService.error(
							{...e.error, text: `Error during conversion of HEIC image: ${e.error}`},
							{
								service: 'FileProcessingService',
							},
						);
					};
				}

				this.heicImageConvertWorkerInstance.onmessage = ({data}: MessageEvent) => {
					const image = new Image();

					if (data.url) {
						image.src = data.url;

						image.onload = function (e) {
							const {height, width} = image;

							resolve({width, height});
						};
					} else {
						reject(null);
					}
				};

				this.heicImageConvertWorkerInstance.postMessage({file});
			}
		});
	}

	async getFileSize(file: File | ILocalFile): Promise<number | undefined> {
		return new Promise<number | undefined>((resolve, reject) => {
			if ((file as File).size) {
				resolve((file as File).size);
			} else {
				reject();
			}
		});
	}

	async getMd5(file: File | ILocalFile): Promise<string | undefined> {
		const reader = new FileReader();

		return new Promise<string | undefined>((resolve, reject) => {
			reader.onload = ({target}: ProgressEvent<FileReader>) => {
				const fileContent = _.get(target, 'result');

				if (_.isArrayBuffer(fileContent)) {
					resolve(SparkMD5.ArrayBuffer.hash(fileContent));
				} else {
					reject('Unsupported format');
				}
			};

			reader.readAsArrayBuffer(file as File);
		});
	}

	async createFilePath(file: File, setFilePath: (url: string) => void): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			if (this.isImageConversionRequired(file)) {
				if (_.isUndefined(this.heicImageConvertWorkerInstance)) {
					this.heicImageConvertWorkerInstance = new HeicImageConvertWorker() as Worker;

					this.heicImageConvertWorkerInstance.onerror = (e: ErrorEvent) => {
						this.logService.error(
							{...e.error, text: `Error during conversion of HEIC image: ${e.error}`},
							{service: 'FileProcessingService'},
						);
						reject();
					};
				}

				this.heicImageConvertWorkerInstance.onmessage = ({data}: MessageEvent) => {
					resolve(setFilePath(data.url));
				};

				this.heicImageConvertWorkerInstance.postMessage({file});
			} else {
				resolve(setFilePath(this.uiContainer.createObjectURL(file)));
			}
		});
	}
}

container.register<AbstractFileProcessingService>(DIToken.FileProcessingService, {useToken: FileProcessingService});

export default FileProcessingService;
