import {ACTION} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class ChannelInfosClientToServerActions extends ClientToServerActions {
	readonly scope = EnumStore.CHANNEL_INFOS;

	get = this.createAction(ACTION.CMDP_GETCHANNELINFO, (channelIds: string[]) => ({
		payload: {
			channelId: channelIds.join(','),
		},
	}));
}

export const channelInfosClientToServerActions = new ChannelInfosClientToServerActions();
