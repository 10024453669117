import {put, select} from 'typed-redux-saga';
import _ from 'lodash';

import {channelsMapClientOnlyActions} from '@messenger/core/src/Redux/ChannelsMap/Actions/channelsMapClientOnlyActions';
import {selectPendingChannelIds} from '@messenger/core/src/Redux/ChannelsMap/Selectors/selectPendingChannelIds';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export const processPendingChannelsMapUpdatesSaga = function* () {
	try {
		const channelIds = yield* select(selectPendingChannelIds);

		if (_.size(channelIds)) {
			yield* put(channelsMapClientOnlyActions.resetPendingChannelIds());
			yield* put(channelsMapClientOnlyActions.startChannelsMapUpdate({channelIds}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processPendingChannelsMapUpdatesSaga'});
	}
};
