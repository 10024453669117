import {PayloadAction} from '@reduxjs/toolkit';
import {put, select} from 'typed-redux-saga';
import {EnumSetTp, SystemMessageKey} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TSswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import {selectSetTp} from '@messenger/core/src/Redux/Session/Selectors/selectSetTp';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {EnumStartStreamStep} from '@messenger/core/src/Redux/Stream/streamSlice';

export const closeModalsOnStreamStartedSaga = function* (action: PayloadAction<TSswMessageType>) {
	try {
		const setTp = yield* select(selectSetTp);

		if (action.payload.messageKey === SystemMessageKey.h_session_stream_wait && setTp === EnumSetTp.GOING_ONLINE) {
			yield* put(streamClientOnlyActions.setStartStreamStep(EnumStartStreamStep.WAITING_STREAM_ACCEPTED));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'closeModalsOnStreamStartedSaga', action});
	}
};
