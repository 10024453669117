import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {introductionClientOnlyActions} from '@messenger/core/src/Redux/Introduction/Action/IntroductionClientOnlyActions';
import {getIsIntroRunning, getIntroductionSteps} from '@messenger/core/src/Redux/Introduction/Selectors';

import {getJohnDoeChannelStep} from 'src/Components/Introduction/StepsHelper';

export const appendJohnDoeChannelStepSaga = function* () {
	try {
		const isRunning = yield* select(getIsIntroRunning);

		if (!isRunning) {
			return;
		}

		const johnDoeStep = getJohnDoeChannelStep(ServiceFactory.i18n.t);
		const currentSteps = yield* select(getIntroductionSteps);

		if (!_.some(currentSteps, ['step', johnDoeStep.step])) {
			yield* put(introductionClientOnlyActions.appendStep(johnDoeStep));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'appendJohnDoeChannelStepSaga'});
	}
};
