import {put, select} from 'typed-redux-saga';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {selectMessagesByMessageKey} from '@messenger/core/src/Redux/Messages/Selectors/selectMessagesByMessageKey';

export function* deleteMessagesSaga({
	payload: {messageKey},
}: ReturnType<typeof messagesClientOnlyActions.deleteMessages>) {
	try {
		const messages = yield* select(selectMessagesByMessageKey, {messageKey});

		if (_.isUndefined(messages)) {
			return;
		}

		for (let i = 0; i < messages.length; i++) {
			yield* put(messagesClientOnlyActions.deleteMessage(messages[i]));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'deleteMessagesSaga'});
	}
}
