import {createSelectorCreator, defaultMemoize} from 'reselect';
import {isEqual} from 'lodash';

import ModelSettingsViewModel from '@messenger/core/src/Redux/ModelSettings/ModelSettingsViewModel';

import {selectModelSettings} from './selectModelSettings';

const selectModelSettingsSelectorCreator = createSelectorCreator(defaultMemoize, isEqual);

export const selectModelSettingsViewModel = selectModelSettingsSelectorCreator(
	[selectModelSettings],
	(modelSettingsState) => new ModelSettingsViewModel(modelSettingsState),
);
