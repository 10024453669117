import { EnumQueryBackendSubAction, ExclusiveOR } from "../@types";
import { channelIdType, IMayHaveChannelId, usrKeyType } from "./channel";
import { ACTION } from "./command/action";
import { baseParamsType } from "./command/baseparams";
import { ICOMMAND, IKeyMaybeValue, IRESPONSE, RESULT } from "./command/icommand";

export interface IBackEndPayload {
  type: any;
  data: any;
}

export declare type TChatData = {
  chatId: number;
  clientId: string;
  server: string;
  width: string;
  height: string;
};

export declare type TGuestData = {
  guestBalance?: number;
  bundleGifts?: TGiftQuantity[];
};

export declare type TGiftQuantity = {
  id: number;
  quantity: number;
};

export declare type TQueryBackendResponsePayload = ExclusiveOR<TChatData, TGuestData>;

/**
 * payload for transport to backend and from backend to client
 */
export interface IBACKENDPAYLOAD {
  /* any object */
  payload?: unknown;
}

type TSubActionParams = {
  subaction: EnumQueryBackendSubAction;
};

/**
 * common command for take a backend action
 */
export class CMDP_QUERYBACKEND implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_QUERYBACKEND;
  public params: TSubActionParams & baseParamsType & channelIdType;
}

/**
 * response CMDP_QUERYBACKEND
 */
export class CMDP_QUERYBACKEND_RESPONSE extends CMDP_QUERYBACKEND implements IRESPONSE, IBACKENDPAYLOAD {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
  /** if jwt-session, any json-payload for transport to to client */
  public payload?: TQueryBackendResponsePayload;
}

/**
 * the update commando from backend over B2B-connection to client for channel
 */
export class CMDC_BACKENDPAYLOAD implements ICOMMAND, IBACKENDPAYLOAD {
  public action: ACTION = ACTION.CMDC_BACKENDPAYLOAD;
  public params: IMayHaveChannelId & usrKeyType & IKeyMaybeValue;
  public payload?: IBackEndPayload;
}

/** the b2b command for publish of CMDC_BACKENDPAYLOAD */
export class CMDP_BACKENDPAYLOAD implements ICOMMAND, IBACKENDPAYLOAD {
  public action: ACTION = ACTION.CMDP_BACKENDPAYLOAD;
  public params: IMayHaveChannelId & usrKeyType & IKeyMaybeValue;
  public payload?: IBackEndPayload;
}
