import {put} from 'typed-redux-saga';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {channelGroupsClientToServerActions} from '@messenger/core/src/Redux/ChannelGroups/Actions/channelGroupsClientToServerActions';
import {channelGroupsClientOnlyActions} from '@messenger/core/src/Redux/ChannelGroups/Actions/channelGroupsClientOnlyActions';

export const processAddOrRemoveChannelGroupSaga = function* ({
	payload,
}: ReturnType<typeof channelGroupsClientOnlyActions.updateGroup>) {
	try {
		const parsedClientData = _.attempt(JSON.parse, payload);

		if (!_.isError(parsedClientData)) {
			payload = parsedClientData;
		}

		const {groupId, channelId, isDeleted} = payload;

		if (groupId && channelId) {
			if (isDeleted) {
				yield* put(channelGroupsClientToServerActions.removeChannelsFromGroup({groupId, channelIds: [channelId]}));
			} else {
				yield* put(channelGroupsClientToServerActions.addChannelsToGroup({groupId, channelIds: [channelId]}));
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processAddOrRemoveChannelGroupSaga'});
	}
};
