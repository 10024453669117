import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import EnumIntroductionStep from '@messenger/core/src/Redux/Introduction/EnumIntroductionStep';
import {VStep} from '@messenger/core/src/Redux/Introduction';

enum EnumActions {
	APPEND_STEP = 'APPEND_STEP',
	CLEAN_UP_STORES = 'CLEAN_UP_STORES',
	DISABLE_BUTTONS = 'DISABLE_BUTTONS',
	DISABLE_CHANNELS_SCROLL = 'DISABLE_CHANNELS_SCROLL',
	ENABLE_BUTTONS = 'ENABLE_BUTTONS',
	ENABLE_CHANNELS_SCROLL = 'ENABLE_CHANNELS_SCROLL',
	GOTO_STEP = 'GOTO_STEP',
	HIDE_MODAL = 'HIDE_MODAL',
	NOTIFY_CHANNEL_RENDERED = 'NOTIFY_CHANNEL_RENDERED',
	NOTIFY_FINISHED = 'NOTIFY_FINISHED',
	NOTIFY_MESSAGES_APPENDED = 'NOTIFY_MESSAGES_APPENDED',
	NOTIFY_STARTED = 'NOTIFY_STARTED',
	REDIRECT = 'REDIRECT',
	RESET = 'RESET_STORE',
	RESET_REDIRECT = 'RESET_REDIRECT',
	SAVE_REPLY_MESSAGE_ID = 'SAVE_REPLY_MESSAGE_ID',
	SET_STEPS = 'SET_STEPS',
	SHOW_MODAL = 'SHOW_MODAL',
	SHOW_ICONS_MEANING_MODAL = 'SHOW_ICONS_MEANING_MODAL',
	HIDE_ICONS_MEANING_MODAL = 'HIDE_ICONS_MEANING_MODAL',
	BEFORE_STEP = 'BEFORE_STEP',
	WAITING_LOADING_MESSAGES = 'WAITING_LOADING_MESSAGES',
}

class IntroductionClientOnlyActions extends ClientOnlyActions<EnumStore.INTRODUCTION> {
	readonly scope = EnumStore.INTRODUCTION;

	resetStore = this.createAction(EnumActions.RESET);
	showModal = this.createAction(EnumActions.SHOW_MODAL);
	hideModal = this.createAction(EnumActions.HIDE_MODAL);
	enableButtons = this.createAction(EnumActions.ENABLE_BUTTONS);
	disableButtons = this.createAction(EnumActions.DISABLE_BUTTONS);
	notifyChannelRendered = this.createAction(EnumActions.NOTIFY_CHANNEL_RENDERED);
	setSteps = this.createAction(EnumActions.SET_STEPS, this.getPrepareAction<VStep[]>());
	appendStep = this.createAction(EnumActions.APPEND_STEP, this.getPrepareAction<VStep>());
	goToStep = this.createAction(EnumActions.GOTO_STEP, (payload: EnumIntroductionStep, meta?: TGoToStepMeta) => ({
		payload,
		meta,
	}));
	cleanUpStore = this.createAction(EnumActions.CLEAN_UP_STORES, this.getPrepareAction<{defaultSteps: VStep[]}>());
	notifyStarted = this.createAction(EnumActions.NOTIFY_STARTED);
	notifyFinished = this.createAction(EnumActions.NOTIFY_FINISHED);
	setReadyToRedirect = this.createAction(EnumActions.REDIRECT);
	resetReadyToRedirect = this.createAction(EnumActions.RESET_REDIRECT);
	disableChannelsScroll = this.createAction(EnumActions.DISABLE_CHANNELS_SCROLL);
	enableChannelsScroll = this.createAction(EnumActions.ENABLE_CHANNELS_SCROLL);
	saveReplyMessageId = this.createAction(EnumActions.SAVE_REPLY_MESSAGE_ID, this.getPrepareAction<string>());
	showIconsMeaningModal = this.createAction(EnumActions.SHOW_ICONS_MEANING_MODAL);
	hideIconsMeaningModal = this.createAction(EnumActions.HIDE_ICONS_MEANING_MODAL);
	beforeStep = this.createAction(EnumActions.BEFORE_STEP, this.getPrepareAction<EnumIntroductionStep>());
	waitingLoadingMessages = this.createAction(EnumActions.WAITING_LOADING_MESSAGES);
}

export type TGoToStepMeta = {
	useDelay: boolean;
};

export const introductionClientOnlyActions = new IntroductionClientOnlyActions();
