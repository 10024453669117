import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectLoginFormState} from '@messenger/core/src/Redux/LoginForm/Selectors/selectLoginFormState';
import {selectShouldAutoLogin} from '@messenger/core/src/Redux/LoginForm/Selectors/selectShouldAutoLogin';
import {selectCanBeLoggedByCredentials} from '@messenger/core/src/Redux/Session/Selectors/selectCanBeLoggedByCredentials';
import {selectCanBeLoggedByForeignSid} from '@messenger/core/src/Redux/Session/Selectors/selectCanBeLoggedByForeignSid';

export const selectIsAutoLoginRequired = createSelector(
	[selectShouldAutoLogin, selectLoginFormState, selectCanBeLoggedByCredentials, selectCanBeLoggedByForeignSid],
	(shouldAutoLogin, loginForm, canBeLoggedByCredentials, canBeLoggedByForeignSID) =>
		_.isEmpty(loginForm.error) && shouldAutoLogin && (canBeLoggedByCredentials || canBeLoggedByForeignSID),
);
