import {takeEvery} from 'typed-redux-saga';

import {messageInputClientOnlyActions} from '@messenger/core/src/Redux/MessageInput/Actions/messageInputClientOnlyActions';

import {saveCurrentCaretPositionSaga} from 'src/Redux/MessageInput/Sagas/saveCurrentCaretPositionSaga';
import {insertToCurrentCaretPositionSaga} from 'src/Redux/MessageInput/Sagas/insertToCurrentCaretPositionSaga';
import {focusCurrentMessageInputSaga} from 'src/Redux/MessageInput/Sagas/focusCurrentMessageInputSaga';

export function* messageInputSagaWatcher() {
	yield* takeEvery(messageInputClientOnlyActions.saveCurrentCaretPosition, saveCurrentCaretPositionSaga);
	yield* takeEvery(messageInputClientOnlyActions.focusCurrentMessageInput, focusCurrentMessageInputSaga);
	yield* takeEvery(messageInputClientOnlyActions.insertToCurrentCaretPosition, insertToCurrentCaretPositionSaga);
}
