import {SessionState} from 'sip.js';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {TSipCall} from '@messenger/core/src/Services/AbstractSipService';

enum EnumSipActions {
	INIT = 'INIT',
	SET_IS_INITIALIZED = 'SET_IS_INITIALIZED',
	CALL_TO = 'CALL_TO',
	SET_CALL = 'SET_CALL',
	RESET_CALL = 'RESET_CALL',
	ACCEPT = 'ACCEPT',
	HANG_UP = 'HANG_UP',
	CHANGE_CALL_STATE = 'CHANGE_CALL_STATE',
	SEND_TONE = 'SEND_TONE',
}

class SipClientOnlyActions extends ClientOnlyActions<EnumStore.SIP> {
	readonly scope = EnumStore.SIP;

	init = this.createAction(EnumSipActions.INIT, this.getPrepareAction<TSipInitPayload>());
	setIsInitialized = this.createAction(EnumSipActions.SET_IS_INITIALIZED, this.getPrepareAction<boolean>());
	callTo = this.createAction(EnumSipActions.CALL_TO, this.getPrepareAction<string>());
	setCall = this.createAction(EnumSipActions.SET_CALL, this.getPrepareAction<TSipCall>());
	resetCall = this.createAction(EnumSipActions.RESET_CALL);
	accept = this.createAction(EnumSipActions.ACCEPT);
	hangUp = this.createAction(EnumSipActions.HANG_UP);
	changeCallState = this.createAction(EnumSipActions.CHANGE_CALL_STATE, this.getPrepareAction<SessionState>());
	sendTone = this.createAction(EnumSipActions.SEND_TONE, this.getPrepareAction<string>());
}

export type TSipInitPayload = {
	login: string;
	password: string;
};

export const sipClientOnlyActions = new SipClientOnlyActions();
