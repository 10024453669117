import {createSelector, Dictionary} from '@reduxjs/toolkit';
import _ from 'lodash';

import {_selectTicketShowEntities} from '@messenger/core/src/Redux/TicketShow/Selectors/ticketShowDefaultSelectors';
import TicketShowVM from '@messenger/core/src/Redux/TicketShow/TicketShowVM';

export const selectAllTicketShowEntitiesVms = createSelector(
	_selectTicketShowEntities,
	(ticketShowEntities): Dictionary<TicketShowVM> =>
		_.omitBy(
			_.mapValues(ticketShowEntities, (ticketShowEntity) =>
				_.isUndefined(ticketShowEntity) ? undefined : new TicketShowVM(ticketShowEntity),
			),
			(showVM) => _.isUndefined(showVM) || showVM.isDeleted,
		),
);
