import {createSlice} from '@reduxjs/toolkit';
import {EnumCurrency, ICurrencyDescription, InitialCurrencies} from 'cmd-control-client-lib';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {currencyClientOnlyActions} from '@messenger/core/src/Redux/Currency/Actions/currencyClientOnlyActions';

const initialState: TCurrencySliceState = {
	currencies: {
		VXC: {
			name: EnumCurrency.VX_COIN,
			longName: 'VXCoin',
			symbol: 'VXC',
			format: 'N0',
			formatJS: {
				style: 'currency',
				currency: EnumCurrency.VX_COIN,
				maximumFractionDigits: 0,
				minimumFractionDigits: 0,
				currencyDisplay: 'code',
			},
		},
	},
	coins2EuroFactor: 0.23,
};

export const currencySlice = createSlice({
	name: EnumStore.CURRENCY,
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(currencyClientOnlyActions.setCurrencies, (state, action) => {
			state.currencies = {...state.currencies, ...action.payload};
		});
		builder.addCase(currencyClientOnlyActions.setCoins2EuroFactor, (state, {payload}) => {
			state.coins2EuroFactor = payload;
		});
	},
});

export type TCurrencySliceState = {
	currencies: {VXC: ICurrencyDescription} & InitialCurrencies;
	coins2EuroFactor: number;
};
