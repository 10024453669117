import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {toyClientOnlyActions} from '@messenger/core/src/Redux/Toy/Actions/toyClientOnlyActions';
import {selectSelectedToy} from '@messenger/core/src/Redux/Toy/Selectors/selectSelectedToy';

/**
 * @param {object} action
 * @return {Generator|void}
 */
export const deselectInactiveToySaga = function* (action: ReturnType<typeof toyClientOnlyActions.receiveToys>) {
	try {
		// check selected device still in list
		const selectedToyId = yield* select(selectSelectedToy);

		if (
			!_.isUndefined(selectedToyId) &&
			Object.prototype.hasOwnProperty.call(action.payload, selectedToyId) &&
			_.get(action.payload, [selectedToyId, 'status'], 0) !== 1
		) {
			yield* put(toyClientOnlyActions.deselectToy());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'deselectInactiveToySaga', action});
	}
};
