import { ACTION, baseParamsType, IBACKENDPAYLOAD, ICOMMAND, IKeyMaybeValue, IRESPONSE, RESULT } from "../cmd-protocol";
import { channelIdType } from "./channel";
import { MayHaveChannelId } from "./media";
import { Currency, EnumTicketType, MediaFile, MediaPrice, MessageId } from "./message";

export type PriceInstruction = {
  min: string;
  max: string;
  default: string;
  pick: string[];
};

export type DurationInstruction = {
  min: string;
  max: string;
  default: string;
  pick: string[];
};

export type TicketPriceDuration = {
  price: PriceInstruction;
  /**dauer in seconds */
  duration: DurationInstruction;
};

export type MediaPrices = {
  imagePrices: PriceInstruction;
  audioPrices: PriceInstruction;
  videoPrices: PriceInstruction;
  ticketPrices?: Record<EnumTicketType, TicketPriceDuration>;
};

/**
 * Command for get prices for media. Will be forwarded to B2B backend.
 */
export class CMDP_SMEDIAGETPRICES implements ICOMMAND {
  public action = ACTION.CMDP_SMEDIAGETPRICES;
  public params: baseParamsType & MayHaveChannelId;
}

/**
 * Response command for CMDP_SMEDIAGETPRICES
 */
export class CMDP_SGETPRICESMEDIA_RESPONSE extends CMDP_SMEDIAGETPRICES implements IRESPONSE, IBACKENDPAYLOAD {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
  /** B2B Infos */
  public payload: Currency & MediaPrices;
}

//------------------------------------------------------------------------------------------------------
/**
 * B2B Command for create of media offer
 */
export class CMDP_SMEDIAOFFERCREATE implements ICOMMAND {
  public action = ACTION.CMDP_SMEDIAOFFERCREATE;
  public params: baseParamsType &
    channelIdType &
    Currency &
    MediaPrice &
    MediaFile &
    MessageId & {
      userPoolId: string;
      usrKey: string;
    };
}

/**
 * B2B Response command for CMDP_SMEDIAOFFERCREATE
 */
export class CMDP_SMEDIAOFFERCREATE_RESPONSE extends CMDP_SMEDIAOFFERCREATE implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

/**
 * B2B command for inform partnter about media purchase. Will be sent to partners B2B backend.
 */
export class CMDP_SINFORMMEDIAPURCHASED implements ICOMMAND {
  public action = ACTION.CMDP_SINFORMMEDIAPURCHASED;
  public params: baseParamsType &
    channelIdType &
    /** the message id of related media offer */
    MessageId &
    /** MediaPrice is added to proxy call from CmdControl to B2B backend */
    MediaPrice &
    /** MediaFile is added to proxy call from Cmdontrol to B2B backend */
    MediaFile;
}

/**
 * Response command for CMDP_SMEDIAPURCHASE.
 */
export class CMDP_SINFORMMEDIAPURCHASED_RESPONSE extends CMDP_SINFORMMEDIAPURCHASED implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}
