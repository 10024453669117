import {
	ACTION,
	CMDP_CHANNELSSUMMARY_RESPONSE,
	CMDP_GETCHANNELS_RESPONSE,
	EnumChannelFlags,
	CMDP_CHANNELSEEN_RESPONSE,
	CMDP_SETCHANNELINFO_RESPONSE,
} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

/**
 * @WARNING
 * After adding new Server Action add it to "serverToClientActionsMap" object
 * @see serverToClientActionsMap
 */
class ChannelsServerToClientActions extends ServerToClientActions<EnumStore.CHANNELS> {
	readonly scope = EnumStore.CHANNELS;

	channelMarkedSeen = this.createAction(
		ACTION.CMDP_CHANNELSEEN,
		this.getPrepareAction<CMDP_CHANNELSEEN_RESPONSE['values'], CMDP_CHANNELSEEN_RESPONSE, boolean | string>(),
	);

	channelFlagsUpdated = this.createAction(
		ACTION.CMDP_SETCHANNELINFO,
		this.getPrepareAction<CMDP_SETCHANNELINFO_RESPONSE['values'], CMDP_SETCHANNELINFO_RESPONSE, boolean | string>(),
	);

	channelSummaryReceived = this.createAction(
		ACTION.CMDP_CHANNELSSUMMARY,
		this.getPrepareAction<CMDP_CHANNELSSUMMARY_RESPONSE['values'], CMDP_CHANNELSSUMMARY_RESPONSE, boolean | string>(),
	);

	channelsReceived = this.createAction(
		ACTION.CMDP_GETCHANNELS,
		this.getPrepareAction<CMDP_GETCHANNELS_RESPONSE['values'], CMDP_GETCHANNELS_RESPONSE, boolean | string>(),
	);
}

export type TBooleanChannelFlags = {
	[key in EnumChannelFlags]?: boolean;
};

export type TChannelFlagsUpdatedMeta = {
	withoutNotification?: boolean;
};

export const channelsServerToClientActions = new ChannelsServerToClientActions();
