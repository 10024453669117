import {PayloadAction} from '@reduxjs/toolkit';
import _ from 'lodash';
import {put, select} from 'typed-redux-saga';
import {EnumMediaState} from 'cmd-control-client-lib';

import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {TSswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import {selectUploadingMessageIds} from '@messenger/core/src/Redux/Messages/Selectors/selectUploadingMessageIds';
import {getTemporaryMessageId} from '@messenger/core/src/Redux/Messages/entityAdapter';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export const markMessageAsUploadedSaga = function* ({
	payload: {messageKey, mediaState},
}: PayloadAction<TSswMessageType>) {
	try {
		const tempMessageId = getTemporaryMessageId(messageKey);
		const uploadingMessageIds = yield* select(selectUploadingMessageIds);

		if (
			_.includes(uploadingMessageIds, tempMessageId) &&
			!_.includes([EnumMediaState.UPLOAD, EnumMediaState.TRANSCODING], mediaState)
		) {
			yield* put(messagesClientOnlyActions.markUploaded(tempMessageId));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'e markMessageAsUploadedSaga'});
	}
};
