import _ from 'lodash';

import {selectIsVideoChatExcludeFiltered} from '@messenger/core/src/Redux/Channels/Selectors/Filter/selectIsVideoChatExcludeFiltered';
import {selectAdminChatsToShow} from '@messenger/core/src/Redux/Chats/Selectors/selectAdminChatsToShow';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectRegularChatVms} from '@messenger/core/src/Redux/Chats/Selectors/selectRegularChatVms';
import {selectChannelEntityVMs} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';

import {selectChatsFilterMatcher} from './selectChatsFilterMatcher';

export const selectRunningChatVmsByFilter = createSelector(
	[
		selectIsVideoChatExcludeFiltered,
		selectRegularChatVms,
		selectAdminChatsToShow,
		selectChatsFilterMatcher,
		selectChannelEntityVMs,
	],
	(isVideoChatExcludeFiltered, chatVms, adminChatsToShowIds, chatsFilterMatcher, channelVMEntities) => {
		if (isVideoChatExcludeFiltered) {
			return [];
		}

		return _.filter(chatVms, (chatVm) => {
			const channelVm = chatVm.channelId && _.get(channelVMEntities, chatVm.channelId);
			const hideAdmins = chatVm.isAdmin && !_.includes(adminChatsToShowIds, chatVm.chatId);
			const matchingFilter = channelVm ? chatsFilterMatcher.isMatches(channelVm) : false;

			return !(
				chatVm.exitCode ||
				matchingFilter ||
				hideAdmins ||
				chatVm.isAllChatsAggregate ||
				chatVm.isGroupChat ||
				chatVm.isLobbyChat
			);
		});
	},
);
