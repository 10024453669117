import {Subscribe} from '@redux-saga/core';
import {SessionState} from 'sip.js';

export enum EnumSipServiceAction {
	INITIALIZED = 'INITIALIZED',
	INCOMING_CALL = 'INCOMING_CALL',
	OUTGOING_CALL = 'OUTGOING_CALL',
	STATE_CHANGED = 'STATE_CHANGED',
}

export enum EnumSipCallType {
	INCOMING = 'INCOMING',
	OUTGOING = 'OUTGOING',
}

export abstract class AbstractSipService {
	abstract init(login: string, password: string, name: string): void;

	abstract setEmitter(emitter: TSipEmitter): void;

	abstract unsetEmitter(): void;

	abstract stop(): void;

	abstract accept(): Promise<void>;

	abstract hangUp(): Promise<void>;

	abstract callTo(to: string): Promise<void>;

	abstract sendTone(tone: string): Promise<void>;

	abstract get micAudioTrack(): MediaStreamTrack | undefined;

	abstract get soundAudioTrack(): MediaStreamTrack | undefined;
}

export type TSipServiceAction<T extends EnumSipServiceAction, P = unknown> = {
	type: T;
	payload: P;
};

export type TSipCall = {
	id: string;
	type: EnumSipCallType;
	name: string;
	state: SessionState;
};

type TSipServiceInitializedAction = TSipServiceAction<EnumSipServiceAction.INITIALIZED, boolean>;
type TSipServiceIncomingAction = TSipServiceAction<EnumSipServiceAction.INCOMING_CALL, TSipCall>;
type TSipServiceOutgoingAction = TSipServiceAction<EnumSipServiceAction.OUTGOING_CALL, TSipCall>;
type TSipServiceIncomingStateChangedAction = TSipServiceAction<EnumSipServiceAction.STATE_CHANGED, SessionState>;

export type TSipServiceActions =
	| TSipServiceInitializedAction
	| TSipServiceIncomingAction
	| TSipServiceOutgoingAction
	| TSipServiceIncomingStateChangedAction;

export type TSipEmitter = Parameters<Subscribe<TSipServiceActions>>[0];
