import {EnumChannelFilterValues} from 'cmd-control-client-lib';

export const filterValueFromBoolean = (state: boolean, toBeExcludedIfFalsy = false) => {
	if (state) {
		return EnumChannelFilterValues.ONLY;
	}

	if (toBeExcludedIfFalsy) {
		return EnumChannelFilterValues.EXCLUDE;
	} else {
		return EnumChannelFilterValues.ANY;
	}
};
