import {createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {sipClientOnlyActions} from '@messenger/core/src/Redux/Sip/Actions/sipClientOnlyActions';
import {TSipCall} from '@messenger/core/src/Services/AbstractSipService';

const initialState: TSipState = {
	isInitialized: false,
};

export const sipSlice = createSlice({
	name: EnumStore.SIP,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(sipClientOnlyActions.setIsInitialized, (state, {payload}) => {
			state.isInitialized = payload;
		});
		builder.addCase(sipClientOnlyActions.setCall, (state, {payload}) => {
			state.call = payload;
		});
		builder.addCase(sipClientOnlyActions.changeCallState, (state, {payload}) => {
			if (!_.isUndefined(state.call)) {
				state.call.state = payload;
			}
		});
		builder.addCase(sipClientOnlyActions.resetCall, (state) => {
			state.call = undefined;
		});
	},
});

type TSipState = {
	isInitialized: boolean;
	call?: TSipCall;
};
