import _ from 'lodash';

import {TChannelsMap, TChannelsRange} from '@messenger/core/src/Redux/ChannelsMap/types';

export const maybeModifyChannelMapSaga = (
	channelsMap: Record<string, TChannelsMap>,
	channelsMapTotal: number,
	channelId: string,
	newWeight: string,
	currentRange?: TChannelsRange,
) => {
	let firstGapIndex: number = -1;
	let lastChannelsMapItemIndex: number = -1;

	for (let index = 0; index <= channelsMapTotal; index++) {
		const channelsMapItem = channelsMap[index];

		if (!channelsMapItem) {
			firstGapIndex = index;

			break;
		}

		if (newWeight > channelsMapItem.weight && lastChannelsMapItemIndex === -1) {
			lastChannelsMapItemIndex = channelsMapItem.id;
		}
	}

	if (firstGapIndex === channelsMapTotal && lastChannelsMapItemIndex === -1) {
		lastChannelsMapItemIndex = channelsMapTotal;
	}

	if (
		lastChannelsMapItemIndex === -1 ||
		!currentRange ||
		(firstGapIndex <= currentRange.endIndex && firstGapIndex < channelsMapTotal)
	) {
		return undefined;
	}

	channelsMap = _.reduce(
		channelsMap,
		(result: Record<string, TChannelsMap>, channelMapItem, index) => {
			if (channelMapItem.id >= lastChannelsMapItemIndex && channelMapItem.id < firstGapIndex) {
				const newIndex = channelMapItem.id + 1;

				result[newIndex] = {...channelMapItem, id: newIndex};
			} else if (channelMapItem.id < firstGapIndex) {
				result[index] = channelMapItem;
			}

			return result;
		},
		{},
	);

	channelsMap[lastChannelsMapItemIndex] = {
		id: lastChannelsMapItemIndex,
		weight: newWeight,
		channelId,
	};

	return channelsMap;
};
