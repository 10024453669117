import {createSelector, Dictionary} from '@reduxjs/toolkit';
import _ from 'lodash';

import {_selectGroupsEntities} from '@messenger/core/src/Redux/Groups/Selectors/groupsDefaultSelectors';
import GroupVM from '@messenger/core/src/Redux/Groups/GroupVM';

export const selectCustomGroupEntitiesVms = createSelector(
	_selectGroupsEntities,
	(groupEntities): Dictionary<GroupVM> =>
		_.omitBy(
			_.mapValues(groupEntities, (groupEntity) => groupEntity && new GroupVM(groupEntity)),
			_.isUndefined,
		),
);
