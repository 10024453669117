import {EnumMessageDirection, SystemMessageKey} from 'cmd-control-client-lib';
import _ from 'lodash';
import {call, put, select} from 'typed-redux-saga';

import {skipWebNotificationOn} from '@messenger/core/src/BusinessLogic/skipWebNotificationOn';
import ChannelInfoVM from '@messenger/core/src/Redux/ChannelInfos/ChannelInfoVM';
import {selectChannelInfoVmById} from '@messenger/core/src/Redux/ChannelInfos/Selectors/channelInfosDefaultSelectors';
import ChannelVM from '@messenger/core/src/Redux/Channels/ChannelVM';
import {
	getChannelIdForSelector,
	selectChannelVMById,
} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';
import {selectActiveChatIds} from '@messenger/core/src/Redux/Chats/Selectors/selectActiveChatIds';
import {messagesServerToClientActions} from '@messenger/core/src/Redux/Messages/Actions';
import {
	getMessageIdForSelector,
	selectMessageVmById,
} from '@messenger/core/src/Redux/Messages/Selectors/messagesDefaultSelectors';
import {webNotificationClientOnlyActions} from '@messenger/core/src/Redux/WebNotification/Actions/webNotificationClientOnlyActions';
import {WebNotificationHelper} from '@messenger/core/src/Redux/WebNotification/WebNotificationHelper';
import {TFocusable} from '@messenger/core/src/Services/AbstractUiContainer';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {parseBoolean} from '@messenger/core/src/Utils/Strings/parseBoolean';

import {isMessageForArchivedChannel, isOldMessageEvent} from 'src/Redux/Messages/Sagas/playSoundIfNotSpamOrMutedSaga';

/**
 * @todo merge with playSoundIfNotSpamOrMutedSaga as it's also reacting on incoming messages
 * @note this Saga should process own event, but not message event
 *
 * @see playSoundIfNotSpamOrMuted
 */
export const showWebNotificationSaga = function* (
	browser: TFocusable,
	action: ReturnType<typeof messagesServerToClientActions.messageReceived>,
) {
	try {
		const message = action.payload;
		const skipByKey = message.messageKey && skipWebNotificationOn.includes(message.messageKey as SystemMessageKey);
		const isDeleted = parseBoolean(message.isDeleted);

		if (!browser.isTabFocused() && message.messageId && !skipByKey && !isDeleted) {
			const isOld = isOldMessageEvent(message);
			const isSpam = yield* call(isMessageForArchivedChannel, message);
			const channelVM: ChannelVM | undefined = !_.isUndefined(message.channelId)
				? yield* select(selectChannelVMById, getChannelIdForSelector(message.channelId))
				: undefined;
			const isMuted = channelVM?.isMuted;

			if (!isOld && !isSpam && !isMuted && message.direction === EnumMessageDirection.IN) {
				const messageVM = yield* select(selectMessageVmById, getMessageIdForSelector(message.messageId));

				if (!messageVM) {
					throw new Error('messageVM is undefined');
				}

				if (messageVM.text || messageVM.hasImage || messageVM.hasAudio) {
					const activeChats: string[] = yield* select(selectActiveChatIds);
					const channelInfoVM: ChannelInfoVM | undefined = !_.isUndefined(message.channelId)
						? yield* select(selectChannelInfoVmById, getChannelIdForSelector(message.channelId))
						: undefined;

					yield* put(
						webNotificationClientOnlyActions.show({
							id: WebNotificationHelper.getNotificationIdByMessageId(messageVM.messageId),
							title: WebNotificationHelper.getNotificationText(messageVM, channelInfoVM),
							options: WebNotificationHelper.getNotificationOptions(message, activeChats, channelInfoVM),
						}),
					);
				}
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'showWebNotification', action});
	}
};
