import 'reflect-metadata';
import {container, singleton} from 'tsyringe';

import AbstractJpegSocketPerChatPreviewApi from '@messenger/core/src/Services/JpegSocketPreviewApi/AbstractJpegSocketPerChatPreviewApi';
import AbstractJpegSocketPreviewApi from '@messenger/core/src/Services/JpegSocketPreviewApi/AbstractJpegSocketPreviewApi';
import DIToken from '@messenger/core/src/BusinessLogic/DIToken';

import JpegSocketPreviewApi from 'src/Services/JpegSocketPreviewApi/JpegSocketPreviewApi';

@singleton()
class JpegSocketPerChatPreviewApi extends AbstractJpegSocketPerChatPreviewApi {
	private _store: Record<string, AbstractJpegSocketPreviewApi> = {};

	createPreview(chatId: string, socketUrl: string) {
		if (!this._store[chatId]) {
			this._store[chatId] = new JpegSocketPreviewApi();
		}

		this._store[chatId].setSocketUrl(socketUrl);
	}

	getPreview(chatId: string) {
		if (!this._store[chatId]) {
			this._store[chatId] = new JpegSocketPreviewApi();
		}

		return this._store[chatId];
	}

	removePreview(chatId: string) {
		const previewApi = this._store[chatId];

		previewApi?.stopSocket();
		delete this._store[chatId];
	}
}

container.register(DIToken.JpegSocketPerChatPreviewApi, {useToken: JpegSocketPerChatPreviewApi});

export default JpegSocketPerChatPreviewApi;
