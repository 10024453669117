import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {SupportedLanguage} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {getCountryByLanguage} from '@messenger/core/src/Locale/helper';

const PageTitle = ({unseen = 0, version = ServiceFactory.env.getVersion(), delay = 1000}: TPageTitleProps) => {
	const {
		t,
		i18n: {language},
	} = useTranslation();

	const title = `${t('common:title')} ${version}`;
	let unseenTitle = '';

	const [offset, setOffset] = useState(unseen > 0 ? 5 : 0);

	useEffect(() => {
		const updateTitle = (interval: ReturnType<typeof setInterval>) => {
			if (unseen === 0) {
				clearInterval(interval);
			}

			setOffset(unseen > 0 && offset < 5 ? offset + 1 : 0);
		};

		const interval: ReturnType<typeof setInterval> = setInterval(() => updateTitle(interval), delay);

		return () => {
			null !== interval && clearInterval(interval);
		};
	}, [unseen, delay, offset, setOffset]);

	if (unseen >= 1) {
		unseenTitle = `***** ${t('common:titleMessage', {total: unseen})} | `;
	}

	const country = getCountryByLanguage(language as SupportedLanguage);

	return (
		<Helmet defaultTitle={title} titleTemplate={`${unseenTitle.substring(offset)} %s | ${title}`}>
			<meta name="description" content={t('common:seo-description')} />
			<meta property="og:locale" content={`${language}_${country.toUpperCase()}`} />
			<meta property="og:title" content={t('common:title')} />
			<meta property="og:image" content="../src/Assets/Images/login-background.jpg" />
			<link rel="image_src" href="../src/Assets/Images/login-background.jpg" />
			<meta name="twitter:image" content="../src/Assets/Images/login-background.jpg" />
		</Helmet>
	);
};

type TPageTitleProps = {
	unseen?: number;
	version?: string;
	delay?: number;
};

export default PageTitle;
