import {createEntityAdapter} from '@messenger/core/src/Utils/Redux/createEntityAdapter';

export const liveSessionChatsEntityAdapter = createEntityAdapter<TLiveSessionChats>({
	selectId: (entity) => entity.liveSessionId,
});

export type TLiveSessionChats = {
	liveSessionId: string;
	liveChatIds: string[];
	channelIds: string[];
};
