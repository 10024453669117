import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {notificationsClientOnlyActions} from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';
import {selectNotificationsIds} from '@messenger/core/src/Redux/Notifications/Selectors/notificationsDefaultSelectors';

export const closeAllNotificationsSaga = function* () {
	try {
		const allNotificationsIds = yield* select(selectNotificationsIds);

		yield* put(notificationsClientOnlyActions.dismissMany(allNotificationsIds));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'closeAllNotificationsSaga'});
	}
};
