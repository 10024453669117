import _ from 'lodash';
import {call, put, select} from 'typed-redux-saga';

import {channelInfosClientToServerActions} from '@messenger/core/src/Redux/ChannelInfos/Actions';
import {selectChannelInfoIds} from '@messenger/core/src/Redux/ChannelInfos/Selectors/channelInfosDefaultSelectors';
import {selectCurrentlyRequestingIds} from '@messenger/core/src/Redux/ChannelInfos/Selectors/selectCurrentlyRequestingIds';
import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions';
import {processChannelsReceivedSaga} from '@messenger/core/src/Redux/Channels/Sagas/processChannelsReceivedSaga';
import {selectArchivedChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/selectArchivedChannelIds';
import {contactNoteClientToServerActions} from '@messenger/core/src/Redux/ContactNote/Actions/contactNoteClientToServerAction';
import {selectContactNoteIds} from '@messenger/core/src/Redux/ContactNote/Selectors/contactNoteDefaultSelectors';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export const processBatchUpsertChannelsSaga = function* (
	action: ReturnType<typeof channelsClientOnlyActions.upsertMany>,
) {
	try {
		const channelIds = _.chain(action.meta).get('commands').map('params.channelId').uniq().value();

		const loadedChannelInfoIds = yield* select(selectChannelInfoIds);
		const requestingChannelInfoIds = yield* select(selectCurrentlyRequestingIds);

		const nonEmptyInfoIds = _.filter(
			channelIds,
			(channelId) => !_.includes(loadedChannelInfoIds, channelId) && !_.includes(requestingChannelInfoIds, channelId),
		);

		if (!_.isEmpty(nonEmptyInfoIds)) {
			yield* put(channelInfosClientToServerActions.get(nonEmptyInfoIds));
		}

		const loadedContactNoteIds = yield* select(selectContactNoteIds);
		const archivedChannelIds = yield* select(selectArchivedChannelIds);

		const nonEmptyNotesIds = _.filter(
			channelIds,
			(channelId) => !_.includes(loadedContactNoteIds, channelId) && !_.includes(archivedChannelIds, channelId),
		);

		yield* call(processChannelsReceivedSaga);

		if (!_.isEmpty(nonEmptyNotesIds)) {
			yield* put(contactNoteClientToServerActions.requestContactNote(nonEmptyNotesIds.join(',')));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processBatchUpsertChannelsSaga'});
	}
};
