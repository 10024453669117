import { EnumBooleanStringified, JSONString } from "../../@types";
import { channelIdType, CMDC_CHANNEL, EnumChannelFilterValues } from "../channel";
import { ACTION } from "../command/action";
import { baseParamsType } from "../command/baseparams";
import { ICOMMAND, IKeyMaybeValue, IRESPONSE, RESULT } from "../command/icommand";

export type groupIdType = {
  /** id */
  groupId: string;
};

export type groupDataType = {
  key?: "ticketShow" | string;
  auto_generated?: boolean;
};

export type groupType = groupIdType & {
  /** display name */
  groupName: string;
  /** create datetime  */
  groupCreated: string;
  /** channel counter */
  groupChannels: string;
  /**  status */
  groupActive?: "0" | "1" | string;
  /** JSON encoded GroupDataType*/
  groupData?: JSONString<groupDataType>;
};

export class CMDC_GROUP implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_GROUP;
  public params: groupType;
}

export class CMDC_CHANNELGROUP implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_CHANNELGROUP;
  public params: groupIdType &
    channelIdType & {
      /** inform deleted channel from group */
      isDeleted?: EnumBooleanStringified;
    };
}

export class CMDP_SGETGROUPS implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SGETGROUPS;
  public params: baseParamsType & { filterActive?: EnumChannelFilterValues | string };
}

export class CMDP_SGETGROUPS_RESPONSE extends CMDP_SGETGROUPS implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_GROUP[];
  public values: IKeyMaybeValue;
}

export class CMDP_SADDGROUP implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SADDGROUP;
  public params: baseParamsType & { groupName: string };
}

export class CMDP_SADDGROUP_RESPONSE extends CMDP_SADDGROUP implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_GROUP[];
  public values: IKeyMaybeValue;
}

export class CMDP_SUPDATEGROUP implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SUPDATEGROUP;
  public params: baseParamsType &
    groupIdType & {
      /** set for change the display name */
      groupName?: string;
      /**set 0 to deactivate group, 1 to activate */
      groupActive?: "0" | "1" | string;
    };
}

export class CMDP_SUPDATEGROUP_RESPONSE extends CMDP_SUPDATEGROUP implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

export class CMDP_SGETGROUPCHANNELS implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SGETGROUPCHANNELS;
  public params: baseParamsType & groupIdType;
}

export class CMDP_SGETGROUPCHANNELS_RESPONSE extends CMDP_SGETGROUPCHANNELS implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_CHANNEL[];
  public values: IKeyMaybeValue;
}

export class CMDP_SGETCHANNELGROUPS implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SGETCHANNELGROUPS;
  public params: baseParamsType & (channelIdType | groupIdType);
}

export class CMDP_SGETCHANNELGROUPS_RESPONSE extends CMDP_SGETCHANNELGROUPS implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_CHANNELGROUP[];
  public values: IKeyMaybeValue;
}

export class CMDP_SADDCHANNELTOGROUP implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SADDCHANNELTOGROUP;
  public params: baseParamsType & channelIdType & groupIdType;
}

export class CMDP_SADDCHANNELTOGROUP_RESPONSE extends CMDP_SADDCHANNELTOGROUP implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

export class CMDP_SDELCHANNELFROMGROUP implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SDELCHANNELFROMGROUP;
  public params: baseParamsType & channelIdType & groupIdType;
}

export class CMDP_SDELCHANNELFROMGROUP_RESPONSE extends CMDP_SDELCHANNELFROMGROUP implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}
