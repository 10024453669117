import {CMDC_LIVESESSION} from 'cmd-control-client-lib';
import _ from 'lodash';

import {createEntityAdapter} from '@messenger/core/src/Utils/Redux/createEntityAdapter';
import {descSortComparerByField} from '@messenger/core/src/Utils/descSortComparerByField';

export const getLiveSessionEntityId = (entity: CMDC_LIVESESSION['params']) => `${entity.sesID}-${entity.startTime}`;

export const getStartTimeFromLiveSessionEntityId = (liveSessionEntityId: string) =>
	_.chain(liveSessionEntityId).split('-').drop().join('-').value();

export const liveSessionEntityAdapter = createEntityAdapter<CMDC_LIVESESSION['params']>({
	selectId: getLiveSessionEntityId,
	sortComparer: (entity1, entity2) => descSortComparerByField(entity1, entity2, 'startTime'),
});
