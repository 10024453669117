import {put} from 'typed-redux-saga';
import {ResultCode} from 'cmd-control-client-lib';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';
import {giftsClientOnlyActions} from '@messenger/core/src/Redux/Gifts/Actions/giftsClientOnlyActions';

export const processGiftsSaga = function* (action: ReturnType<typeof sessionServerToClientActions.initialized>) {
	try {
		if (action.meta.result.code === ResultCode.OK) {
			const gifts = _.get(action.payload, 'gifts', '');

			yield* put(giftsClientOnlyActions.setGifts(JSON.parse(gifts)));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processGiftsSaga'});
	}
};
