import {call, put} from 'typed-redux-saga';

import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {notificationsClientOnlyActions} from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';

export const processNetworkStatusUpdateSaga = function* ({
	payload: {isOnline, isResumable},
}: ReturnType<typeof clientClientOnlyActions.updateNetworkStatus>) {
	try {
		const api = yield* call([ServiceFactory, ServiceFactory.getVControlApi]);

		if (isOnline) {
			yield* call(
				[ServiceFactory.notifications, ServiceFactory.notifications.close],
				EnumSnackbarNotificationKeys.NO_NETWORK_KEY,
			);
			yield* call(
				[ServiceFactory.notifications, ServiceFactory.notifications.close],
				EnumSnackbarNotificationKeys.SOCKET_ERROR_NOTIFICATION_KEY,
			);
			yield* put(messagesClientOnlyActions.resetIsLoading());

			if (isResumable) {
				yield* call([api, api.resume]);
			}
		} else {
			yield* put(
				notificationsClientOnlyActions.enqueuePersistent(
					ServiceFactory.i18n.t('common:no-network'),
					EnumAbstractNotificationVariant.ERROR,
					{key: EnumSnackbarNotificationKeys.NO_NETWORK_KEY},
					undefined,
					undefined,
					true,
				),
			);

			yield* call([api, api.pause], isResumable);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processNetworkStatusUpdateSaga'});
	}
};
