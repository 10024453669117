import {ACTION, CMDP_STARTBANDWIDTHTEST_RESPONSE, CMDP_STOPBANDWIDTHTEST_RESPONSE} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class SpeedTestServerToClientActions extends ServerToClientActions<EnumStore.SPEED_TEST> {
	readonly scope = EnumStore.SPEED_TEST;

	startTestReceived = this.createAction(
		ACTION.CMDP_STARTBANDWIDTHTEST,
		this.getPrepareAction<CMDP_STARTBANDWIDTHTEST_RESPONSE['values'], CMDP_STARTBANDWIDTHTEST_RESPONSE, false>(),
	);

	stopTestReceived = this.createAction(
		ACTION.CMDP_STOPBANDWIDTHTEST,
		this.getPrepareAction<CMDP_STOPBANDWIDTHTEST_RESPONSE['values'], CMDP_STOPBANDWIDTHTEST_RESPONSE, false>(),
	);
}

export const speedTestServerToClientActions = new SpeedTestServerToClientActions();
