import {CMDC_ONLINESTATE} from 'cmd-control-client-lib';

import {ClientOnlyActions, TMultiEntityActionMeta} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum EnumOnlineStateActions {
	UPSERT_MANY = 'UPSERT_MANY',
	ADD_MANY = 'ADD_MANY',
}

class OnlineStateClientOnlyActions extends ClientOnlyActions<EnumStore.ONLINE_STATE> {
	readonly scope = EnumStore.ONLINE_STATE;

	upsertMany = this.createAction(
		EnumOnlineStateActions.UPSERT_MANY,
		this.getPrepareAction<CMDC_ONLINESTATE['params'][], TMultiEntityActionMeta<CMDC_ONLINESTATE>>(),
	);
	addMany = this.createAction(
		EnumOnlineStateActions.ADD_MANY,
		this.getPrepareAction<CMDC_ONLINESTATE['params'][], TMultiEntityActionMeta<CMDC_ONLINESTATE>>(),
	);
}

export const onlineStateClientOnlyActions = new OnlineStateClientOnlyActions();
