import {ACTION, ResultCode, CMDP_SMSGESTIMATE_RESPONSE} from 'cmd-control-client-lib';
import _ from 'lodash';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class ChannelGroupsPurchaseAbilityServerToClientActions extends ServerToClientActions<EnumStore.CHANNEL_GROUPS_PURCHASE_ABILITY> {
	readonly scope = EnumStore.CHANNEL_GROUPS_PURCHASE_ABILITY;

	checkCanReceiveAttachmentReceived = this.createAction(
		ACTION.CMDP_SMSGESTIMATE,
		(payload: CMDP_SMSGESTIMATE_RESPONSE['values'], meta: CMDP_SMSGESTIMATE_RESPONSE) => {
			return {
				payload: {
					...payload,
					canReceiveChannels: payload.canReceiveChannels ? _.split(payload.canReceiveChannels, ',') : [],
					canPurchaseChannels: payload.canPurchaseChannels ? _.split(payload.canPurchaseChannels, ',') : [],
				},

				meta,
				error: meta.result && meta.result.code !== ResultCode.OK ? meta.result.reason : false,
			};
		},
	);
}

export const channelGroupsPurchaseAbilityServerToClientActions =
	new ChannelGroupsPurchaseAbilityServerToClientActions();
