import {createSlice} from '@reduxjs/toolkit';
import {INotificationProps} from 'react-web-notification';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {webNotificationClientOnlyActions} from '@messenger/core/src/Redux/WebNotification/Actions/webNotificationClientOnlyActions';

export const initialWebNotificationState: TWebNotificationsState = {
	notification: undefined,
};

export const webNotificationSlice = createSlice({
	name: EnumStore.WEB_NOTIFICATION,
	initialState: initialWebNotificationState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(webNotificationClientOnlyActions.remove, (state) => {
			state.notification = undefined;
		});
		builder.addCase(webNotificationClientOnlyActions.show, (state, action) => {
			state.notification = action.payload;
		});
	},
});

export type TWebNotification = Partial<INotificationProps> & {
	id: string;
	title: string;
	options?: INotificationProps['options'] & {
		tag: string;
	};
};

export type TWebNotificationsState = {
	notification?: TWebNotification;
};
