import {
	JSONString,
	MSettingBool,
	MSettingFloat,
	MSettingMultiLangString,
	MSettingNameEnum,
	MSettings,
	RequestStateEnum,
	SupportedLanguage,
} from 'cmd-control-client-lib';
import {EnumCurrency} from 'cmd-control-client-lib/dist/@types';
import {assign, isUndefined, isError, attempt} from 'lodash';

import {TModelSettingsState} from '@messenger/core/src/Redux/ModelSettings/modelSettingsSlice';

export type TMultilangTextValueType = {
	[key in SupportedLanguage]: string;
};

type TModelSetting = {
	isReadOnly: boolean;
	isOnlineChangeAllowed: boolean;
};

export type TModelSettingBoolean = TModelSetting & {
	value: boolean;
};

export type TModelSettingNumber = TModelSetting & {
	value: number;
	minValue: number;
	maxValue: number;
	range?: number[];
	step?: number;
	currency: EnumCurrency;
};

export type TModelSettingMultilangText = TModelSetting & {
	value: TMultilangTextValueType;
	minLength?: number;
	maxLength?: number;
};

export type TModelSettingStateRequest = TModelSetting & {
	value: RequestStateEnum;
	nextPossibleRequestDate?: Date;
};

export const defaultMultilangTextValue = {
	[SupportedLanguage.DE]: '',
	[SupportedLanguage.EN]: '',
	[SupportedLanguage.ES]: '',
};

export default class ModelSettingsViewModel {
	readonly isLoading: boolean;
	readonly isSaving: boolean;
	readonly canSetupHeatmap: boolean;
	readonly canSetupChatPrices: boolean;

	readonly heatmapEnabled?: TModelSettingBoolean;
	readonly heatmapDescription?: TModelSettingMultilangText;
	readonly messengerPrice?: TModelSettingNumber;
	readonly videoChatPrice?: TModelSettingNumber;
	readonly privateChatPrice?: TModelSettingNumber;
	readonly voyeurChatPrice?: TModelSettingNumber;
	readonly singleC2CChatPrice?: TModelSettingNumber;
	readonly voyeurChatEnabled?: TModelSettingBoolean;
	readonly voyeurChatPrivateAudioEnabled?: TModelSettingBoolean;
	readonly softChatEnabled?: TModelSettingBoolean;
	readonly lovenseToyRequest?: TModelSettingStateRequest;
	readonly ticketShowRequest?: TModelSettingStateRequest;
	readonly parsedFields?: MSettings['fields'];

	constructor({isLoading, savingQueueLength, mSettings}: TModelSettingsState) {
		this.isLoading = isLoading;
		this.isSaving = savingQueueLength > 0;

		const parsedFields = ModelSettingsViewModel.parseSettings(mSettings);

		const {
			[MSettingNameEnum.HEATMAP_ENABLED]: heatmapEnabled,
			[MSettingNameEnum.HEATMAP_DESCRIPTION]: heatmapDescription,
			[MSettingNameEnum.MESSENGER_PRICE]: messengerPrice,
			[MSettingNameEnum.VIDEO_CHAT_PRICE]: videoChatPrice,
			[MSettingNameEnum.VOYEUR_CHAT_PRICE]: voyeurChatPrice,
			[MSettingNameEnum.PRIVATE_C2C_CHAT_PRICE]: singleC2CChatPrice,
			[MSettingNameEnum.PRIVATE_CHAT_PRICE]: privateChatPrice,
			[MSettingNameEnum.VOYEUR_CHAT_ENABLED]: voyeurChatEnabled,
			[MSettingNameEnum.VOYEUR_CHAT_PRIVATE_AUDIO]: voyeurChatPrivateAudioEnabled,
			[MSettingNameEnum.SOFT_CHAT_ENABLED]: softChatEnabled,
			[MSettingNameEnum.LOVENSE_TOY_REQUEST]: lovenseToyRequest,
			[MSettingNameEnum.TICKET_SHOW_REQUEST]: ticketShowRequest,
		} = parsedFields;

		this.parsedFields = parsedFields;
		this.heatmapEnabled = heatmapEnabled && ModelSettingsViewModel.convertBoolean(heatmapEnabled);
		this.heatmapDescription = heatmapDescription && ModelSettingsViewModel.convertMultilangText(heatmapDescription);
		this.messengerPrice = messengerPrice && ModelSettingsViewModel.convertNumber(messengerPrice);
		this.videoChatPrice = videoChatPrice && ModelSettingsViewModel.convertNumber(videoChatPrice);
		this.privateChatPrice = privateChatPrice && ModelSettingsViewModel.convertNumber(privateChatPrice);
		this.voyeurChatPrice = voyeurChatPrice && ModelSettingsViewModel.convertNumber(voyeurChatPrice);
		this.singleC2CChatPrice = singleC2CChatPrice && ModelSettingsViewModel.convertNumber(singleC2CChatPrice);
		this.voyeurChatEnabled = voyeurChatEnabled && ModelSettingsViewModel.convertBoolean(voyeurChatEnabled);
		this.voyeurChatPrivateAudioEnabled =
			voyeurChatPrivateAudioEnabled && ModelSettingsViewModel.convertBoolean(voyeurChatPrivateAudioEnabled);
		this.softChatEnabled = softChatEnabled && ModelSettingsViewModel.convertBoolean(softChatEnabled);

		this.lovenseToyRequest = lovenseToyRequest && {
			value: lovenseToyRequest.value,
			isReadOnly: false,
			isOnlineChangeAllowed: false,
			nextPossibleRequestDate: lovenseToyRequest.nextPossibleRequestDate || undefined,
		};

		this.ticketShowRequest = ticketShowRequest && {
			value: ticketShowRequest.value,
			isReadOnly: false,
			isOnlineChangeAllowed: false,
			nextPossibleRequestDate: ticketShowRequest.nextPossibleRequestDate || undefined,
		};

		this.canSetupHeatmap = Boolean(this.heatmapEnabled && this.heatmapDescription);
		this.canSetupChatPrices = Boolean(this.messengerPrice || this.videoChatPrice || this.privateChatPrice);
	}

	private static parseSettings = (settings?: JSONString<MSettings>): MSettings['fields'] => {
		const parseResult = attempt(JSON.parse, settings);

		if (isUndefined(settings) || isError(parseResult)) {
			return {};
		}

		return parseResult.fields;
	};

	static convertBoolean({isReadOnly, isOnlineChangeAllowed, value}: MSettingBool): TModelSettingBoolean {
		return {
			isReadOnly,
			isOnlineChangeAllowed,
			value,
		};
	}

	static convertNumber({
		isReadOnly,
		isOnlineChangeAllowed,
		value,
		minValue,
		maxValue,
		range,
		step,
		currency,
	}: MSettingFloat): TModelSettingNumber {
		return {
			isReadOnly,
			isOnlineChangeAllowed,
			value,
			minValue,
			maxValue,
			range: range || undefined,
			step: step || undefined,
			currency: currency as EnumCurrency,
		};
	}

	static convertMultilangText({
		isReadOnly,
		isOnlineChangeAllowed,
		value,
		minLength,
		maxLength,
	}: MSettingMultiLangString): TModelSettingMultilangText {
		return {
			isReadOnly,
			isOnlineChangeAllowed,
			minLength: minLength || undefined,
			maxLength: maxLength || undefined,
			value: assign({}, defaultMultilangTextValue, Object.fromEntries(value.map((item) => [item.lang, item.text]))),
		};
	}
}
