import _ from 'lodash';
import {put, select} from 'typed-redux-saga';
import {ACTION} from 'cmd-control-client-lib';

import {channelsClientOnlyActions, channelsServerToClientActions} from '@messenger/core/src/Redux/Channels/Actions';
import {selectVisibleChannelsLimit} from '@messenger/core/src/Redux/Channels/Selectors/selectVisibleChannelsLimit';
import {selectFilteredChannelsCount} from '@messenger/core/src/Redux/Channels/Selectors/selectFilteredChannelsCount';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {messagesClientToServerActions} from '@messenger/core/src/Redux/Messages/Actions';
import ChannelHelper from '@messenger/core/src/Redux/Channels/ChannelHelper';

export const processChannelsReceivedSaga = function* (
	action?: ReturnType<typeof channelsServerToClientActions.channelsReceived>,
) {
	try {
		const visibleChannelsLimit = yield* select(selectVisibleChannelsLimit);
		const filteredChannelsCount = yield* select(selectFilteredChannelsCount);

		if (visibleChannelsLimit > filteredChannelsCount) {
			yield* put(
				channelsClientOnlyActions.setVisibleChannelsLimit(
					Math.max(filteredChannelsCount, ServiceFactory.env.getInitialLimitOfVisibleChats()),
				),
			);
		} else if (visibleChannelsLimit !== ServiceFactory.env.getInitialLimitOfVisibleChannels()) {
			yield* put(
				channelsClientOnlyActions.setVisibleChannelsLimit(
					Math.min(filteredChannelsCount, visibleChannelsLimit + ServiceFactory.env.getVisibleChannelsLimitIncrement()),
				),
			);
		}

		if (!_.isUndefined(action)) {
			const tvChannelId = _.chain(action.meta.commands)
				.find(
					(command) => command.action === ACTION.CMDC_CHANNEL && ChannelHelper.isTVChannel(command.params.channelId),
				)
				.get('params.channelId')
				.value();

			if (tvChannelId) {
				yield* put(
					messagesClientToServerActions.requestHistory({
						channelId: tvChannelId,
						limit: 1,
					}),
				);
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processChannelsReceivedSaga'});
	}
};
