import _ from 'lodash';
import {call, put, select, take} from 'typed-redux-saga';

import {
	EnumMessageHistorySubject,
	messagesClientOnlyActions,
	messagesServerToClientActions,
} from '@messenger/core/src/Redux/Messages/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';
import {selectMessagesHistoryStatuses} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/selectMessagesHistoryStatuses';
import {selectChannelMessagesIds} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/ChannelMessages/selectChannelMessagesIds';

export const findMessageIndexInHistorySaga = function* ({
	payload: {channelId, messageId},
}: ReturnType<typeof messagesClientOnlyActions.navigateToMessage>) {
	let messageIndex = -1;

	if (!channelId) {
		return messageIndex;
	}

	try {
		yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
			text: ServiceFactory.i18n.t('messages:loading'),
			variant: EnumAbstractNotificationVariant.INFO,
			key: EnumSnackbarNotificationKeys.LOADING_MESSAGES,
		});

		let attemptsLeft = ServiceFactory.env.getMessageAdditionalLoadingAttempts();

		do {
			const {[channelId]: messagesHistoryStatuses} = yield* select(selectMessagesHistoryStatuses);

			if (messagesHistoryStatuses?.endReached) {
				break;
			}

			if (!messagesHistoryStatuses?.isLoading) {
				yield* put(
					messagesClientOnlyActions.requestMessagesHistory({
						subjectType: EnumMessageHistorySubject.CHANNEL,
						subjectId: channelId,
					}),
				);
			}

			yield* take(messagesServerToClientActions.messagesHistoryReceived);

			const messageIds = yield* select(selectChannelMessagesIds, {channelId});

			messageIndex = _.indexOf(messageIds, messageId);

			attemptsLeft--;
		} while (attemptsLeft > 0 && messageIndex === -1);

		yield* call(
			[ServiceFactory.notifications, ServiceFactory.notifications.close],
			EnumSnackbarNotificationKeys.LOADING_MESSAGES,
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'findMessageIndexInHistorySaga'});
	}

	return messageIndex;
};
