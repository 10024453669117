import {createSelector} from '@reduxjs/toolkit';
import _ from 'lodash';

import {_selectChatSummaryEntities} from '@messenger/core/src/Redux/ChatSummary/Selectors/chatsSummaryDefaultSelectors';
import {ChatSummaryVM} from '@messenger/core/src/Redux/ChatSummary/ChatSummaryVM';

export const selectChatSummaryEntitiesVms = createSelector(_selectChatSummaryEntities, (ChatSummaryEntities) =>
	_.mapValues(ChatSummaryEntities, (ChatSummaryEntity) => {
		return !_.isUndefined(ChatSummaryEntity) ? new ChatSummaryVM(ChatSummaryEntity) : undefined;
	}),
);
