import {ACTION, EnumMobileVideoCallState, VideoSdkConfig} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class VideoCallClientToServerActions extends ClientToServerActions<EnumStore.VIDEO_CALL> {
	readonly scope = EnumStore.VIDEO_CALL;

	initiateCall = this.createAction(ACTION.CMDP_STARTMOBILEVIDEOCALL, this.getPrepareAction<{channelId: string}>());

	updateCall = this.createAction(
		ACTION.CMDP_UPDATEMOBILEVIDEOCALL,
		({
			videoSdkConfig,
			deviceId,
			state,
		}: {
			videoSdkConfig: VideoSdkConfig;
			deviceId: string;
			state: EnumMobileVideoCallState;
		}) => ({
			payload: {videoSdkConfig: JSON.stringify(videoSdkConfig), deviceId, state},
		}),
	);
}

export const videoCallClientToServerActions = new VideoCallClientToServerActions();
