import {takeEvery} from 'typed-redux-saga';

import {queryServerToClientActions} from '@messenger/core/src/Redux/QueryUser/Actions/queryServerToClientActions';
import AbstractUiContainer from '@messenger/core/src/Services/AbstractUiContainer';
import {queryClientOnlyActions} from '@messenger/core/src/Redux/QueryUser/Actions/queryClientOnlyActions';

import {showNotificationWithTimerSaga} from 'src/Redux/QueryUser/Sagas/showNotificationWithTimerSaga';
import {removeNotificationWithTimerSaga} from 'src/Redux/QueryUser/Sagas/removeNotificationWithTimerSaga';
import {showWebNotificationWithActionsSaga} from 'src/Redux/QueryUser/Sagas/showWebNotificationWithActionsSaga';

export const removeOn = [
	queryServerToClientActions.queryAnswerResultReceived,
	queryServerToClientActions.queryAnswered,
	queryClientOnlyActions.abortQuery,
];

function* queryUserSagaWatcher(browser: AbstractUiContainer) {
	yield* takeEvery(queryServerToClientActions.queryReceived, showNotificationWithTimerSaga);
	yield* takeEvery(queryServerToClientActions.queryReceived.type, showWebNotificationWithActionsSaga, browser);
	yield* takeEvery(removeOn, removeNotificationWithTimerSaga);
}

export default queryUserSagaWatcher;
