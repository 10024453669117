import {takeEvery} from 'typed-redux-saga';

import {liveSessionsServerToClientActions} from '@messenger/core/src/Redux/LiveSessions/Actions/liveSessionsServerToClientActions';
import {liveSessionsClientOnlyActions} from '@messenger/core/src/Redux/LiveSessions/Actions/liveSessionsClientOnlyActions';
import {updateLiveSessionChatsSaga} from '@messenger/core/src/Redux/LiveSessions/Sagas/updateLiveSessionChatsSaga';
import {loadLiveSessionsNextPageSaga} from '@messenger/core/src/Redux/LiveSessions/Sagas/loadLiveSessionsNextPageSaga';
import {loadLiveSessionsByDateSaga} from '@messenger/core/src/Redux/LiveSessions/Sagas/loadLiveSessionsByDateSaga';

function* liveSessionsSagaWatcher() {
	yield* takeEvery(liveSessionsServerToClientActions.liveSessionsReceived, updateLiveSessionChatsSaga);
	yield* takeEvery(liveSessionsClientOnlyActions.loadNextPage, loadLiveSessionsNextPageSaga);
	yield* takeEvery(liveSessionsClientOnlyActions.loadByDate, loadLiveSessionsByDateSaga);
}

export default liveSessionsSagaWatcher;
