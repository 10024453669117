import {channelIdType, OnlineStateType} from 'cmd-control-client-lib';

import {createEntityAdapter} from '@messenger/core/src/Utils/Redux/createEntityAdapter';

export type ChannelOnlineStateType = OnlineStateType & channelIdType;

export const onlineStateAdapter = createEntityAdapter<ChannelOnlineStateType>({
	/** selectId - should be unique and always existing field for every entity **/
	selectId: (state) => state.channelId,
});
