import {put} from 'typed-redux-saga';
import {isMobile} from 'react-device-detect';

import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export function* processRemoveEmptyAudioAttachmentSaga() {
	try {
		if (isMobile && ServiceFactory.env.isAppMobileEnabled()) {
			yield* put(attachmentClientOnlyActions.detachCurrentGuestAttachment());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processRemoveEmptyAudioAttachmentSaga'});
	}
}
