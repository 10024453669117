import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {onlineStateClientToServerActions} from '@messenger/core/src/Redux/OnlineState/Actions';
import {selectIsLoggedIn} from '@messenger/core/src/Redux/Session/Selectors/selectIsLoggedIn';

export function* requestModelOnlineStateSaga() {
	try {
		const isLoggedIn = yield* select(selectIsLoggedIn);

		if (!isLoggedIn) {
			return;
		}

		yield* put(onlineStateClientToServerActions.requestModelOnlineState());
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'requestModelOnlineStateSaga'});
	}
}
