import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectIsClientLogoutModalActive} from '@messenger/core/src/Redux/Client/Selectors/selectIsClientLogoutModalActive';
import {selectIsWebRtcOutputStreamActive} from '@messenger/core/src/Redux/Stream/Selectors/selectIsWebRtcOutputStreamActive';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';

export function* processBeforeUnloadSaga(event: Event) {
	try {
		const isLogoutModalActive = yield* select(selectIsClientLogoutModalActive);
		const isWebRtcOutputStreamActive = yield* select(selectIsWebRtcOutputStreamActive);

		if (isLogoutModalActive || !isWebRtcOutputStreamActive) {
			return;
		}

		yield* put(clientClientOnlyActions.showLogoutWarning());
		event.preventDefault();
		event.stopPropagation();
		event.returnValue = true;
	} catch (e) {
		ServiceFactory.logService.error(e, {saga: 'processBeforeUnloadSaga'});
	}
}
