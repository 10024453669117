import {put, select} from 'typed-redux-saga';

import {sessionClientToServerActions} from '@messenger/core/src/Redux/Session/Actions';
import {selectPermanentData} from '@messenger/core/src/Redux/Session/Selectors/PermanentData/selectPermanentData';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TPermanentData} from '@messenger/core/src/Redux/Session/Model';

export const updatePermanentDataSaga = function* <T extends keyof TPermanentData>(key: T, value: TPermanentData[T]) {
	try {
		const permanentData = yield* select(selectPermanentData);

		yield* put(sessionClientToServerActions.updatePermanentData({...permanentData, [key]: value}));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'updatePermanentDataSaga'});
	}
};
