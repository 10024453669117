import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux/createSelector';
import {EnumPermanentSettings} from '@messenger/core/src/Redux/Session/Model';

import {selectPermanentData} from './selectPermanentData';

export const selectShownInfoWizards = createSelector([selectPermanentData], (permanentData) =>
	_.get(permanentData, EnumPermanentSettings.SHOWN_INFO_WIZARDS, []),
);
