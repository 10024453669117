import {ACTION, ChannelMediaLinkState, EnumMediaType, UploadedMediaDirection} from 'cmd-control-client-lib';
import _ from 'lodash';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {getMediaHistorySubjectId} from '@messenger/core/src/Redux/Media/getMediaHistorySubjectId';

export const MEDIA_HISTORY_SUBJECT_ID = 'mediaHistorySubjectId';

class MediaClientToServerActions extends ClientToServerActions<EnumStore.MEDIA> {
	readonly scope = EnumStore.MEDIA;

	requestMediaHistory = this.createAction(
		ACTION.CMDP_SGETUPLOADEDMEDIA2,
		({
			skip,
			limit,
			...filters
		}: TMediaHistoryFilters & {
			limit?: number;
			skip?: number;
		}) => ({
			payload: {
				[MEDIA_HISTORY_SUBJECT_ID]: getMediaHistorySubjectId(filters),
				direction: filters.direction || '',
				channelId: filters.channelId || '',
				mediaType: _.join(filters.mediaType, ','),
				linkState: _.join(filters.linkState, ','),
				limit: String(limit || 50),
				skip: String(skip || 0),
				sortBy: 'mediaCreated',
			},
		}),
	);
}

export const mediaClientToServerActions = new MediaClientToServerActions();

export type TMediaHistoryFilters = {
	channelId?: string;
	linkState?: ChannelMediaLinkState[];
	mediaType?: EnumMediaType[];
	direction?: UploadedMediaDirection;
};
