import axios, {AxiosResponse} from 'axios';
import {container, inject, singleton} from 'tsyringe';

import IDeviceApi from '@messenger/core/src/Services/Device/IDeviceApi';
import DevicesListResponse from '@messenger/core/src/Services/Device/DevicesListResponse';
import {IDeviceRawResponse} from '@messenger/core/src/Services/Device/IDeviceRawResponse';
import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import EnvInterfaceService from '@messenger/core/src/Services/EnvInterfaceService';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

import RequestFailedResponse from './RequestFailedResponse';
import EnumResponseCode from './EnumResponseCode';
import EnumCommands from './EnumCommands';

const intensityMap = {
	min: 0,
	max: 20,
};

@singleton()
class DeviceLocalApi implements IDeviceApi {
	constructor(@inject(DIToken.EnvInterfaceService) protected env: EnvInterfaceService) {}

	protected getRequestConfig() {
		return {
			headers: {
				accept: 'application/json',
			},
		};
	}

	/**
	 * @return {Promise<DevicesListResponse|RequestFailedResponse>}
	 */
	async requestToysList() {
		return (
			axios
				.get(this.env.getDeviceLANUrl() + '/' + EnumCommands.GET_TOYS, this.getRequestConfig())
				// convert to predictable objects
				.then((response: AxiosResponse<IDeviceRawResponse>) =>
					response.data.type === 'ok'
						? new DevicesListResponse(response.data.data)
						: new RequestFailedResponse(response.data.code),
				)
				// failed to get the response - set disabled
				.catch((error: Error) => {
					if (this.env.shouldLogDeviceApiErrors()) {
						ServiceFactory.logService.error(error);
					} else {
						ServiceFactory.logService.debug(
							'To suppress network error set REACT_APP_DEVICE_MOCK_DEVICES=true in .env.local',
						);
					}

					return new RequestFailedResponse(EnumResponseCode.NOT_STARTED);
				})
		);
	}

	async sendVibrate(toyId: string, intensity: number = intensityMap.min) {
		const speed = Math.ceil(intensityMap.max * intensity);
		const startUrl = this.env.getDeviceLANUrl() + '/' + EnumCommands.VIBRATE + `?v=${speed}&t=${toyId}`;

		return (
			axios
				.get(startUrl, this.getRequestConfig())
				// check response code
				.then((response: AxiosResponse) =>
					response.data.type === 'ok' ? {success: true} : new RequestFailedResponse(response.data.code),
				)
				// failed to get the response - set disabled
				.catch((e: Error) => {
					this.env.shouldLogDeviceApiErrors() && ServiceFactory.logService.error(e);

					return new RequestFailedResponse(EnumResponseCode.NOT_STARTED);
				})
		);
	}
}

container.register<DeviceLocalApi>(DIToken.ToyControlApi, {useToken: DeviceLocalApi});

export default DeviceLocalApi;
