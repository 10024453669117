import _ from 'lodash';
import {call, put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {DeviceTypes, TAvailableMediaDevices} from '@messenger/core/src/Redux/MediaDevice/mediaDeviceSlice';
import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';

export function* getAvailableMediaDevicesSaga() {
	try {
		const api = ServiceFactory.webRtcApi;
		const mediaDevices: MediaDeviceInfo[] = yield* call([api, api.externalDevices]);

		const tmp = _.groupBy(mediaDevices, 'kind');
		const devices: TAvailableMediaDevices = {
			[DeviceTypes.AUDIO_INPUT]: _.get(tmp, DeviceTypes.AUDIO_INPUT, []),
			[DeviceTypes.VIDEO_INPUT]: _.get(tmp, DeviceTypes.VIDEO_INPUT, []),
			[DeviceTypes.AUDIO_OUTPUT]: _.get(tmp, DeviceTypes.AUDIO_OUTPUT, []),
		};

		yield* put(mediaDeviceClientOnlyActions.setAvailableMediaDevices(devices));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'getAvailableMediaDevicesSaga'});
	}
}
