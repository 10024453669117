import {CMDC_GROUP} from 'cmd-control-client-lib';

import {ClientOnlyActions, TMultiEntityActionMeta} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum GroupsAction {
	RESET_STORE = 'RESET_STORE',
	UPSERT_MANY = 'UPSERT_MANY',
	RESET_ERROR = 'RESET_ERROR',
	EDIT_GROUP = 'EDIT_GROUP',
}

class GroupsClientOnlyActions extends ClientOnlyActions<EnumStore.GROUPS> {
	readonly scope = EnumStore.GROUPS;

	upsertMany = this.createAction(
		GroupsAction.UPSERT_MANY,
		this.getPrepareAction<CMDC_GROUP['params'][], TMultiEntityActionMeta<CMDC_GROUP>>(),
	);

	resetStore = this.createAction(GroupsAction.RESET_STORE);

	resetError = this.createAction(GroupsAction.RESET_ERROR);

	editGroup = this.createAction(GroupsAction.EDIT_GROUP, this.getPrepareAction<{groupId: string}>());
}

export const groupsClientOnlyActions = new GroupsClientOnlyActions();
