import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {channelGroupsPurchaseAbilityClientToServerActions} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Actions/channelGroupsPurchaseAbilityClientToServerActions';
import {selectTargetChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetChannelIds';
import {selectTargetCustomGroupIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetCustomGroupIds';
import {selectTargetSystemGroupRecord} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetSystemGroupRecord';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {selectIsAudioRecording} from '@messenger/core/src/Redux/Attachment/Selectors/selectIsAudioRecording';
import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions/attachmentClientOnlyActions';

export const processDeleteAttachmentSaga = function* () {
	try {
		const guestIdentity = yield* select(selectCurrentGuestIdentity);
		const isAudioRecording = yield* select(selectIsAudioRecording);

		if (isAudioRecording) {
			yield* put(attachmentClientOnlyActions.stopRecordingAudio({recordedSuccessfully: false}));
		}

		if (guestIdentity?.guestType === EnumGuestType.BULK) {
			const targetChannelIds = yield* select(selectTargetChannelIds);
			const targetCustomGroupIds = yield* select(selectTargetCustomGroupIds);
			const systemGroups = yield* select(selectTargetSystemGroupRecord);

			if (!_.isEmpty(targetCustomGroupIds) || !_.isEmpty(targetChannelIds) || !_.isEmpty(systemGroups)) {
				yield* put(
					channelGroupsPurchaseAbilityClientToServerActions.checkCanReceiveAttachment({
						groupIds: targetCustomGroupIds,
						channelIds: targetChannelIds,
						...systemGroups,
					}),
				);
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processDeleteAttachmentSaga'});
	}
};
