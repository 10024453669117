import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {TChannelGroupsPurchaseAbility} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/channelGroupsPurchaseAbilitySlice';

enum ChannelGroupsAction {
	CHANGE = 'CHANGE',
	RESET_STORE = 'RESET_STORE',
}

class ChannelGroupsPurchaseAbilityClientOnlyActions extends ClientOnlyActions<EnumStore.CHANNEL_GROUPS_PURCHASE_ABILITY> {
	readonly scope = EnumStore.CHANNEL_GROUPS_PURCHASE_ABILITY;

	change = this.createAction(
		ChannelGroupsAction.CHANGE,
		this.getPrepareAction<
			TChannelGroupsPurchaseAbility & {
				channelCount: number;
				canReceiveChannels: string[];
				canPurchaseChannels: string[];
			}
		>(),
	);

	resetStore = this.createAction(ChannelGroupsAction.RESET_STORE);
}

export const channelGroupsPurchaseAbilityClientOnlyActions = new ChannelGroupsPurchaseAbilityClientOnlyActions();
