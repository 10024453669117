import { EnumStreamStatusType } from "../@types";
import { ICOMMAND, ACTION, baseParamsType, IRESPONSE, RESULT, IKeyMaybeValue } from "../cmd-protocol";

export enum EnumPlayerEventType {
  Play = "Play",
  Error = "Error",
  Warn = "Warn",
  Info = "Info",
}

/** */
export class CMDP_SSTATUS implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SSTATUS;
  public params: baseParamsType & {
    type: EnumStreamStatusType;
    value: string;
  } & {
    /**comaseparated list for PROXY_STATE_INFO*/
    channelId?: string;
    /**comaseparated list for PROXY_STATE_INFO*/
    chatIDs?: string;
  };
}

export class CMDP_SSTATUS_RESPONSE extends CMDP_SSTATUS implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

export class CMDC_SSTATUS implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_SSTATUS;
  public params: { type: EnumStreamStatusType };
}
