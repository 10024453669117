import _ from 'lodash';

import {onlineStateAdapter} from '@messenger/core/src/Redux/OnlineState/entityAdapter';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import getOnlineStateVMCached from '@messenger/core/src/Redux/OnlineState/getOnlineStateVMCached';

export const {selectEntities} = onlineStateAdapter.getSelectors<IRootState>((state) => state[EnumStore.ONLINE_STATE]);

export const selectOnlineStateEntityVMs = createSelector([selectEntities], (entities) =>
	_.mapValues(entities, (entity) => getOnlineStateVMCached(entity)),
);
