import {put} from 'typed-redux-saga';

import {testVideoChatClientToServerActions} from '@messenger/core/src/Redux/TestVideoChat/Actions/testVideoChatClientToServerActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export const startVideoChatTestSaga = function* () {
	try {
		yield* put(testVideoChatClientToServerActions.startTestVideoChat());
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'getVideoChatTestValuesSaga'});
	}
};
