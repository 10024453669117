export enum ExitCode {
  CLIENTCEXIT = 100,
  CLIENTLOGOUT = 101,
  CLIENTDESTRUCT = 102,
  CLIENTEXITWIN = 103,
  CLIENTUPGRADE = 104,
  CLIENTERROR = 105,
  CLIENTCHARGE = 106,
  CLIENTDISCONNECT = 107,
  TIMEOUT_ON_CLIENT_SIDE = 108,
  CLIENT_ERROR = 109,
  HOSTKICK = 201,
  HOSTSTOPSEND = 202,
  HOSTLOGOUT = 203,
  HOSTRELOGIN = 204,
  HOSTSENDFAIL = 205,
  HOSTDEVICEFAIL = 206,
  HOSTSTILLPICTURE = 207,
  HOSTCPUOVERLOAD = 208,
  HOSTMICRONOPEGEL = 209,
  HOSTSTREAMERROR = 210,
  HOSTSESSIONEXPIRED = 211,
  SERVER_ADMIN_OFF = 212,
  HOSTSTREAMRESTART = 213,
  HOSTSTREAMEXPIRED = 214,
  HOSTWAITSTREAMTIMEOUT = 215,
  HOSTSTREAMSTOP = 216,
  HOSTSTOPUNKNOWN = 217,
  ADMINKICK = 301,
  AVS_ACK_OK = 302,
  AVS_ACK_DENIED = 303,
  AVS_DENIED_NOPICTURE = 304,
  AVS_CANCELED_BY_GUEST = 305,
  AVS_FAILED = 306,
  PREVIEW_LIMIT_HOST = 401,
  PREVIEW_LIMIT_HOUR = 402,
  PREVIEW_OFFLINE = 403,
  PREVIEW_SINGLE = 404,
  PREVIEW_FSK_DENIED = 405,
  PREVIEW_ACCESS_DENIED = 406,
  PREVIEW_DISABLED = 407,
  VIDEOTIMELIMIT = 501,
  TEXTTIMELIMIT = 502,
  FREETIMELIMIT = 503,
  FSKDENIED = 601,
  AVSCONVERSION = 602,
  PREPAID_TIMEOUT = 901,
  CLIENTNEWCHAT = 902,
  VOICECALLHANGUP = 903,
  VOICECALLNEWCHAT = 904,
  GWKILL = 905,
  HOSTSINGLE = 906,
  VIDEOFAIL_HOST_PUSH = 907,
  HOSTLOBBY = 908,
  VIDEOFAIL_GUEST_PULL = 910,
  GWKILLSTOPMODE = 911,
  GWKILLCONVERT = 912,
  KILL_UNPAYED = 913,
  DDOS_DETECTED = 914,
  CLIENTLOCK = 920,
  HOSTLOCK = 921,
  TIMEOUT_ON_SERVER_SIDE = 990,
  PARTNERKICK = 991,
  PHPGENERAL = 995,
  VIDEOTIMEOUT_HOST = 996,
  VIDEOTIMEOUT_GUEST = 997,
  Ununsed = 998,
  SERVERDOWN = 999,
}
