import _ from 'lodash';
import {CMDC_CONTACTNOTE} from 'cmd-control-client-lib';

export const entityProcessor = (contactNote: CMDC_CONTACTNOTE['params']): CMDC_CONTACTNOTE['params'] => ({
	...contactNote,
	// we need this check because fields that are empty do not come from server
	firstName: _.get(contactNote, 'firstName', ''),
	lastName: _.get(contactNote, 'lastName', ''),
	age: _.get(contactNote, 'age', ''),
	city: _.get(contactNote, 'city', ''),
	notes: _.get(contactNote, 'notes', ''),
});
