import {put, select} from 'typed-redux-saga';

import {notificationsClientOnlyActions} from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectNonErrorNotificationIds} from '@messenger/core/src/Redux/Notifications/Selectors/selectNonErrorNotificationIds';

export const closeNonErrorNotificationsSaga = function* () {
	try {
		const nonErrorNotificationIds = yield* select(selectNonErrorNotificationIds);

		yield* put(notificationsClientOnlyActions.dismissMany(nonErrorNotificationIds));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'closeNonErrorNotificationsSaga'});
	}
};
