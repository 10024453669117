import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {channelsMapEntityAdapter} from '@messenger/core/src/Redux/ChannelsMap/channelsMapEntityAdapter';

const {selectById, selectAll, selectEntities, selectTotal} = channelsMapEntityAdapter.getSelectors<IRootState>(
	(state) => state[EnumStore.CHANNELS_MAP],
);

export const selectChannelsMapById = selectById;
export const selectChannelsMaps = selectAll;
export const selectChannelsMapEntities = selectEntities;
export const selectChannelsMapCount = selectTotal;
