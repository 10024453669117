import _ from 'lodash';
import {call} from 'typed-redux-saga';
import {ResultCode} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TSessionCMDPUpdatedAction} from '@messenger/core/src/Redux/Session/Actions';

export const resetVControlLangSaga = function* (action: TSessionCMDPUpdatedAction) {
	try {
		if (action.meta.result && action.meta.result.code === ResultCode.OK) {
			const vControlApi = ServiceFactory.getVControlApi();

			if (!_.isUndefined(action.payload.language) && action.payload.language !== vControlApi.language) {
				yield* call([vControlApi, vControlApi.changeLanguage], action.payload.language);
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'resetVControlLangSaga'});
	}
};
