import _ from 'lodash';

import GroupVM from '@messenger/core/src/Redux/Groups/GroupVM';
import ChannelVM from '@messenger/core/src/Redux/Channels/ChannelVM';

export default class ChannelOrGroupVM {
	public readonly isGroup: boolean = false;
	public readonly isAutoGenerated: boolean = false;
	public readonly name: string = '';
	public readonly channelId?: string;
	public readonly groupId?: string;
	public readonly channelVM?: ChannelVM;
	public readonly groupVM?: GroupVM;
	public readonly groupChannels?: number = 0;

	constructor(channelVM?: ChannelVM, groupVM?: GroupVM) {
		if (groupVM) {
			this.groupVM = groupVM;
			this.groupId = groupVM.groupId;
			this.name = groupVM.groupName;
			this.groupChannels = _.parseInt(groupVM.groupChannels, 10) || 0;
			this.isGroup = true;
			this.isAutoGenerated = groupVM.isAutoGenerated;
		} else if (channelVM) {
			this.channelVM = channelVM;
			this.name = this.channelVM.username;
			this.channelId = this.channelVM.channelId;
		}
	}
}
