export enum EnumCodecContentType {
	OPUS = 'audio/mp4;codecs="opus"',
	VORBIS = 'audio/ogg;codecs="vorbis"',
	H264 = 'video/mp4',
	VP8 = 'video/webm;codecs="vp8"',
	VP9 = 'video/webm;codecs="vp9"',
	WEBM = 'video/webm',
	HLS = 'application/vnd.apple.mpegURL',
	H264_AVC = 'video/mp4; codecs="avc1.42E01E"',
	H264_MP4 = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',
	H265_HEC = 'video/mp4; codecs="hvc1.1.L0.0"',
	H265_HEV = 'video/mp4; codecs="hev1.1.L0.0"',
}
