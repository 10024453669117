import {ACTION, CMDP_SADDGROUP_RESPONSE, CMDP_SUPDATEGROUP_RESPONSE} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class GroupsServerToClientActions extends ServerToClientActions<EnumStore.GROUPS> {
	readonly scope = EnumStore.GROUPS;

	groupAdded = this.createAction(
		ACTION.CMDP_SADDGROUP,
		(payload: CMDP_SADDGROUP_RESPONSE['values'], meta: CMDP_SADDGROUP_RESPONSE, error?: string | boolean) => ({
			payload,
			meta,
			error,
		}),
	);

	groupUpdated = this.createAction(
		ACTION.CMDP_SUPDATEGROUP,
		this.getPrepareAction<CMDP_SUPDATEGROUP_RESPONSE['values'], CMDP_SUPDATEGROUP_RESPONSE>(),
	);
}

export const groupServerToClientActions = new GroupsServerToClientActions();
