import {createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

import {channelsMapClientOnlyActions} from './Actions/channelsMapClientOnlyActions';
import {channelsMapEntityAdapter} from './channelsMapEntityAdapter';
import {channelsMapInitialState} from './channelsMapInitialState';

export const channelsMapSlice = createSlice({
	name: EnumStore.CHANNELS_MAP,
	initialState: channelsMapInitialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(channelsMapClientOnlyActions.setCurrentRange, (state, action) => {
			state.previousRange = state.currentRange;
			state.currentRange = action.payload;
		});
		builder.addCase(channelsMapClientOnlyActions.setSelectedIndex, (state, action) => {
			state.selectedIndex = action.payload;
		});
		builder.addCase(channelsMapClientOnlyActions.setTotal, (state, {payload: {total}}) => {
			state.total = total;
		});
		builder.addCase(channelsMapClientOnlyActions.setChannelsMeta, (state, {payload: {channelsMeta}}) => {
			state.channelsMeta = channelsMeta;
		});
		builder.addCase(channelsMapClientOnlyActions.increaseLoadingCounter, (state) => {
			state.loadingCounter = state.loadingCounter + 1;
		});
		builder.addCase(channelsMapClientOnlyActions.decreaseLoadingCounter, (state) => {
			state.loadingCounter = state.loadingCounter > 0 ? state.loadingCounter - 1 : 0;
		});
		builder.addCase(channelsMapClientOnlyActions.startChannelsMapUpdate, (state, {payload: {updateUuid}}) => {
			state.updateUuids.push(updateUuid);
		});
		builder.addCase(channelsMapClientOnlyActions.endChannelsMapUpdate, (state, {payload: {updateUuid}}) => {
			state.updateUuids = _.without(state.updateUuids, updateUuid);
		});
		builder.addCase(channelsMapClientOnlyActions.clearChannelsMapUpdate, (state) => {
			state.updateUuids = [];
		});
		builder.addCase(channelsMapClientOnlyActions.addPendingChannelIds, (state, {payload: {channelIds}}) => {
			state.pendingChannelIds = _.uniq([...state.pendingChannelIds, ...channelIds]);
		});
		builder.addCase(channelsMapClientOnlyActions.resetPendingChannelIds, (state) => {
			state.pendingChannelIds = [];
		});

		builder.addCase(channelsMapClientOnlyActions.upsertMany, channelsMapEntityAdapter.upsertMany);
		builder.addCase(channelsMapClientOnlyActions.removeMany, channelsMapEntityAdapter.removeMany);
		builder.addCase(channelsMapClientOnlyActions.removeOne, channelsMapEntityAdapter.removeOne);
		builder.addCase(channelsMapClientOnlyActions.removeAll, channelsMapEntityAdapter.removeAll);
		builder.addCase(channelsMapClientOnlyActions.addMany, channelsMapEntityAdapter.addMany);
	},
});
