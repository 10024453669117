import {put} from 'typed-redux-saga';
import _ from 'lodash';

import {mediaLinksClientOnlyActions} from '@messenger/core/src/Redux/MediaLinks/Actions/mediaLinksClientOnlyActions';
import {TMediaLink} from '@messenger/core/src/Redux/MediaLinks/mediaLinkEntityAdapter';
import {mediaClientOnlyActions} from '@messenger/core/src/Redux/Media/Actions/mediaClientOnlyActions';

export const processMediaUpsertManySaga = function* ({payload}: ReturnType<typeof mediaClientOnlyActions.upsertMany>) {
	yield* put(
		mediaLinksClientOnlyActions.setMany(
			_.filter(
				payload,
				({channelId, mediaMd5}) => !_.isUndefined(channelId) && !_.isUndefined(mediaMd5),
			) as TMediaLink[],
		),
	);
};
