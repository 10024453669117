import {call} from 'typed-redux-saga';
import {EnumSetTp, SessionFlag} from 'cmd-control-client-lib';
import _ from 'lodash';

import {TSessionUpdatedAction} from '@messenger/core/src/Redux/Session/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

import {startTicketShowSaga} from 'src/Redux/TicketShow/Sagas/startTicketShowSaga';

export function* startTicketShowOnOpenLobbyChatSaga({payload}: TSessionUpdatedAction) {
	try {
		if (_.get(payload, 'setTp') === EnumSetTp.ONLINE && _.get(payload, 'flags').includes(SessionFlag.LOBBY_OPENED)) {
			yield* call(startTicketShowSaga);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'startTicketShowOnOpenLobbyChatSaga'});
	}
}
