import {call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {cleanUpStoresSaga} from '@messenger/core/src/Redux/Session/Sagas/cleanUpStoresSaga';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';

export const processLoggedOutSaga = function* () {
	try {
		yield* call(cleanUpStoresSaga);

		const {goHome} = yield* getNavigationContext();

		yield* call(goHome);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processLoggedOutSaga'});
	}
};
