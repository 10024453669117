import {select, put} from 'typed-redux-saga';
import _ from 'lodash';

import {channelsMapClientOnlyActions} from '@messenger/core/src/Redux/ChannelsMap/Actions/channelsMapClientOnlyActions';
import {selectChannelsMapEntities} from '@messenger/core/src/Redux/ChannelsMap/Selectors/channelsMapDefaultSelectors';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TChannelsRange} from '@messenger/core/src/Redux/ChannelsMap/types';

export const setCurrentRangeSaga = function* ({
	payload: {startIndex, endIndex},
}: ReturnType<typeof channelsMapClientOnlyActions.setCurrentRange>) {
	try {
		const channelsMap = yield* select(selectChannelsMapEntities);

		const ranges: TChannelsRange[] = [];
		let prevIndex: number = 0;

		for (let index = startIndex; index <= endIndex; index++) {
			if (_.isUndefined(channelsMap[index])) {
				const lastRange = _.last(ranges);

				if (lastRange && index - prevIndex === 1) {
					lastRange.endIndex = index;
				} else {
					ranges.push({startIndex: index, endIndex: index});
				}

				prevIndex = index;
			}
		}

		for (let index = 0; index < ranges.length; index++) {
			yield* put(channelsMapClientOnlyActions.requestChannelsMap(ranges[index]));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'setCurrentRangeSaga'});
	}
};
