import {put, select} from 'typed-redux-saga';
import _ from 'lodash';

import {channelsMapClientOnlyActions} from '@messenger/core/src/Redux/ChannelsMap/Actions/channelsMapClientOnlyActions';
import {selectChannelsMapEntities} from '@messenger/core/src/Redux/ChannelsMap/Selectors/channelsMapDefaultSelectors';
import {selectCurrentRange} from '@messenger/core/src/Redux/ChannelsMap/Selectors/selectCurrentRange';
import {TChannelsRange} from '@messenger/core/src/Redux/ChannelsMap/types';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectChannelsMapTotal} from '@messenger/core/src/Redux/ChannelsMap/Selectors/selectChannelsMapTotal';

export const afterChannelsMapSavedSaga = function* () {
	try {
		const currentRange = yield* select(selectCurrentRange);
		const total = yield* select(selectChannelsMapTotal);

		if (!currentRange || !total) {
			return;
		}

		const channelsMap = yield* select(selectChannelsMapEntities);

		const ranges: TChannelsRange[] = [];
		let prevIndex: number = 0;

		for (let index = currentRange.startIndex; index <= currentRange.endIndex; index++) {
			if (_.isUndefined(channelsMap[index]) && total > index) {
				const lastRange = _.last(ranges);

				if (lastRange && index - prevIndex === 1) {
					lastRange.endIndex = index;
				} else {
					ranges.push({startIndex: index, endIndex: index});
				}

				prevIndex = index;
			}
		}

		for (let index = 0; index < ranges.length; index++) {
			yield* put(channelsMapClientOnlyActions.requestChannelsMap(ranges[index]));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'fillCurrentRangeGapsSaga'});
	}
};
