import {takeEvery} from 'typed-redux-saga';

import {speedTestClientOnlyActions} from '@messenger/core/src/Redux/SpeedTest/Actions/speedTestClientOnlyActions';
import {startSpeedTestSaga} from '@messenger/core/src/Redux/SpeedTest/Sagas/startSpeedTestSaga';
import {sendSpeedTestReportSaga} from '@messenger/core/src/Redux/SpeedTest/Sagas/sendSpeedTestReportSaga';
import {speedTestServerToClientActions} from '@messenger/core/src/Redux/SpeedTest/Actions/speedTestServerToClientActions';

export function* speedTestWatcherSaga() {
	yield* takeEvery(speedTestServerToClientActions.startTestReceived, startSpeedTestSaga);
	yield* takeEvery(speedTestClientOnlyActions.markEnd, sendSpeedTestReportSaga);
}
