import {call, select, put} from 'typed-redux-saga';

import {selectIsWebRtcOutputStreamActive} from '@messenger/core/src/Redux/Stream/Selectors/selectIsWebRtcOutputStreamActive';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {streamPreviewClientOnlyActions} from '@messenger/core/src/Redux/StreamPreview/Actions/streamPreviewClientOnlyActions';
import EnumPreviewType from '@messenger/core/src/BusinessLogic/EnumPreviewType';
import {MAIN_PREVIEW_ID} from '@messenger/core/src/Services/JpegSocketPreviewApi/AbstractJpegSocketPreviewApi';
import {streamPreviewServerToClientActions} from '@messenger/core/src/Redux/StreamPreview/Actions/streamPreviewServerToClientActions';

export function* openMainPreviewSocketSaga(
	action: ReturnType<typeof streamPreviewServerToClientActions.previewStarted>,
) {
	try {
		const hasWebRtcOutputStreamActive: boolean = yield* select(selectIsWebRtcOutputStreamActive);
		const {mediaJpegWsUrl} = action.payload;

		if (mediaJpegWsUrl && !hasWebRtcOutputStreamActive) {
			const api = ServiceFactory.jpegSocketPreviewApi;

			yield* put(streamPreviewClientOnlyActions.setPreviewType(EnumPreviewType.JPEG));
			yield* call(
				[api, api.createPreview],
				MAIN_PREVIEW_ID,
				mediaJpegWsUrl + '&format=1&width=320&height=240&sizemode=stretch',
			);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'openMainPreviewSocketSaga', action});
	}
}
