import _ from 'lodash';
import {SystemMessageKey} from 'cmd-control-client-lib';

import {TSswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import {stripTags} from '@messenger/core/src/Utils/Strings/stripTags';
import ChannelInfoVM from '@messenger/core/src/Redux/ChannelInfos/ChannelInfoVM';
import {MessageViewModel} from '@messenger/core/src/Redux/Messages/MessageViewModel';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import IMessagePart from '@messenger/core/src/BusinessLogic/IMessagePart';
import EnumMessageTypePart from '@messenger/core/src/BusinessLogic/EnumMessagePartType';
import {TWebNotification} from '@messenger/core/src/Redux/WebNotification/webNotificationSlice';

export const DEFAULT_NOTIFICATION_TAG = 'messenger';

export class WebNotificationHelper {
	static getNotificationIdByMessageId = (messageId: string): string => {
		return `msg-${messageId}`;
	};

	static getRandomNotificationId = (): string => _.uniqueId('web-notify');

	static getNotificationText = (message: MessageViewModel, channelInfoVM?: ChannelInfoVM) => {
		let text = '';

		const i18next = ServiceFactory.i18n;

		/**
		 * @note bind the context of translate function
		 * @link https://github.com/i18next/i18next/issues/1287
		 */
		const structure = message.getMessageStructure(i18next.language, i18next.t.bind(i18next));

		_.forEach(structure, (part: IMessagePart) => {
			switch (part.type) {
				case EnumMessageTypePart.GIFT:
					text += '🎁';
					break;

				case EnumMessageTypePart.FAVORITE:
					text += '❤️';
					break;

				case EnumMessageTypePart.SMILE:
					text += part.label;
					break;

				default:
					text += part.value;
					break;
			}
		});

		switch (true) {
			case message.hasImage:
				text += '🖼';
				break;
			case message.hasAudio:
				text += '📻';
				break;
		}

		if (
			!_.isUndefined(channelInfoVM) &&
			message.messageKey !== SystemMessageKey.h_chat_video_start &&
			message.messageKey !== SystemMessageKey.h_chat_cam2cam_start &&
			message.messageKey !== SystemMessageKey.h_chat_cam2cam_stop &&
			message.messageKey !== SystemMessageKey.h_chat_stop
		) {
			text = channelInfoVM.username + ': ' + text;
		}

		return stripTags(`${text}`);
	};

	static getNotificationOptions = (
		message: TSswMessageType,
		activeChatIds: string[] = [],
		channelInfoVM?: ChannelInfoVM,
	) => {
		const options: TWebNotification['options'] = {
			tag: DEFAULT_NOTIFICATION_TAG,
		};

		if (!_.isUndefined(message.chatID) && activeChatIds.includes(message.chatID)) {
			options.tag = message.chatID;
		} else if (!_.isUndefined(message.channelId)) {
			options.tag = message.channelId;
		}

		if (!_.isUndefined(channelInfoVM)) {
			options.image = channelInfoVM.avatar;
		}

		return options;
	};
}
