import {Action} from 'redux';
import {eventChannel} from 'redux-saga';

import {TKeyboardShortcut} from '@messenger/core/src/Redux/Session/Model';
import {shortcutToStringTransform} from '@messenger/core/src/Utils/shortcutToStringTransform';

import {ShortcutsService} from 'src/Services/ShortcutsService';

/**
 * @link https://www.npmjs.com/package/shortcuts
 */
export const createShortcutsChannelSaga = function (shortcuts: ShortcutsService, list: TKeyboardShortcut[]) {
	return eventChannel<Action>((emitHandler: Function) => {
		shortcuts.setEmit(emitHandler);
		shortcuts.addMany(
			list.map((shortcut) => ({
				label: shortcutToStringTransform(shortcut.keyStatus),
				text: shortcut.text,
				autoSend: shortcut.autoSend,
			})),
		);

		// unsubscribe
		return () => shortcuts.reset();
	});
};
