import React, {Ref} from 'react';
import {useTranslation} from 'react-i18next';

import PropertyHandler from '@messenger/core/src/Types/PropertyHandler';
import DialogPopUp from '@messenger/uikit/src/Dialog/DialogPopUp/DialogPopUp';
import EnumChatPageTestIds from '@messenger/uikit/src/TestIds/EnumChatPageTestIds';

const MediaPurchasedContent = React.forwardRef((props: TMediaPurchasedContentProps, ref: Ref<Element>) => {
	const {t} = useTranslation();

	return (
		<DialogPopUp
			dataTestId={`${EnumChatPageTestIds.PURCHAISED_MEDIA_SNACKBAR}`}
			btn0DataTestId={EnumChatPageTestIds.PURCHAISED_MEDIA_SNACKBAR_CLOSE_BTN}
			btn1DataTestId={EnumChatPageTestIds.PURCHAISED_MEDIA_SNACKBAR_SHOW_BTN}
			ref={ref}
			body={props.message}
			onClose={props.onClose}
			btn0Text={t('common:close')}
			onBtn0Click={props.onClose}
			onBtn1Click={props.onReply}
			btn1Text={t('notification:show-btn')}
			timer={10000}
		/>
	);
});

type TMediaPurchasedContentProps = {
	onReply: PropertyHandler;
	onClose: PropertyHandler;
	message: string;
};

MediaPurchasedContent.displayName = 'MediaPurchasedContent';
export default MediaPurchasedContent;
