import {
	ACTION,
	CMDP_SDELETETICKETSHOW_RESPONSE,
	CMDP_SUPSERTTICKETSHOW_RESPONSE,
	IRESPONSE,
} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class TicketShowServerToClientActions extends ServerToClientActions<EnumStore.TICKET_SHOW> {
	readonly scope = EnumStore.TICKET_SHOW;

	ticketShowsReceived = this.createAction(
		ACTION.CMDP_SGETTICKETSHOWS,
		this.getPrepareAction<IRESPONSE['values'], IRESPONSE>(),
	);

	ticketShowEdited = this.createAction(
		ACTION.CMDP_SUPSERTTICKETSHOW,
		(
			payload: CMDP_SUPSERTTICKETSHOW_RESPONSE['values'],
			meta: CMDP_SUPSERTTICKETSHOW_RESPONSE,
			error?: string | boolean,
		) => ({
			payload,
			meta,
			error,
		}),
	);

	ticketShowDeleted = this.createAction(
		ACTION.CMDP_SDELETETICKETSHOW,
		this.getPrepareAction<CMDP_SDELETETICKETSHOW_RESPONSE['values'], CMDP_SDELETETICKETSHOW_RESPONSE, false>(),
	);
}

export const ticketShowServerToClientActions = new TicketShowServerToClientActions();
