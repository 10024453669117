import {ACTION} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class OnlineStateClientToServerActions extends ClientToServerActions<EnumStore.ONLINE_STATE> {
	readonly scope = EnumStore.ONLINE_STATE;

	requestOnlineState = this.createAction(ACTION.CMDP_GETONLINESTATES, (channelIds: string[]) => ({
		payload: {
			channelId: channelIds.join(','),
		},
	}));

	requestModelOnlineState = this.createAction(ACTION.CMDP_GETONLINESTATE);
}

export const onlineStateClientToServerActions = new OnlineStateClientToServerActions();
