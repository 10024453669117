import AbstractJpegSocketPreviewApi from '@messenger/core/src/Services/JpegSocketPreviewApi/AbstractJpegSocketPreviewApi';

abstract class AbstractJpegSocketPerChatPreviewApi {
	abstract createPreview(chatId: string, socketUrl: string): void;

	abstract getPreview(chatId: string): AbstractJpegSocketPreviewApi;

	abstract removePreview(chatId: string): void;
}

export default AbstractJpegSocketPerChatPreviewApi;
