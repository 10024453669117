import {put, select} from 'typed-redux-saga';
import moment from 'moment';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {ticketShowClientOnlyActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowClientOnlyActions';
import {selectAllTicketShowVms} from '@messenger/core/src/Redux/TicketShow/Selectors/selectAllTicketShowVms';

export const startTicketShowSaga = function* () {
	try {
		const ticketShowVMs = yield* select(selectAllTicketShowVms);
		const ticketShow = _.find(
			ticketShowVMs,
			(ticketShowVM) =>
				!_.isUndefined(ticketShowVM) &&
				moment(ticketShowVM.showEnd).isAfter() &&
				moment(ticketShowVM.showStart).subtract(2, 'm').isBefore(),
		);

		if (ticketShow) {
			yield* put(ticketShowClientOnlyActions.setSoonStartTicketShowId({ticketId: undefined}));
			yield* put(ticketShowClientOnlyActions.setCurrentTicketShowId({ticketId: ticketShow.showId}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'startTicketShowSaga'});
	}
};
