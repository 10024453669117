import _ from 'lodash';

import {MessageViewModel} from '@messenger/core/src/Redux/Messages/MessageViewModel';

export const sliceMessageHistoryByMessageId = (messages: MessageViewModel[], messageId?: string) =>
	messageId
		? _.slice(
				messages,
				_.findIndex(messages, {
					messageId: messageId,
				}),
		  )
		: messages;
