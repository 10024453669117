import {PayloadAction} from '@reduxjs/toolkit';
import {put} from 'typed-redux-saga';

import EnumIntroductionStep from '@messenger/core/src/Redux/Introduction/EnumIntroductionStep';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {introductionClientOnlyActions} from '@messenger/core/src/Redux/Introduction/Action/IntroductionClientOnlyActions';

export function* beforeStepSaga(action: PayloadAction<EnumIntroductionStep>) {
	try {
		if (action.payload === EnumIntroductionStep.ICONS_MEANING) {
			yield* put(introductionClientOnlyActions.showIconsMeaningModal());
		} else {
			yield* put(introductionClientOnlyActions.hideIconsMeaningModal());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'beforeStepSaga'});
	}
}
