import {ACTION, CMDP_GETLIVEHISTORY, EnumChannelFilterValues} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class LiveSessionsClientToServerActions extends ClientToServerActions<EnumStore.LIVE_SESSIONS> {
	readonly scope = EnumStore.LIVE_SESSIONS;

	getLiveSessions = this.createAction(ACTION.CMDP_GETLIVEHISTORY, (payload: CMDP_GETLIVEHISTORY['params']) => ({
		payload: {
			...payload,
			filterEmpty: EnumChannelFilterValues.EXCLUDE,
		},
	}));
}

export const liveSessionsClientToServerActions = new LiveSessionsClientToServerActions();
