import {select, put} from 'typed-redux-saga';

import {selectCurrentGuestAttachmentId} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestAttachmentId';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions';

export const detachCurrentGuestAttachmentSaga = function* () {
	try {
		const attachmentId = yield* select(selectCurrentGuestAttachmentId);

		yield* put(attachmentClientOnlyActions.detach({attachmentId}));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'detachCurrentGuestAttachmentSaga'});
	}
};
