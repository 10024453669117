import {ACTION, CMDP_SSETCONTACTNOTE} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class ContactNoteClientToServerActions extends ClientToServerActions<EnumStore.CONTACT_NOTE> {
	readonly scope = EnumStore.CONTACT_NOTE;

	saveContactNote = this.createAction(
		ACTION.CMDP_SSETCONTACTNOTE,
		this.getPrepareAction<CMDP_SSETCONTACTNOTE['params']>(),
	);

	requestContactNote = this.createAction(ACTION.CMDP_SGETCONTACTNOTE, (payload: string) => ({
		payload: {
			channelId: payload,
		},
	}));
}

export const contactNoteClientToServerActions = new ContactNoteClientToServerActions();
