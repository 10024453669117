import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

import {featureShowcaseClientOnlyActions} from './Actions/featureShowcaseClientOnlyActions';
import {TInfoWizard} from './TInfoWizard';

export type TFeatureShowcaseState = {
	type: TInfoWizard | null;
};

export const defaultState: TFeatureShowcaseState = {
	type: null,
};

export const featureShowcaseSlice = createSlice<
	TFeatureShowcaseState,
	SliceCaseReducers<TFeatureShowcaseState>,
	EnumStore
>({
	name: EnumStore.FEATURE_SHOWCASE,
	initialState: defaultState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(featureShowcaseClientOnlyActions.start, (state, action) => {
			state.type = action.payload.type;
		});
		builder.addCase(featureShowcaseClientOnlyActions.stop, (state) => {
			state.type = null;
		});
	},
});
