import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {
	getMessageIdForSelector,
	selectMessageVmById,
} from '@messenger/core/src/Redux/Messages/Selectors/messagesDefaultSelectors';
import {getTemporaryMessageId} from '@messenger/core/src/Redux/Messages/entityAdapter';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export const removeOrModifyTemporaryMessageSaga = function* (messageKey: string, channelIdsForDelete: string[]) {
	try {
		const tempMessageId = getTemporaryMessageId(messageKey);
		const tempMessageVM = yield* select(selectMessageVmById, getMessageIdForSelector(tempMessageId));

		if (_.isUndefined(tempMessageVM)) {
			return;
		}

		const channelIds = _.filter(tempMessageVM.channelIds, (item) => !_.includes(channelIdsForDelete, item));

		if (!_.isEmpty(channelIds)) {
			yield* put(messagesClientOnlyActions.updateMessageChannels({messageId: tempMessageId, channelIds}));

			return;
		}

		yield* put(messagesClientOnlyActions.removeMessageById(tempMessageId));
		yield* put(messagesClientOnlyActions.removeSentMessageKey(messageKey));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'removeOrModifyTemporaryMessageSaga'});
	}
};
