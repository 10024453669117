import {SupportedLanguage} from 'cmd-control-client-lib';
import {
	NewableModule,
	Callback,
	ExistsFunction,
	FormatFunction,
	i18n,
	InitOptions,
	Module,
	Modules,
	Newable,
	ResourceStore,
	Services,
	TFunction,
} from 'i18next';
import {ReportNamespaces} from 'react-i18next';

abstract class AbstractI18n implements i18n {
	abstract store: ResourceStore;
	abstract reportNamespaces: ReportNamespaces;

	abstract t: TFunction;

	abstract init(): Promise<TFunction>;

	abstract loadResources(callback?: (err: any) => void): void;

	abstract use<T extends Module>(module: T | NewableModule<T> | Newable<T>): this;

	abstract modules: Modules;

	abstract services: Services;

	abstract exists: ExistsFunction;

	abstract getDataByLanguage(lng: string): {translation: {[key: string]: string}};

	abstract getFixedT(...args: any[]): TFunction;

	abstract changeLanguage(lng: string, callback?: Callback): Promise<TFunction>;

	abstract language: SupportedLanguage;

	abstract languages: SupportedLanguage[];

	abstract loadNamespaces(ns: string | string[], callback?: Callback): Promise<void>;

	abstract loadLanguages(lngs: string | string[], callback?: Callback): Promise<void>;

	abstract reloadResources(lngs?: string | string[], ns?: string | string[], callback?: () => void): Promise<void>;
	abstract reloadResources(lngs: null, ns: string | string[], callback?: () => void): Promise<void>;

	abstract setDefaultNamespace(ns: string): void;

	abstract dir(lng?: string): 'ltr' | 'rtl';

	abstract format: FormatFunction;

	abstract createInstance(options?: InitOptions, callback?: Callback): i18n;

	abstract cloneInstance(options?: InitOptions, callback?: Callback): i18n;

	abstract on(event: 'initialized', callback: (options: InitOptions) => void): void;

	abstract on(event: 'loaded', callback: (loaded: boolean) => void): void;

	abstract on(event: 'failedLoading', callback: (lng: string, ns: string, msg: string) => void): void;

	abstract on(
		event: 'missingKey',
		callback: (lngs: string[], namespace: string, key: string, res: string) => void,
	): void;

	abstract on(event: 'added' | 'removed', callback: (lng: string, ns: string) => void): void;

	abstract on(event: 'languageChanged', callback: (lng: string) => void): void;

	abstract on(event: string, listener: (...args: any[]) => void): void;

	abstract off(event: string, listener: (...args: any[]) => void): void;

	abstract getResource(
		lng: string,
		ns: string,
		key: string,
		options?: Pick<InitOptions, 'keySeparator' | 'ignoreJSONStructure'>,
	): any;

	abstract addResource(
		lng: string,
		ns: string,
		key: string,
		value: string,
		options?: {keySeparator?: string; silent?: boolean},
	): i18n;

	abstract addResources(lng: string, ns: string, resources: any): i18n;

	abstract addResourceBundle(lng: string, ns: string, resources: any, deep?: boolean, overwrite?: boolean): i18n;

	abstract hasResourceBundle(lng: string, ns: string): boolean;

	abstract getResourceBundle(lng: string, ns: string): any;

	abstract removeResourceBundle(lng: string, ns: string): i18n;

	abstract options: InitOptions;

	abstract isInitialized: boolean;

	abstract emit(eventName: string): void;

	abstract resolvedLanguage: string;

	abstract hasLoadedNamespace(ns: string, options?: Pick<InitOptions, 'fallbackLng'>): boolean;
}

export default AbstractI18n;
