import ILocalFile from '@messenger/core/src/Redux/Media/ILocalFile';
import {TDimensionObject} from '@messenger/core/src/Redux/Attachment/Sagas/processSelectAttachmentSaga';

abstract class AbstractFileProcessingService {
	abstract isImageConversionRequired(file: File | ILocalFile): boolean;

	abstract getFileSize(file: File | ILocalFile): Promise<number | undefined>;

	abstract getImageDimension(file: File | ILocalFile): Promise<TDimensionObject | undefined>;

	abstract getMd5(file: File | ILocalFile): Promise<string | undefined>;

	abstract createFilePath(file: File, setFilePath: (url: string) => void): void;
}

export default AbstractFileProcessingService;
