import {call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {sipClientOnlyActions} from '@messenger/core/src/Redux/Sip/Actions/sipClientOnlyActions';

export const acceptCallSaga = function* (_action: ReturnType<typeof sipClientOnlyActions.accept>) {
	try {
		yield* call([ServiceFactory.sip, ServiceFactory.sip.accept]);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'acceptCallSaga'});
	}
};
