import { ACTION } from "../command/action";
import { baseParamsType } from "../command/baseparams";
import { ICOMMAND, IKeyMaybeValue, IRESPONSE, RESULT } from "../command/icommand";

/**
 * Create new user account
 */
export class CMDP_SIGNUP implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SIGNUP;
  public params: baseParamsType & {
    login: string;
    password: string;
    ualias: string;
    language: string;
  };
}

/**
 * reponse
 */
export class CMDP_SIGNUP_RESPONSE extends CMDP_SIGNUP implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

/**
 * query password reset
 */
export class CMDP_RESETPASSWORD implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_RESETPASSWORD;
  public params: baseParamsType & {
    login: string;
    language: string;
  };
}

/**
 * reponse for CMDP_RESETPASSWORD
 */
export class CMDP_RESETPASSWORD_RESPONSE extends CMDP_RESETPASSWORD implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

/**
 * delete account
 */
export class CMDP_SDELETE_USER implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SDELETE_USER;
  public params: baseParamsType;
}

/**
 * reponse for CMDP_SDELETE_USER
 */
export class CMDP_SDELETE_USER_RESPONSE extends CMDP_SDELETE_USER implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}
