import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum ChannelMediaSummaryAction {
	RESET_STORE = 'RESET_STORE',
}

class ChannelMediaSummaryClientOnlyAction extends ClientOnlyActions<EnumStore.CHANNEL_MEDIA_SUMMARY> {
	readonly scope = EnumStore.CHANNEL_MEDIA_SUMMARY;

	resetStore = this.createAction(ChannelMediaSummaryAction.RESET_STORE);
}

export const channelMediaSummaryClientOnlyAction = new ChannelMediaSummaryClientOnlyAction();
