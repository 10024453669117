import {call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export const releaseMediaDevicePermissionsSaga = function* () {
	try {
		const api = ServiceFactory.webRtcApi;

		yield* call([api, api.releasePermissions]);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'releaseMediaDevicePermissionsSaga'});
	}
};
