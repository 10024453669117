import { ACTION, baseParamsType, ICOMMAND, IKeyMaybeValue, IRESPONSE, RESULT } from "../cmd-protocol";
import { channelIdType } from "./channel";
import { MediaMd5, MediaOffer } from "./message";

/**
 * Frontent command for offer media.
 */
export class CMDP_SOPENMEDIAOFFER implements ICOMMAND {
  public action = ACTION.CMDP_SOPENMEDIAOFFER;
  public params: baseParamsType & channelIdType & MediaMd5 & MediaOffer & { text?: string };
}

/**
 * Response command for CMDP_SOPENMEDIAOFFER.
 */
export class CMDP_SOPENMEDIAOFFER_RESPONSE extends CMDP_SOPENMEDIAOFFER implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

/**
 * Frontend command for close of offer.
 */
export class CMDP_SCLOSEMEDIAOFFER implements ICOMMAND {
  public action = ACTION.CMDP_SCLOSEMEDIAOFFER;
  public params: baseParamsType &
    channelIdType & {
      messageId?: string;
    };
}

/**
 * Response command for CMDP_SCLOSEMEDIAOFFER.
 */
export class CMDP_SCLOSEMEDIAOFFER_RESPONSE extends CMDP_SCLOSEMEDIAOFFER implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}
