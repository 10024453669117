import {put, call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {removeStoredCredentialsSaga} from '@messenger/core/src/Redux/Session/Sagas/removeStoredCredentialsSaga';
import {loginFormClientOnlyActions} from '@messenger/core/src/Redux/LoginForm/Actions/loginFormClientOnlyActions';

export const preventNextScreenAutoLoginSaga = function* () {
	try {
		yield* call(removeStoredCredentialsSaga);
		yield* put(loginFormClientOnlyActions.disableAutoLogin());
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'preventNextScreenAutoLogin'});
	}
};
