import _ from 'lodash';
import {Moment} from 'moment';

import allChatsFilter from '@messenger/core/src/BusinessLogic/MessagesDisplayFilters/AllChatsFilter';
import {
	selectMessageVms,
	selectSent2AllMessages,
} from '@messenger/core/src/Redux/Messages/Selectors/messagesDefaultSelectors';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';
import {selectMessagesHistoryStatuses} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/selectMessagesHistoryStatuses';
import {sliceMessageHistoryByMessageId} from '@messenger/core/src/Utils/Messages/sliceMessageHistoryByMessageId';

export const selectLiveChatMessages = createSelector(
	[
		selectMessageVms,
		selectSent2AllMessages,
		(state: IRootState, params: {timeFrame?: {start?: Moment; end?: Moment}; liveSessionId?: string}) => params,
		selectMessagesHistoryStatuses,
	],
	(messages, sent2AllMessagesByMessageKey, {timeFrame, liveSessionId}, messagesHistoryStatuses) => {
		const liveChatId = liveSessionId || ALL_CHATS;

		if (!messagesHistoryStatuses[liveChatId]?.isReady) {
			return [];
		}

		const filteredMessages = _.filter(messages, (messageVM) =>
			allChatsFilter(messageVM, sent2AllMessagesByMessageKey, timeFrame),
		);

		return timeFrame && liveChatId === ALL_CHATS
			? filteredMessages
			: sliceMessageHistoryByMessageId(
					filteredMessages,
					messagesHistoryStatuses[liveChatId]?.searchAfter?.searchAfterId,
			  );
	},
);
