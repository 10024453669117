import _ from 'lodash';
import {call} from 'typed-redux-saga';
import {SystemMessageKey} from 'cmd-control-client-lib';
import {PayloadAction} from '@reduxjs/toolkit';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import {TSswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';

export const showSoftChatWarningSaga = function* (action: PayloadAction<TSswMessageType>) {
	try {
		if (
			SystemMessageKey.h_session_mixed_profile_on === action.payload.messageKey &&
			!_.isUndefined(action.payload.text)
		) {
			yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
				text: action.payload.text,
				variant: EnumAbstractNotificationVariant.WARNING,
				key: EnumSnackbarNotificationKeys.SOFT_CHAT_IS_ON,
				duration: ServiceFactory.env.getSoftChatWarningTimeout(),
			});
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'showSoftChatWarningSaga'});
	}
};
