import {takeEvery} from 'typed-redux-saga';

import AbstractUiContainer from '@messenger/core/src/Services/AbstractUiContainer';

import {windowEventActionCreator} from './windowEventActionCreator';
import {processOnlineStatusChangeSaga} from './Sagas/processOnlineStatusChangeSaga';
import {processBeforeUnloadSaga} from './Sagas/processBeforeUnloadSaga';
import {processUnloadSaga} from './Sagas/processUnloadSaga';

export default function* windowEventsWatcher(browser: AbstractUiContainer) {
	yield* takeEvery(windowEventActionCreator('online', browser), processOnlineStatusChangeSaga);
	yield* takeEvery(windowEventActionCreator('offline', browser), processOnlineStatusChangeSaga);
	yield* takeEvery(windowEventActionCreator('beforeunload', browser), processBeforeUnloadSaga);
	yield* takeEvery(windowEventActionCreator('unload', browser), processUnloadSaga);
}
