import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import TReactionItem from '@messenger/core/src/Types/TReactionItem';

import {reactionsClientOnlyActions} from './Actions/reactionsClientOnlyActions';

export type TReactionsState = {
	reactionList: TReactionItem[];
};

export const defaultState: TReactionsState = {
	reactionList: [],
};

export const reactionsSlice = createSlice<TReactionsState, SliceCaseReducers<TReactionsState>, EnumStore>({
	name: EnumStore.REACTIONS,
	initialState: defaultState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(reactionsClientOnlyActions.setReactions, (state, action) => {
			state.reactionList = action.payload;
		});
	},
});
