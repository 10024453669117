import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions/attachmentClientOnlyActions';
import {selectCurrentGuestAttachmentId} from '@messenger/core/src/Redux/Attachment/Selectors/selectCurrentGuestAttachmentId';

export const clearAttachmentSaga = function* () {
	try {
		//@NOTE Here we can put some logic to execute before attachment is deleted
		const attachmentId = yield* select(selectCurrentGuestAttachmentId);

		yield* put(attachmentClientOnlyActions.detach({attachmentId}));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'clearAttachmentSaga'});
	}
};
