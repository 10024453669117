const SOUND_TRACKS = {
	text: {
		ogg: require('../Assets/Sounds/text.ogg'),
		mp3: require('../Assets/Sounds/text.mp3'),
	},
	text2video: {
		ogg: require('../Assets/Sounds/text2video.ogg'),
		mp3: require('../Assets/Sounds/text2video.mp3'),
	},
	user_entered: {
		ogg: require('../Assets/Sounds/user_entered.ogg'),
		mp3: require('../Assets/Sounds/user_entered.mp3'),
	},
	'VISIT-X_Mann_low_03': {
		ogg: require('../Assets/Sounds/VISIT-X_Mann_low_03.ogg'),
		mp3: require('../Assets/Sounds/VISIT-X_Mann_low_03.mp3'),
	},
	user_left: {
		ogg: require('../Assets/Sounds/user_left.ogg'),
		mp3: require('../Assets/Sounds/user_left.mp3'),
	},
	whoosh: {
		ogg: require('../Assets/Sounds/whoosh.ogg'),
		mp3: require('../Assets/Sounds/whoosh.mp3'),
	},
};

export const getSound = (type: string) => {
	if (type in SOUND_TRACKS) {
		return SOUND_TRACKS[type as keyof typeof SOUND_TRACKS];
	}

	throw new Error(`Unsupported sound: "${type}"`);
};
