import cuid from 'cuid';
import {Map} from 'immutable';

export class ObjectStoreService {
	private static map: Map<string, any> = Map({});

	static set = (data: object, key?: string) => {
		const dataKey = key ?? cuid();

		this.map = this.map.set(dataKey, data);

		return dataKey;
	};
	static get = <T extends object>(key: string): T | undefined => this.map.get(key);
	static has = (key: string) => this.map.has(key);
	static delete = (key: string) => (this.map = this.map.delete(key));
	static pull = <T extends object>(key: string): T | undefined => {
		const value = this.map.get(key);

		if (this.map.has(key)) {
			this.map = this.map.delete(key);
		}

		return value;
	};
}
