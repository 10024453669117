export const MAIN_PREVIEW_ID = '__MAIN';

export enum ENUM_WSS_JPEG_PREVIEW_ACTION {
	START = 'CMDS_STARTVIDEOPULL',
}

export const startJpegPreviewSocketCommand = (fps = 15, base64 = true) => ({
	action: ENUM_WSS_JPEG_PREVIEW_ACTION.START,
	params: {
		fps,
		base64,
	},
});

abstract class AbstractJpegSocketPreviewApi {
	abstract setSocketUrl(url: string): void;

	abstract stopSocket(): void;

	abstract addSubscription(sub: HTMLImageElement): void;

	abstract removeSubscription(sub: HTMLImageElement): void;

	abstract notifyAll(imageBytes: string): void;
}

export default AbstractJpegSocketPreviewApi;
