import {ACTION} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class StreamPreviewClientToServerActions extends ClientToServerActions<EnumStore.STREAM_PREVIEW> {
	readonly scope = EnumStore.STREAM_PREVIEW;

	requestPreview = this.createAction(ACTION.CMDP_START_STREAM_PREVIEW);
}

export const streamPreviewClientToServerActions = new StreamPreviewClientToServerActions();
