import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {TWebNotification} from '@messenger/core/src/Redux/WebNotification/webNotificationSlice';

enum EnumWebNotificationActions {
	REMOVE = 'REMOVE',
	SHOW = 'SHOW',
}

class WebNotificationClientOnlyActions extends ClientOnlyActions<EnumStore.WEB_NOTIFICATION> {
	readonly scope = EnumStore.WEB_NOTIFICATION;

	remove = this.createAction(EnumWebNotificationActions.REMOVE);
	show = this.createAction(EnumWebNotificationActions.SHOW, this.getPrepareAction<TWebNotification>());
}

export const webNotificationClientOnlyActions = new WebNotificationClientOnlyActions();
