import {createSelector} from '@reduxjs/toolkit';
import _ from 'lodash';

import {selectTargetChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetChannelIds';
import {_selectAllMediaPricesChannelIds} from '@messenger/core/src/Redux/MediaPrice/Selectors/mediaPricesDefaultSelectors';
import {selectErroredMediaPricesMap} from '@messenger/core/src/Redux/MediaPrice/Selectors/selectErroredMediaPricesMap';
import {selectLoadingMediaPricesMap} from '@messenger/core/src/Redux/MediaPrice/Selectors/selectLoadingMediaPricesMap';

export const selectTargetChannelIdsWithNoPrices = createSelector(
	[selectLoadingMediaPricesMap, selectErroredMediaPricesMap, _selectAllMediaPricesChannelIds, selectTargetChannelIds],
	(isLoadingMap, isErroredMap, allIds, targetChannelIds) =>
		_.reject(
			targetChannelIds,
			(channelId) => isLoadingMap[channelId] || Boolean(isErroredMap[channelId]) || _.includes(allIds, channelId),
		),
);
