import md5 from 'md5';
import {
	ACTION,
	EnumBooleanDigitized,
	EnumBooleanStringified,
	EnumProductId,
	EnumService0900Operation,
	SupportedLanguage,
} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import {TPermanentData} from '@messenger/core/src/Redux/Session/Model';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class SessionClientToServerActions extends ClientToServerActions<EnumStore.SESSION> {
	readonly scope = EnumStore.SESSION;

	logIn = this.createAction(
		ACTION.CMDP_SLOGIN,
		({
			credentials,
			language,
			autoLogin,
		}: {
			credentials: {login: string; password: string} | {foreignSID: string};
			language?: SupportedLanguage;
			autoLogin?: boolean;
		}) => {
			return {
				payload: {
					...credentials,
					language,
					/**
					 * @note to prevent multiple messages for chat/channel
					 * @note this is a hot-fix until @vl fixes the server
					 */
					msn: EnumBooleanDigitized.TRUE,
					autoLogin: autoLogin ? EnumBooleanDigitized.TRUE : EnumBooleanDigitized.FALSE,
				},
			};
		},
	);

	signUp = this.createAction(
		ACTION.CMDP_SIGNUP,
		(login: string, password: string, passwordHashed: boolean, ualias: string, language: SupportedLanguage) => ({
			payload: {
				login,
				password: passwordHashed ? password : md5(password),
				ualias,
				language,
			},
		}),
	);

	resetPassword = this.createAction(ACTION.CMDP_RESETPASSWORD, (login: string, language: SupportedLanguage) => ({
		payload: {
			login,
			language,
		},
	}));

	logOut = this.createAction(ACTION.CMDP_LOGOUT, ({code}: {code: number} = {code: 203}) => ({
		payload: {
			code,
		},
	}));

	logOutAllDevices = this.createAction(ACTION.CMDP_SLOGOUT, () => ({
		payload: {
			allDevices: EnumBooleanStringified.TRUE,
		},
	}));

	setPhoneService = this.createAction(ACTION.CMDP_SERVICE0900, (isEnabled: boolean) => ({
		payload: {
			productId: EnumProductId.Default,
			operation: isEnabled ? EnumService0900Operation.ENABLE : EnumService0900Operation.DISABLE,
		},
	}));

	updatePermanentData = this.createAction(ACTION.CMDP_SUPDATE, (permanentData: TPermanentData) => ({
		payload: {
			permanentData: JSON.stringify(permanentData),
		},
	}));

	updateLanguage = this.createAction(ACTION.CMDP_SUPDATE, (language: SupportedLanguage) => ({
		payload: {
			language,
		},
	}));

	setAppearOffline = this.createAction(ACTION.CMDP_SUPDATE, (state: boolean) => ({
		payload: {
			babeStation24State: state ? EnumBooleanStringified.TRUE : EnumBooleanStringified.FALSE,
		},
	}));

	setLiveChatPreview = this.createAction(ACTION.CMDP_SUPDATE, (state: boolean) => ({
		payload: {
			livePreviewState: state ? EnumBooleanStringified.TRUE : EnumBooleanStringified.FALSE,
		},
	}));

	setVideoCallAvailability = this.createAction(ACTION.CMDP_SUPDATE, (state: boolean) => {
		return {
			payload: {
				mobileVideoCallState: state ? EnumBooleanStringified.TRUE : EnumBooleanStringified.FALSE,
			},
		};
	});

	enableLobbyMode = this.createAction(ACTION.CMDP_OPEN_LOBBY);
	disableLobbyMode = this.createAction(ACTION.CMDP_CLOSE_LOBBY);

	deleteAccount = this.createAction(ACTION.CMDP_SDELETE_USER);

	setFsk16 = this.createAction(ACTION.CMDP_FSK16, (fsk16: boolean) => ({payload: {fsk16}}));
}

export const sessionClientToServerActions = new SessionClientToServerActions();
