import 'reflect-metadata';
import _ from 'lodash';
import adapter from 'webrtc-adapter';
import {container, singleton} from 'tsyringe';
import type {WebRtcOutputConnection} from '@campoint/vxwebrtc';

import {
	EnumMediaStreamTrackKind,
	IStreamConnectionProps,
} from '@messenger/core/src/Services/WebRtc/CommonConnectionTypes';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import WebRtcConfigVM from '@messenger/core/src/Redux/Stream/WebRtcConfigVM';
import AbstractWebRtcApi from '@messenger/core/src/Services/AbstractWebRtcApi';
import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import {dynamicImport} from '@messenger/core/src/Utils/dynamicImport';

const WEBRTC_ADAPTER_NOT_SUPPORTED_BROWSER = 'Not a supported browser.';

@singleton()
class WebRtcApi extends AbstractWebRtcApi {
	private _stream?: MediaStream;

	private _outputConnection?: WebRtcOutputConnection;

	checkBrowserSupport(): boolean {
		return adapter.browserDetails.browser !== WEBRTC_ADAPTER_NOT_SUPPORTED_BROWSER;
	}

	requestPermissions(constraints: MediaStreamConstraints): Promise<void> {
		if (this._outputConnection) {
			return this.changeVideoTrack(constraints);
		}

		this.releasePermissions();

		return navigator.mediaDevices.getUserMedia(constraints).then((mediaStream: MediaStream) => {
			this._stream = mediaStream;
		});
	}

	changeVideoTrack({video}: MediaStreamConstraints): Promise<void> {
		if (!this.mediaStream) {
			ServiceFactory.logService.error('WebRtcApi.changeVideoTrack MediaStream is not available');
		}

		this.mediaStream?.getTracks().forEach((track) => {
			if (track.kind === EnumMediaStreamTrackKind.VIDEO) {
				track.stop();
				this.mediaStream?.removeTrack(track);
			}
		});

		return navigator.mediaDevices.getUserMedia({video}).then((mediaStream) => {
			const [videoTrack] = mediaStream.getVideoTracks();

			this._outputConnection?.changeTrack(videoTrack);
			this.mediaStream?.addTrack(videoTrack);
		});
	}

	get mediaStream(): MediaStream | undefined {
		return this._stream;
	}

	async externalDevices(): Promise<MediaDeviceInfo[]> {
		return await navigator.mediaDevices.enumerateDevices();
	}

	releasePermissions(): void {
		if (this._outputConnection) {
			ServiceFactory.logService.error('WebRtcApi.releasePermissions this will break WebRtc stream');
		}

		if (this._stream) {
			this._stream.getTracks().forEach((track) => track.stop());
		}

		this._stream = undefined;
	}

	get isVideoCameraAvailable(): boolean {
		return this._stream ? this._stream.getVideoTracks().length > 0 : false;
	}

	get isMicrophoneAvailable(): boolean {
		return this._stream ? this._stream.getAudioTracks().length > 0 : false;
	}

	get audioTrack(): MediaStreamTrack | undefined {
		if (!this._stream) {
			return undefined;
		}

		return _.head(this._stream.getAudioTracks());
	}

	get videoTrack(): MediaStreamTrack | undefined {
		if (!this._stream) {
			return undefined;
		}

		return _.head(this._stream.getVideoTracks());
	}

	get videoTrackSettings(): MediaTrackSettings | undefined {
		const videoTrack = this.videoTrack;

		if (!videoTrack) {
			return undefined;
		}

		return videoTrack.getSettings();
	}

	get audioTrackSettings(): MediaTrackSettings | undefined {
		const audioTrack = this.audioTrack;

		if (!audioTrack) {
			return undefined;
		}

		return audioTrack.getSettings();
	}

	muteAudioTrack() {
		const track = this.audioTrack;

		if (track) {
			track.enabled = false;
		}
	}

	unMuteAudioTrack() {
		const track = this.audioTrack;

		if (track) {
			track.enabled = true;
		}
	}

	isAudioTrackEnabled(): boolean {
		return this.isMicrophoneAvailable ? !!this.audioTrack?.enabled : false;
	}

	startOutputStream(props: IStreamConnectionProps): void {
		const stream = this._stream;

		if (
			!stream ||
			this._outputConnection ||
			(!props.videoCodec && adapter.browserDetails.browser !== 'safari') ||
			!props.webRTCConfig.applicationName
		) {
			const state = [
				stream ? '' : 'MediaStream is not available',
				this._outputConnection ? 'there is another OutputConnection' : '',
				props.videoCodec ? '' : 'This browser does not support video codecs for webrtc',
				props.webRTCConfig.applicationName ? '' : 'ApplicationName is required to start stream',
			];

			throw new Error(`LogicException ${state.join(', ')}`);
		}

		dynamicImport(() => import('@campoint/vxwebrtc')).then(({WebRtcOutputConnection}) => {
			this._outputConnection = new WebRtcOutputConnection({
				wsUrl: props.webRTCConfig.url,
				localStream: stream,
				streamInfo: {
					applicationName: props.webRTCConfig.applicationName,
					streamName: props.webRTCConfig.streamName,
					sessionId: '',
				},
				mediaInfo: {
					audioBitrate: props.webRTCConfig.audio.bitRate,
					audioCodec: props.audioCodec || '',
					videoBitrate: props.webRTCConfig.video.bitRate,
					videoCodec: props.videoCodec || '',
					videoFrameRate: props.webRTCConfig.video.frameRate,
					h264CodecOptions: props.webRTCConfig.h264CodecOptions,
					vp9CodecOptions: props.webRTCConfig.vp9CodecOptions,
				},
				onStop: props.onStop,
				onError: props.onError,
			});

			this._outputConnection.start();
		});
	}

	async getStreamStats(): Promise<RTCStatsReport | undefined> {
		if (!this._outputConnection) {
			return undefined;
		}

		return this._outputConnection.stats();
	}

	stopOutputStream(): void {
		if (this._outputConnection) {
			this._outputConnection.stop();
			this._outputConnection = undefined;
		}

		this.releasePermissions();
	}

	devicesToConstraints(
		webRtcConfigVM: WebRtcConfigVM,
		isMicrophoneRequired = true,
		videoSource?: string,
		audioSource?: string,
	): MediaStreamConstraints {
		let audioConstraint: MediaTrackConstraints | boolean = false;
		const videoConstraint: MediaTrackConstraints = this.filterSupportedConstraints({
			...webRtcConfigVM.video,
			deviceId: videoSource ? videoSource : undefined,
		});

		if (isMicrophoneRequired) {
			audioConstraint = this.filterSupportedConstraints({
				...webRtcConfigVM.audio,
				deviceId: audioSource ? audioSource : undefined,
			});
		}

		return {
			video: videoConstraint,
			audio: audioConstraint,
		};
	}

	private filterSupportedConstraints(obj: MediaTrackConstraintSet) {
		const result: Record<string, MediaTrackConstraintSet[keyof MediaTrackConstraintSet]> = {};
		const supportedConstraints = ServiceFactory.uiContainer.getSupportedConstraints();

		_.forEach(obj, (v, k) => {
			if (k !== 'aspectRatio' && _.get(supportedConstraints, [k], false)) {
				result[k] = v;
			}
		});

		return result;
	}
}

container.register<AbstractWebRtcApi>(DIToken.WebRtcApi, {useToken: WebRtcApi});

export default WebRtcApi;
