import {put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {ticketShowClientOnlyActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowClientOnlyActions';

export const disableTicketShowSaga = function* () {
	try {
		yield* put(ticketShowClientOnlyActions.setCurrentTicketShowId({ticketId: undefined}));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'disableTicketShowSaga'});
	}
};
