import _ from 'lodash';
import {PayloadAction} from '@reduxjs/toolkit';
import {call, put, select, take} from 'typed-redux-saga';
import {EnumChannelFlags} from 'cmd-control-client-lib';

import {
	channelsClientToServerActions,
	TChannelFlagsUpdatedMeta,
	TUpdateChannelFlagsPayload,
} from '@messenger/core/src/Redux/Channels/Actions';
import {selectPinnedChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/selectPinnedChannelIds';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {notificationsClientOnlyActions} from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';

export const reverseChannelFlagsSaga = function* (action: PayloadAction<TUpdateChannelFlagsPayload>) {
	try {
		const meta: TChannelFlagsUpdatedMeta = {withoutNotification: true};
		const {payload} = action;

		if (!payload[EnumChannelFlags.PINNED]) {
			yield* put(channelsClientToServerActions.setChannelFlags(payload, meta));

			return;
		}

		const maxPinnedChannelsNumber = yield* call([ServiceFactory.env, ServiceFactory.env.maxPinnedChannelLimit]);
		const currentlyPinnedChannels = yield* select(selectPinnedChannelIds);
		const pinnedAndSelectedChannelsNumber = _.size(_.union(currentlyPinnedChannels, payload.channelIds));
		const notificationKey = payload.channelIds.join(',');

		if (pinnedAndSelectedChannelsNumber > maxPinnedChannelsNumber) {
			yield* call([ServiceFactory.notifications, ServiceFactory.notifications.close], notificationKey);

			// wait until fully removed
			yield* take(notificationsClientOnlyActions.removeSnackbar.type);

			yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
				text: ServiceFactory.i18n.t('user:list.pin-limit-reached', {pin: maxPinnedChannelsNumber}),
				variant: EnumAbstractNotificationVariant.ERROR,
				duration: yield* call([ServiceFactory.env, ServiceFactory.env.getSnackTimeout]),
				key: notificationKey,
			});
		} else {
			yield* put(channelsClientToServerActions.setChannelFlags(payload, meta));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'setChannelFlagsSaga'});
	}
};
