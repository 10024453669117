import {select, put, call} from 'typed-redux-saga';

import {selectRunningChatVmByChannelId} from '@messenger/core/src/Redux/Chats/Selectors/selectRunningChatVmByChannelId';
import {selectAreAllChannelsShown} from '@messenger/core/src/Redux/Channels/Selectors/Filter/selectAreAllChannelsShown';
import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions';
import {filterValueFromBoolean} from '@messenger/core/src/BusinessLogic/Channels/filterValueFromBoolean';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {routingClientOnlyActions} from '@messenger/core/src/Redux/Routing/Actions/routingClientOnlyActions';
import {
	getChannelIdForSelector,
	selectChannelVMById,
} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';

export const navigateToChannelSaga = function* ({
	payload: {channelId},
}: ReturnType<typeof routingClientOnlyActions.navigateToChannel>) {
	try {
		if (!channelId) {
			return;
		}

		const chatVm = yield* select(selectRunningChatVmByChannelId, {channelId});
		const {goToChatMessages, goToChannelMessages} = yield* getNavigationContext();

		if (chatVm) {
			yield* call(goToChatMessages, chatVm.chatId);
		} else {
			const isAll = yield* select(selectAreAllChannelsShown, undefined);

			if (!isAll) {
				yield* put(channelsClientOnlyActions.resetFilter());
			}

			const channelVm = yield* select(selectChannelVMById, getChannelIdForSelector(channelId));

			if (channelVm?.isArchived) {
				yield* put(
					channelsClientOnlyActions.setFilters({
						filterArchived: filterValueFromBoolean(true),
					}),
				);
			}

			yield* call(goToChannelMessages, channelId);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'selectChatIfExistByChannelIdSaga'});
	}
};
