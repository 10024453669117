import {ChannelType} from 'cmd-control-client-lib';

import {cachedViewModelFactory} from '@messenger/core/src/Utils/cachedViewModelFactory';
import ChannelVM from '@messenger/core/src/Redux/Channels/ChannelVM';
import {ContactNoteViewModel} from '@messenger/core/src/Redux/ContactNote/ViewModel';
import ChannelInfoVM from '@messenger/core/src/Redux/ChannelInfos/ChannelInfoVM';
import OnlineStateVM from '@messenger/core/src/Redux/OnlineState/OnlineStateVM';

export const getChannelVmCached = cachedViewModelFactory<
	ChannelType,
	ChannelVM,
	[
		contactNoteVM: ContactNoteViewModel | undefined,
		channelInfoVM: ChannelInfoVM | undefined,
		teamChannelId: string | undefined,
		onlineState: OnlineStateVM | undefined,
	]
>(ChannelVM);
