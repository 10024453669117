import {createSlice} from '@reduxjs/toolkit';
import {CMDP_START_STREAM_PREVIEW_RESPONSE, JSONString} from 'cmd-control-client-lib';

import {TNanoPlayerConfigEntry} from '@messenger/core/src/Types/ExtendedBrowserWindow';
import EnumPreviewType from '@messenger/core/src/BusinessLogic/EnumPreviewType';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

import {streamPreviewServerToClientActions} from './Actions/streamPreviewServerToClientActions';
import {streamPreviewClientOnlyActions} from './Actions/streamPreviewClientOnlyActions';

const initialStreamPreviewState: {
	streamPreviewType: EnumPreviewType;
	sources?: CMDP_START_STREAM_PREVIEW_RESPONSE['values'] & {
		mediaH5Live?: JSONString<TNanoPlayerConfigEntry['h5live']>;
		mediaHlsUrl?: string;
		mediaJpegUrl?: string;
	};
} = {
	streamPreviewType: EnumPreviewType.LOCAL,
	sources: undefined,
};

export const streamPreviewSlice = createSlice({
	name: EnumStore.STREAM_PREVIEW,
	initialState: initialStreamPreviewState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(streamPreviewClientOnlyActions.resetStore, () => initialStreamPreviewState);
		builder.addCase(streamPreviewClientOnlyActions.resetSources, (state) => {
			state.sources = undefined;
		});
		builder.addCase(streamPreviewClientOnlyActions.setPreviewType, (state, {payload}) => {
			state.streamPreviewType = payload;
		});
		builder.addCase(streamPreviewServerToClientActions.previewStarted, (state, {payload}) => {
			state.sources = payload;
		});
	},
});
