import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';

import {selectCurrentGuestIsAdmin} from './selectCurrentGuestIsAdmin';
import {selectCurrentGuestIsTvAdmin} from './selectCurrentGuestIsTvAdmin';
import {selectCurrentGuestIsTeamChannel} from './selectCurrentGuestIsTeamChannel';
import {selectCurrentGuestIsLobbyChat} from './selectCurrentGuestIsLobbyChat';
import {selectCurrentGuestIsVoyeur} from './selectCurrentGuestIsVoyeur';

export const selectCurrentGuestHasUserInfo = createSelector(
	[
		selectCurrentGuestIdentity,
		selectCurrentGuestIsAdmin,
		selectCurrentGuestIsTvAdmin,
		selectCurrentGuestIsTeamChannel,
		selectCurrentGuestIsLobbyChat,
		selectCurrentGuestIsVoyeur,
	],
	(guestIdentity, isAdmin, isTvAdmin, isTeamChannel, isLobbyChat, isVoyeur) =>
		Boolean(guestIdentity?.channelId) && !isAdmin && !isTvAdmin && !isTeamChannel && !isLobbyChat && !isVoyeur,
);
