import _ from 'lodash';
import {
	ACTION,
	CMDC_CMSG,
	CMDP_MSG_RESPONSE,
	CMDP_SGETMESSAGEHISTORY_RESPONSE,
	CMDP_SUPLOADMEDIA_RESPONSE,
	EnumMediaState,
	EnumMessageType,
	ResultCode,
} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import uploadFieldsThatAreNotIMessage from '@messenger/core/src/BusinessLogic/uploadFieldsThatAreNotIMessage';
import {getTemporaryMessageId} from '@messenger/core/src/Redux/Messages/entityAdapter';
import {getDefaultMessageTime} from '@messenger/core/src/Utils/Messages/getDefaultMessageTime';

const skipPayloadFields = ['_t2', '_ds', '_uniq', '_iid'];

/**
 * @WARNING
 * After adding new Server Action add it to "serverToClientActionsMap" object
 * @see serverToClientActionsMap
 */
class MessagesServerToClientActions extends ServerToClientActions<EnumStore.MESSAGES> {
	readonly scope = EnumStore.MESSAGES;

	messageSendResult = this.createAction(
		ACTION.CMDP_MSG,
		(payload: CMDP_MSG_RESPONSE['values'], meta: CMDP_MSG_RESPONSE, error?: boolean | string) => {
			return {
				payload: _.omit(
					{
						...payload,
						...meta.params,
					},
					skipPayloadFields,
				),
				meta,
				error,
			};
		},
	);

	messageReceived = this.createAction(ACTION.CMDC_CMSG, (payload: CMDC_CMSG['params'], meta?: CMDC_CMSG) => {
		let clientData = _.attempt(JSON.parse, payload.clientData);

		// messages coming from the server are SENT, not SENDING
		if (!_.isError(clientData)) {
			clientData.isSending = false;
		} else {
			clientData = {};
		}

		return {
			payload: {
				...payload,
				time: getDefaultMessageTime(payload),
				clientData: JSON.stringify(clientData),
				timeSeen: payload.msgType === EnumMessageType.CHAT ? undefined : payload.timeSeen,
				reaction: payload.reaction,
			},
			meta,
		};
	});

	messageWithMediaReceived = this.createAction(
		ACTION.CMDP_SUPLOADMEDIA,
		(payload: CMDP_SUPLOADMEDIA_RESPONSE['values'], meta: CMDP_SUPLOADMEDIA_RESPONSE) => {
			const messageKey = _.get(meta, 'params.messageKey');

			const updatedPayload = {
				id: getTemporaryMessageId(messageKey),
				changes: {
					..._.pickBy(
						{
							..._.omit(payload, ['_ds', '_t2']),
							..._.omit(meta.params, uploadFieldsThatAreNotIMessage),
						},
						_.identity,
					),
					messageKey: messageKey,
					messageId: meta.params.isBulk ? getTemporaryMessageId(messageKey) : meta.values.messageId,
					mediaState: EnumMediaState.TRANSCODING,
				},
			};

			//doesn't changes channels (for temp bulk message that was recived)
			if (meta.params.isBulk) {
				updatedPayload.changes = _.omit(updatedPayload.changes, 'channelId');
			}

			return {
				payload: {...updatedPayload},
				meta,
				error: meta.result.code !== ResultCode.OK ? meta.result.reason : false,
			};
		},
	);

	messagesHistoryReceived = this.createAction(
		ACTION.CMDP_SGETMESSAGEHISTORY,
		this.getPrepareAction<CMDP_SGETMESSAGEHISTORY_RESPONSE['values'], CMDP_SGETMESSAGEHISTORY_RESPONSE, false>(),
	);
}

export const messagesServerToClientActions = new MessagesServerToClientActions();
