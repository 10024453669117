import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {IconType} from '@messenger/uikit/src/Icon';
import Button from '@messenger/uikit/src/Button/Button';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

import VxLive from 'src/Assets/Images/vx_live_logo4.jpg';
import PageTitle from 'src/Routes/PageContainer/PageTitle/PageTitle';
import {Routes} from 'src/Routes/Routes';

import FormattedErrorMessage from './FormattedErrorMessage';

const GlobalError = ({message}: TGlobalErrorProps) => {
	const {t} = useTranslation();
	const isBrowserSupported = ServiceFactory.uiContainer.isBrowserSupported();

	return (
		<Dialog aria-labelledby="global-error" className="global-error" maxWidth="xs" open fullWidth>
			<PageTitle />
			<DialogTitle id="page-not-found-title" style={{textAlign: 'center'}}>
				<img src={VxLive} alt="VX Live" />
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{isBrowserSupported ? (
						<>
							<FormattedErrorMessage message={message} />
							{t('common:global-error')}
						</>
					) : (
						t('common:browser-not-supported.title')
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" startIconType={IconType.refresh} onClick={() => window.location.reload()}>
					{t('common:global-error-refresh')}
				</Button>
				<Button color="secondary" startIconType={IconType.home} href={Routes.home}>
					{t('common:global-error-home')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

type TGlobalErrorProps = {
	message?: string;
};

export default GlobalError;
