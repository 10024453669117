import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {mediaEntityAdapter, TMedia} from '@messenger/core/src/Redux/Media/mediaEntityAdapter';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import {getMediaVmCached} from '@messenger/core/src/Redux/Media/getMediaVmCached';
import {MediaVm} from '@messenger/core/src/Redux/Media/MediaVm';

const {
	selectEntities: _selectEntities,
	selectAll: _selectAll,
	selectById: _selectById,
} = mediaEntityAdapter.getSelectors<IRootState>((state) => state[EnumStore.MEDIA]);

export const selectMediaVmById = createSelector([_selectById], getMediaVmCached);

export const selectMediaVms = createSelector([_selectAll], (entities) =>
	_.chain(entities)
		.map((entity) => getMediaVmCached(entity))
		.compact()
		.value(),
);

export const selectMediaEntityVms = createSelector([_selectEntities], (entities) =>
	_.reduce(
		entities,
		(result: Record<string, MediaVm>, entity: TMedia | undefined) => {
			const mediaVm = getMediaVmCached(entity);

			if (mediaVm) {
				result[mediaVm.mediaMd5] = mediaVm;
			}

			return result;
		},
		{},
	),
);
