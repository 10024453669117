import 'reflect-metadata';
import _ from 'lodash';
import {Shortcuts} from 'shortcuts';
import {container, singleton} from 'tsyringe';

import {
	clientClientOnlyActions,
	TShortcutAppliedPayload,
} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

@singleton()
export class ShortcutsService {
	private readonly shortcuts: Shortcuts;
	private emit: Function | undefined;
	private stopped = false;

	constructor() {
		this.shortcuts = new Shortcuts({
			shouldHandleEvent: ({type, repeat}) => !repeat && type === 'keydown',
		});

		this.handle = this.handle.bind(this);
	}

	protected handle(event: TShortcutAppliedPayload) {
		// stop processing if stopped
		if (this.stopped) {
			return false;
		}

		if (!_.isFunction(this.emit)) {
			ServiceFactory.logService.error(`No emitter found, skipping event ${event.label}`);

			return false;
		}

		this.emit(clientClientOnlyActions.applyShortcut(event));

		return true;
	}

	setEmit(value: Function) {
		this.emit = value;
	}

	addMany(shortcuts: TShortcutAppliedPayload[]) {
		this.shortcuts.add(
			shortcuts.map((shortcut) => ({
				shortcut: shortcut.label,
				handler: () => this.handle(shortcut),
			})),
		);
	}

	stop() {
		this.stopped = true;
	}

	start() {
		this.stopped = false;
	}

	isStopped() {
		return this.stopped;
	}

	reset() {
		this.shortcuts.reset();
	}
}

container.register<ShortcutsService>(DIToken.shortcutsService, {useToken: ShortcutsService});
