import _ from 'lodash';

import {selectCurrencies} from '@messenger/core/src/Redux/Currency/Selectors/selectCurrencies';
import MediaPricesViewModel from '@messenger/core/src/Redux/MediaPrice/MediaPricesViewModel';
import {createSelector} from '@messenger/core/src/Utils/Redux/createSelector';
import {selectPricesForTargetChannelIds} from '@messenger/core/src/Redux/MediaPrice/Selectors/selectPricesForTargetChannelIds';

export const selectMediaPricesVmForTargetChannelIds = createSelector(
	[selectPricesForTargetChannelIds, selectCurrencies],
	(prices, currencies): MediaPricesViewModel | undefined => {
		return _.isUndefined(prices) ? undefined : new MediaPricesViewModel(prices, currencies);
	},
);
