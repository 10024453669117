import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import TReactionItem from '@messenger/core/src/Types/TReactionItem';

enum EnumActions {
	SET_REACTIONS = 'SET_REACTIONS',
}

class ReactionsClientOnlyActions extends ClientOnlyActions {
	scope = EnumStore.REACTIONS;
	setReactions = this.createAction(EnumActions.SET_REACTIONS, this.getPrepareAction<TReactionItem[]>());
}

export const reactionsClientOnlyActions = new ReactionsClientOnlyActions();
