import {createSlice} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {sedCardPictureAdapter} from '@messenger/core/src/Redux/SedCardPicture/sedCardPictureAdapter';
import {sedCardPictureClientToServerActions} from '@messenger/core/src/Redux/SedCardPicture/Actions/sedCardPictureClientToServerActions';
import {sedCardPictureServerToClientActions} from '@messenger/core/src/Redux/SedCardPicture/Actions/sedCardPictureServerToClientActions';
import {sedCardPictureClientOnlyActions} from '@messenger/core/src/Redux/SedCardPicture/Actions/sedCardPictureClientOnlyActions';

export const initialState = sedCardPictureAdapter.getInitialState({
	isLoading: false,
});

export const sedCardPictureSlice = createSlice({
	name: EnumStore.SED_CARD_PICTURE,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(sedCardPictureServerToClientActions.getSedCardPictures, (state) => {
			state.isLoading = false;
		});
		builder.addCase(sedCardPictureClientToServerActions.getSedCardPicture, (state) => {
			state.isLoading = false;
		});
		builder.addCase(sedCardPictureClientOnlyActions.addMany, sedCardPictureAdapter.addMany);
		builder.addCase(sedCardPictureClientOnlyActions.resetStore, () => initialState);
	},
});
