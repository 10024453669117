import {ACTION, CMDP_GETLIVEHISTORY_RESPONSE} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class LiveSessionsServerToClientActions extends ServerToClientActions<EnumStore.LIVE_SESSIONS> {
	readonly scope = EnumStore.LIVE_SESSIONS;

	liveSessionsReceived = this.createAction(
		ACTION.CMDP_GETLIVEHISTORY,
		this.getPrepareAction<CMDP_GETLIVEHISTORY_RESPONSE['values'], CMDP_GETLIVEHISTORY_RESPONSE>(),
	);
}

export const liveSessionsServerToClientActions = new LiveSessionsServerToClientActions();
