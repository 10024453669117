import _ from 'lodash';
import {EnumBooleanDigitized} from 'cmd-control-client-lib';

import {THavingChatID} from '@messenger/core/src/Redux/Messages/Model';
import {
	THavingQueryIds,
	TQueryUserModel,
	QueryUserAnswerChoice,
} from '@messenger/core/src/Redux/QueryUser/TQueryUserModel';

class QueryUserViewModel implements THavingQueryIds, THavingChatID {
	public readonly queryId?: string;
	public readonly messageId?: string;
	public readonly chatID?: string;
	public readonly channelId?: string;

	public readonly timeout: number = NaN;
	public readonly key: string = '';
	public readonly text: string = '';
	public readonly defaultChoiceLabel: QueryUserAnswerChoice = QueryUserAnswerChoice.BUTTON_0;
	public readonly defaultChoiceValue: EnumBooleanDigitized = EnumBooleanDigitized.FALSE;
	public readonly button0Value: EnumBooleanDigitized = EnumBooleanDigitized.FALSE;
	public readonly button1Value: EnumBooleanDigitized = EnumBooleanDigitized.TRUE;
	public readonly button0Text: string = '';
	public readonly button1Text: string = '';

	constructor(protected readonly query: TQueryUserModel) {
		this.timeout = parseInt(query.timeout, 10);
		this.defaultChoiceLabel = query.btndef;
		this.defaultChoiceValue = query.btnDefault;
		this.button0Text = query.btn0;
		this.button1Text = query.btn1;

		this.key = _.get(query, 'key', '');
		this.text = _.get(query, 'text', '');
		this.queryId = _.get(query, 'queryId');
		this.messageId = _.get(query, 'messageId');
		this.chatID = _.get(query, 'chatID');
		this.channelId = _.get(query, 'channelId');
	}
}

export default QueryUserViewModel;
