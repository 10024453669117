import {ACTION, groupType} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class GroupsClientToServerActions extends ClientToServerActions<EnumStore.GROUPS> {
	readonly scope = EnumStore.GROUPS;

	getGroups = this.createAction(ACTION.CMDP_SGETGROUPS);

	editGroup = this.createAction(ACTION.CMDP_SUPDATEGROUP, (payload: Partial<groupType>) => {
		return {
			payload: {
				groupId: payload.groupId,
				groupName: payload.groupName,
			},
		};
	});

	createGroup = this.createAction(
		ACTION.CMDP_SADDGROUP,
		(payload: {group: Partial<groupType>; channelIds: string[]}) => {
			return {
				payload: {
					groupName: payload.group.groupName,
				},
				meta: {
					channelIds: payload.channelIds,
				},
			};
		},
	);
}

export const groupsClientToServerActions = new GroupsClientToServerActions();
