import React, {ComponentProps, ReactNode} from 'react';
import {DialogContent, DialogContentText, DialogTitle} from '@mui/material';

import CircularProgressWithLabel from '@messenger/uikit/src/Dialog/CircularProgressWithLabel/CircularProgressWithLabel';
import FormButtons from '@messenger/uikit/src/Form/FormButtons/FormButtons';
import Button from '@messenger/uikit/src/Button/Button';

const DialogWindowContent = ({
	timer,
	handleTimeout,
	onBtn0Click,
	title,
	body,
	btn0Text,
	isBtn0Disabled,
	btn0DataTestId,
	btn1Text,
	onBtn1Click,
	isBtn1Disabled,
	btn1DataTestId,
	isAlert,
	children,
	buttonSize,
}: TDialogWindowsContentProps) => {
	return (
		<>
			{timer && isAlert && (
				<CircularProgressWithLabel isGrowing={true} timer={timer} onEnd={handleTimeout ? handleTimeout : onBtn0Click} />
			)}
			{title && <DialogTitle variant="body2">{title}</DialogTitle>}
			{(body || children) && (
				<DialogContent>
					{body && <DialogContentText>{body}</DialogContentText>}
					{children}
				</DialogContent>
			)}
			{((btn0Text && onBtn0Click) || (btn1Text && onBtn1Click)) && (
				<FormButtons isAlertGroupButtons>
					{btn0Text && onBtn0Click && (
						<Button
							disabled={isBtn0Disabled}
							data-test-id={btn0DataTestId}
							onClick={onBtn0Click}
							key={1}
							size={buttonSize}
						>
							{btn0Text}
						</Button>
					)}

					{btn1Text && onBtn1Click && (
						<Button
							variant="contained"
							disabled={isBtn1Disabled}
							data-test-id={btn1DataTestId}
							onClick={onBtn1Click}
							size={buttonSize}
						>
							{btn1Text}
						</Button>
					)}
				</FormButtons>
			)}
		</>
	);
};

type TDialogWindowsContentProps = {
	isAlert?: boolean;
	btn1DataTestId?: string;
	btn0DataTestId?: string;
	buttonSize?: ComponentProps<typeof Button>['size'];
	timer?: number;
	title?: string;
	body?: string;
	btn0Text?: string;
	btn1Text?: string;
	children?: ReactNode;
	isBtn1Disabled?: boolean;
	isBtn0Disabled?: boolean;
	onBtn0Click?: () => void;
	onBtn1Click?: () => void;
	handleTimeout?: () => void;
};
export default DialogWindowContent;
