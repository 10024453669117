import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {toyClientOnlyActions} from '@messenger/core/src/Redux/Toy/Actions/toyClientOnlyActions';
import {selectSelectedToy} from '@messenger/core/src/Redux/Toy/Selectors/selectSelectedToy';

/**
 * @param {object} action
 * @return {Generator|void}
 */
export const selectOnlyToyIfNotYetSaga = function* (action: ReturnType<typeof toyClientOnlyActions.receiveToys>) {
	try {
		// check selected device still in list
		const selectedToyId = yield* select(selectSelectedToy);
		const toyIds = Object.keys(action.payload);

		// no toy selected and received only one
		if (_.isUndefined(selectedToyId) && toyIds.length === 1) {
			const onlyToyId = toyIds[0];
			const toy = action.payload[onlyToyId];

			// check toy active & not low battery
			if (_.get(toy, 'status', 0) === 1 && (!_.has(toy, 'battery') || _.get(toy, 'battery', 100) < 20)) {
				yield* put(toyClientOnlyActions.selectToy(onlyToyId));
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'selectOnlyToyIfNotYetSaga', action});
	}
};
