import IRootState from '@messenger/core/src/Redux/IRootState';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {queryUserAdapter} from '@messenger/core/src/Redux/QueryUser/entityAdapter';

export const selectQueryState = (state: IRootState) => state[EnumStore.QUERY_USER];

/**
 * List of predefined selectors
 */
export const {
	selectIds: selectQueriesIds,
	selectEntities: selectQueriesEntities,
	selectAll: selectAllQueries,
	selectTotal: selectTotalQueries,
	selectById: selectQueryById,
} = queryUserAdapter.getSelectors<IRootState>(selectQueryState);
