import _ from 'lodash';
import {channelSummaryInfo} from 'cmd-control-client-lib';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectChannelsSummary} from '@messenger/core/src/Redux/Channels/Selectors/selectChannelsSummary';
import GroupVM from '@messenger/core/src/Redux/Groups/GroupVM';
import {
	REGULAR_CUSTOMER_GROUP,
	PINNED_GROUP,
	SYSTEM_GROUPS,
	ADVERTISED_GROUP,
	ACTIVE_GROUP,
} from '@messenger/core/src/Redux/Groups/systemGroups';

const systemGroupsFromSummaryMap: Record<string, keyof channelSummaryInfo> = {
	[REGULAR_CUSTOMER_GROUP]: 'regular',
	[PINNED_GROUP]: 'pinned',
	[ADVERTISED_GROUP]: 'advertised',
	[ACTIVE_GROUP]: 'active',
};

export const selectSystemGroupVms = createSelector([selectChannelsSummary], (channelsSummary) => {
	return _.map(SYSTEM_GROUPS, (systemGroupName) => {
		return new GroupVM({
			groupId: systemGroupName,
			groupName: systemGroupName,
			groupChannels: (channelsSummary || {})[systemGroupsFromSummaryMap[systemGroupName]] || '0',
			groupCreated: '1970-01-01T00:00:00Z',
		});
	});
});
