import _ from 'lodash';
import React, {memo, Ref} from 'react';
import {EnumBooleanDigitized} from 'cmd-control-client-lib';

import QueryUserViewModel from '@messenger/core/src/Redux/QueryUser/QueryUserViewModel';
import PropertyHandler from '@messenger/core/src/Types/PropertyHandler';
import DialogPopUp from '@messenger/uikit/src/Dialog/DialogPopUp/DialogPopUp';
import EnumChatPageTestIds from '@messenger/uikit/src/TestIds/EnumChatPageTestIds';

import './_ContentWithTimer.scss';

const ContentWithTimer = React.forwardRef((props: TContentTimerProps, ref: Ref<Element>) => {
	const hasErrored = !_.isUndefined(props.error);

	const onButton = (
		buttonNumber: EnumBooleanDigitized,
		isTimeout: EnumBooleanDigitized = EnumBooleanDigitized.FALSE,
	) => {
		if (props.isLoading || hasErrored) {
			return;
		}

		props.queryReply(buttonNumber, isTimeout);
	};

	return (
		<DialogPopUp
			dataTestId={`${EnumChatPageTestIds.NOTIFICATION_BAR}-${props.query.key}`}
			btn0DataTestId={EnumChatPageTestIds.NOTIFICATION_BAR_BTN_0}
			btn1DataTestId={EnumChatPageTestIds.NOTIFICATION_BAR_BTN_1}
			ref={ref}
			body={hasErrored ? props.error : props.query.text}
			handleTimeout={() => onButton(props.query.button0Value, EnumBooleanDigitized.TRUE)}
			onClose={() => onButton(props.query.button0Value)}
			btn0Text={props.query.button0Text}
			onBtn0Click={() => onButton(props.query.button0Value)}
			onBtn1Click={() => onButton(props.query.button1Value)}
			btn1Text={props.query.button1Text}
			timer={props.query.timeout}
		/>
	);
});

type TContentTimerProps = {
	query: QueryUserViewModel;
	error?: string;
	isLoading: boolean;
	queryReply: PropertyHandler<EnumBooleanDigitized, EnumBooleanDigitized>;
};
ContentWithTimer.displayName = 'ContentWithTimer';
export default memo(ContentWithTimer);
