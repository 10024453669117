import { JSONString } from "../../@types/json-stringified";
import { ComaSeparatedValues } from "../sessionstate";
import { ResultCode } from "./resultcode";
import { ACTION } from "./action";

/**
 * @remarks cmd-control socket by default returns only string values
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type StringBasedType = ComaSeparatedValues<string> | JSONString<Object> | string;

/**
 * Base dictionary\<strting,string\> object used for params and values
 * @remarks might not have some of the values, e.g. value might be undefined
 */
export interface IKeyMaybeValue {
  [key: string]: StringBasedType | undefined;
}

/**
 * @remarks always has values
 */
export interface IKeyValue {
  [key: string]: StringBasedType;
}

/**
 * Base command interface
 */
export interface ICOMMAND {
  /**
   * action is the name of command
   */
  action: ACTION;
  /**
   * command parameters
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;
}

/**
 * result object is the status of CMDP_XXXX_RESPONSE
 */
export class RESULT {
  public code: ResultCode;
  /**
   * infostring string to client
   */
  public reason: string;
}

/**
 * Response from CmdControl to client
 */
export interface IRESPONSE extends ICOMMAND {
  /**
   * Response status
   */
  result: RESULT;
  /**
   * command array to client
   */
  commands: ICOMMAND[];
  /**
   * response values
   */
  values: IKeyMaybeValue;
}
