const uploadFieldsThatAreNotIMessage = [
	'DisplayHeight',
	'VerticalResolution',
	'HorizontalResolution',
	'DisplayWidth',
	'ImageMimeType',
	'Orientation',
	'PixelFormat',
	'filecontenttype',
	'format',
	'md5',
	'step',
	'fileformat',
	'filename',
	'origfilename',
	'transactionID',
];

export default uploadFieldsThatAreNotIMessage;
