import _ from 'lodash';
import {all, call} from 'typed-redux-saga';

import {messagesClientOnlyActions, messagesServerToClientActions} from '@messenger/core/src/Redux/Messages/Actions';
import {markMessageAsUploadedSaga} from '@messenger/core/src/Redux/Messages/Sagas/markMessageAsUploadedSaga';
import {rejectQueryUserRequestOnChatExitSaga} from '@messenger/core/src/Redux/Messages/Sagas/rejectQueryUserRequestOnChatExitSaga';
import {removeTemporaryMessagesSaga} from '@messenger/core/src/Redux/Messages/Sagas/removeTemporaryMessagesSaga';
import {showSoftChatWarningSaga} from '@messenger/core/src/Redux/Messages/Sagas/showSoftChatWarningSaga';
import {switchActiveChannelToChatSaga} from '@messenger/core/src/Redux/Messages/Sagas/switchActiveChannelToChatSaga';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {updateMediaAfterSendMessageWithMediaSaga} from '@messenger/core/src/Redux/Messages/Sagas/updateMediaAfterSendMessageWithMediaSaga';

export const processBatchUpsertSaga = function* (action: ReturnType<typeof messagesClientOnlyActions.setMany>) {
	try {
		if (!_.isEmpty(action.payload)) {
			yield* call(removeTemporaryMessagesSaga, action);
			yield* call(updateMediaAfterSendMessageWithMediaSaga, action);
		}

		yield* all(
			_.flatMap(action.payload, (message) => {
				const action = messagesServerToClientActions.messageReceived(message);

				return [
					call(markMessageAsUploadedSaga, action),
					call(showSoftChatWarningSaga, action),
					call(switchActiveChannelToChatSaga, action),
					call(rejectQueryUserRequestOnChatExitSaga, action),
				];
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processBatchUpsertSaga'});
	}
};
