import {call, put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectIsWebRtcOutputStreamActive} from '@messenger/core/src/Redux/Stream/Selectors/selectIsWebRtcOutputStreamActive';
import {streamClientToServerActions} from '@messenger/core/src/Redux/Stream/Actions';

export function* processUnloadSaga() {
	try {
		const isWebRtcOutputStreamActive = yield* select(selectIsWebRtcOutputStreamActive);

		if (isWebRtcOutputStreamActive) {
			yield* put(streamClientToServerActions.stopSend());
		}

		const api = ServiceFactory.getVControlApi();

		yield* call([api, api.closeConnection]);
	} catch (e) {
		ServiceFactory.logService.error(e, {saga: 'processUnloadSaga'});
	}
}
