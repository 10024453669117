import {call} from 'typed-redux-saga';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import {
	EnumAbstractNotificationCloseReason,
	EnumAbstractNotificationVariant,
} from '@messenger/core/src/Services/UINotification';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';

export const processBackendConnectionStatusUpdateSaga = function* ({
	payload: {isConnected, reConnectionTimeout},
}: ReturnType<typeof clientClientOnlyActions.updateBackendConnectionStatus>) {
	try {
		if (!isConnected) {
			yield* call(
				[ServiceFactory.notifications, ServiceFactory.notifications.close],
				EnumSnackbarNotificationKeys.SOCKET_ERROR_NOTIFICATION_KEY,
			);

			if (_.isUndefined(reConnectionTimeout)) {
				const vControlApi = ServiceFactory.getVControlApi();

				yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
					isOnlyDesktop: true,
					duration: null,
					variant: EnumAbstractNotificationVariant.ERROR,
					persist: true,
					key: EnumSnackbarNotificationKeys.SOCKET_ERROR_NOTIFICATION_KEY,
					text: ServiceFactory.i18n.t('common:socket-fatal-error'),
					buttonText: ServiceFactory.i18n.t('common:reconnect'),
					onClose: (event, reason) => {
						if (reason === EnumAbstractNotificationCloseReason.USER) {
							ServiceFactory.notifications.close();
							vControlApi.closeConnection();
							vControlApi.connect();
						}
					},
				});
			} else {
				yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
					isOnlyDesktop: true,
					duration: reConnectionTimeout,
					variant: EnumAbstractNotificationVariant.ERROR,
					key: EnumSnackbarNotificationKeys.SOCKET_ERROR_NOTIFICATION_KEY,
					text: ServiceFactory.i18n.t('common:socket-error', {interval: reConnectionTimeout / 1000}),
				});
			}
		}
	} catch (e) {
		ServiceFactory.logService.error(e, {saga: 'processBackendConnectionStatusUpdateSaga'});
	}
};
