import {call} from 'typed-redux-saga';
import _ from 'lodash';

import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';
import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {ticketShowClientOnlyActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowClientOnlyActions';

export const afterTicketShowModalToStoreSaga = function* (
	action: ReturnType<typeof ticketShowClientOnlyActions.setModalAfterTicketShow>,
) {
	try {
		const store = ServiceFactory.store;

		if (!_.isUndefined(action.payload.setLocal)) {
			yield* call([store, store.set], EnumLocalStorageKeys.SHOW_MODAL_AFTER_TICKET_SHOW, action.payload.setLocal, {
				storeType: EnumStoreType.LOCAL,
			});
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'afterTicketShowModalToStoreSaga'});
	}
};
