import {ACTION} from 'cmd-control-client-lib';
import {CMDC_TOYACTION} from 'cmd-control-client-lib/dist/protocol/toy';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class ToyServerToClientActions extends ServerToClientActions<EnumStore.STREAM> {
	readonly scope = EnumStore.STREAM;

	queueVibration = this.createAction(
		ACTION.CMDC_TOYACTION,
		(payload: CMDC_TOYACTION['params'], meta: CMDC_TOYACTION) => ({
			payload: {
				id: `${payload.sessionID}-${payload.commandID}`,
				duration: payload.duration ? parseInt(payload.duration, 10) : 0,
				intensity: payload.intensity ? parseFloat(payload.intensity) : 0,
				channelId: payload.channelId,
			},
			meta,
		}),
	);
}

export const toyServerToClientActions = new ToyServerToClientActions();
