import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {TInfoWizard} from '@messenger/core/src/Redux/FeatureShowcase/TInfoWizard';

enum EnumActions {
	START = 'START',
	STOP = 'STOP',
}

class FeatureShowcaseClientOnlyActions extends ClientOnlyActions {
	scope = EnumStore.FEATURE_SHOWCASE;

	start = this.createAction(EnumActions.START, this.getPrepareAction<{type: TInfoWizard}>());
	stop = this.createAction(EnumActions.STOP);
}

export const featureShowcaseClientOnlyActions = new FeatureShowcaseClientOnlyActions();
