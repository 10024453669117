import {EnumMediaType} from 'cmd-control-client-lib';

import {TEntityPrice} from '@messenger/core/src/Redux/Currency/Selectors/selectPriceFormatted';

abstract class AbstractMediaViewModel<T extends [EnumMediaType] | [] = [EnumMediaType]> {
	abstract getMin(...args: [...T, number] | T): TEntityPrice;

	abstract getMax(...args: [...T, number] | T): TEntityPrice;

	abstract getPredefinedList(...args: T): number[];

	abstract isValid(value: number, mediaType?: EnumMediaType): boolean;
}

export interface IPredefinedPrice {
	value: number;
	label: string;
}

export default AbstractMediaViewModel;
