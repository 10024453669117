import _ from 'lodash';
import {call, put, race, select, take} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions/attachmentClientOnlyActions';
import {selectTargetChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetChannelIds';
import {selectTargetCustomGroupIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetCustomGroupIds';
import {selectIsAudioRecording} from '@messenger/core/src/Redux/Attachment/Selectors/selectIsAudioRecording';
import {selectAreSomeChannelMediaForTargetChannelIdsChecking} from '@messenger/core/src/Redux/MediaLinks/Selectors/selectAreSomeChannelMediaForTargetChannelIdsChecking';
import {selectChannelGroupsPurchaseAbility} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Selectors/selectChannelGroupsPurchaseAbility';
import {EnumCanPurchase} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/channelGroupsPurchaseAbilitySlice';
import {channelGroupsPurchaseAbilityClientOnlyActions} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Actions/channelGroupsPurchaseAbilityClientOnlyActions';
import {selectTargetSystemGroupIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetSystemGroupIds';
import {selectCurrentAttachmentVm} from '@messenger/core/src/Redux/Attachment/Selectors/selectCurrentAttachmentVm';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {messageInputClientOnlyActions} from '@messenger/core/src/Redux/MessageInput/Actions/messageInputClientOnlyActions';
import {selectSelectedChannelsCount} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Selectors/selectSelectedChannelsCount';
import {selectAllowedReceivedChannels} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Selectors/selectAllowedReceivedChannels';
import {selectAllowedPurchasedChannels} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Selectors/selectAllowedPurchasedChannels';
import {BULK} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';

import {submitMessageWithoutFileUploadingSaga} from './submitMessageWithoutFileUploadingSaga';

export const submitMessageSaga = function* ({
	payload: {message: messageText},
}: ReturnType<typeof messagesClientOnlyActions.sendMessage>) {
	try {
		const guestIdentity = yield* select(selectCurrentGuestIdentity);

		if (yield* select(selectIsAudioRecording)) {
			const {errorRecording} = yield* race({
				successAttachment: take(attachmentClientOnlyActions.attach.type),
				errorRecording: take(attachmentClientOnlyActions.audioRecordingFailed.type),
			});

			if (errorRecording) {
				return;
			}

			if (guestIdentity?.guestType === EnumGuestType.BULK) {
				yield* take(channelGroupsPurchaseAbilityClientOnlyActions.change);
			}
		}

		const attachment = yield* select(selectCurrentAttachmentVm);

		if (
			guestIdentity?.guestType === EnumGuestType.ALL ||
			(guestIdentity?.guestType === EnumGuestType.CHAT && !attachment)
		) {
			yield* call(submitMessageWithoutFileUploadingSaga, messageText);

			return;
		}

		let targetChannelIds = yield* select(selectTargetChannelIds);

		const targetCustomGroupIds = yield* select(selectTargetCustomGroupIds);
		const targetSystemGroupIds = yield* select(selectTargetSystemGroupIds);

		if (_.isEmpty(targetChannelIds) && _.isEmpty(targetCustomGroupIds) && _.isEmpty(targetSystemGroupIds)) {
			return;
		}

		const isAttachmentChecking = yield* select(selectAreSomeChannelMediaForTargetChannelIdsChecking);

		if (isAttachmentChecking && _.isEmpty(targetSystemGroupIds)) {
			return;
		}

		const targetChannelGroupsAbility = yield* select(selectChannelGroupsPurchaseAbility, {
			attachmentId: attachment?.attachmentId,
		});

		if (
			targetChannelGroupsAbility?.canReceiveAttachment === EnumCanPurchase.NO_ONE ||
			(attachment?.price && targetChannelGroupsAbility?.canPurchase === EnumCanPurchase.NO_ONE)
		) {
			return;
		}

		targetChannelIds = _.difference(targetChannelIds, targetChannelGroupsAbility?.canNotReceiveChannels || []);
		const selectedChannelsCount = yield* select(selectSelectedChannelsCount);
		const allowedReceivedChannels = yield* select(selectAllowedReceivedChannels);
		const allowedPurchasedChannels = yield* select(selectAllowedPurchasedChannels);

		let sentChannelCount = !_.isUndefined(attachment) ? _.size(allowedReceivedChannels) : selectedChannelsCount;

		if (attachment?.price) {
			targetChannelIds = _.difference(targetChannelIds, targetChannelGroupsAbility?.canNotPurchaseChannels || []);
			sentChannelCount = _.size(allowedPurchasedChannels);
		}

		const channelId = _.join(targetChannelIds, ',');
		const groupId = _.join(targetCustomGroupIds, ',');

		if (
			(_.isEmpty(channelId) && _.isEmpty(groupId) && _.isEmpty(targetSystemGroupIds)) ||
			attachment?.hasAttachmentError
		) {
			return;
		}

		yield* put(messagesClientOnlyActions.setSentChannelCount(sentChannelCount));

		const hasUniqueMediaAttachment = !_.isUndefined(attachment) && attachment.hasMedia && !attachment.isDuplicatedMedia;
		const hasDuplicateAttachment = !_.isUndefined(attachment) && attachment.isDuplicatedMedia;
		const isTextOnlyMessage = !_.isEmpty(messageText) && _.isUndefined(attachment);

		if (
			isTextOnlyMessage ||
			hasDuplicateAttachment ||
			(_.isUndefined(channelId) && _.isUndefined(groupId) && _.isEmpty(targetSystemGroupIds))
		) {
			yield* call(
				submitMessageWithoutFileUploadingSaga,
				messageText,
				channelId,
				attachment,
				groupId,
				targetSystemGroupIds,
			);
		} else if (hasUniqueMediaAttachment) {
			const isBulk = guestIdentity?.guestType === EnumGuestType.BULK;

			if (isBulk) {
				targetChannelIds = attachment.price ? allowedPurchasedChannels : allowedReceivedChannels;
			}

			yield* put(
				messagesClientOnlyActions.sendMessageWithMediaUpload({
					text: messageText,
					chatID: guestIdentity?.chatId,
					channelId: _.join(targetChannelIds, ','),
					attachment,
					isBulk,
				}),
			);

			yield* put(messageInputClientOnlyActions.removeOne(isBulk ? BULK : targetChannelIds[0]));
		}

		if (!_.isUndefined(attachment)) {
			yield* put(attachmentClientOnlyActions.clear({isSent: true}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'submitMessageSaga'});
	}
};
