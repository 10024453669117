import {call, put} from 'typed-redux-saga';
import _ from 'lodash';
import {EnumStreamStatusType} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {streamClientToServerActions, streamServerToClientActions} from '@messenger/core/src/Redux/Stream/Actions';

export const statusReceivedSaga = function* ({payload}: ReturnType<typeof streamServerToClientActions.statusReceived>) {
	try {
		if (payload.type === EnumStreamStatusType.WEBRTC_STATS) {
			const stats = yield* call([ServiceFactory.webRtcApi, ServiceFactory.webRtcApi.getStreamStats]);

			if (!_.isUndefined(stats)) {
				yield* put(streamClientToServerActions.sendStreamStats(stats));
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'statusReceivedSaga'});
	}
};
