import {call, put} from 'typed-redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';
import {ACTION, CMDP_OPEN_LOBBY_RESPONSE, ResultCode} from 'cmd-control-client-lib';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {ticketShowClientOnlyActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowClientOnlyActions';
import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';
import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';

export const scheduleAfterTicketShowModalSaga = function* (action: TYStartLobbyResultAction) {
	try {
		if (action.meta.result.code === ResultCode.OK) {
			const api = ServiceFactory.store;
			const showModalAfterTicketShow = yield* call([api, api.get], EnumLocalStorageKeys.SHOW_MODAL_AFTER_TICKET_SHOW, {
				storeType: EnumStoreType.LOCAL,
			});

			if (showModalAfterTicketShow || _.isNull(showModalAfterTicketShow)) {
				yield* put(ticketShowClientOnlyActions.scheduleModalAfterTicketShow());
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'scheduleAfterTicketShowModalSaga', action});
	}
};

export type TYStartLobbyResultAction = PayloadAction<never, ACTION.CMDP_OPEN_LOBBY, CMDP_OPEN_LOBBY_RESPONSE>;
