import {useMemo, useRef} from 'react';
import _ from 'lodash';

import {usePrevious} from '@messenger/core/src/Hooks/usePrevious';
import {EnumMessageInGroupPosition} from '@messenger/uikit/src/MessagesHistory/types';

export const INITIAL_FIRST_INDEX = 10000;

export const useMessagesHistoryConfig = (groupedMessageIds: Record<string, string[][]>) => {
	const firstMessageIndex = useRef(INITIAL_FIRST_INDEX);

	const [messageIds, groupCounts, groupKeys, inGroupPositions] = useMemo(() => {
		const inGroupPositions: Record<string, EnumMessageInGroupPosition> = {};

		const flattened = _.map(groupedMessageIds, (group) => {
			_.map(group, (ids) => {
				_.map(ids, (id, index) => {
					const isFirstInGroup = !index;
					const isLastInGroup = index === _.findLastIndex(ids);

					if (isFirstInGroup && isLastInGroup) {
						inGroupPositions[id] = EnumMessageInGroupPosition.single;
					} else if (isFirstInGroup) {
						inGroupPositions[id] = EnumMessageInGroupPosition.first;
					} else if (isLastInGroup) {
						inGroupPositions[id] = EnumMessageInGroupPosition.last;
					} else {
						inGroupPositions[id] = EnumMessageInGroupPosition.middle;
					}
				});
			});

			return _.flatten(group);
		});

		return [
			_.chain(flattened).flatten().compact().value(),
			_.map(flattened, (group) => group.length),
			_.keys(groupedMessageIds),
			inGroupPositions,
		];
	}, [groupedMessageIds]);

	const lastMessageId = _.last(messageIds);
	const prevLastMessageId = usePrevious(lastMessageId);
	const messagesCount = _.size(messageIds);
	const prevMessagesCount = usePrevious(messagesCount);

	if (!_.isUndefined(prevMessagesCount) && messagesCount > prevMessagesCount && lastMessageId === prevLastMessageId) {
		firstMessageIndex.current = firstMessageIndex.current - (messagesCount - prevMessagesCount);
	}

	return {
		messageIds,
		groupCounts,
		groupKeys,
		inGroupPositions,
		messagesCount,
		firstMessageIndex: firstMessageIndex.current,
		lastMessageId,
	};
};
