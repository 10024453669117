import {createSlice} from '@reduxjs/toolkit';

import EnumChatFontSize from '@messenger/core/src/BusinessLogic/EnumChatFontSize';
import {
	clientClientOnlyActions,
	EnumStopStreamWarningAction,
	TAppVersionInfo,
} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

export const defaultState: TClientState = {
	isReady: false,
	isBulkMessage: false,
	isUserInfoOpen: false,
	isCam2CamAreaOpen: false,
	lastC2CChatId: null,
	userInfoChannelId: null,
	isSoundMuted: true,
	isSoundsTestRunning: false,
	isMacroSettingModalOpen: false,
	hasLogoutModal: false,
	warningModalAction: EnumStopStreamWarningAction.LOGOUT,
	softChatContactSupport: false,
	isConnected: false,
	serverDisabled: false,
	chatFontSize: EnumChatFontSize.MEDIUM,
	isShortcutWhileRecordingModalOpen: false,
	isNetworkAvailable: false,
	backendConnectionStatus: undefined,
	appVersionInfo: null,
	isDrawerOpened: false,
	replyMessageId: null,
};

export const clientSlice = createSlice({
	name: EnumStore.CLIENT,
	initialState: defaultState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(clientClientOnlyActions.setClientReady, (state) => {
			state.isReady = true;
		});

		builder.addCase(clientClientOnlyActions.setIsConnected, (state) => {
			state.isConnected = true;
			state.isNetworkAvailable = true;
		});

		builder.addCase(clientClientOnlyActions.setIsDisconnected, (state) => {
			state.isConnected = false;
		});

		builder.addCase(clientClientOnlyActions.setIsBulkMessage, (state, {payload}) => {
			state.isBulkMessage = payload;
		});

		builder.addCase(clientClientOnlyActions.toggleMacroModal, (state) => {
			state.isMacroSettingModalOpen = !state.isMacroSettingModalOpen;
		});

		builder.addCase(clientClientOnlyActions.openCam2CamArea, (state, {payload: {chatId}}) => {
			state.isCam2CamAreaOpen = true;

			if (chatId) {
				state.lastC2CChatId = chatId;
			}
		});

		builder.addCase(clientClientOnlyActions.closeCam2CamArea, (state) => {
			state.isCam2CamAreaOpen = false;
		});

		builder.addCase(clientClientOnlyActions.forceDrawer, (state, {payload: {isOpened}}) => {
			state.isDrawerOpened = isOpened;
		});

		builder.addCase(clientClientOnlyActions.toggleDrawer, (state) => {
			state.isDrawerOpened = !state.isDrawerOpened;
		});

		builder.addCase(clientClientOnlyActions.setUserAreaChannelId, (state, {payload: {channelId}}) => {
			state.userInfoChannelId = channelId;
		});

		builder.addCase(clientClientOnlyActions.resetUserAreaChannelId, (state) => {
			state.userInfoChannelId = null;
		});

		builder.addCase(clientClientOnlyActions.closeUserInfoArea, (state) => {
			state.isUserInfoOpen = false;
			state.userInfoChannelId = null;
		});

		builder.addCase(clientClientOnlyActions.openUserInfoArea, (state, {payload: {channelId}}) => {
			state.userInfoChannelId = channelId;
			state.isUserInfoOpen = true;
		});

		builder.addCase(clientClientOnlyActions.toggleSoundMuted, (state) => {
			state.isSoundMuted = !state.isSoundMuted;
		});

		builder.addCase(clientClientOnlyActions.showLogoutWarning, (state, {payload}) => {
			state.hasLogoutModal = true;
			state.warningModalAction = payload || EnumStopStreamWarningAction.LOGOUT;
		});

		builder.addCase(clientClientOnlyActions.resetStore, (state) => {
			const {isReady, isNetworkAvailable} = state;

			return {...defaultState, isReady, isNetworkAvailable};
		});

		builder.addCase(clientClientOnlyActions.hideLogoutWarning, (state) => {
			state.hasLogoutModal = false;
			state.warningModalAction = EnumStopStreamWarningAction.LOGOUT;
		});

		builder.addCase(clientClientOnlyActions.setReplyMessageId, (state, {payload: {messageId}}) => {
			state.replyMessageId = messageId;
		});

		builder.addCase(clientClientOnlyActions.resetReplyMessageId, (state) => {
			state.replyMessageId = null;
		});

		builder.addCase(clientClientOnlyActions.disableServer, (state) => {
			state.serverDisabled = true;
		});

		builder.addCase(clientClientOnlyActions.enableServer, (state) => {
			state.serverDisabled = false;
		});

		builder.addCase(clientClientOnlyActions.toggleSoftChatAvailability, (state) => {
			state.softChatContactSupport = !state.softChatContactSupport;
		});

		builder.addCase(clientClientOnlyActions.setChatFontSize, (state, {payload}) => {
			state.chatFontSize = payload;
		});

		builder.addCase(clientClientOnlyActions.loadChatFontSizeFromStore, (state, {payload}) => {
			state.chatFontSize = payload;
		});

		builder.addCase(clientClientOnlyActions.soundsAccepted, (state) => {
			state.isSoundsTestRunning = true;
		});

		builder.addCase(clientClientOnlyActions.soundsTestResult, (state, {payload}) => {
			state.isSoundMuted = !payload;
			state.isSoundsTestRunning = false;
		});

		builder.addCase(clientClientOnlyActions.soundsDeclined, (state) => {
			state.isSoundMuted = true;
		});

		builder.addCase(clientClientOnlyActions.setShortcutWhileRecordingModalOpen, (state, {payload}) => {
			state.isShortcutWhileRecordingModalOpen = payload;
		});

		builder.addCase(clientClientOnlyActions.updateNetworkStatus, (state, {payload: {isOnline}}) => {
			state.isNetworkAvailable = isOnline;
		});

		builder.addCase(clientClientOnlyActions.updateBackendConnectionStatus, (state, {payload}) => {
			state.backendConnectionStatus = payload;
		});

		builder.addCase(clientClientOnlyActions.setAppVersionInfo, (state, {payload}) => {
			state.appVersionInfo = payload;
		});

		builder.addCase(clientClientOnlyActions.setCacheSize, (state, {payload}) => {
			state.cacheSize = payload;
		});
	},
});

export type TBackendConnectionStatus = {isConnected: boolean; reConnectionTimeout?: number};

export type TClientState = {
	isReady: boolean;
	isBulkMessage: boolean;
	isUserInfoOpen: boolean;
	isCam2CamAreaOpen: boolean;
	lastC2CChatId: null | string;
	userInfoChannelId: null | string;
	isSoundMuted: boolean;
	isSoundsTestRunning: boolean;
	isMacroSettingModalOpen: boolean;
	hasLogoutModal: boolean;
	warningModalAction: EnumStopStreamWarningAction;
	softChatContactSupport: boolean;
	isConnected: boolean;
	serverDisabled: boolean;
	chatFontSize: EnumChatFontSize;
	isShortcutWhileRecordingModalOpen: boolean;
	isNetworkAvailable: boolean;
	backendConnectionStatus?: TBackendConnectionStatus;
	appVersionInfo: null | TAppVersionInfo;
	cacheSize?: number;
	isDrawerOpened: boolean;
	replyMessageId: null | string;
};
