import _ from 'lodash';

import {
	TAudioPermissions,
	TConstrainULongRange,
	TVideoPermissions,
	TWebRTCConfig,
} from '@messenger/core/src/Redux/Stream/Model';

function shiftBitrate(bitRate: TConstrainULongRange): TConstrainULongRange {
	return {
		min: bitRate.min / 1000,
		ideal: bitRate.ideal / 1000,
		max: bitRate.max / 1000,
	};
}

class WebRtcConfigVM {
	url = '';
	applicationName = '';
	streamName = '';

	h264CodecOptions = {};
	vp9CodecOptions = {};

	video: TVideoPermissions = {
		width: {
			min: 1024,
			ideal: 1280,
			max: 1920,
		},
		height: {
			min: 576,
			ideal: 720,
			max: 1080,
		},
		frameRate: {
			min: 15,
			ideal: 30,
			max: 30,
		},
		bitRate: {
			min: 800000,
			ideal: 2500000,
			max: 3500000,
		},
	};

	audio: TAudioPermissions = {
		bitRate: {
			min: 32000,
			ideal: 64000,
			max: 128000,
		},
	};

	constructor(webRtcConfig: TWebRTCConfig) {
		this.url = _.get(webRtcConfig, 'url');
		this.applicationName = _.get(webRtcConfig, 'appName');
		this.streamName = _.get(webRtcConfig, 'streamName');

		this.video = {
			...this.video,
			..._.get(webRtcConfig, 'video', {}),
		};

		this.audio = {
			...this.audio,
			..._.get(webRtcConfig, 'audio', {}),
		};

		this.h264CodecOptions = _.get(webRtcConfig, 'h264', {});
		this.vp9CodecOptions = _.get(webRtcConfig, 'VP9', {});

		this.video.bitRate = shiftBitrate(this.video.bitRate);
		this.audio.bitRate = shiftBitrate(this.audio.bitRate);
	}
}

export default WebRtcConfigVM;
