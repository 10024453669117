import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum EnumClientOnlyActions {
	RESET = 'RESET',
	MARK_PROGRESS = 'MARK_PROGRESS',
	MARK_END = 'MARK_END',
	SET_ERROR = 'SET_ERROR',
}

class SpeedTestClientOnlyActions extends ClientOnlyActions<EnumStore.STREAM_PREVIEW> {
	readonly scope = EnumStore.STREAM_PREVIEW;

	resetStore = this.createAction(EnumClientOnlyActions.RESET);

	setError = this.createAction(EnumClientOnlyActions.SET_ERROR, this.getPrepareAction<string>());

	markProgress = this.createAction(
		EnumClientOnlyActions.MARK_PROGRESS,
		this.getPrepareAction<{progress: number; measured: number}>(),
	);

	markEnd = this.createAction(EnumClientOnlyActions.MARK_END);
}

export const speedTestClientOnlyActions = new SpeedTestClientOnlyActions();
