import React, {memo, useEffect, useState} from 'react';
import {Box, CircularProgress, Typography} from '@mui/material';
import {CircularProgressProps} from '@mui/material/CircularProgress';
import _ from 'lodash';

import style from './CircularProgressWithLabel.module.scss';

const CircularProgressStatic = ({
	value,
	timer,
	isGrowing = false,
	...props
}: CircularProgressProps & {timer: number; value: number; isGrowing?: boolean}) => {
	const initialValue = isGrowing ? 100 - Math.round((value / timer) * 100) : Math.round((value / timer) * 100);

	return (
		<Box className={style.topBox}>
			<CircularProgress variant="determinate" {...props} value={initialValue} />
			<Box className={style.innerBox}>
				{!_.isUndefined(value) && (
					<Typography variant="caption" component="div" color="text.secondary">
						{Math.round(value)}
					</Typography>
				)}
			</Box>
		</Box>
	);
};

const CircularProgressWithLabel = ({onEnd, onTick, step = 1000, timer, ...props}: CircularProgressWithLabelProps) => {
	const [progress, setProgress] = useState(timer);

	useEffect(() => {
		const timerId = setInterval(() => {
			if (progress > 0) {
				setProgress(progress - step);
				!_.isUndefined(onTick) && onTick();
			}
		}, step);

		if (progress <= 0 && !_.isUndefined(onEnd)) {
			onEnd();
		}

		return () => clearInterval(timerId);
	}, [onEnd, onTick, progress, step]);

	return <CircularProgressStatic value={progress / step} timer={timer / step} {...props} />;
};

type CircularProgressWithLabelProps = CircularProgressProps & {
	isGrowing?: boolean;
	timer: number;
	step?: number;
	onEnd?: (...args: any[]) => void;
	onTick?: (...args: any[]) => void;
};
export default memo(CircularProgressWithLabel);
