import {takeEvery} from 'typed-redux-saga';

import {chatsClientOnlyActions, chatsServerToClientActions} from '@messenger/core/src/Redux/Chats/Actions';
import {syncVoyeurShownFlagSaga} from '@messenger/core/src/Redux/Chats/Sagas/syncVoyeurShownFlagSaga';
import {processBatchUpsertSaga} from '@messenger/core/src/Redux/Chats/Sagas/processBatchUpsertSaga';
import {processGuestStopsVoyeurSaga} from '@messenger/core/src/Redux/Chats/Sagas/processGuestStopsVoyeurSaga';

function* chatsSagaWatcher() {
	yield* takeEvery(chatsServerToClientActions.guestStopsVoyeur.type, syncVoyeurShownFlagSaga);
	yield* takeEvery(chatsClientOnlyActions.upsertMany, processBatchUpsertSaga);
	yield* takeEvery(chatsServerToClientActions.guestStopsVoyeur.type, processGuestStopsVoyeurSaga);
}

export default chatsSagaWatcher;
