import _ from 'lodash';
import {call, all} from 'typed-redux-saga';
import {CMDC_CMSG} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {messagesClientOnlyActions, messagesServerToClientActions} from '@messenger/core/src/Redux/Messages/Actions';
import AbstractUiContainer from '@messenger/core/src/Services/AbstractUiContainer';

import {showWebNotificationSaga} from 'src/Redux/Messages/Sagas/showWebNotificationSaga';
import {closeModalsOnStreamStartedSaga} from 'src/Redux/Stream/Sagas/closeModalsOnStreamStartedSaga';
import {checkStartTicketShowSaga} from 'src/Redux/TicketShow/Sagas/checkStartTicketShowSaga';
import {checkCanceledTicketShowSaga} from 'src/Redux/TicketShow/Sagas/checkCanceledTicketShowSaga';

export const processBatchUpsertWebSaga = function* (
	browser: AbstractUiContainer,
	action: ReturnType<typeof messagesClientOnlyActions.setMany>,
) {
	try {
		if (_.isEmpty(action.payload)) {
			throw new Error('payload in empty');
		}

		yield* all(
			_.flatMap(action.payload, (message) => {
				const action = messagesServerToClientActions.messageReceived(message as CMDC_CMSG['params']);

				return [call(closeModalsOnStreamStartedSaga, action)];
			}),
		);

		yield* call(
			showWebNotificationSaga,
			browser,
			messagesServerToClientActions.messageReceived(_.last(action.payload) as CMDC_CMSG['params']),
		);

		yield* call(
			checkStartTicketShowSaga,
			messagesServerToClientActions.messageReceived(_.last(action.payload) as CMDC_CMSG['params']),
		);

		yield* call(
			checkCanceledTicketShowSaga,
			messagesServerToClientActions.messageReceived(_.last(action.payload) as CMDC_CMSG['params']),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processBatchUpsertWebSaga', action});
	}
};
