import {put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {chatsClientOnlyActions} from '@messenger/core/src/Redux/Chats/Actions';
import {TSessionUpdatedAction} from '@messenger/core/src/Redux/Session/Actions';

export const markChatAsPrivateSaga = function* ({payload}: TSessionUpdatedAction) {
	try {
		if ('isSingle' in payload && 'chatID' in payload) {
			yield* put(
				chatsClientOnlyActions.markAsPrivate({
					chatID: payload.chatID,
					isSingle: payload.isSingle,
				}),
			);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'markChatAsPrivateSaga'});
	}
};
