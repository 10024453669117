import _ from 'lodash';

import {TKeyStatus} from '@messenger/core/src/Redux/Session/Model';

export enum EnumSystemKey {
	CTRL = 'Ctrl',
	ALT = 'Alt',
	SHIFT = 'Shift',
	COMMAND = 'Cmd',
}

export const shortcutToStringTransform = (keyStatus: TKeyStatus): string => {
	if (_.isString(keyStatus)) {
		return keyStatus;
	}

	const keysString: string[] = [];
	const keysMap = {
		ctrlKey: EnumSystemKey.CTRL,
		altKey: EnumSystemKey.ALT,
		shiftKey: EnumSystemKey.SHIFT,
		metaKey: EnumSystemKey.COMMAND,
	};
	const keys: Array<keyof typeof keysMap> = ['ctrlKey', 'altKey', 'shiftKey', 'metaKey'];

	keys.forEach((key) => {
		if (keyStatus[key]) {
			keysString.push(keysMap[key]);
		}
	});

	if (!keys.some((el) => keysMap[el] === keyStatus.key) && keyStatus.key !== 'Control') {
		keysString.push(keyStatus.key?.toUpperCase());
	}

	return keysString.join('+');
};
