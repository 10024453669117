import React, {ForwardedRef, forwardRef} from 'react';
import {Badge as MuiBadge, BadgeProps} from '@mui/material';

const Badge = forwardRef(({isBordered = false, children, ...props}: TBadgeProps, ref: ForwardedRef<HTMLDivElement>) => {
	return (
		<MuiBadge
			{...props}
			ref={ref}
			sx={[
				isBordered && {
					'.MuiBadge-badge': {
						borderWidth: 2,
					},
				},
			]}
		>
			{children}
		</MuiBadge>
	);
});

export type TBadgeProps = Pick<
	BadgeProps,
	'className' | 'invisible' | 'variant' | 'badgeContent' | 'anchorOrigin' | 'overlap' | 'color' | 'children' | 'title'
> & {
	isBordered?: boolean;
};

export default Badge;
