import {put, select} from 'typed-redux-saga';

import {selectMessagesHistoryStatuses} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/selectMessagesHistoryStatuses';
import {
	EnumMessageHistorySubject,
	messagesClientOnlyActions,
	messagesClientToServerActions,
} from '@messenger/core/src/Redux/Messages/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumReservedChannelIds} from '@messenger/core/src/BusinessLogic/EnumReservedChannelIds';
import {getLiveSessionIdForSelector} from '@messenger/core/src/Redux/LiveSessions/Selectors/liveSessionsDefaultSelectors';
import {selectLiveSessionChatsById} from '@messenger/core/src/Redux/LiveSessionChats/Selectors/liveSessionChatsDefaultSelectors';

export function* requestMessagesHistorySaga({
	payload: {subjectType, subjectId},
}: ReturnType<typeof messagesClientOnlyActions.requestMessagesHistory>) {
	try {
		if (subjectType === EnumMessageHistorySubject.CHANNEL && subjectId === EnumReservedChannelIds.CHANNEL_ID_JOHN_DOE) {
			return;
		}

		const {[subjectId]: messagesHistoryStatuses} = yield* select(selectMessagesHistoryStatuses);

		if (messagesHistoryStatuses?.isLoading || messagesHistoryStatuses?.endReached) {
			return;
		}

		let liveSessionChatIds: string[] = [];

		if (subjectType === EnumMessageHistorySubject.LIVE_SESSION) {
			const chats = yield* select(selectLiveSessionChatsById, getLiveSessionIdForSelector(subjectId));

			if (chats?.liveChatIds) {
				liveSessionChatIds = chats.liveChatIds;
			}
		}

		yield* put(
			messagesClientToServerActions.requestMessagesHistory({
				subjectType,
				subjectId,
				searchAfter: messagesHistoryStatuses?.searchAfter,
				liveSessionChatIds,
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'requestMessagesHistorySaga'});
	}
}
