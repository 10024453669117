import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum EnumActions {
	ABUSEREPORT_RESET_ERROR = 'ABUSEREPORT_RESET_ERROR',
	ABUSEREPORT_SENT = 'ABUSEREPORT_SENT',
	ABUSEREPORT_SUCCESS = 'ABUSEREPORT_SUCCESS',
	ABUSEREPORT_FAILED = 'ABUSEREPORT_FAILED',
	ABUSEREPORT_SEND = 'ABUSEREPORT_SEND',
	ABUSEREPORT_SAVE = 'ABUSEREPORT_SAVE',
}

export type ISendAbuseReportPayload = {
	channelID: string;
	messageID?: string;
	reasons: string;
	details: string;
	notify: boolean;
};

export type TAbuseReportFormSuccessPayload = {
	notify: boolean;
};

export type TAbuseReportFormFailedPayload = {
	error?: string;
	notify: boolean;
};

class AbuseReportClientOnlyActions extends ClientOnlyActions<EnumStore.ABUSEREPORT> {
	readonly scope = EnumStore.ABUSEREPORT;

	resetError = this.createAction(EnumActions.ABUSEREPORT_RESET_ERROR);
	sent = this.createAction(EnumActions.ABUSEREPORT_SENT);
	send = this.createAction(EnumActions.ABUSEREPORT_SEND, this.getPrepareAction<ISendAbuseReportPayload>());
	success = this.createAction(EnumActions.ABUSEREPORT_SUCCESS, this.getPrepareAction<TAbuseReportFormSuccessPayload>());
	failed = this.createAction(EnumActions.ABUSEREPORT_FAILED, this.getPrepareAction<TAbuseReportFormFailedPayload>());
}

export const abuseReportClientOnlyActions = new AbuseReportClientOnlyActions();
