import _ from 'lodash';
import {MediaFile} from 'cmd-control-client-lib/dist/protocol/message';
import {UploadedMediaDirection} from 'cmd-control-client-lib';

import {createEntityAdapter} from '@messenger/core/src/Utils/Redux/createEntityAdapter';
import {descSortComparerByField} from '@messenger/core/src/Utils/descSortComparerByField';

export type TMedia = MediaFile & {direction?: UploadedMediaDirection};

export const mediaEntityAdapter = createEntityAdapter<TMedia>({
	selectId: (state) => _.toString(state.mediaMd5),
	sortComparer: (media1, media2) => descSortComparerByField(media1, media2, 'mediaCreated'),
});
