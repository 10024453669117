import React from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {Box} from '@mui/material';

import style from './MessageGroupTitle.module.scss';

const MessageGroupTitle = ({date}: TMessageGroupTitleProps) => {
	const {t} = useTranslation();
	const groupDate = moment(date);
	const today = moment().startOf('day');
	const yesterday = today.clone().subtract(1, 'day').startOf('day');
	const isToday = groupDate.isSame(today, 'day');
	const isYesterday = groupDate.isSame(yesterday, 'day');

	return (
		<Box className={style.container}>
			<span className={style.text}>
				{isToday ? t('date:today') : null}
				{isYesterday ? t('date:yesterday') : null}
				{!isToday && !isYesterday ? groupDate.format(t('momentFormat:dateWeek')) : null}
			</span>
		</Box>
	);
};

type TMessageGroupTitleProps = {
	date: string;
};

export default MessageGroupTitle;
