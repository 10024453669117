import {ACTION, EnumBooleanStringified} from 'cmd-control-client-lib';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';

class ChannelGroupsPurchaseAbilityClientToServerActions extends ClientToServerActions<EnumStore.CHANNEL_GROUPS_PURCHASE_ABILITY> {
	readonly scope = EnumStore.CHANNEL_GROUPS_PURCHASE_ABILITY;

	checkCanReceiveAttachment = this.createAction(
		ACTION.CMDP_SMSGESTIMATE,
		({mediaMd5, withMedia = false, groupIds, channelIds, ...restPayload}: TChannelGroupActionPayload) => ({
			payload: {
				mediaMd5: mediaMd5 ?? '',
				selectGroupId: _.join(groupIds, ','),
				channelId: _.join(channelIds, ','),
				withMedia,
				...restPayload,
			},
		}),
	);
}

type TSystemGroups = {
	selectActive?: EnumBooleanStringified;
	selectRegularCustomer?: EnumBooleanStringified;
	selectAdvertised?: EnumBooleanStringified;
	selectPinned?: EnumBooleanStringified;
};

export type TChannelGroupActionPayload = {
	channelIds: string[];
	groupIds?: string[];
	mediaMd5?: string;
	withMedia?: boolean;
} & TSystemGroups;

export const channelGroupsPurchaseAbilityClientToServerActions =
	new ChannelGroupsPurchaseAbilityClientToServerActions();
