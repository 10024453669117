import {getContext} from 'typed-redux-saga';

export enum EnumSagaContext {
	ROUTER = 'router',
}

export type TNavigateOptions = {replace?: boolean};

export type TSagaContext = {
	[EnumSagaContext.ROUTER]: {
		goToChannels: (options?: TNavigateOptions) => void;
		goToChannelMessages: (channelId: string, options?: TNavigateOptions) => void;
		goToChannelMessage: (channelId: string, options?: TNavigateOptions) => void;
		goToIntro: (isChannel?: boolean) => void;
		goToChatMessages: (chatId: string, options?: TNavigateOptions) => void;
		goToChatMessage: (chatId: string, options?: TNavigateOptions) => void;
		goHome: (options?: TNavigateOptions) => void;
		goUp: () => void;
		isChannelPage: (channelId: string) => boolean;
		isChatPage: (chatId: string) => boolean;
		isBulkMessagePage: () => boolean;
		goToBulkMessage: (options?: TNavigateOptions) => void;
	};
};

export const getNavigationContext = function* () {
	return yield* getContext<TSagaContext[EnumSagaContext.ROUTER]>(EnumSagaContext.ROUTER);
};
