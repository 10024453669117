import cuid from 'cuid';
import _ from 'lodash';
import {PayloadAction} from '@reduxjs/toolkit';
import {call, put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {
	EnumAbstractNotificationVariant,
	TAbstractNotificationOptions,
} from '@messenger/core/src/Services/UINotification';
import {
	abuseReportClientOnlyActions,
	ISendAbuseReportPayload,
} from '@messenger/core/src/Redux/AbuseReport/Actions/abuseReportClientOnlyActions';
import {selectSessionLogin} from '@messenger/core/src/Redux/Session/Selectors/selectSessionLogin';
import {selectSessionId} from '@messenger/core/src/Redux/Session/Selectors/selectSessionId';
import {selectFeedbackUploadUrl} from '@messenger/core/src/Redux/Feedback/Selectors/selectFeedbackUploadUrl';

export const sendAbuseReportSaga = function* (action: PayloadAction<ISendAbuseReportPayload>) {
	const getErrorText = (error: unknown) =>
		_.isError(error) ? error.message : ServiceFactory.i18n.t('abuse:something-went-wrong');

	try {
		const vControlApi = ServiceFactory.getVControlApi();
		const uploadFeedbackUrl = yield* select(selectFeedbackUploadUrl);

		yield* put(abuseReportClientOnlyActions.sent());

		const login = yield* select(selectSessionLogin);
		const sid = yield* select(selectSessionId);

		const report = new FormData();

		report.append('login', login);
		report.append('channelID', action.payload.channelID);
		report.append('sid', sid ?? '');

		if (action.payload.messageID) {
			report.append('messageID', ' ' + action.payload.messageID);
		}

		report.append('reasons', action.payload.reasons || '');
		report.append('details', action.payload.details || '');

		const {success, error} = yield* call([vControlApi, vControlApi.submitFeedbackForm], uploadFeedbackUrl, report);
		let notification: TAbstractNotificationOptions | undefined;

		const notificationKey = cuid();
		const event = success
			? abuseReportClientOnlyActions.success(action.payload)
			: abuseReportClientOnlyActions.failed({
					error: getErrorText(error),
					notify: action.payload.notify,
			  });

		yield* put(event);

		if (success) {
			notification = {
				text: ServiceFactory.i18n.t('abuse:sent'),
				variant: EnumAbstractNotificationVariant.SUCCESS,
				key: notificationKey,
			};
		} else {
			notification = {
				text: ServiceFactory.i18n.t('feedback:failed', {error: getErrorText(error)}),
				variant: EnumAbstractNotificationVariant.ERROR,
				key: notificationKey,
			};
		}

		if (action.payload.notify && !_.isUndefined(notification)) {
			yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], notification);
		}
	} catch (error) {
		if (action.payload.notify) {
			yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
				text: getErrorText(error),
				variant: EnumAbstractNotificationVariant.ERROR,
				key: cuid(),
			});
		}

		ServiceFactory.logService.error(error, {saga: 'sendAbuseReportSaga'});
	}
};
