import {IMediaWebRtc} from '@messenger/core/src/BusinessLogic/IMediaWebRtc';
import IWebRtcPreviewApi from '@messenger/core/src/Services/WebRtcPreviewApi/IWebRtcPreviewApi';

abstract class AbstractWebRtcPreviewChatApi {
	abstract createPreview(chatId: string, mediaWebRtc: IMediaWebRtc): void;

	abstract getPreview(chatId: string): IWebRtcPreviewApi;

	abstract removePreview(chatId: string): void;
}

export default AbstractWebRtcPreviewChatApi;
