import {PayloadAction} from '@reduxjs/toolkit';
import {call, delay, put, select} from 'typed-redux-saga';

import {EnumReservedChannelIds} from '@messenger/core/src/BusinessLogic/EnumReservedChannelIds';
import {channelInfosClientOnlyActions} from '@messenger/core/src/Redux/ChannelInfos/Actions/channelInfosClientOnlyActions';
import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions';
import {getChannelExists} from '@messenger/core/src/Redux/Channels/Selectors/getChannelExists';
import {channelsMapClientOnlyActions} from '@messenger/core/src/Redux/ChannelsMap/Actions/channelsMapClientOnlyActions';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {introductionClientOnlyActions} from '@messenger/core/src/Redux/Introduction/Action/IntroductionClientOnlyActions';
import EnumIntroductionStep from '@messenger/core/src/Redux/Introduction/EnumIntroductionStep';
import {getChannelsScrollDisabled, getIsIntroRunning} from '@messenger/core/src/Redux/Introduction/Selectors';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

import {getJohnDoeMessage} from 'src/Components/Introduction/john-doe-channel-messages';
import ChannelHelper, {JohnDoeChannelsMapId, JohnDoeChannelWeight} from 'src/Redux/Channels/ChannelHelper';

export const appendDataToStoreForStepSaga = function* (action: PayloadAction<EnumIntroductionStep>) {
	try {
		const isRunning = yield* select(getIsIntroRunning);

		if (!isRunning) {
			return;
		}

		const isScrollDisabled = yield* select(getChannelsScrollDisabled);

		switch (action.payload) {
			case EnumIntroductionStep.CHANNELS_LIST: {
				if (!isScrollDisabled) {
					yield* put(introductionClientOnlyActions.disableChannelsScroll());
				}

				const johnDoeChannel = ChannelHelper.getJohnDoeChannel();
				const channelExists = yield* select(getChannelExists, {channelId: johnDoeChannel.channelId});

				if (!channelExists) {
					// wait so that message appearance is visible to user
					yield* delay(600);

					// put channel & info data
					yield* put(channelsClientOnlyActions.append(johnDoeChannel));
					yield* put(channelInfosClientOnlyActions.upsertOne(ChannelHelper.getJohnDoeContactInfo()));
					yield* put(
						channelsMapClientOnlyActions.upsertMany([
							{
								id: JohnDoeChannelsMapId,
								weight: JohnDoeChannelWeight,
								channelId: johnDoeChannel.channelId,
							},
						]),
					);
					yield* put(introductionClientOnlyActions.notifyChannelRendered());

					yield* put(
						messagesClientOnlyActions.addIntroductionMessages([
							getJohnDoeMessage(0, ServiceFactory.i18n.t),
							getJohnDoeMessage(1, ServiceFactory.i18n.t),
							getJohnDoeMessage(2, ServiceFactory.i18n.t),
							getJohnDoeMessage(3, ServiceFactory.i18n.t),
						]),
					);
				}

				const guestIdentity = yield* select(selectCurrentGuestIdentity);

				if (guestIdentity?.channelId !== EnumReservedChannelIds.CHANNEL_ID_JOHN_DOE) {
					const {goToIntro} = yield* getNavigationContext();

					yield* call(goToIntro, true);
				}

				break;
			}

			case EnumIntroductionStep.MESSAGE_AREA: {
				break;
			}

			case EnumIntroductionStep.CHANNELS_ALL_CHATS:
			case EnumIntroductionStep.CHANNELS_ALL_MESSAGES:
			case EnumIntroductionStep.CHANNELS_JOHN_DOE:
			case EnumIntroductionStep.CHANNELS_FILTER:
				if (!isScrollDisabled) {
					yield* put(introductionClientOnlyActions.disableChannelsScroll());
				}

				break;

			default:
				if (isScrollDisabled) {
					yield* put(introductionClientOnlyActions.enableChannelsScroll());
				}

				break;
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'appendDataToStoreForStepSaga'});
	}
};
