import { ACTION, baseParamsType, ICOMMAND, IRESPONSE, RESULT, StringBasedType } from "../../cmd-protocol";

/**
 *  8320 only
 */
export class CMDP_SSTARTTESTVIDEOCHAT implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SSTARTTESTVIDEOCHAT;
  /** login params */
  public params: baseParamsType & {
    ualias?: string;
    tlimit?: string;
    lang?: string;
    userKey?: string;
    voyeur?: string;
    fsk?: string;
    canSingle?: string;
    canTip?: string;
    canCam2Cam?: string;
    tlimitNotify?: string;
    publicText?: string;
    guestAvatar?: string;
    modelAvatar?: string;
    formatsIncluded?: string;
    formatsExcluded?: string;
    formatsPriority?: string;
  };
}

/**
 *  response for CMDP_SSTARTTESTVIDEOCHAT
 */
export class CMDP_SSTARTTESTVIDEOCHAT_RESPONSE extends CMDP_SSTARTTESTVIDEOCHAT implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: {
    [key: string]: StringBasedType;
    clientID?: string;
    server?: string;
  };
}
