import {
	ACTION,
	CMDP_STARTMOBILEVIDEOCALL_RESPONSE,
	CMDP_UPDATEMOBILEVIDEOCALL_RESPONSE,
	VideoSdkConfig,
} from 'cmd-control-client-lib';
import _ from 'lodash';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class VideoCallServerToClientActions extends ServerToClientActions<EnumStore.VIDEO_CALL> {
	readonly scope = EnumStore.VIDEO_CALL;

	callStarted = this.createAction(
		ACTION.CMDP_STARTMOBILEVIDEOCALL,
		(
			payload: CMDP_STARTMOBILEVIDEOCALL_RESPONSE['values'],
			meta: CMDP_STARTMOBILEVIDEOCALL_RESPONSE,
			error?: string | boolean,
		) => {
			const videoSdkConfig = _.attempt(JSON.parse, payload.videoSdkConfig);

			return {
				payload: (_.isError(videoSdkConfig) ? {} : videoSdkConfig) as VideoSdkConfig,
				meta,
				error,
			};
		},
	);

	callUpdated = this.createAction(
		ACTION.CMDP_UPDATEMOBILEVIDEOCALL,
		this.getPrepareAction<CMDP_UPDATEMOBILEVIDEOCALL_RESPONSE['values'], CMDP_UPDATEMOBILEVIDEOCALL_RESPONSE>(),
	);
}

export const videoCallServerToClientActions = new VideoCallServerToClientActions();
