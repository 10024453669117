import { EnumBooleanStringified, JSONString } from "../../@types";
import { ICOMMAND, IKeyMaybeValue, IRESPONSE, RESULT } from "../command/icommand";
import { ACTION } from "../command/action";
import { baseParamsType } from "../command/baseparams";

export type MobileVideoCallScheduleType = {
  /*empty on add, obligatory on update  */
  scheduleId?: string;
  startMinute: string;
  stopMinute: string;
  /*use binary semantic, "1010000" = M+W on, other days off*/
  weekDays: string;
  /*default is true*/
  active?: EnumBooleanStringified;
  /*when update & deleted*/
  deleted?: EnumBooleanStringified;
};

export type MobileVideoCallScheduleTypeCMDC = Omit<MobileVideoCallScheduleType, "scheduleId"> & {
  scheduleId: string;
};

export class CMDC_MVCSCHEDULE implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_MVCSCHEDULE;
  public params: MobileVideoCallScheduleTypeCMDC;
}

export class CMDP_SGETMVCSCHEDULE implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SGETMVCSCHEDULE;
  public params: baseParamsType & {
    /*optional to get the one schedule*/
    scheduleId?: string;
  };
}
export class CMDP_SGETMVCSCHEDULE_RESPONSE extends CMDP_SGETMVCSCHEDULE implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_MVCSCHEDULE[];
  public values: IKeyMaybeValue;
}

export class CMDP_SUPSERTMVCSCHEDULE implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SUPSERTMVCSCHEDULE;
  public params: baseParamsType & MobileVideoCallScheduleType;
}
export class CMDP_SUPSERTMVCSCHEDULE_RESPONSE extends CMDP_SUPSERTMVCSCHEDULE implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_MVCSCHEDULE[];
  public values: IKeyMaybeValue;
}

export class CMDP_SDELETEMVCSCHEDULE implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SDELETEMVCSCHEDULE;
  public params: baseParamsType & { scheduleId: string };
}
export class CMDP_SDELETEMVCSCHEDULE_RESPONSE extends CMDP_SDELETEMVCSCHEDULE implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

export class CMDP_SUPSERTMVCSCHEDULES implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SUPSERTMVCSCHEDULES;
  public params: baseParamsType & {
    schedules: JSONString<MobileVideoCallScheduleType[]>;
  };
}
export class CMDP_SUPSERTMVCSCHEDULES_RESPONSE extends CMDP_SUPSERTMVCSCHEDULES implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_MVCSCHEDULE[];
  public values: IKeyMaybeValue;
}
