import 'reflect-metadata';
import axios from 'axios';
import {container, inject, registry, singleton} from 'tsyringe';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import AbstractSpeedTestApi from '@messenger/core/src/Services/SpeedTest/AbstractSpeedTestApi';
import DIToken from '@messenger/core/src/BusinessLogic/DIToken';

@registry([
	{
		token: DIToken.speedTestPayloadSize,
		useValue: ServiceFactory.env.getSpeedTestPayloadSize(),
	},
	{
		token: DIToken.speedTestAlphabet,
		useValue: ServiceFactory.env.getSpeedTestAlphabet(),
	},
])
@singleton()
class SpeedTestApi extends AbstractSpeedTestApi {
	constructor(
		@inject(DIToken.speedTestPayloadSize) public readonly payloadSize: number,
		@inject(DIToken.speedTestAlphabet) private readonly alphabet: string,
	) {
		super();
	}

	protected generatePayload(): string {
		let payload = '';

		for (let i = 0; i < this.payloadSize; i++) {
			payload += this.alphabet.charAt(Math.floor(Math.random() * this.alphabet.length));
		}

		return payload;
	}

	async sendRequest(speedTestUrl: string): Promise<boolean> {
		return axios.post(speedTestUrl, {data: this.generatePayload()}).then((response) => response.status === 200);
	}
}

container.register<AbstractSpeedTestApi>(DIToken.SpeedTestApi, {useToken: SpeedTestApi});

export default SpeedTestApi;
