import {call} from 'typed-redux-saga';

import {loginFormClientOnlyActions} from '@messenger/core/src/Redux/LoginForm/Actions/loginFormClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export const logSuccessLoginSaga = function* (_action: ReturnType<typeof loginFormClientOnlyActions.submitSuccess>) {
	try {
		yield* call([ServiceFactory.analyticsService, ServiceFactory.analyticsService.logSignIn], 'email');
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'logSuccessLoginSaga'});
	}
};
