import {ACTION, CMDP_SGETSEDCARDPICTURES_RESPONSE} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class SedCardPictureServerToClientActions extends ServerToClientActions<EnumStore.SED_CARD_PICTURE> {
	readonly scope = EnumStore.SED_CARD_PICTURE;

	getSedCardPictures = this.createAction(
		ACTION.CMDP_SGETSEDCARDPICTURES,
		this.getPrepareAction<CMDP_SGETSEDCARDPICTURES_RESPONSE['values'], CMDP_SGETSEDCARDPICTURES_RESPONSE>(),
	);
}

export const sedCardPictureServerToClientActions = new SedCardPictureServerToClientActions();
