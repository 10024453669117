import {call, put, race, select, take} from 'typed-redux-saga';
import _ from 'lodash';
import {AnyAction} from 'redux';
import {batchActions} from 'redux-batched-actions';

import {channelsMapClientOnlyActions} from '@messenger/core/src/Redux/ChannelsMap/Actions/channelsMapClientOnlyActions';
import {selectChannelsMapEntities} from '@messenger/core/src/Redux/ChannelsMap/Selectors/channelsMapDefaultSelectors';
import {selectChannelVmsListByFilterIds} from '@messenger/core/src/Redux/Channels/Selectors/selectChannelVmsListByFilterIds';
import {TChannelsMap} from '@messenger/core/src/Redux/ChannelsMap/types';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {
	getChannelIdForSelector,
	selectChannelVMById,
} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';
import {selectChannelsMeta} from '@messenger/core/src/Redux/ChannelsMap/Selectors/selectChannelsMeta';
import {selectChannelsMapTotal} from '@messenger/core/src/Redux/ChannelsMap/Selectors/selectChannelsMapTotal';
import {selectChannelsMapUpdateUuids} from '@messenger/core/src/Redux/ChannelsMap/Selectors/selectChannelsMapUpdateUuids';
import {selectCurrentRange} from '@messenger/core/src/Redux/ChannelsMap/Selectors/selectCurrentRange';

import {maybeModifyChannelMapSaga} from 'src/Redux/ChannelsMap/Sagas/maybeModifyChannelMapSaga';
import {modifyChannelsMapSaga} from 'src/Redux/ChannelsMap/Sagas/modifyChannelsMapSaga';

export const startChannelsMapUpdateSaga = function* ({
	payload: {channelIds, updateUuid},
}: ReturnType<typeof channelsMapClientOnlyActions.startChannelsMapUpdate>) {
	try {
		let channelsMapTotal = yield* select(selectChannelsMapTotal);

		if (!_.size(channelIds) || _.isUndefined(channelsMapTotal)) {
			yield* put(channelsMapClientOnlyActions.endChannelsMapUpdate({updateUuid}));

			return;
		}

		const updateUuids = yield* select(selectChannelsMapUpdateUuids);
		const uuidIndex = _.indexOf(updateUuids, updateUuid);

		if (uuidIndex > 0) {
			const [clearMapAction] = yield* race([
				take(channelsMapClientOnlyActions.clearChannelsMapUpdate),
				take(
					(action: AnyAction) =>
						_.get(action, 'type') === channelsMapClientOnlyActions.endChannelsMapUpdate.type &&
						_.get(action, 'payload.updateUuid') === updateUuids[uuidIndex - 1],
				),
			]);

			if (clearMapAction) {
				return;
			}
		}

		const filteredChannelIds = yield* select(selectChannelVmsListByFilterIds);
		const currentRange = yield* select(selectCurrentRange);
		const channelsMeta = {...(yield* select(selectChannelsMeta))};
		let channelsMap = (yield* select(selectChannelsMapEntities)) as Record<string, TChannelsMap>;
		let isChannelsMapUpdated = false;
		let isChannelsMetaUpdated = false;
		let refreshCurrentRange = false;

		for (let index = 0; index < channelIds.length; index++) {
			const channelId = channelIds[index];
			const {isFiltered: currentIsFiltered, weight: currentWeight} = channelsMeta[channelId] || {};
			const newIsFiltered = _.includes(filteredChannelIds, channelId);
			const newWeight = (yield* select(selectChannelVMById, getChannelIdForSelector(channelId)))?.weight;

			if (_.isUndefined(newWeight) || (currentIsFiltered === newIsFiltered && newWeight === currentWeight)) {
				continue;
			}

			const isChannelAdded = newIsFiltered && !currentIsFiltered;
			const isChannelRemoved = !newIsFiltered && currentIsFiltered;

			channelsMeta[channelId] = {
				weight: newWeight,
				isFiltered: newIsFiltered,
			};

			isChannelsMetaUpdated = true;

			if (refreshCurrentRange || (currentIsFiltered === newIsFiltered && !newIsFiltered)) {
				continue;
			}

			if (_.isUndefined(currentWeight)) {
				let updateResult;

				if (newIsFiltered) {
					updateResult = yield* call(
						maybeModifyChannelMapSaga,
						channelsMap,
						channelsMapTotal,
						channelId,
						newWeight,
						currentRange,
					);
				}

				if (!updateResult) {
					refreshCurrentRange = true;
					isChannelsMapUpdated = false;
				} else {
					isChannelsMapUpdated = true;
					channelsMapTotal = channelsMapTotal + _.size(updateResult) - _.size(channelsMap);
					channelsMap = updateResult;
				}
			} else {
				isChannelsMapUpdated = true;

				channelsMap = yield* call(
					modifyChannelsMapSaga,
					channelsMap,
					channelId,
					isChannelAdded ? undefined : currentWeight,
					isChannelRemoved ? undefined : newWeight,
				);

				channelsMapTotal = channelsMapTotal + Number(isChannelAdded) - Number(isChannelRemoved);
			}
		}

		const actions: AnyAction[] = [];

		if (isChannelsMetaUpdated) {
			actions.push(channelsMapClientOnlyActions.setChannelsMeta({channelsMeta: {...channelsMeta}}));
		}

		if (isChannelsMapUpdated) {
			actions.push(
				channelsMapClientOnlyActions.removeAll(),
				channelsMapClientOnlyActions.addMany(_.values(channelsMap)),
				channelsMapClientOnlyActions.setTotal({total: Math.max(0, channelsMapTotal)}),
			);
		}

		if (!_.isEmpty(actions)) {
			yield* put(batchActions(actions));
		}

		if (isChannelsMapUpdated) {
			yield* put(channelsMapClientOnlyActions.channelsMapSaved());
		}

		if (refreshCurrentRange) {
			yield* put(channelsMapClientOnlyActions.clearChannelsMapUpdate());
			yield* put(channelsMapClientOnlyActions.refreshCurrentRange());
		} else {
			yield* put(channelsMapClientOnlyActions.endChannelsMapUpdate({updateUuid}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'startChannelsMapUpdateSaga'});
	} finally {
		yield* put(channelsMapClientOnlyActions.endChannelsMapUpdate({updateUuid}));
	}
};
