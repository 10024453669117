import { EnumBooleanDigitized, EnumBooleanStringified, JSONString } from "../../@types";
import { ACTION, baseParamsType, chatIdType, CMDC_CMSG, EnumChannelFilterValues, ICOMMAND } from "../../cmd-protocol";
import { IKeyMaybeValue, IRESPONSE, RESULT } from "../command/icommand";

/**
 * Chat flags
 *   SELECT CHAT_FLAG_SHORT || ' = "' || CHAT_FLAG_SHORT || '",// ' || CHAT_FLAG_DESCRIPTION  FROM CHAT_FLAGS order by CHAT_FLAG_VAL
 */

export enum ChatFlags {
  VIDEO = "VIDEO", // video
  VOYEUR = "VOYEUR", // Voyeurchat
  CAM2CAM = "CAM2CAM", // Cam2Cam used
  SINGLE = "SINGLE", // Singlechat applied
  DISCOUNT = "DISCOUNT", // Discount granted
  TEXT = "TEXT", // Textchat Intention, set on chat_init
  FSK16 = "FSK16", // Chat is in FSK16 Mode
  VOYEUR2S = "VOYEUR2S", // Voyer Chat for Single Session
  RECORD = "RECORD", // Record VideoChat on Server
  FREE = "FREE", // FreeChat, SexOle feature.
  HASUPLPICS = "HASUPLPICS", // User has picture upload
  PREVIEW = "PREVIEW", // User has used livePreview
  FSKACK = "FSKACK", // Host Acknowledged FSK16
  VOYSHOWN = "VOYSHOWN", // Voyerchat converted to Normalchat
  PARTY = "PARTY", // PartyChat, SexOle feature.
  FREECHAT = "FREECHAT", // Videochat is free, except for singleprice
  TOY = "TOY", // TOY is used
  CONVERSION = "CONVERSION", // Conversion chat, user without charges, host payout 0.15 EUR/Min
  TESTCHAT = "TESTCHAT", // Testchat with a test account
  SECONDARY = "SECONDARY", // Set if there are already chats with this host or related sessions
  AUDIO = "AUDIO", // Audio was used (since vxsoft 8.6.0.0)
  CONVERTED = "CONVERTED", // Chat is converted to payed. SexOle feature.
  TIP = "TIP", // TIP or MSN GIFT is used
  GUESTCHAT = "GUESTCHAT", // Chat with Guest-Model on VXPages
  MOBILE = "MOBILE", // 30 cent fixed payout for sender
  FLATRATE = "FLATRATE", // Flatrate VXpages chat. Single is chargeable.
  SINGLEC2C = "SINGLEC2C", // Private show (web-rtc cam2cam) chat
}

export enum EnumIntentionString {
  TEXT = "TEXT",
  VOYEUR = "VOYEUR",
  VIDEO = "VIDEO",
  SINGLECAM2CAM = "SINGLECAM2CAM",
}

export class SChatInfo implements IKeyMaybeValue {
  [key: string]: string;
  /** the unique chatId*/
  public chatID: string;
  /** the chat type, can be changed. 15 contentpartner, 16 prepaid, 13 - dialerchat*/
  public type: string;
  /** chat flags coma separated. see enum ChatFlags*/
  public flags: string | keyof typeof ChatFlags;
  /** start timestamp */
  public startTime: string;
  /** stop timestamp */
  public stopTime?: string;
  /** the paid time */
  public paidTime?: string;
  /** exit code, see  */
  public exitCode?: string;
  /** admin chat */
  public admin?: EnumBooleanDigitized = EnumBooleanDigitized.FALSE;
  /** you can kick und ban this user */
  public canBan?: EnumBooleanDigitized = EnumBooleanDigitized.FALSE;
  /** you can give a discount to this user*/
  public canDiscount?: EnumBooleanDigitized = EnumBooleanDigitized.FALSE;
  /** single ability */
  public canSingle?: EnumBooleanDigitized;
  /** c2c ability */
  public canCam2Cam?: EnumBooleanDigitized;
  /** the category */
  public ccl_cat2?: string;
  /** messenger channel id */
  public channelId?: string;
  // eslint-disable-next-line tsdoc/syntax
  /** @deprecated */
  public fixedPayout?: string;
  /** the soft/hard status */
  public fsk?: "16" | "18";
  /** the discount */
  public hasDiscount?: string;
  /** info message for this chat */
  public infoMessage?: string;
  /** the chat is public, others users in ther public chats can see your messages */
  public isPublicText?: EnumBooleanDigitized = EnumBooleanDigitized.FALSE;
  /** the chat is a mass lobby show, no system messages or CMDC_CHAT_UPDATE will be arrived */
  public isLobby?: EnumBooleanDigitized = EnumBooleanDigitized.FALSE;
  /** stats about this user*/
  public jsonStats?: string;
  /** language of user */
  public lang?: string;
  /** the user name */
  public login?: string;
  /** the admin is shown */
  public showAdmin?: string;
  /** internal, the usr_id of user for type 13 chat */
  public userKey?: string;
  /** the related tiked id*/
  public ticketId?: string;
  public imgSrc?: string;
  /** is voyeur to single */
  public voyeur2single?: EnumBooleanDigitized = EnumBooleanDigitized.FALSE;
  /** the intention of chat type from guest. this is not actual chat type, only intention */
  public chattype: EnumIntentionString | string;
  /** preview state:  "1" if the preview is run, "0" if the preview is stopped */
  public previewRun?: "1" | "0" | string;
}

export class CMDC_CINIT implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_CINIT;
  public params: SChatInfo;
}

export type ChatOnlineStats = {
  /** the video time in seconds */
  videoTime?: string;
  /** the  video time as string 00:33:22 */
  videoTimeString?: string;
  /* number of lobby customer online */
  lobbyUsers?: string;
};

/*update state of chat with partner*/
export class CMDC_UPDATE_CHAT implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_UPDATE_CHAT;
  public params: SChatInfo & ChatOnlineStats;
}

export enum EndChatType {
  PREPARED = "PREPARED",
  LOBBY = "LOBBY", // = ticket show
  ADMIN = "ADMIN",
  SINGLEC2C = "SINGLEC2C",
  SINGLE = "SINGLE",
  LIVE = "LIVE",
  VOYEUR = "VOYEUR",
  VOYEUR2S = "VOYEUR2S",
  PREVIEW = "PREVIEW",
  PREVIEW_VIP = "PREVIEW_VIP",
  PREVIEW_SHORT = "PREVIEW_SHORT",
  PREVIEW_VOUCHER = "PREVIEW_VOUCHER",
  MOBILEVIDEOCALL = "MOBILEVIDEOCALL",
}

export class CMDC_CEXIT implements ICOMMAND {
  public action = ACTION.CMDC_CEXIT;
  public params: {
    [key: string]: string;
    chatID: string;
    exitCode: string;
    exitMessage?: string;
    endChatType?: EndChatType;
  };
}

export class CMDP_SGETCHATS implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SGETCHATS;
  // chatID can be coma delimited list
  public params: baseParamsType & { chatID?: string };
}

export class CMDP_SGETCHATS_RESPONSE extends CMDP_SGETCHATS implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_CINIT[];
  public values: IKeyMaybeValue;
}

export class CMDP_SGETCHATHISTORY implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SGETCHATHISTORY;
  // chatID can be coma delimited list
  public params: baseParamsType & chatIdType & { skipChannelMessages: EnumBooleanStringified };
}

export class CMDP_SGETCHATHISTORY_RESPONSE extends CMDP_SGETCHATHISTORY implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_CMSG[];
  public values: IKeyMaybeValue;
}

export class CMDP_SQUERY_DISCOUNT implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SQUERY_DISCOUNT;
  public params: baseParamsType & chatIdType;
}

export class CMDP_SQUERY_DISCOUNT_RESPONSE extends CMDP_SQUERY_DISCOUNT implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: {
    canDiscount?: EnumBooleanDigitized;
    reason?: string;
    hasDiscount: EnumBooleanDigitized;
    endDate?: string;
  };
}

export class CMDP_SET_DISCOUNT implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SET_DISCOUNT;
  public params: baseParamsType &
    chatIdType & {
      rate: string;
      time: string;
    };
}

export class CMDP_SET_DISCOUNT_RESPONSE extends CMDP_SET_DISCOUNT implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

export class CMDP_KICK implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_KICK;
  public params: baseParamsType & chatIdType;
}

export class CMDP_KICK_RESPONSE extends CMDP_KICK implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

export class CMDP_SGETCHATSSUMMARY implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SGETCHATSSUMMARY;
  public params: baseParamsType & { channelId: string };
}

export class CMDP_SGETCHATSSUMMARY_RESPONSE extends CMDP_SGETCHATSSUMMARY implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  // "summary": "{\"FIRST\":\"2013-11-20\",\"LAST\":\"2022-09-01\",\"CHATS\":1930}",
  public values: IKeyMaybeValue & { summary?: string } & {
    first?: string;
    last?: string;
    chats?: string;
  };
}

/**
 * query online time summary for last 3 month
 */
export class CMDP_GETONLINESUMMARY implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_GETONLINESUMMARY;
  public params: baseParamsType;
}

/**
 * Response for CMDP_GETONLINESUMMARY
 */

export class CMDP_GETONLINESUMMARY_RESPONSE extends CMDP_GETONLINESUMMARY implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: {
    // [{"2023-07-07":2726},{"2023-07-19":7663},{"2023-08-02":1479},{"2023-07-04":97},{"2023-07-11":4327},{"2023-07-18":1804},{"2023-07-27":3297}]"
    summary: JSONString<any> | string;
  };
}

export class CMDC_LIVESESSION implements ICOMMAND {
  public action: ACTION.CMDC_LIVESESSION;
  public params: {
    /** the Id on the session, not unique, but sesId + startTime is unique */
    sesID: string;
    /** start time */
    startTime: string;
    /** stop time */
    stopTime: string;
    /** live chats counter*/
    chats: string;
  };
}

export class CMDC_LIVECHAT implements ICOMMAND {
  public action: ACTION.CMDC_LIVECHAT;
  public params: {
    /** the unique chat id */
    chatID: string;
    /** the id of live session */
    sesID: string;
    /** start time of live chat, is between startTime and stopTime of live session */
    startTime: string;
    /** last timestamp of live chat*/
    stopTime: string;
    /** the exit code, 0 -if chat is live */
    exitCode: string;
    /** VIDEO, VOYEUR, SINGLE */
    type: string;
    /** username (user alias) */
    login?: string;
    /** channelId, if given */
    channelId?: string;
    /** endChatType*/
    endChatType?: EndChatType;
  };
}

/**
 * query online time history for last 2 month max
 */
export class CMDP_GETLIVEHISTORY implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_GETLIVEHISTORY;
  public params: baseParamsType & {
    /** json-encoded datetime, default now-6 month, will be adjusted if set to smaller than first known date */
    dateFrom?: string;
    /** json-encoded datetime, default now() +1 day */
    dateTo?: string;
    /** filter empty live sessions*/
    filterEmpty?: EnumChannelFilterValues;
    /** pagination */
    skip?: string;
    /** pagination */
    limit?: string;
  };
}

/**
 * Response for CMDP_GETLIVEHISTORY
 */
export class CMDP_GETLIVEHISTORY_RESPONSE extends CMDP_GETLIVEHISTORY implements IRESPONSE {
  public result: RESULT;
  /** array of CMDC_LIVESESSION[] + CMDC_LIVECHAT[] */
  public commands: ICOMMAND[];
  public values: {
    /** json-encoded datetime*/
    firstSession: string;
    /** json-encoded datetime*/
    lastSession: string;
    /** number of Sessions */
    totalSessions: string;
  };
}
