import React, {ComponentProps, ForwardedRef, forwardRef} from 'react';
import classNames from 'classnames';

import ActionIconButton from '@messenger/uikit/src/IconButton/ActionIconButton/ActionIconButton';
import {Icon, IconSize, IconType} from '@messenger/uikit/src/Icon';
import Button from '@messenger/uikit/src/Button/Button';
import Badge from '@messenger/uikit/src/Badge/Badge';

import style from './ScrollToIconButton.module.scss';

const ScrollToIconButton = forwardRef(
	({numberMessages, className, ...restProps}: TScrollToIconButtonProps, ref: ForwardedRef<HTMLButtonElement>) => (
		<Button
			{...restProps}
			ref={ref}
			className={classNames(className, style.button, {[style.isNumber]: numberMessages > 0})}
		>
			{numberMessages > 0 && (
				<Badge overlap="circular" variant="dot" className={style.badge}>
					<Icon type={IconType.msnEmpty} size={IconSize.medium} />
				</Badge>
			)}
			<Icon type={IconType.expandMore} size={IconSize.medium} />
		</Button>
	),
);

type TScrollToIconButtonProps = ComponentProps<typeof ActionIconButton> & {
	numberMessages: number;
};

export default ScrollToIconButton;
