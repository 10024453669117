import {put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';

export const processStreamingEndedSaga = function* () {
	try {
		yield* put(streamClientOnlyActions.setIsStreamStartedFromCurrentInstance(false));
		yield* put(mediaDeviceClientOnlyActions.releaseMediaDevicePermissions());
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processStreamingEndedSaga'});
	}
};
