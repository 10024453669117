import {call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {markChatAsPrivateSaga} from '@messenger/core/src/Redux/Session/Sagas/markChatAsPrivateSaga';
import {closeModalsOnSetTpChangesSaga} from '@messenger/core/src/Redux/Session/Sagas/closeModalsOnSetTpChangesSaga';
import {TSessionUpdatedAction} from '@messenger/core/src/Redux/Session/Actions';

import {processWebRtcStreamStartedSaga} from 'src/Redux/Session/Sagas/processWebRtcStreamStartedSaga';

export const processSessionUpdateSaga = function* (action: TSessionUpdatedAction) {
	try {
		yield* call(processWebRtcStreamStartedSaga, action);
		yield* call(markChatAsPrivateSaga, action);
		yield* call(closeModalsOnSetTpChangesSaga, action);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processSessionUpdateSaga', action});
	}
};
