import {createSlice} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {onlineStateAdapter} from '@messenger/core/src/Redux/OnlineState/entityAdapter';
import {onlineStateClientOnlyActions} from '@messenger/core/src/Redux/OnlineState/Actions/onlineStateClientOnlyActions';

export const onlineStateSlice = createSlice({
	name: EnumStore.ONLINE_STATE,
	initialState: onlineStateAdapter.getInitialState(),
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(onlineStateClientOnlyActions.upsertMany, onlineStateAdapter.upsertMany);
		builder.addCase(onlineStateClientOnlyActions.addMany, onlineStateAdapter.addMany);
	},
});
