import {takeEvery} from 'typed-redux-saga';

import {sipClientOnlyActions} from '@messenger/core/src/Redux/Sip/Actions/sipClientOnlyActions';

import {processCallToSaga} from './Sagas/processCallToSaga';
import {acceptCallSaga} from './Sagas/acceptCallSaga';
import {hangUpCallSaga} from './Sagas/hangUpCallSaga';
import {initSipSaga} from './Sagas/initSipSaga';
import {processChangeCallStateSaga} from './Sagas/processChangeCallStateSaga';
import {sendToneSaga} from './Sagas/sendToneSaga';

export const sipSagaWatcher = function* () {
	yield* takeEvery(sipClientOnlyActions.init, initSipSaga);
	yield* takeEvery(sipClientOnlyActions.callTo, processCallToSaga);
	yield* takeEvery(sipClientOnlyActions.accept, acceptCallSaga);
	yield* takeEvery(sipClientOnlyActions.hangUp, hangUpCallSaga);
	yield* takeEvery(sipClientOnlyActions.changeCallState, processChangeCallStateSaga);
	yield* takeEvery(sipClientOnlyActions.sendTone, sendToneSaga);
};
