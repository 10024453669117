import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux/createSelector';
import {selectChannelGroupVms} from '@messenger/core/src/Redux/ChannelGroups/Selectors/selectChannelGroupVms';
import {selectTargetCustomGroupIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetCustomGroupIds';

export const selectTargetChannelInGroupIds = createSelector(
	[selectTargetCustomGroupIds, selectChannelGroupVms],
	(targetCustomGroupIds, channelGroupVMs) => {
		return _.chain(channelGroupVMs)
			.filter((item) => !_.isUndefined(item) && targetCustomGroupIds.includes(item.groupId) && !item.isDeleted)
			.map('channelId')
			.value();
	},
);
