import {createSelector} from '@messenger/core/src/Utils/Redux';
import {TStartStream} from '@messenger/core/src/Redux/Stream/streamSlice';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectStartStreamState} from '@messenger/core/src/Redux/Stream/Selectors/selectStartStreamState';
import IBrowserCapabilities from '@messenger/core/src/Redux/Stream/IBrowserCapabilities';

export const selectBrowserCapabilities = createSelector(
	selectStartStreamState,
	(startStream: TStartStream): IBrowserCapabilities => ({
		...startStream.browserCapabilities,
		supportedConstraints: ServiceFactory.uiContainer.getSupportedConstraints(),
	}),
);
