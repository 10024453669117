import {call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';
import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';

export const removeStoredCredentialsSaga = function* () {
	try {
		const store = ServiceFactory.store;

		yield* call([store, store.remove], EnumLocalStorageKeys.LOGIN, {storeType: EnumStoreType.SESSION});
		yield* call([store, store.remove], EnumLocalStorageKeys.LOGIN, {storeType: EnumStoreType.LOCAL});
		yield* call([store, store.remove], EnumLocalStorageKeys.PASSWORD_HASH, {storeType: EnumStoreType.SESSION});
		yield* call([store, store.remove], EnumLocalStorageKeys.PASSWORD_HASH, {storeType: EnumStoreType.LOCAL});
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'removeStoredCredentialsSaga'});
	}
};
