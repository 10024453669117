import {ACTION, CMDP_GETONLINESUMMARY_RESPONSE} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class OnlineSummaryServerToClientActions extends ServerToClientActions<EnumStore.ONLINE_SUMMARY> {
	readonly scope = EnumStore.ONLINE_SUMMARY;

	getOnlineSummary = this.createAction(
		ACTION.CMDP_GETONLINESUMMARY,
		this.getPrepareAction<CMDP_GETONLINESUMMARY_RESPONSE['values'], CMDP_GETONLINESUMMARY_RESPONSE, false>(),
	);
}

export const onlineSummaryServerToClientActions = new OnlineSummaryServerToClientActions();
