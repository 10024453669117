import _ from 'lodash';
import 'reflect-metadata';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectGuestChannelVms} from '@messenger/core/src/Redux/Channels/Selectors/selectGuestChannelVms';
import {selectAllGroupsVms} from '@messenger/core/src/Redux/Groups/Selectors/selectAllGroupsVms';
import ChannelOrGroupVM from '@messenger/core/src/Redux/Channels/ChannelOrGroupVM';

export const selectGuestChannelAndGroupVms = createSelector(
	[selectGuestChannelVms, selectAllGroupsVms],
	(channelVMs, groupsVMs) => {
		return _.concat(
			_.map(channelVMs, (item) => new ChannelOrGroupVM(item)),
			_.map(groupsVMs, (item) => new ChannelOrGroupVM(undefined, item)),
		);
	},
);
