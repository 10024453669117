import {createSlice} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {onlineSummaryClientToServerActions} from '@messenger/core/src/Redux/OnlineSummary/Actions/onlineSummaryClientToServerActions';
import {onlineSummaryServerToClientActions} from '@messenger/core/src/Redux/OnlineSummary/Actions/onlineSummaryServerToClientActions';

export const onlineSummaryInitialState: IOnlineSummaryState = {
	summary: undefined,
	isLoading: false,
	isLoaded: false,
};

export const onlineSummarySlice = createSlice({
	name: EnumStore.ONLINE_SUMMARY,
	initialState: onlineSummaryInitialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(onlineSummaryClientToServerActions.getOnlineSummary, (state) => {
			state.isLoading = true;
		});
		builder.addCase(onlineSummaryServerToClientActions.getOnlineSummary, (state, {payload: {summary}}) => {
			state.summary = summary;
			state.isLoading = false;
			state.isLoaded = true;
		});
	},
});

export interface IOnlineSummaryState {
	summary?: string;
	isLoading: boolean;
	isLoaded: boolean;
}
