import {takeEvery} from 'typed-redux-saga';

import {feedbackClientOnlyActions} from '@messenger/core/src/Redux/Feedback/Actions/feedbackClientOnlyActions';
import {sendFeedbackSaga} from '@messenger/core/src/Redux/Feedback/Sagas/sendFeedbackSaga';

function* feedbackSagaWatcher() {
	yield* takeEvery(feedbackClientOnlyActions.send.type, sendFeedbackSaga);
}

export default feedbackSagaWatcher;
