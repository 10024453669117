import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {giftsAdapter} from '@messenger/core/src/Redux/Gifts/giftsAdapter';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import GiftVM from '@messenger/core/src/Redux/Gifts/GiftVM';

const {selectEntities} = giftsAdapter.getSelectors<IRootState>((state) => state[EnumStore.GIFTS]);

export const selectGiftEntityVMs = createSelector([selectEntities], (gifts) =>
	_.reduce(
		gifts,
		(result: Record<string, GiftVM>, gift, key) => {
			if (gift) {
				result[key] = new GiftVM(gift);
			}

			return result;
		},
		{},
	),
);
