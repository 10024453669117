import {createSelector} from '@messenger/core/src/Utils/Redux';
import {BULK, ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';

export const selectCurrentMessageInputId = createSelector([selectCurrentGuestIdentity], (guestIdentity): string => {
	switch (guestIdentity?.guestType) {
		case EnumGuestType.BULK:
			return BULK;

		case EnumGuestType.CHANNEL:
			return guestIdentity.channelId;

		case EnumGuestType.CHAT:
			return guestIdentity.channelId || guestIdentity.chatId;

		case EnumGuestType.ALL:
			return ALL_CHATS;

		default:
			return '';
	}
});
