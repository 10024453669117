import _ from 'lodash';
import {createSelectorCreator, defaultMemoize} from 'reselect';

import IRootState from '@messenger/core/src/Redux/IRootState';
import {TChannelsState} from '@messenger/core/src/Redux/Channels/initialState';
import {selectChannelsState} from '@messenger/core/src/Redux/Channels/Selectors/selectChannelsState';

const getTeamChannelIdSelectorCreator = createSelectorCreator(defaultMemoize, _.isEqual);

/**
 * @warning: to add dependency, you should customize comparator in createSelectorCreator
 */
export const selectTeamChannelId = getTeamChannelIdSelectorCreator<IRootState, TChannelsState, string | undefined>(
	[selectChannelsState],
	(channelsState) => channelsState.teamChannelId,
);
