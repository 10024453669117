export type TLoginFormState = {
	rememberMe: boolean;
	password: string;
	passwordHash: string;
	login: string;
	isLoggingIn: boolean;
	error: string;
	autoLogin: boolean;
	gtcUrl?: string;
};

export const defaultState: TLoginFormState = {
	login: '',
	password: '',
	passwordHash: '',
	autoLogin: false,
	rememberMe: false,
	isLoggingIn: false,
	error: '',
};
