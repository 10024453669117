import {EnumBooleanStringified} from 'cmd-control-client-lib';
import _ from 'lodash';

import {isTemporaryMessageId, messageAdapter} from '@messenger/core/src/Redux/Messages/entityAdapter';
import IRootState from '@messenger/core/src/Redux/IRootState';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {TDefaultSelectors} from '@messenger/core/src/Redux/TDefaultSelectors';
import {TSswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import {getMessageVMCached, getMessageVMDependencies} from '@messenger/core/src/Redux/Messages/getMessageVMCached';
import {selectGiftEntityVMs} from '@messenger/core/src/Redux/Gifts/Selectors/giftsDefaultSelectors';
import {selectMessageMediaUploads} from '@messenger/core/src/Redux/Messages/Selectors/selectMessageMediaUploads';
import {selectFailedToSentMessagesData} from '@messenger/core/src/Redux/Messages/Selectors/selectFailedToSentMessagesData';
import {selectAdminChats} from '@messenger/core/src/Redux/Chats/Selectors/selectAdminChats';
import {selectChannelEntityVMs} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';
import {selectRunningChatVms} from '@messenger/core/src/Redux/Chats/Selectors/selectRunningChatVms';
import {selectSentMessageKeys} from '@messenger/core/src/Redux/Messages/Selectors/selectSentMessageKeys';
import MessageHelper from '@messenger/core/src/BusinessLogic/MessagesDisplayFilters/MessageHelper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TMessageEntityId = string & {__messageEntityIdBrand: any};
export type TSent2AllReactions = Record<string, number | undefined>;
export type TSent2AllMessages = Record<
	string,
	{messageId: TMessageEntityId; reactions?: TSent2AllReactions; relatedMessageIds: TMessageEntityId[]}
>;

export const getMessageIdForSelector = (messageId?: string) => messageId as TMessageEntityId;

const {selectIds, selectEntities, selectAll, selectById} = messageAdapter.getSelectors<IRootState>(
	(state) => state[EnumStore.MESSAGES],
) as TDefaultSelectors<TSswMessageType, TMessageEntityId>;

export const selectMessageIds = selectIds;
export const selectMessageEntities = selectEntities;
export const selectTotalMessagesGroupedByChannelId = createSelector(selectEntities, (messages) =>
	_.countBy(messages, 'channelId'),
);

export const selectSent2AllMessages = createSelector(selectAll, (messages) =>
	_.chain(messages)
		.reduce((result: TSent2AllMessages, message) => {
			const {messageId, messageKey, reaction, canDelete, channelId} = message;

			if (messageId && messageKey && !isTemporaryMessageId(messageId) && !MessageHelper.isSystem(message)) {
				if (
					canDelete === EnumBooleanStringified.TRUE ||
					(channelId && !_.get(result, [messageKey, 'channelId'])) ||
					!_.get(result, [messageKey, 'messageId'])
				) {
					_.set(result, [messageKey, 'messageId'], messageId);
					_.set(result, [messageKey, 'channelId'], channelId);
				}

				_.set(
					result,
					[messageKey, 'relatedMessageIds'],
					[..._.get(result, [messageKey, 'relatedMessageIds'], []), messageId],
				);

				if (reaction) {
					const reactions = _.get(result, [messageKey, 'reactions']);

					_.set(result, [messageKey, 'reactions'], {...reactions, [reaction]: (_.get(reactions, reaction) || 0) + 1});
				}
			}

			return result;
		}, {})
		.pickBy(({relatedMessageIds}) => _.size(relatedMessageIds) > 1)
		.value(),
);

export const selectMessageVmById = createSelector(
	[
		selectById,
		selectGiftEntityVMs,
		selectMessageMediaUploads,
		selectFailedToSentMessagesData,
		selectChannelEntityVMs,
		selectSentMessageKeys,
		selectSent2AllMessages,
		selectRunningChatVms,
		selectAdminChats,
	],
	(
		message,
		giftEntities,
		mediaUploads,
		failedToSentMessagesData,
		channelVMs,
		sentMessageKeys,
		sent2AllMessages,
		runningChatVMs,
		adminChatVMs,
	) =>
		message
			? getMessageVMCached(
					message,
					getMessageVMDependencies(
						message,
						giftEntities,
						mediaUploads,
						failedToSentMessagesData,
						channelVMs,
						sentMessageKeys,
						sent2AllMessages,
						runningChatVMs,
						adminChatVMs,
					),
			  )
			: undefined,
);

export const selectMessageVms = createSelector(
	[
		selectAll,
		selectGiftEntityVMs,
		selectMessageMediaUploads,
		selectFailedToSentMessagesData,
		selectChannelEntityVMs,
		selectSentMessageKeys,
		selectSent2AllMessages,
		selectRunningChatVms,
		selectAdminChats,
	],
	(
		messages,
		giftEntities,
		mediaUploads,
		failedToSentMessagesData,
		channelVMs,
		sentMessageKeys,
		sent2AllMessages,
		runningChatVMs,
		adminChatVMs,
	) =>
		_.chain(messages)
			.map((message) =>
				getMessageVMCached(
					message,
					getMessageVMDependencies(
						message,
						giftEntities,
						mediaUploads,
						failedToSentMessagesData,
						channelVMs,
						sentMessageKeys,
						sent2AllMessages,
						runningChatVMs,
						adminChatVMs,
					),
				),
			)
			.compact()
			.value(),
);
