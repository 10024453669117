import {put, select} from 'typed-redux-saga';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectTargetCustomGroupIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetCustomGroupIds';
import {selectTargetChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetChannelIds';
import {selectTargetSystemGroupRecord} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetSystemGroupRecord';
import {channelGroupsPurchaseAbilityClientToServerActions} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Actions/channelGroupsPurchaseAbilityClientToServerActions';
import {channelGroupsPurchaseAbilityClientOnlyActions} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Actions/channelGroupsPurchaseAbilityClientOnlyActions';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {selectCurrentAttachmentVm} from '@messenger/core/src/Redux/Attachment/Selectors/selectCurrentAttachmentVm';

export const checkCanReceiveAttachmentSaga = function* () {
	try {
		const guestIdentity = yield* select(selectCurrentGuestIdentity);

		if (guestIdentity?.guestType !== EnumGuestType.BULK) {
			return;
		}

		const attachment = yield* select(selectCurrentAttachmentVm);
		const targetChannelIds = yield* select(selectTargetChannelIds);
		const targetCustomGroupIds = yield* select(selectTargetCustomGroupIds);
		const systemGroups = yield* select(selectTargetSystemGroupRecord);

		if (
			(!_.isEmpty(targetCustomGroupIds) || !_.isEmpty(targetChannelIds) || !_.isEmpty(systemGroups)) &&
			(!attachment || attachment.hasMedia)
		) {
			yield* put(
				channelGroupsPurchaseAbilityClientToServerActions.checkCanReceiveAttachment({
					groupIds: targetCustomGroupIds,
					channelIds: targetChannelIds,
					mediaMd5: attachment?.mediaMd5 || '',
					withMedia: !_.isEmpty(attachment),
					...systemGroups,
				}),
			);
		} else {
			yield* put(channelGroupsPurchaseAbilityClientOnlyActions.resetStore());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'checkCanReceiveAttachmentSaga'});
	}
};
