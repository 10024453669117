import {call} from 'typed-redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export function* closePreviewSocketSaga(action: PayloadAction<string>) {
	try {
		const api = ServiceFactory.jpegSocketPreviewApi;

		yield* call([api, api.removePreview], action.payload);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'closePreviewSocketSaga'});
	}
}
