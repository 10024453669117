import RequestFailedResponse from '@messenger/core/src/Services/Device/RequestFailedResponse';
import toysMock from '@messenger/core/src/__mocks__/Device/GetToys.json';
import IDeviceApi from '@messenger/core/src/Services/Device/IDeviceApi';

import DevicesListResponse from './DevicesListResponse';

const intensityMap = {
	min: 0,
	max: 20,
};

interface IVibrateResponse {
	toyId: string;
	intensity: number;
	success: boolean;
}

export default class DeviceMockApi implements IDeviceApi {
	async requestToysList(): Promise<DevicesListResponse | RequestFailedResponse> {
		return new Promise((resolve) => {
			setTimeout(() => resolve(new DevicesListResponse(toysMock.data)), 350);
		});
	}

	async sendVibrate(
		toyId: string,
		intensity: number = intensityMap.min,
	): Promise<IVibrateResponse | RequestFailedResponse> {
		// this is a mock, nothing to do here
		return new Promise((resolve) =>
			resolve({
				toyId,
				intensity,
				success: true,
			}),
		);
	}
}
