import {ACTION} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import SpeedTestResultAdapter from '@messenger/core/src/Services/SpeedTest/SpeedTestResultAdapter';

class SpeedTestClientToServerActions extends ClientToServerActions<EnumStore.SPEED_TEST> {
	readonly scope = EnumStore.SPEED_TEST;

	startTest = this.createAction(ACTION.CMDP_STARTBANDWIDTHTEST);

	stopTest = this.createAction(ACTION.CMDP_STOPBANDWIDTHTEST, (result: SpeedTestResultAdapter) => ({
		payload: result.toSocket(),
	}));
}

export const speedTestClientToServerActions = new SpeedTestClientToServerActions();
