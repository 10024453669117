import {createSlice, EntityState} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {
	channelMediaSummaryEntityAdapter,
	TChannelMediaSummary,
} from '@messenger/core/src/Redux/ChannelMediaSummary/entityAdapter';
import {channelMediaSummaryClientOnlyAction} from '@messenger/core/src/Redux/ChannelMediaSummary/Actions/channelMediaSummaryClientOnlyActions';
import {channelMediaSummaryServerToClientActions} from '@messenger/core/src/Redux/ChannelMediaSummary/Actions/channelMediaSummaryServerToClientActions';
import {channelMediaSummaryClientToServerActions} from '@messenger/core/src/Redux/ChannelMediaSummary/Actions/channelMediaSummaryClientToServerActions';

export const initialChannelMediaSummaryState: TChannelMediaSummaryState =
	channelMediaSummaryEntityAdapter.getInitialState({
		isLoading: false,
	});

export const channelMediaSummarySlice = createSlice({
	name: EnumStore.CHANNEL_MEDIA_SUMMARY,
	initialState: initialChannelMediaSummaryState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(channelMediaSummaryServerToClientActions.channelMediaSummaryReceived, (state, action) => {
			channelMediaSummaryEntityAdapter.upsertOne(state, action);
			state.isLoading = false;
		});
		builder.addCase(channelMediaSummaryClientOnlyAction.resetStore, () => initialChannelMediaSummaryState);
		builder.addCase(channelMediaSummaryClientToServerActions.getChannelMediaSummary, (state) => {
			state.isLoading = true;
		});
	},
});

export type TChannelMediaSummaryState = EntityState<TChannelMediaSummary> & {
	isLoading: boolean;
};
