import {PayloadAction} from '@reduxjs/toolkit';
import {call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import EnumNotifications from '@messenger/core/src/BusinessLogic/EnumNotifications';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';

export const notificationSaga = function* (action: PayloadAction<EnumNotifications>) {
	try {
		switch (action.payload) {
			case EnumNotifications.RECORD_AUDIO_IF_ONLINE:
				yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
					text: ServiceFactory.i18n.t('notification:record-audio-if-online'),
					variant: EnumAbstractNotificationVariant.WARNING,
					key: EnumSnackbarNotificationKeys.RECORD_AUDIO_IF_ONLINE,
				});
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'notificationSaga'});
	}
};
