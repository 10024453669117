/**
 * @link https=//device.com/developer/docs/lanlevel
 */
enum EnumResponseCode {
	OK = 200,
	NOT_STARTED = 500, // http server not started or disabled
	INVALID_COMMAND = 400, // Invalid Command
	TOY_NOT_FOUND = 401, // Toy Not Found
	TOY_NOT_CONNECTED = 402, // Toy Not Connected
	TOY_COMMAND_UNSUPPORTED = 403, // Toy Not Support This Command
	INVALID_PARAMETER = 404, // Invalid Parameter
	OTHER_ERROR = 505, // Other Errors
	SERVER_ERROR = 506, // Server Error. Please restart the Body Chat (whatever that is)
}

export default EnumResponseCode;
