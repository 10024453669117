import {put} from 'typed-redux-saga';

import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export function* updateMediaDeviceConstraintsSaga() {
	try {
		yield* put(
			mediaDeviceClientOnlyActions.requestMediaDevicePermissions({
				isMicrophoneRequired: true,
			}),
		);
	} catch (e) {
		ServiceFactory.logService.debug(e, {saga: 'updateMediaDeviceConstraintsSaga'});
	}
}
