import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {TMultilangTextValueType} from '@messenger/core/src/Redux/ModelSettings/ModelSettingsViewModel';

enum EnumClientActions {
	RESET_STORE = 'RESET_STORE',
	SAVE_HEATMAP_SETTINGS = 'SAVE_HEATMAP_SETTINGS',
	SAVE_VIDEO_CHAT_SETTINGS = 'SAVE_VIDEO_CHAT_SETTINGS',
}

class ModelSettingsClientOnlyActions extends ClientOnlyActions<EnumStore.MODEL_SETTINGS> {
	readonly scope = EnumStore.MODEL_SETTINGS;

	resetStore = this.createAction(EnumClientActions.RESET_STORE);
	saveHeatmapSettings = this.createAction(
		EnumClientActions.SAVE_HEATMAP_SETTINGS,
		this.getPrepareAction<THeatmapSettings>(),
	);
	saveVideoChatSettings = this.createAction(
		EnumClientActions.SAVE_VIDEO_CHAT_SETTINGS,
		this.getPrepareAction<TVideoChatSettings>(),
	);
}

export const modelSettingsClientOnlyActions = new ModelSettingsClientOnlyActions();

export type THeatmapSettings = {
	heatmapEnabled: boolean;
	heatmapText: TMultilangTextValueType;
};

export type TVideoChatSettings = {
	messengerPrice?: number;
	videoChatPrice?: number;
	privateChatPrice?: number;
	voyeurChatPrice?: number;
	singleC2CChatPrice?: number;
};
