import { EnumBooleanStringified } from "../../@types";
import { ICOMMAND, ACTION, IRESPONSE, RESULT, IKeyMaybeValue } from "../../cmd-protocol";
import { baseParamsType } from "../command/baseparams";

export enum EnumPushNotificationFilter {
  ALL = "all",
  REGULAR = "regular",
  NONE = "none",
}

export type UserSettings = {
  pushNotificationFilter?: EnumPushNotificationFilter;
  readyForVideoCall?: EnumBooleanStringified;
};

/**
 * set user setting
 */
export class CMDP_GETSETTINGS implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SETSETTINGS;
  public params: baseParamsType;
}

/**
 * set user setting response
 */
export class CMDP_GETSETTINGS_RESPONSE extends CMDP_GETSETTINGS implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue & UserSettings;
}

/**
 * set user setting
 */
export class CMDP_SETSETTINGS implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SETSETTINGS;
  public params: baseParamsType & UserSettings;
}

/**
 * set user setting response
 */
export class CMDP_SETSETTINGS_RESPONSE extends CMDP_SETSETTINGS implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}
