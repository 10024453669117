import _ from 'lodash';
import {select, call} from 'typed-redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';
import {selectRunningChatsPaginatedIds} from '@messenger/core/src/Redux/Chats/Selectors/selectRunningChatsPaginatedIds';
import {EnumKeyboardNavigate} from '@messenger/core/src/Redux/Client/EnumKeyboardNavigate';
import {selectRunningGroupChatIds} from '@messenger/core/src/Redux/Chats/Selectors/GroupChat/selectRunningGroupChatIds';
import {selectChannelVmsListByFilterSortedIds} from '@messenger/core/src/Redux/Channels/Selectors/selectChannelVmsListByFilterSortedIds';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

export const navigateChatsAndChannelsSaga = function* (action: PayloadAction<EnumKeyboardNavigate>) {
	try {
		const guestIdentity = yield* select(selectCurrentGuestIdentity);
		const channelIds: string[] = yield* select(selectChannelVmsListByFilterSortedIds);

		const runningChatIds: string[] = _.concat(
			yield* select(selectRunningGroupChatIds),
			yield* select(selectRunningChatsPaginatedIds),
		);
		const {goToChatMessages, goToChannelMessages} = yield* getNavigationContext();

		switch (action.payload) {
			case EnumKeyboardNavigate.DOWN: {
				if (!_.isEmpty(runningChatIds) && guestIdentity?.guestType !== EnumGuestType.CHANNEL) {
					// switch to next chat
					const nextChatId = _.get(runningChatIds, _.indexOf(runningChatIds, guestIdentity?.chatId) + 1);

					if (nextChatId) {
						yield* call(goToChatMessages, nextChatId);
						break;
					}
				}

				const nextChannelIndex = _.indexOf(channelIds, guestIdentity?.channelId) + 1;
				const nextChannelId = _.get(channelIds, nextChannelIndex);

				if (nextChannelId) {
					yield* call(goToChannelMessages, nextChannelId);
				} else {
					yield* call(goToChatMessages, ALL_CHATS);
				}

				break;
			}

			case EnumKeyboardNavigate.UP: {
				if (!_.isEmpty(runningChatIds)) {
					if (guestIdentity?.guestType === EnumGuestType.CHANNEL && guestIdentity.channelId === _.head(channelIds)) {
						const prevChatId = _.last(runningChatIds) as string;

						yield* call(goToChatMessages, prevChatId);

						break;
					}

					if (guestIdentity?.guestType === EnumGuestType.CHAT) {
						const prevChatId = _.get(runningChatIds, _.indexOf(runningChatIds, guestIdentity.chatId) - 1);

						if (prevChatId) {
							yield* call(goToChatMessages, prevChatId);
						} else {
							yield* call(goToChatMessages, ALL_CHATS);
						}

						break;
					}
				}

				const prevChannelIndex = _.indexOf(channelIds, guestIdentity?.channelId) - 1;
				const prevChannelId = _.get(channelIds, prevChannelIndex);

				if (prevChannelId === undefined && prevChannelIndex === -1) {
					const prevChatId = _.get(runningChatIds, _.indexOf(runningChatIds, guestIdentity?.chatId) - 1, ALL_CHATS);

					yield* call(goToChatMessages, prevChatId);

					break;
				}

				if (prevChannelId) {
					yield* call(goToChannelMessages, prevChannelId);
				} else {
					yield* call(goToChatMessages, ALL_CHATS);
				}

				break;
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'navigateChatsAndChannelsSaga'});
	}
};
