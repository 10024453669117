import {includes} from 'lodash';

import GiftVM from '@messenger/core/src/Redux/Gifts/GiftVM';
import {getMessengerPlaceholderByGift} from '@messenger/core/src/Utils/Gifts/getMessengerPlaceholderByGift';
import {getVChatPlaceholderByGift} from '@messenger/core/src/Utils/Gifts/getVChatPlaceholderByGift';

export const getMessageGiftPlaceholder = function (messageText: string, gift: GiftVM) {
	let placeholder = getMessengerPlaceholderByGift(gift);

	if (!includes(messageText, placeholder)) {
		placeholder = getVChatPlaceholderByGift(gift);
	}

	return placeholder;
};
