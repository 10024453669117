import {put, select, take} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectSessionVm} from '@messenger/core/src/Redux/Session/Selectors/selectSessionVm';
import {selectLoginFormState} from '@messenger/core/src/Redux/LoginForm/Selectors/selectLoginFormState';
import {
	sessionClientOnlyActions,
	sessionClientToServerActions,
	sessionServerToClientActions,
} from '@messenger/core/src/Redux/Session/Actions';
import {selectIsAutoLoginRequired} from '@messenger/core/src/Redux/Session/Selectors/selectIsAutoLoginRequired';
import {selectCanBeLoggedByForeignSid} from '@messenger/core/src/Redux/Session/Selectors/selectCanBeLoggedByForeignSid';
import {selectCanBeLoggedByCredentials} from '@messenger/core/src/Redux/Session/Selectors/selectCanBeLoggedByCredentials';

export const processAutoLoginSaga = function* () {
	try {
		const sessionVM = yield* select(selectSessionVm);
		const {login, passwordHash: password} = yield* select(selectLoginFormState);
		const isAutoLoginRequired = yield* select(selectIsAutoLoginRequired);
		const canBeLoggedByForeignSID = yield* select(selectCanBeLoggedByForeignSid);
		const canBeLoggedByCredentials = yield* select(selectCanBeLoggedByCredentials);
		const shouldPerformAutoLogin = isAutoLoginRequired && (canBeLoggedByForeignSID || canBeLoggedByCredentials);
		const foreignSID = sessionVM.foreignSID;

		if (shouldPerformAutoLogin) {
			yield* put(
				sessionClientToServerActions.logIn({
					credentials: foreignSID ? {foreignSID} : {login, password},
					language: sessionVM.language,
					autoLogin: true,
				}),
			);

			if (foreignSID) {
				yield* put(sessionClientOnlyActions.setForeignSID(undefined));
			}

			yield* take(sessionServerToClientActions.loggedIn);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processAutoLoginSaga'});
	}
};
