import _ from 'lodash';
import {put} from 'typed-redux-saga';

import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';

export const saveTeamChannelIdSaga = function* (action: ReturnType<typeof sessionServerToClientActions.loggedIn>) {
	try {
		if (!_.isUndefined(action.payload.supportChannelId)) {
			const teamChannelIdString = action.payload.supportChannelId.toString();

			yield* put(channelsClientOnlyActions.setTeamChannelId(teamChannelIdString));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'saveTeamChannelIdSaga'});
	}
};
