import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectCurrentGuestAttachmentId} from '@messenger/core/src/Redux/Attachment/Selectors/selectCurrentGuestAttachmentId';

import {_selectAttachmentEntities} from './attachmentDefaultSelectors';

export const selectCurrentAttachment = createSelector(
	[_selectAttachmentEntities, selectCurrentGuestAttachmentId],
	(attachmentEntities, attachmentId) => {
		return attachmentEntities[attachmentId];
	},
);
