import { IBACKENDPAYLOAD } from "./backend";
import { channelIdSelectOrFilter } from "./channel";
import { ACTION } from "./command/action";
import { baseParamsType } from "./command/baseparams";
import { ICOMMAND, IRESPONSE, RESULT, IKeyMaybeValue } from "./command/icommand";
import { EnumMediaType, MediaOffer, MessageParams } from "./message";

export enum UploadStep {
  earlyreject = "earlyreject",
  lateaccept = "lateaccept",
  informuploaded = "informuploaded",
  transcoded = "transcoded",
  transcodeerror = "transcodeError",
  error = "error",
}

/** internal use only, may be changed without announce*/
export type UploadServiceReadOnlyParams = {
  step?: UploadStep;
  fileformat?: EnumMediaType;

  md5?: string;
  transactionID?: string;

  origfilename?: string;

  imgSrc?: string;
  imgSrcSet?: string;
  height?: string;
  width?: string;

  audioMp3?: string;
  audioOgg?: string;
  audioM4a?: string;
  audioDuration?: string;

  videoPoster?: string;
  videoPosterPixelated?: string;
  videoHls?: string;
  videoHeight?: string;
  videoWidth?: string;
  videoDuration?: string;
};

/**
 * the upload command
 * usage POST to \{uploadMediaUrl\}?action=CMDP_SUPLOADMEDIA&sessionId=\{\}&channelId=\{\}.....
 */
export class CMDP_SUPLOADMEDIA implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SUPLOADMEDIA;
  public params: baseParamsType & channelIdSelectOrFilter & MessageParams & MediaOffer;
}

/**
 * Response for CMDP_SUPLOADMEDIA
 */
export class CMDP_SUPLOADMEDIA_RESPONSE extends CMDP_SUPLOADMEDIA implements IRESPONSE, IBACKENDPAYLOAD {
  public params: baseParamsType & channelIdSelectOrFilter & MessageParams & MediaOffer & UploadServiceReadOnlyParams;
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue & {
    /** channels skipped from upload if media already was purchased, and you try to make a new offer */
    skippedChannels?: string;
  };
  /** backendpayload */
  public payload?: unknown;
}
