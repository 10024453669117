import _ from 'lodash';
import {ACTION} from 'cmd-control-client-lib';
import {call} from 'typed-redux-saga';

import {chatsClientOnlyActions} from '@messenger/core/src/Redux/Chats/Actions';

import {deviceOrientationSaga} from 'src/Redux/MediaDevice/Sagas/deviceOrientationSaga';

export function* sendInitialStreamOrientationSaga(action: ReturnType<typeof chatsClientOnlyActions.upsertMany>) {
	const isINIT = _.some(action.meta.commands, {action: ACTION.CMDC_CINIT});

	if (isINIT) {
		yield* call(deviceOrientationSaga);
	}
}
