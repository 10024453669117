import {select, put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {ObjectStoreService} from '@messenger/core/src/Services/ObjectStore/ObjectStoreService';
import {messageInputClientOnlyActions} from '@messenger/core/src/Redux/MessageInput/Actions/messageInputClientOnlyActions';
import {selectCurrentMessageInputId} from '@messenger/core/src/Redux/MessageInput/Selectors/selectCurrentMessageInputId';

import {MESSAGE_INPUT_REF_ID} from 'src/Components/Chat/MessageArea/MessageInput/TextInput/TextInput';

export const saveCurrentCaretPositionSaga = function* () {
	try {
		const textAreaEl = ObjectStoreService.get<HTMLTextAreaElement>(MESSAGE_INPUT_REF_ID);
		const messageInputId = yield* select(selectCurrentMessageInputId);

		yield* put(
			messageInputClientOnlyActions.upsertOne({id: messageInputId, caretPosition: textAreaEl?.selectionStart}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'saveCurrentCaretPositionSaga'});
	}
};
