import {createAction, Reducer} from '@reduxjs/toolkit';
import {PayloadAction} from '@reduxjs/toolkit/src/createAction';
import _ from 'lodash';
import {combineReducers} from 'redux';
import {enableBatching} from 'redux-batched-actions';

import {speedTestSlice} from '@messenger/core/src/Redux/SpeedTest/speedTestSlice';
import {streamPreviewSlice} from '@messenger/core/src/Redux/StreamPreview/streamPreviewSlice';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {abuseReportSlice} from '@messenger/core/src/Redux/AbuseReport/abuseReportSlice';
import {attachmentSlice} from '@messenger/core/src/Redux/Attachment/attachmentSlice';
import {cam2CamPreviewSlice} from '@messenger/core/src/Redux/Cam2CamPreview/cam2CamPreviewSlice';
import {channelGroupsSlice} from '@messenger/core/src/Redux/ChannelGroups/channelGroupsSlice';
import {channelGroupsPurchaseAbilitySlice} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/channelGroupsPurchaseAbilitySlice';
import {channelInfoSlice} from '@messenger/core/src/Redux/ChannelInfos/channelInfoSlice';
import {channelMediaSummarySlice} from '@messenger/core/src/Redux/ChannelMediaSummary/channelMediaSummarySlice';
import {initialState as initialChannelsState} from '@messenger/core/src/Redux/Channels/initialState';
import {channelsSlice} from '@messenger/core/src/Redux/Channels/channelsSlice';
import {channelsMapSlice} from '@messenger/core/src/Redux/ChannelsMap/channelsMapSlice';
import {chatsSlice} from '@messenger/core/src/Redux/Chats/chatsSlice';
import {chatSummarySlice} from '@messenger/core/src/Redux/ChatSummary/chatSummarySlice';
import {clientSlice} from '@messenger/core/src/Redux/Client/clientSlice';
import {contactNoteSlice} from '@messenger/core/src/Redux/ContactNote/contactNoteSlice';
import {sessionSlice, defaultState as initialSessionSlice} from '@messenger/core/src/Redux/Session/sessionSlice';
import {messageInputSlice} from '@messenger/core/src/Redux/MessageInput/messageInputSlice';
import {onlineSummarySlice} from '@messenger/core/src/Redux/OnlineSummary/onlineSummarySlice';
import {currencySlice} from '@messenger/core/src/Redux/Currency/currencySlice';
import {featureShowcaseSlice} from '@messenger/core/src/Redux/FeatureShowcase/featureShowcaseSlice';
import {feedbackSlice} from '@messenger/core/src/Redux/Feedback/feedbackSlice';
import {giftsSlice} from '@messenger/core/src/Redux/Gifts/giftsSlice';
import {groupsSlice} from '@messenger/core/src/Redux/Groups/groupsSlice';
import {introductionSlice} from '@messenger/core/src/Redux/Introduction/introductionSlice';
import {liveChatsSlice} from '@messenger/core/src/Redux/LiveChats/liveChatsSlice';
import {liveSessionChatsSlice} from '@messenger/core/src/Redux/LiveSessionChats/liveSessionChatsSlice';
import {liveSessionsSlice} from '@messenger/core/src/Redux/LiveSessions/liveSessionsSlice';
import {loginFormSlice} from '@messenger/core/src/Redux/LoginForm/loginFormSlice';
import {mediaSlice} from '@messenger/core/src/Redux/Media/mediaSlice';
import {mediaDeviceSlice} from '@messenger/core/src/Redux/MediaDevice/mediaDeviceSlice';
import {mediaLinksSlice} from '@messenger/core/src/Redux/MediaLinks/mediaLinksSlice';
import {mediaPricesSlice} from '@messenger/core/src/Redux/MediaPrice/mediaPricesSlice';
import {mediaUploadProgressSlice} from '@messenger/core/src/Redux/MediaUploadProgress/mediaUploadProgressSlice';
import {isTemporaryMessageId, messageAdapter} from '@messenger/core/src/Redux/Messages/entityAdapter';
import {messagesSlice, initialState} from '@messenger/core/src/Redux/Messages/messagesSlice';
import {modelSettingsSlice} from '@messenger/core/src/Redux/ModelSettings/modelSettingsSlice';
import {notificationsSlice} from '@messenger/core/src/Redux/Notifications/notificationsSlice';
import {onlineStateSlice} from '@messenger/core/src/Redux/OnlineState/onlineStateSlice';
import {pushNotificationsSlice} from '@messenger/core/src/Redux/PushNotifications/pushNotificationsSlice';
import {queryUserSlice} from '@messenger/core/src/Redux/QueryUser/queryUserSlice';
import {reactionsSlice} from '@messenger/core/src/Redux/Reactions/reactionsSlice';
import {sedCardPictureSlice} from '@messenger/core/src/Redux/SedCardPicture/sedCardPictureSlice';
import {sipSlice} from '@messenger/core/src/Redux/Sip/sipSlice';
import {streamSlice} from '@messenger/core/src/Redux/Stream/streamSlice';
import {testVideoChatSlice} from '@messenger/core/src/Redux/TestVideoChat/testVideoChatSlice';
import {ticketShowSlice} from '@messenger/core/src/Redux/TicketShow/ticketShowSlice';
import {toySlice} from '@messenger/core/src/Redux/Toy/toySlice';
import {videoCallSlice} from '@messenger/core/src/Redux/VideoCall/videoCallSlice';
import {webNotificationSlice} from '@messenger/core/src/Redux/WebNotification/webNotificationSlice';

const reducers = {
	[EnumStore.ABUSEREPORT]: abuseReportSlice.reducer,
	[EnumStore.ATTACHMENT]: attachmentSlice.reducer,
	[EnumStore.CAM_2_CAM_PREVIEW]: cam2CamPreviewSlice.reducer,
	[EnumStore.CHATS]: chatsSlice.reducer,
	[EnumStore.CHAT_SUMMARY]: chatSummarySlice.reducer,
	[EnumStore.CHANNELS]: channelsSlice.reducer,
	[EnumStore.MESSAGE_INPUT]: messageInputSlice.reducer,
	[EnumStore.CHANNEL_INFOS]: channelInfoSlice.reducer,
	[EnumStore.CHANNEL_MEDIA_SUMMARY]: channelMediaSummarySlice.reducer,
	[EnumStore.CHANNEL_GROUPS]: channelGroupsSlice.reducer,
	[EnumStore.CLIENT]: clientSlice.reducer,
	[EnumStore.CONTACT_NOTE]: contactNoteSlice.reducer,
	[EnumStore.CURRENCY]: currencySlice.reducer,
	[EnumStore.FEATURE_SHOWCASE]: featureShowcaseSlice.reducer,
	[EnumStore.FEEDBACK]: feedbackSlice.reducer,
	[EnumStore.INTRODUCTION]: introductionSlice.reducer,
	[EnumStore.LOGIN_FORM]: loginFormSlice.reducer,
	[EnumStore.GIFTS]: giftsSlice.reducer,
	[EnumStore.SESSION]: sessionSlice.reducer,
	[EnumStore.STREAM]: streamSlice.reducer,
	[EnumStore.LIVE_CHATS]: liveChatsSlice.reducer,
	[EnumStore.LIVE_SESSIONS]: liveSessionsSlice.reducer,
	[EnumStore.LIVE_SESSION_CHATS]: liveSessionChatsSlice.reducer,
	[EnumStore.MEDIA]: mediaSlice.reducer,
	[EnumStore.MEDIA_LINKS]: mediaLinksSlice.reducer,
	[EnumStore.MEDIA_DEVICE]: mediaDeviceSlice.reducer,
	[EnumStore.MEDIA_PRICES]: mediaPricesSlice.reducer,
	[EnumStore.MESSAGES]: messagesSlice.reducer,
	[EnumStore.MODEL_SETTINGS]: modelSettingsSlice.reducer,
	[EnumStore.NOTIFICATIONS]: notificationsSlice.reducer, // notistack part of the store
	[EnumStore.ONLINE_STATE]: onlineStateSlice.reducer,
	[EnumStore.ONLINE_SUMMARY]: onlineSummarySlice.reducer,
	[EnumStore.PUSH_NOTIFICATIONS]: pushNotificationsSlice.reducer,
	[EnumStore.REACTIONS]: reactionsSlice.reducer,
	[EnumStore.QUERY_USER]: queryUserSlice.reducer,
	[EnumStore.STREAM_PREVIEW]: streamPreviewSlice.reducer,
	[EnumStore.SPEED_TEST]: speedTestSlice.reducer,
	[EnumStore.GROUPS]: groupsSlice.reducer,
	[EnumStore.TEST_VIDEO_CHAT]: testVideoChatSlice.reducer,
	[EnumStore.TICKET_SHOW]: ticketShowSlice.reducer,
	[EnumStore.TOY]: toySlice.reducer,
	[EnumStore.WEB_NOTIFICATION]: webNotificationSlice.reducer,
	[EnumStore.SED_CARD_PICTURE]: sedCardPictureSlice.reducer,
	[EnumStore.CHANNEL_GROUPS_PURCHASE_ABILITY]: channelGroupsPurchaseAbilitySlice.reducer,
	[EnumStore.SIP]: sipSlice.reducer,
	[EnumStore.VIDEO_CALL]: videoCallSlice.reducer,
	[EnumStore.CHANNELS_MAP]: channelsMapSlice.reducer,
	[EnumStore.MEDIA_UPLOAD_PROGRESS]: mediaUploadProgressSlice.reducer,
};

export const resetAllStores = createAction<{keepLocalStores: boolean} | undefined>('app/reset_all_stores');

const combinedReducers = combineReducers(reducers);

const rootReducer: Reducer<ReturnType<typeof combinedReducers>> = (state, action) => {
	const newState = combinedReducers(action.type === resetAllStores.type ? undefined : state, action);

	if (action.type === resetAllStores.type && !_.isUndefined(state)) {
		newState[EnumStore.CURRENCY] = state[EnumStore.CURRENCY];
		newState[EnumStore.GIFTS] = state[EnumStore.GIFTS];
		newState[EnumStore.CLIENT] = state[EnumStore.CLIENT];
		newState[EnumStore.VIDEO_CALL] = state[EnumStore.VIDEO_CALL];

		const resetStoreAction = action as PayloadAction<{keepLocalStores: boolean} | undefined>;

		if (resetStoreAction.payload?.keepLocalStores) {
			newState[EnumStore.CHANNELS] = {
				...initialChannelsState,
				..._.pick(state[EnumStore.CHANNELS], 'filter', 'targetIds', 'targetGroupIds', 'isTargetsSelectEnabled'),
			};
			newState[EnumStore.ATTACHMENT] = state[EnumStore.ATTACHMENT];
			newState[EnumStore.MESSAGE_INPUT] = state[EnumStore.MESSAGE_INPUT];
			newState[EnumStore.FEEDBACK] = state[EnumStore.FEEDBACK];
			newState[EnumStore.LOGIN_FORM] = state[EnumStore.LOGIN_FORM];
			newState[EnumStore.INTRODUCTION] = state[EnumStore.INTRODUCTION];
			newState[EnumStore.SESSION] = {...initialSessionSlice, foreignSID: state[EnumStore.SESSION].foreignSID};

			const tmpOldMessageState = state[EnumStore.MESSAGES];

			const newMessageState = messageAdapter.removeMany(
				tmpOldMessageState,
				_.filter(tmpOldMessageState.ids, (item) => !isTemporaryMessageId(item.toString())),
			);

			//need to save mediaUploads too, if uploading temporary message with media in progress
			newState[EnumStore.MESSAGES] = {
				...initialState,
				ids: newMessageState.ids,
				entities: newMessageState.entities,
				mediaUploads: tmpOldMessageState.mediaUploads,
			};
		}
	}

	return newState;
};

export default enableBatching(rootReducer);
