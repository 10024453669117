import {eventChannel} from 'redux-saga';

import AbstractUiContainer from '@messenger/core/src/Services/AbstractUiContainer';

export const windowEventActionCreator = (eventName: keyof WindowEventMap, browser: AbstractUiContainer) =>
	eventChannel((emitter) => {
		const eventHandler = (event: Event) => {
			emitter(event);
		};

		browser.addEventListener(eventName, eventHandler);

		return () => {
			browser.removeEventListener(eventName, eventHandler);
		};
	});
