import {SnackbarMessage} from 'notistack';

import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';
import {EnumNotificationStatus} from '@messenger/core/src/Redux/Notifications/EnumNotificationStatus';
import {TAbstractNotificationOptions} from '@messenger/core/src/Services/UINotification';
import {createEntityAdapter} from '@messenger/core/src/Utils/Redux/createEntityAdapter';

export const notificationsAdapter = createEntityAdapter<TNotification>({
	selectId: (notification) => notification.key,
});

export type TNotification = {
	key: string | EnumSnackbarNotificationKeys;
	notificationTitle: string | undefined;
	message: SnackbarMessage;
	options: TAbstractNotificationOptions;
	status: EnumNotificationStatus;
	isOnlyMobile: boolean | undefined;
	isPositionBottom: boolean | undefined;
	isOnlyDesktop?: boolean;
};
