import {ACTION} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import ICam2CamConnectionConfig from '@messenger/core/src/Redux/Cam2CamPreview/Model';

/**
 * @WARNING
 * After adding new Server Action add it to "serverToClientActionsMap" object
 * @see serverToClientActionsMap
 */
class Cam2camPreviewServerToClientActions extends ServerToClientActions<EnumStore.CAM_2_CAM_PREVIEW> {
	readonly scope = EnumStore.CAM_2_CAM_PREVIEW;

	guestStartsCam2Cam = this.createAction(
		ACTION.CMDC_CSTARTVIDEOPULL,
		this.getPrepareAction<ICam2CamConnectionConfig>(),
	);

	guestStopsCam2Cam = this.createAction(ACTION.CMDC_CSTOPVIDEOPULL, (payload: ICam2CamConnectionConfig) => ({
		payload: payload.chatID,
	}));
}

export const cam2camPreviewServerToClientActions = new Cam2camPreviewServerToClientActions();
