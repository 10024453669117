import {
	ACTION,
	CMDP_SADDCHANNELTOGROUP_RESPONSE,
	CMDP_SDELCHANNELFROMGROUP_RESPONSE,
	CMDP_SGETCHANNELGROUPS_RESPONSE,
} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class ChannelGroupsServerToClientActions extends ServerToClientActions<EnumStore.CHANNEL_GROUPS> {
	readonly scope = EnumStore.CHANNEL_GROUPS;

	channelGroupsReceivedMany = this.createAction(
		ACTION.CMDP_SGETCHANNELGROUPS,
		(
			payload: CMDP_SGETCHANNELGROUPS_RESPONSE['values'],
			meta: CMDP_SGETCHANNELGROUPS_RESPONSE,
			error?: string | boolean,
		) => ({
			payload,
			meta,
			error,
		}),
	);

	removedChannelsFromGroup = this.createAction(
		ACTION.CMDP_SDELCHANNELFROMGROUP,
		(
			payload: CMDP_SDELCHANNELFROMGROUP_RESPONSE['values'],
			meta: CMDP_SDELCHANNELFROMGROUP_RESPONSE,
			error?: string | boolean,
		) => ({
			payload,
			meta,
			error,
		}),
	);

	addedChannelsToGroup = this.createAction(
		ACTION.CMDP_SADDCHANNELTOGROUP,
		(
			payload: CMDP_SADDCHANNELTOGROUP_RESPONSE['values'],
			meta: CMDP_SADDCHANNELTOGROUP_RESPONSE,
			error?: string | boolean,
		) => ({
			payload,
			meta,
			error,
		}),
	);
}

export const channelGroupsServerToClientActions = new ChannelGroupsServerToClientActions();
