import _ from 'lodash';

import {selectChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';
import {createSelector} from '@messenger/core/src/Utils/Redux/createSelector';

import {getPropsChannelId} from './getPropsChannelId';

export const getChannelExists = createSelector([selectChannelIds, getPropsChannelId], (channelIds, channelId) =>
	_.includes(channelIds, channelId),
);
