import React, {ReactNode, Ref} from 'react';
import {Alert, IconButton} from '@mui/material';

import DialogWindowContent from '@messenger/uikit/src/Dialog/DialogWindowsContent';
import {Icon, IconType} from '@messenger/uikit/src/Icon';

import style from './DialogPopUp.module.scss';

const DialogPopUp = React.forwardRef(
	(
		{
			title,
			body,
			btn0Text,
			btn1Text,
			onClose,
			onBtn0Click,
			onBtn1Click,
			handleTimeout,
			dataTestId,
			btn1DataTestId,
			btn0DataTestId,
			timer,
			children,
			isBtn0Disabled = false,
			isBtn1Disabled = false,
		}: TDialogWindowProps,
		ref: Ref<any>,
	) => {
		return (
			<Alert
				elevation={6}
				ref={ref}
				icon={false}
				data-test-id={dataTestId}
				className={style.alertType}
				action={
					<IconButton className={style.closeButton} aria-label="close" onClick={onClose} size="large">
						<Icon type={IconType.close} />
					</IconButton>
				}
			>
				<DialogWindowContent
					btn1Text={btn1Text}
					onBtn1Click={onBtn1Click}
					title={title}
					body={body}
					btn0Text={btn0Text}
					onBtn0Click={onBtn0Click}
					timer={timer}
					isBtn0Disabled={isBtn0Disabled}
					isBtn1Disabled={isBtn1Disabled}
					isAlert={true}
					btn0DataTestId={btn0DataTestId}
					btn1DataTestId={btn1DataTestId}
					handleTimeout={handleTimeout}
				>
					{children}
				</DialogWindowContent>
			</Alert>
		);
	},
);

type TDialogWindowProps = {
	dataTestId?: string;
	btn1DataTestId?: string;
	btn0DataTestId?: string;
	timer?: number;
	title?: string;
	body?: string;
	btn0Text?: string;
	btn1Text?: string;
	children?: ReactNode;
	isBtn1Disabled?: boolean;
	isBtn0Disabled?: boolean;
	onClose?: () => void;
	onBtn0Click?: () => void;
	onBtn1Click?: () => void;
	handleTimeout?: () => void;
};

DialogPopUp.displayName = 'DialogWindow';

export default DialogPopUp;
