import {put, select, call} from 'typed-redux-saga';

import {selectIsLoggedIn} from '@messenger/core/src/Redux/Session/Selectors/selectIsLoggedIn';
import {sessionClientToServerActions} from '@messenger/core/src/Redux/Session/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';

export const changeLanguageOnServerSaga = function* ({
	payload,
}: ReturnType<typeof clientClientOnlyActions.changeLanguage>) {
	try {
		const isLoggedIn = yield* select(selectIsLoggedIn);

		if (!isLoggedIn) {
			yield* call([ServiceFactory.i18n, ServiceFactory.i18n.changeLanguage], payload);
		}

		yield* put(sessionClientToServerActions.updateLanguage(payload));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'changeLanguageOnServerSaga'});
	}
};
