import {ChannelInfoType} from 'cmd-control-client-lib';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {channelInfoEntityAdapter} from '@messenger/core/src/Redux/ChannelInfos/channelInfoEntityAdapter';
import {TChannelEntityId} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {TDefaultSelectors} from '@messenger/core/src/Redux/TDefaultSelectors';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import {getChannelInfoVmCached} from '@messenger/core/src/Redux/ChannelInfos/getChannelInfoVmCached';

const {selectIds, selectEntities, selectById} = channelInfoEntityAdapter.getSelectors<IRootState>(
	(state) => state[EnumStore.CHANNEL_INFOS],
) as TDefaultSelectors<ChannelInfoType, TChannelEntityId>;

export const selectChannelInfoIds = selectIds;

export const selectChannelInfoEntityVms = createSelector([selectEntities], (entities) =>
	_.mapValues(entities, (entity) => getChannelInfoVmCached(entity)),
);

export const selectChannelInfoVmById = createSelector([selectById], getChannelInfoVmCached);
