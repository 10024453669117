import {takeEvery} from 'typed-redux-saga';

import {testVideoChatClientOnlyActions} from '@messenger/core/src/Redux/TestVideoChat/Actions/testVideoChatClientOnlyActions';

import {startVideoChatTestSaga} from 'src/Redux/VideoChatTest/Sagas/startVideoChatTestSaga';

function* videoChatTestSagaWatcher() {
	yield* takeEvery(testVideoChatClientOnlyActions.open.type, startVideoChatTestSaga);
}

export default videoChatTestSagaWatcher;
