import _ from 'lodash';
import {PayloadAction} from '@reduxjs/toolkit';
import {batchActions} from 'redux-batched-actions';
import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {introductionClientOnlyActions} from '@messenger/core/src/Redux/Introduction/Action/IntroductionClientOnlyActions';
import EnumIntroductionStep from '@messenger/core/src/Redux/Introduction/EnumIntroductionStep';
import {getIntroductionSteps, getIsIntroRunning} from '@messenger/core/src/Redux/Introduction/Selectors';

import {getDoeChatMessageStep, getDoeMsnMessageStep} from 'src/Components/Introduction/StepsHelper';

export const appendMessagesStepsSaga = function* (action: PayloadAction<EnumIntroductionStep>) {
	try {
		const isRunning = yield* select(getIsIntroRunning);

		if (!isRunning || action.payload !== EnumIntroductionStep.MESSAGE_AREA) {
			return;
		}

		const currentSteps = yield* select(getIntroductionSteps);
		const actions = [];
		const newSteps = [getDoeChatMessageStep(ServiceFactory.i18n.t), getDoeMsnMessageStep(ServiceFactory.i18n.t)];

		for (let i = 0; i < newSteps.length; i++) {
			const newStep = newSteps[i];

			if (!_.some(currentSteps, ['step', newStep.step])) {
				actions.push(introductionClientOnlyActions.appendStep(newStep));
			}
		}

		if (!_.isEmpty(actions)) {
			yield* put(batchActions(actions));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'appendMessagesStepsSaga'});
	}
};
