import 'reflect-metadata';
import _ from 'lodash';
import {container, singleton} from 'tsyringe';

import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import AbstractWebRtcPreviewChatApi from '@messenger/core/src/Services/WebRtcPreviewApi/AbstractWebRtcPreviewChatApi';
import {IMediaWebRtc} from '@messenger/core/src/BusinessLogic/IMediaWebRtc';

import WebRtcPreviewApi from 'src/Services/WebRtcPreviewApi/WebRtcPreviewApi';

@singleton()
class WebRtcCam2camPreviewApi extends AbstractWebRtcPreviewChatApi {
	private _store: Record<string, WebRtcPreviewApi> = {};

	createPreview(chatId: string, mediaWebRtc: IMediaWebRtc) {
		if (_.isUndefined(this._store[chatId])) {
			this._store[chatId] = new WebRtcPreviewApi();
		}

		this._store[chatId].setConnectionOption(mediaWebRtc);
		this._store[chatId].play();
	}

	getPreview(chatId: string) {
		if (_.isUndefined(this._store[chatId])) {
			this._store[chatId] = new WebRtcPreviewApi();
		}

		return this._store[chatId];
	}

	removePreview(chatId: string) {
		this._store[chatId]?.stop();
		delete this._store[chatId];
	}
}

container.register(DIToken.WebRtcCam2camPreviewApi, {useToken: WebRtcCam2camPreviewApi});

export default WebRtcCam2camPreviewApi;
