import 'reflect-metadata';
import _ from 'lodash';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {SupportedLanguage} from 'cmd-control-client-lib';
import {container} from 'tsyringe';
import moment from 'moment';

/** Include moment locales explicitly */
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';
import SupportedLanguageList from '@messenger/core/src/BusinessLogic/SupportedLanguageList';

import VxLiveLanguageDetector from 'src/Locale/LanguageDetector';

/**
 * @link https://www.i18next.com/overview/getting-started
 * @link https://medium.com/@jishnu61/6-easy-steps-to-localize-your-react-application-internationalization-with-i18next-8de9cc3a66a1
 */

const resBundle = _.merge(
	{...require('i18next-resource-store-loader!@messenger/core/src/Locale/')},
	{...require('i18next-resource-store-loader!@messenger/web/src/Locale/')},
);

i18n
	.use(VxLiveLanguageDetector)
	.use(initReactI18next)
	.init(
		{
			debug: ServiceFactory.env.i18nDebugEnabled(),
			detection: {
				// order and from where user language should be detected
				order: ['localStorage', 'VxLiveLanguageDetector'],

				// keys or params to lookup language from
				lookupLocalStorage: EnumLocalStorageKeys.I_18_NEXT_LNG,
				lookupQuerystring: 'lng',
				lookupCookie: 'i18next',

				// cache user language on
				caches: ['localStorage', 'cookie'],
				excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

				// optional expire and domain for set cookie
				cookieMinutes: 10,
				cookieDomain: ServiceFactory.env.getCookieDomain(),
			},
			react: {
				bindI18nStore: module?.hot ? 'added' : undefined,
			},
			nonExplicitSupportedLngs: true,
			resources: resBundle,
			load: 'languageOnly',
			lowerCaseLng: true,
			supportedLngs: SupportedLanguageList,
			fallbackLng: SupportedLanguage.DE, // use de if detected lng is not available
			interpolation: {
				escapeValue: false, // react already safes from xss
			},
		},
		() => moment.locale(i18n.language),
	);

if (module?.hot) {
	const hmrAcceptHandler = (res: any) =>
		Object.keys(res).forEach((lang) => {
			Object.keys(res[lang]).forEach((namespace) => {
				i18n.addResourceBundle(lang, namespace, res[lang][namespace], true, true);
			});
		});

	module.hot.accept('i18next-resource-store-loader!@messenger/web/src/Locale/', () => {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		hmrAcceptHandler(require('i18next-resource-store-loader!@messenger/web/src/Locale/'));
	});

	module.hot.accept('i18next-resource-store-loader!@messenger/core/src/Locale/', () => {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		hmrAcceptHandler(require('i18next-resource-store-loader!@messenger/core/src/Locale/'));
	});
}

container.register<SupportedLanguage>(DIToken.currentLanguage, {useFactory: () => i18n.language as SupportedLanguage});
container.register(DIToken.I18n, {useValue: i18n});

export default i18n;
