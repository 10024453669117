import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectTargetChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetChannelIds';

import {selectCheckingChannelIds} from './selectCheckingChannelIds';

export const selectAreSomeChannelMediaForTargetChannelIdsChecking = createSelector(
	[selectTargetChannelIds, selectCheckingChannelIds],
	(targetChannelIds, checkingChannelIds) =>
		_.some(targetChannelIds, (channelId) => _.includes(checkingChannelIds, channelId)),
);
