import {IGiftPrice, IGiftTranslation, EnumBooleanStringified} from 'cmd-control-client-lib';

import {TGift} from '@messenger/core/src/Redux/Gifts//Model';

export default class GiftVM {
	id: number | string;
	name: string;
	price: IGiftPrice;
	imageUrl: string;
	active: EnumBooleanStringified;
	categoryId: number | string;
	translations: IGiftTranslation[];

	constructor(gift: TGift) {
		this.id = gift.id;
		this.name = gift.name;
		this.price = gift.price;
		this.imageUrl = gift.imageUrl;
		this.active = gift.active;
		this.categoryId = gift.categoryId;
		this.translations = gift.translations;
	}
}
