enum EnumSnackbarNotificationKeys {
	KICKED_BY_ADMIN = 'KICKED_BY_ADMIN',
	BULK_SELECTED_CHANNELS_UPDATE = 'BULK_SELECTED_CHANNELS_UPDATE',
	BULK_MESSAGE_SENT = 'BULK_MESSAGE_SENT',
	PROCESSING_BULK_MESSAGE_SENT = 'PROCESSING_BULK_MESSAGE_SENT',
	CAMERA_PERMISSION = 'CAMERA_PERMISSION_NOTIFICATION_KEY',
	MICROPHONE_PERMISSION = 'MICROPHONE_PERMISSION_NOTIFICATION_KEY',
	SOFT_CHAT_IS_ON = 'SOFT_CHAT_IS_ON',
	QUERY_REQUEST = 'QUERY_REQUEST',
	RECORD_AUDIO_IF_ONLINE = 'RECORD_AUDIO_IF_ONLINE',
	GROUP_CHANGED_NOTIFICATION_KEY = 'GROUP_CHANGED_NOTIFICATION_KEY',
	CANCELLED_TICKETSHOW = 'CANCELLED_TICKETSHOW',
	VIDEO_CALL_STATUS_CHANGED = 'VIDEO_CALL_STATUS_CHANGED',
	LOADING_MESSAGES = 'LOADING_MESSAGES',
	CURRENT_MOBILE_VIDEO_CALL = 'CURRENT_MOBILE_VIDEO_CALL',
	SOCKET_ERROR_NOTIFICATION_KEY = 'SOCKET_ERROR_NOTIFICATION_KEY',
	NO_NETWORK_KEY = 'NO_NETWORK_KEY',
	ENABLE_SOUND_NOTIFICATIONS_KEY = 'ENABLE_SOUND_NOTIFICATIONS_KEY',
	SOON_TICKET_SHOW = 'SOON_TICKET_SHOW',
	NOTIFICATION_KEY_LOBBY_FAILED = 'NOTIFICATION_KEY_LOBBY_FAILED',
	UPLOAD_ERROR = 'UPLOAD_ERROR',
	GTC_WARNING = 'GTC_WARNING',
}

export default EnumSnackbarNotificationKeys;
