export enum EnumProductLinks {
  CHAT_MOTTO = "chatMotto",
  CHAT_SETTINGS = "chatSettings",
  HELP = "help",
  HELP_TOY = "helpToy",
  HELP_LOVENSE = "helpLovense",
  HELP_SOFTWARE = "helpSoftware",
  MESSAGING = "messaging",
  PICTURE_TOOL = "picturePool",
  PICTURE_TOOL_SEDCARD12 = "sedcardVX12",
  PICTURE_TOOL_SEDCARD16 = "sedcardVX16",
  PICTURE_TOOL_SEDCARD18 = "sedcardVX18",
  PROFILE = "profile",
  PASSWORD_RESET = "passwordReset",
  SERVICE0900 = "service0900",
}

export interface IProductConfig {
  key: string;
  links: {
    [key in EnumProductLinks]: string;
  };
}
