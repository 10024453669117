import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import FeedbackConstants from '@messenger/core/src/BusinessLogic/FeedbackConstants';
import {selectSessionId} from '@messenger/core/src/Redux/Session/Selectors/selectSessionId';
import {selectSessionLogin} from '@messenger/core/src/Redux/Session/Selectors/selectSessionLogin';
import {feedbackClientOnlyActions} from '@messenger/core/src/Redux/Feedback/Actions/feedbackClientOnlyActions';
import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';

/**
 * @param {Object} action
 * @return {Generator|void}
 * @see SendFeedbackSaga
 */
export const sendFeedbackSilentlySaga = function* (
	action: ReturnType<typeof sessionServerToClientActions.feedbackRequested>,
) {
	try {
		const sessionId = yield* select(selectSessionId);
		const login = yield* select(selectSessionLogin);
		const name = !_.isUndefined(action.payload.namefrom)
			? action.payload.namefrom
			: FeedbackConstants.AUTO_FEEDBACK_NAME;
		const email = !_.isUndefined(action.payload.emailfrom)
			? action.payload.emailfrom
			: FeedbackConstants.AUTO_FEEDBACK_EMAIL;
		const phone = FeedbackConstants.AUTO_FEEDBACK_PHONE;
		const short = !_.isUndefined(action.payload.subj) ? action.payload.subj : FeedbackConstants.AUTO_FEEDBACK_SHORT;
		const longDesc = !_.isUndefined(action.payload.text) ? action.payload.text : FeedbackConstants.AUTO_FEEDBACK_LONG;

		// send event though another saga
		yield* put(feedbackClientOnlyActions.send({sessionId, login, name, email, phone, short, longDesc, notify: false}));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'sendFeedbackSilentlySaga'});
	}
};
