import {CMDC_CMEDIA} from 'cmd-control-client-lib';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

import {TMediaHistoryFilters} from './mediaClientToServerActions';

enum MediaAction {
	SET_UPLOAD_URL = 'SET_UPLOAD_URL',
	UPSERT_MANY = 'UPSERT_MANY',
	ADD_MANY = 'ADD_MANY',
	REQUEST_MEDIA_HISTORY = 'REQUEST_MEDIA_HISTORY',
}

class MediaClientOnlyActions extends ClientOnlyActions<EnumStore.MEDIA> {
	readonly scope = EnumStore.MEDIA;

	setMediaUploadUrl = this.createAction(MediaAction.SET_UPLOAD_URL, this.getPrepareAction<string>());

	upsertMany = this.createAction(MediaAction.UPSERT_MANY, this.getPrepareAction<CMDC_CMEDIA['params'][]>());

	addMany = this.createAction(MediaAction.ADD_MANY, this.getPrepareAction<CMDC_CMEDIA['params'][]>());

	requestMediaHistory = this.createAction(
		MediaAction.REQUEST_MEDIA_HISTORY,
		this.getPrepareAction<TMediaHistoryFilters>(),
	);
}

export const mediaClientOnlyActions = new MediaClientOnlyActions();
