import {container, inject, singleton} from 'tsyringe';
import {Invitation, Inviter, Registerer, Session, SessionState, UserAgent, UserAgentOptions} from 'sip.js';
import _ from 'lodash';
import {SessionDescriptionHandler} from 'sip.js/lib/platform/web';

import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import {
	AbstractSipService,
	EnumSipCallType,
	EnumSipServiceAction,
	TSipEmitter,
	TSipServiceActions,
} from '@messenger/core/src/Services/AbstractSipService';
import AbstractUINotificationService from '@messenger/core/src/Services/AbstractUINotificationService';
import EnvInterfaceService from '@messenger/core/src/Services/EnvInterfaceService';

@singleton()
class SipService extends AbstractSipService {
	private userAgent?: UserAgent;
	private registerer?: Registerer;
	private session?: Session;
	private emitter?: TSipEmitter;
	private toneDuration = 1000;

	constructor(
		@inject(DIToken.UINotificationService) protected notifications: AbstractUINotificationService,
		@inject(DIToken.EnvInterfaceService) protected env: EnvInterfaceService,
	) {
		super();
	}

	private makeUserAddress = (number: string) => UserAgent.makeURI(`sip:${number}@${this.env.sipRealm()}`);

	init = (login: string, password: string, name: string) => {
		const options: UserAgentOptions = {
			logBuiltinEnabled: false,
			displayName: name,
			authorizationUsername: login,
			authorizationPassword: password,
			uri: this.makeUserAddress(login),
			transportOptions: {
				server: this.env.sipWebsocketUrl(),
			},

			delegate: {
				onInvite: this.onInvite.bind(this),
				onConnect: () => {
					if (!_.isUndefined(this.registerer)) {
						this.registerer.register();
					}
				},
				onDisconnect: (_error) => {
					if (!_.isUndefined(this.registerer)) {
						this.registerer.dispose();
					}
				},
			},
		};

		this.userAgent = new UserAgent(options);
		this.registerer = new Registerer(this.userAgent);

		this.userAgent.start().then(() => {
			this.emit({type: EnumSipServiceAction.INITIALIZED, payload: true});
		});
	};

	stop = () => {
		if (!_.isUndefined(this.userAgent)) {
			this.userAgent.stop().then(() => {
				this.emit({type: EnumSipServiceAction.INITIALIZED, payload: false});
			});
		}
	};

	callTo = (to: string): Promise<void> => {
		if (!_.isUndefined(this.userAgent)) {
			const target = this.makeUserAddress(to);

			if (!_.isUndefined(target)) {
				this.session = new Inviter(this.userAgent, target, {
					sessionDescriptionHandlerOptions: {
						constraints: {audio: true, video: false},
					},
				});

				this.session.stateChange.addListener(this.stateChangeListener.bind(this));

				return this.session.invite().then(() => {
					if (this.session instanceof Inviter) {
						this.emit({
							type: EnumSipServiceAction.OUTGOING_CALL,
							payload: {
								id: this.session.request.callId,
								type: EnumSipCallType.OUTGOING,
								name: to,
								state: SessionState.Initial,
							},
						});
						this.emit({
							type: EnumSipServiceAction.STATE_CHANGED,
							payload: SessionState.Initial,
						});
					}
				});
			}
		}

		return Promise.reject();
	};

	accept = (): Promise<void> => {
		if (!_.isUndefined(this.session) && this.session instanceof Invitation) {
			return this.session.accept({
				sessionDescriptionHandlerOptions: {
					constraints: {audio: true, video: false},
				},
			});
		}

		return Promise.resolve();
	};
	hangUp = (): Promise<void> => {
		if (!_.isUndefined(this.session)) {
			switch (this.session.state) {
				case SessionState.Initial:
					if (this.session instanceof Inviter) {
						return this.session.cancel();
					} else if (this.session instanceof Invitation) {
						return this.session.reject();
					} else {
						throw new Error('Unknown session type.');
					}

				case SessionState.Establishing:
					if (this.session instanceof Inviter) {
						return this.session.cancel();
					} else if (this.session instanceof Invitation) {
						return this.session.reject();
					} else {
						throw new Error('Unknown session type.');
					}

				case SessionState.Established:
					return this.session.bye().then(_.noop);
				case SessionState.Terminating:
					break;
				case SessionState.Terminated:
					break;
				default:
					throw new Error('Unknown state');
			}
		}

		return Promise.resolve();
	};

	setEmitter = (emitter: TSipEmitter) => {
		this.emitter = emitter;
	};

	unsetEmitter = () => {
		this.emitter = undefined;
	};

	private emit = (action: TSipServiceActions) => {
		if (_.isFunction(this.emitter)) {
			this.emitter(action);
		}
	};

	sendTone = (tone: string): Promise<void> => {
		// As RFC 6086 states, sending DTMF via INFO is not standardized...
		//
		// Companies have been using INFO messages in order to transport
		// Dual-Tone Multi-Frequency (DTMF) tones.  All mechanisms are
		// proprietary and have not been standardized.
		// https://tools.ietf.org/html/rfc6086#section-2
		//
		// It is however widely supported based on this draft:
		// https://tools.ietf.org/html/draft-kaplan-dispatch-info-dtmf-package-00

		// Validate tone
		if (!/^[0-9A-D#*,]$/.exec(tone)) {
			return Promise.reject(new Error('Invalid DTMF tone.'));
		}

		if (!_.isUndefined(this.session)) {
			// The UA MUST populate the "application/dtmf-relay" body, as defined
			// earlier, with the button pressed and the duration it was pressed
			// for.  Technically, this actually requires the INFO to be generated
			// when the user *releases* the button, however if the user has still
			// not released a button after 5 seconds, which is the maximum duration
			// supported by this mechanism, the UA should generate the INFO at that
			// time.
			// https://tools.ietf.org/html/draft-kaplan-dispatch-info-dtmf-package-00#section-5.3
			const body = {
				contentDisposition: 'render',
				contentType: 'application/dtmf-relay',
				content: `Signal=${tone}\r\nDuration=${this.toneDuration}`,
			};
			const requestOptions = {body};

			return this.session.info({requestOptions}).then(_.noop);
		}

		return Promise.resolve();
	};

	get micAudioTrack(): MediaStreamTrack | undefined {
		if (this.session?.sessionDescriptionHandler instanceof SessionDescriptionHandler) {
			return this.session.sessionDescriptionHandler.localMediaStream
				.getTracks()
				.find((track) => track.kind === 'audio');
		}

		return undefined;
	}

	get soundAudioTrack(): MediaStreamTrack | undefined {
		if (this.session?.sessionDescriptionHandler instanceof SessionDescriptionHandler) {
			return this.session.sessionDescriptionHandler.remoteMediaStream
				.getTracks()
				.find((track) => track.kind === 'audio');
		}

		return undefined;
	}

	private onInvite = (invitation: Invitation) => {
		if (_.isUndefined(this.session)) {
			this.session = invitation;
			this.emit({
				type: EnumSipServiceAction.INCOMING_CALL,
				payload: {
					id: invitation.request.callId,
					type: EnumSipCallType.INCOMING,
					name: invitation.request.from.displayName,
					state: SessionState.Initial,
				},
			});
			this.emit({
				type: EnumSipServiceAction.STATE_CHANGED,
				payload: SessionState.Initial,
			});

			this.session.stateChange.addListener(this.stateChangeListener.bind(this));
		} else {
			invitation.reject().then(_.noop);
		}
	};

	private stateChangeListener = (newState: SessionState) => {
		this.emit({
			type: EnumSipServiceAction.STATE_CHANGED,
			payload: newState,
		});

		if (newState === SessionState.Terminated) {
			this.session = undefined;
		}
	};
}

container.register(DIToken.Sip, {useToken: SipService});

export default SipService;
