import _ from 'lodash';
import {call} from 'typed-redux-saga';

import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {UPLOAD_FILESIZE_LIMIT} from '@messenger/core/src/BusinessLogic/Constants/Constants';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import ILocalFile from '@messenger/core/src/Redux/Media/ILocalFile';

export const processCheckValidFileSaga = function* (
	payload: ReturnType<typeof attachmentClientOnlyActions.addFileToAttachment>['payload'],
) {
	try {
		if (_.isUndefined(payload.file) && !payload.mediaMd5) {
			return {isValid: false};
		}

		if (payload.file && payload.file.type.includes('image/')) {
			//get image dimensions;
			const dimensionObj = yield* call(
				[ServiceFactory.fileProcessing, ServiceFactory.fileProcessing.getImageDimension],
				payload.file,
			);

			const UPLOAD_IMAGE_DIMENSION_LIMIT = yield* call([ServiceFactory.env, ServiceFactory.env.getMinImageDimension]);

			if (!_.isUndefined(dimensionObj)) {
				if (dimensionObj.width < UPLOAD_IMAGE_DIMENSION_LIMIT || dimensionObj.height < UPLOAD_IMAGE_DIMENSION_LIMIT) {
					const notification = {
						text: ServiceFactory.i18n.t('messages:area.drop-zone-upload-failed-image-too-small', {
							limit: UPLOAD_IMAGE_DIMENSION_LIMIT,
						}),
						variant: EnumAbstractNotificationVariant.WARNING,
						key: 'upload_failed_image_too_small',
					};

					yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], notification);

					return {isValid: false};
				}
			}
		}

		let fileSize = undefined;

		if (!payload.mediaMd5) {
			fileSize = yield* call(
				[ServiceFactory.fileProcessing, ServiceFactory.fileProcessing.getFileSize],
				payload.file as File | ILocalFile,
			);
		}

		if (!_.isUndefined(fileSize) && fileSize > UPLOAD_FILESIZE_LIMIT) {
			const notification = {
				text: ServiceFactory.i18n.t('messages:area.drop-zone-upload-failed-sizelimit', {
					limit: UPLOAD_FILESIZE_LIMIT / (1024 * 1024),
				}),
				variant: EnumAbstractNotificationVariant.WARNING,
				key: 'upload_failed_image_too_small',
			};

			yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], notification);

			return {isValid: false};
		}

		return {isValid: true, fileSize};
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processCheckValidFileSaga'});

		return {isValid: false};
	}
};
