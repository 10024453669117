import {takeEvery} from 'typed-redux-saga';

import {processChannelGroupsPurchaseAbilitySaga} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Sagas/processChannelGroupsPurchaseAbilitySaga';
import {channelGroupsPurchaseAbilityServerToClientActions} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Actions/channelGroupsPurchaseAbilityServerToClientActions';

function* channelGroupsPurchaseAbilitySagaWatcher() {
	yield* takeEvery(
		channelGroupsPurchaseAbilityServerToClientActions.checkCanReceiveAttachmentReceived,
		processChannelGroupsPurchaseAbilitySaga,
	);
}

export default channelGroupsPurchaseAbilitySagaWatcher;
