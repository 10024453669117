import {takeEvery} from 'typed-redux-saga';

import {mediaPricesClientOnlyActions} from '@messenger/core/src/Redux/MediaPrice/Actions/mediaPricesClientOnlyActions';
import {requestMediaPricesForTargetChannelsSaga} from '@messenger/core/src/Redux/MediaPrice/Sagas/requestMediaPricesForTargetChannelsSaga';

function* mediaPricesSagaWatcher() {
	yield* takeEvery(mediaPricesClientOnlyActions.requestPrices.type, requestMediaPricesForTargetChannelsSaga);
}

export default mediaPricesSagaWatcher;
