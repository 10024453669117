import {ACTION, ResultCode, CMDP_SGETCHATSSUMMARY_RESPONSE} from 'cmd-control-client-lib';
import _ from 'lodash';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class ChatsStatsServerToClientActions extends ServerToClientActions<EnumStore.CHAT_SUMMARY> {
	readonly scope = EnumStore.CHAT_SUMMARY;

	chatSummaryReceived = this.createAction(
		ACTION.CMDP_SGETCHATSSUMMARY,
		(payload: CMDP_SGETCHATSSUMMARY_RESPONSE['values'], meta: CMDP_SGETCHATSSUMMARY_RESPONSE) => {
			const summary = _.attempt(JSON.parse, payload.summary);
			let error = undefined;

			if (meta.result.code !== ResultCode.OK) {
				error = meta.result.reason;
			} else if (_.isError(summary)) {
				error = summary.message;
			}

			return {
				payload: {
					channelId: meta.params.channelId,
					...(_.isError(summary) ? {} : summary),
				},
				meta,
				error,
			};
		},
	);
}

export const chatSummaryServerToClientActions = new ChatsStatsServerToClientActions();
