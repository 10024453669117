import {ACTION} from 'cmd-control-client-lib';
import moment from 'moment';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import TicketShowVM from '@messenger/core/src/Redux/TicketShow/TicketShowVM';
import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';

class TicketShowClientToServerActions extends ClientToServerActions<EnumStore.TICKET_SHOW> {
	readonly scope = EnumStore.TICKET_SHOW;

	getTicketShows = this.createAction(ACTION.CMDP_SGETTICKETSHOWS);

	createTicketShow = this.createAction(ACTION.CMDP_SUPSERTTICKETSHOW, (payload: Partial<TicketShowVM>) => ({
		payload: {
			showId: payload.showId,
			showStart: moment(payload.showStart).toJSON(),
			showEnd: moment(payload.showEnd).toJSON(),
			showName: payload.showName,
			showImageUrl: payload.showImageUrl,
			pictureId: payload.pictureId,
			showCurrency: payload.showCurrency,
			showPrice: _.toString(payload.showPrice),
			showSales: _.toString(payload.showSales),
			titleDE: _.trim(payload.titleDE),
			titleEN: _.trim(payload.titleEN),
			descriptionDE: _.trim(payload.descriptionDE),
			descriptionEN: _.trim(payload.descriptionEN),
			status: payload.status,
		},
	}));

	deleteTicketShow = this.createAction(ACTION.CMDP_SDELETETICKETSHOW, this.getPrepareAction<{showId: string}>());
}

export const ticketShowClientToServerActions = new TicketShowClientToServerActions();
