import replaceAll from 'string.prototype.replaceall';
import _ from 'lodash';

const legacySmileys = {
	':)': '🙂',
	':-)': '🙂',
	':(': '🙁',
	':-(': '🙁',
	':D': '😄',
	':-D': '😄',
	':|': '😐',
	';)': '😉',
	';-)': '😉',
	':P': '😋',
	':*(': '😢',
	':*': '😘',
	'(H)': '😎',
	':[': '😒',
	'=)': '😃',
	':O': '😮',
	'(L)': '😍',
	':Z': '😴',
	':-/': '😕',
	':X': '🤐',
	'(M)': '😤',
	'(G)': '😘',
	':?': '🤔',
};

export const replaceLegacySmileys = (text: string) => {
	if (_.isEmpty(text)) {
		return '';
	}

	_.forOwn(legacySmileys, (value, key) => {
		text = replaceAll(text, key, value);
	});

	return text;
};
