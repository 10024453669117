import _ from 'lodash';
import {container, inject, singleton} from 'tsyringe';
import {InfoWizard} from 'cmd-control-client-lib';

import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';
import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import AbstractFeatureShowcaseService from '@messenger/core/src/Services/AbstractFeatureShowcaseService';

import StoreService from './StoreService';

@singleton()
export class FeatureShowcaseService extends AbstractFeatureShowcaseService<InfoWizard> {
	constructor(@inject(DIToken.StoreService) protected store: StoreService) {
		super();
	}

	async isLegacyIntroShown(modelId?: string) {
		return (
			!_.isNil(await this.store.get(`${EnumLocalStorageKeys.INTRO_SHOWN}:${modelId}`)) ||
			!_.isNil(await this.store.get(EnumLocalStorageKeys.INTRO_SHOWN))
		);
	}

	lastFeature = InfoWizard.FIRSTTIME;
}

container.register(DIToken.FeatureShowcase, {useToken: FeatureShowcaseService});

export default FeatureShowcaseService;
