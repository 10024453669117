import {call, put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';

export function* stopWebRtcOutputStreamSaga(isFailed: boolean) {
	try {
		const api = ServiceFactory.webRtcApi;

		yield* call([api, api.stopOutputStream]);
		yield* put(mediaDeviceClientOnlyActions.releaseMediaDevicePermissions());
		yield* put(streamClientOnlyActions.stopStream());

		if (isFailed) {
			yield* put(streamClientOnlyActions.goOffline(true));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'stopWebRtcOutputStreamSaga', isFailed});
	}
}
