import { EnumCurrency } from "../../@types";
import { ICOMMAND, ACTION, baseParamsType, IRESPONSE, RESULT, IKeyMaybeValue } from "../../cmd-protocol";

export enum EnumTicketShowStatusType {
  OK = "ok",
  CANCELLED = "cancelled",
  DELETED = "deleted",
}

export type ticketShow = {
  showId: string;
  /*json datetime */
  showStart: string;
  /** json datetime */
  showEnd: string;
  /** show Name, readonly*/
  showName: string;
  /** show image url, deprecated*/
  showImageUrl?: string;
  /** pictureId, the replacement for showImageUrl*/
  pictureId?: string;
  /** readonly const */
  showCurrency: EnumCurrency;
  /** float F2 */
  showPrice: string;
  /** long */
  showSales: string;
  titleDE: string;
  titleEN: string;
  descriptionDE: string;
  descriptionEN: string;
  status: EnumTicketShowStatusType | string;
};

export class CMDC_CTICKETSHOW implements ICOMMAND {
  public action = ACTION.CMDC_CTICKETSHOW;
  public params: ticketShow;
}

export class CMDP_SGETTICKETSHOWS implements ICOMMAND {
  public action = ACTION.CMDP_SGETTICKETSHOWS;
  public params: baseParamsType & {
    /**showId if know */
    showId?: string;
    /* optional */
    showStart?: string;
    /* optional */
    showEnd?: string;
  };
}

export class CMDP_SUPSERTTICKETSHOW implements ICOMMAND {
  public action = ACTION.CMDP_SUPSERTTICKETSHOW;
  public params: baseParamsType & ticketShow;
}

export class CMDP_SUPSERTTICKETSHOW_RESPONSE extends CMDP_SUPSERTTICKETSHOW implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_CTICKETSHOW[];
  public values: IKeyMaybeValue;
}

export class CMDP_SDELETETICKETSHOW implements ICOMMAND {
  public action = ACTION.CMDP_SDELETETICKETSHOW;
  public params: baseParamsType & { showId?: string };
}

export class CMDP_SDELETETICKETSHOW_RESPONSE extends CMDP_SDELETETICKETSHOW implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

/**
 * start ticket show
 */
export class CMDP_SSTARTTICKETSHOW implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SSTARTTICKETSHOW;
  public params: baseParamsType & {
    /** custom show name */
    ualias?: string;
  };
}

export class CMDP_SSTARTTICKETSHOW_RESPONSE extends CMDP_SSTARTTICKETSHOW implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue & {
    /** the chat id */
    chatID: string;
  };
}

/**
 * stop ticket show
 */
export class CMDP_SSTOPTICKETSHOW implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SSTOPTICKETSHOW;
  public params: baseParamsType;
}

export class CMDP_SSTOPTICKETSHOW_RESPONSE extends CMDP_SSTOPTICKETSHOW implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

/** the sedcardpicrure */
export class CMDC_SEDCARDPICTURE implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_SEDCARDPICTURE;
  public params: {
    /** picture id*/
    pictureId?: string;
    /** sedcards.fsk12, sedcards.fsk16 ununsed for mnow */
    type: string;
    /** sedcart, avatar, unused for now */
    subtype: string;
    /**use the imageSrc as value for showImageUrl */
    imgSrc: string;
    /** use imgSrcSet for UI only*/
    imgSrcSet: string;
  };
}

/** get bitmap urls that can be used for show.imageUrl */
export class CMDP_SGETSEDCARDPICTURES implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SGETSEDCARDPICTURES;
  public params: baseParamsType;
}

/** reponse */
export class CMDP_SGETSEDCARDPICTURES_RESPONSE extends CMDP_SGETSEDCARDPICTURES implements IRESPONSE {
  public result: RESULT;
  public commands: CMDC_SEDCARDPICTURE[];
  public values: IKeyMaybeValue;
}
