import {EntityState} from '@reduxjs/toolkit';

import {mediaPricesAdapter} from '@messenger/core/src/Redux/MediaPrice/entityAdapter';
import {IMediaPrice} from '@messenger/core/src/Redux/MediaPrice/IMediaPrice';

export type TMediaPricesState = EntityState<IMediaPrice> & {
	loading: {
		// channelId -> isLoading = TRUE, loaded = FALSE
		[channelId: string]: boolean;
	};
	errors: {
		// channelId -> error string | boolean
		[channelId: string]: string | boolean;
	};
	defaultPrices?: Omit<IMediaPrice, 'channelId'>;
	isDefaultPricesLoading: boolean;
	defaultPricesError?: string;
};

export const mediaPricesInitialState: TMediaPricesState = mediaPricesAdapter.getInitialState({
	loading: {},
	errors: {},
	defaultPrices: undefined,
	isDefaultPricesLoading: false,
	defaultPricesError: undefined,
});
