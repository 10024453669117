import {createSlice} from '@reduxjs/toolkit';
import {CMDP_STARTBANDWIDTHTEST_RESPONSE, ResultCode} from 'cmd-control-client-lib';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

import {speedTestClientOnlyActions} from './Actions/speedTestClientOnlyActions';
import {speedTestServerToClientActions} from './Actions/speedTestServerToClientActions';
import {speedTestClientToServerActions} from './Actions/speedTestClientToServerActions';

const initialSpeedTestState: {
	error?: string;
	isStarted: boolean;
	isFinished: boolean;
	startTime: number;
	progress: number;
	requests: number;
	measured: number;
	currentTest?: CMDP_STARTBANDWIDTHTEST_RESPONSE['values'];
} = {
	startTime: 0,
	isStarted: false,
	isFinished: false,
	progress: 0,
	requests: 0,
	measured: 0,
};

export const speedTestSlice = createSlice({
	name: EnumStore.SPEED_TEST,
	initialState: initialSpeedTestState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(speedTestClientOnlyActions.resetStore, () => initialSpeedTestState);
		builder.addCase(speedTestClientToServerActions.startTest, () => {
			return {...initialSpeedTestState, startTime: Date.now(), isStarted: true};
		});
		builder.addCase(speedTestClientOnlyActions.markProgress, (state, {payload}) => {
			state.requests = state.requests + 1;
			state.progress = payload.progress;
			state.measured = payload.measured;
		});
		builder.addCase(speedTestClientOnlyActions.markEnd, (state) => {
			state.isFinished = true;
		});
		builder.addCase(speedTestClientOnlyActions.setError, (state, {payload}) => {
			state.error = payload;
		});
		builder.addCase(speedTestServerToClientActions.startTestReceived, (state, {payload}) => {
			state.currentTest = payload;
		});
		builder.addCase(speedTestServerToClientActions.stopTestReceived, (state, {meta}) => {
			if (meta.result.code === ResultCode.OK) {
				state.isStarted = false;
			} else {
				state.error = meta.result.reason;
			}
		});
	},
});
