import {ACTION} from 'cmd-control-client-lib';
import {ActionCreatorWithPreparedPayload} from '@reduxjs/toolkit';

import {mediaPricesServerToClientActions} from '@messenger/core/src/Redux/MediaPrice/Actions/mediaPricesServerToClientActions';
import {queryServerToClientActions} from '@messenger/core/src/Redux/QueryUser/Actions/queryServerToClientActions';
import {toyServerToClientActions} from '@messenger/core/src/Redux/Toy/Actions/toyServerToClientActions';
import {channelsClientOnlyActions, channelsServerToClientActions} from '@messenger/core/src/Redux/Channels/Actions';
import {cam2camPreviewServerToClientActions} from '@messenger/core/src/Redux/Cam2CamPreview/Actions/cam2camPreviewServerToClientActions';
import {chatsClientOnlyActions, chatsServerToClientActions} from '@messenger/core/src/Redux/Chats/Actions';
import {messagesServerToClientActions} from '@messenger/core/src/Redux/Messages/Actions/messagesServerToClientActions';
import {streamServerToClientActions} from '@messenger/core/src/Redux/Stream/Actions';
import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';
import {channelInfosServerToClientActions} from '@messenger/core/src/Redux/ChannelInfos/Actions/channelInfosServerToClientActions';
import {onlineSummaryServerToClientActions} from '@messenger/core/src/Redux/OnlineSummary/Actions/onlineSummaryServerToClientActions';
import {pushNotificationsServerToClientActions} from '@messenger/core/src/Redux/PushNotifications/Actions';
import {testVideoChatServerToClientActions} from '@messenger/core/src/Redux/TestVideoChat/Actions/testVideoChatServerToClientActions';
import {mediaServerToClientActions} from '@messenger/core/src/Redux/Media/Actions/mediaServerToClientActions';
import {mediaClientOnlyActions} from '@messenger/core/src/Redux/Media/Actions/mediaClientOnlyActions';
import {modelSettingsServerToClientActions} from '@messenger/core/src/Redux/ModelSettings/Actions/modelSettingsServerToClientActions';
import {ticketShowServerToClientActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowServerToClientActions';
import {streamPreviewServerToClientActions} from '@messenger/core/src/Redux/StreamPreview/Actions/streamPreviewServerToClientActions';
import {speedTestServerToClientActions} from '@messenger/core/src/Redux/SpeedTest/Actions/speedTestServerToClientActions';
import {chatSummaryServerToClientActions} from '@messenger/core/src/Redux/ChatSummary/Actions/chatSummaryServerToClientActions';
import {groupServerToClientActions} from '@messenger/core/src/Redux/Groups/Actions/groupsServerToClientActions';
import {channelGroupsServerToClientActions} from '@messenger/core/src/Redux/ChannelGroups/Actions/channelGroupsServerToClientActions';
import {channelMediaSummaryServerToClientActions} from '@messenger/core/src/Redux/ChannelMediaSummary/Actions/channelMediaSummaryServerToClientActions';
import {channelInfosClientOnlyActions} from '@messenger/core/src/Redux/ChannelInfos/Actions/channelInfosClientOnlyActions';
import {contactNoteClientOnlyActions} from '@messenger/core/src/Redux/ContactNote/Actions/contactNoteClientOnlyActions';
import {onlineStateClientOnlyActions} from '@messenger/core/src/Redux/OnlineState/Actions';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {sedCardPictureServerToClientActions} from '@messenger/core/src/Redux/SedCardPicture/Actions/sedCardPictureServerToClientActions';
import {sedCardPictureClientOnlyActions} from '@messenger/core/src/Redux/SedCardPicture/Actions/sedCardPictureClientOnlyActions';
import {groupsClientOnlyActions} from '@messenger/core/src/Redux/Groups/Actions/groupsClientOnlyActions';
import {channelGroupsClientOnlyActions} from '@messenger/core/src/Redux/ChannelGroups/Actions/channelGroupsClientOnlyActions';
import {liveSessionsServerToClientActions} from '@messenger/core/src/Redux/LiveSessions/Actions/liveSessionsServerToClientActions';
import {liveSessionsClientOnlyActions} from '@messenger/core/src/Redux/LiveSessions/Actions/liveSessionsClientOnlyActions';
import {channelGroupsPurchaseAbilityServerToClientActions} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Actions/channelGroupsPurchaseAbilityServerToClientActions';
import {liveChatsClientOnlyActions} from '@messenger/core/src/Redux/LiveChats/Actions/liveChatsClientOnlyActions';
import {TServerCommands, TServerSubCommands} from '@messenger/core/src/Actions/types';
import EnumActionRole from '@messenger/core/src/BusinessLogic/EnumActionRole';
import {ticketShowClientOnlyActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowClientOnlyActions';
import {videoCallServerToClientActions} from '@messenger/core/src//Redux/VideoCall/Actions';
import {mediaLinksServerToClientActions} from '@messenger/core/src/Redux/MediaLinks/Actions/mediaLinksServerToClientActions';

import {TMultiEntityActionMeta, TSingleEntityActionMeta} from './ActionCreator';

export const commandToActionMap: TCommandToActionMap = {
	[ACTION.CMDP_GETCHANNELINFO]: {
		actionCreator: channelInfosServerToClientActions.channelInfosRequested,
		batchedSubCommandToActionMap: {
			[ACTION.CMDC_CHANNELINFO]: {
				actionCreator: channelInfosClientOnlyActions.upsertMany,
				isMultiEntityAction: true,
			},
		},
	},
	[ACTION.CMDP_GETCHANNELS]: {
		actionCreator: channelsServerToClientActions.channelsReceived,
		batchedSubCommandToActionMap: {
			[ACTION.CMDC_CHANNELINFO]: {
				actionCreator: channelInfosClientOnlyActions.addMany,
				isMultiEntityAction: true,
			},
			[ACTION.CMDC_CONTACTNOTE]: {
				actionCreator: contactNoteClientOnlyActions.addMany,
				isMultiEntityAction: true,
			},
			[ACTION.CMDC_ONLINESTATE]: {
				actionCreator: onlineStateClientOnlyActions.addMany,
				isMultiEntityAction: true,
			},
			[ACTION.CMDC_CHANNEL]: {actionCreator: channelsClientOnlyActions.addMany, isMultiEntityAction: true},
		},
	},
	[ACTION.CMDP_SGETMESSAGEHISTORY]: {
		actionCreator: messagesServerToClientActions.messagesHistoryReceived,
		batchedSubCommandToActionMap: {
			[ACTION.CMDC_CMSG]: {actionCreator: messagesClientOnlyActions.addMany, isMultiEntityAction: true},
		},
	},
	[ACTION.CMDP_SGETCHATS]: {
		actionCreator: chatsServerToClientActions.get,
	},
	[ACTION.CMDP_SADDGROUP]: {
		actionCreator: groupServerToClientActions.groupAdded,
		batchedSubCommandToActionMap: {
			[ACTION.CMDC_GROUP]: {actionCreator: groupsClientOnlyActions.upsertMany, isMultiEntityAction: true},
		},
	},
	[ACTION.CMDP_SGETCHANNELGROUPS]: {
		actionCreator: channelGroupsServerToClientActions.channelGroupsReceivedMany,
		batchedSubCommandToActionMap: {
			[ACTION.CMDC_CHANNELGROUP]: {
				actionCreator: channelGroupsClientOnlyActions.upsertMany,
				isMultiEntityAction: true,
			},
		},
	},

	[ACTION.CMDP_GETLIVEHISTORY]: {
		actionCreator: liveSessionsServerToClientActions.liveSessionsReceived,
		batchedSubCommandToActionMap: {
			[ACTION.CMDC_LIVESESSION]: {
				actionCreator: liveSessionsClientOnlyActions.addMany,
				isMultiEntityAction: true,
			},
			[ACTION.CMDC_LIVECHAT]: {actionCreator: liveChatsClientOnlyActions.addMany, isMultiEntityAction: true},
		},
	},

	[ACTION.CMDP_SGETSEDCARDPICTURES]: {
		actionCreator: sedCardPictureServerToClientActions.getSedCardPictures,
		batchedSubCommandToActionMap: {
			[ACTION.CMDC_SEDCARDPICTURE]: {
				actionCreator: sedCardPictureClientOnlyActions.addMany,
				isMultiEntityAction: true,
			},
		},
	},

	[ACTION.CMDP_MSG]: {actionCreator: messagesServerToClientActions.messageSendResult},
	[ACTION.CMDP_QUERYUSER]: {actionCreator: queryServerToClientActions.queryAnswerResultReceived},
	[ACTION.CMDP_CHANNELSEEN]: {actionCreator: channelsServerToClientActions.channelMarkedSeen},
	[ACTION.CMDP_CHANNELSSUMMARY]: {actionCreator: channelsServerToClientActions.channelSummaryReceived},
	[ACTION.CMDP_SET_DISCOUNT]: {actionCreator: chatsServerToClientActions.setDiscount},
	[ACTION.CMDP_SUPLOADMEDIA]: {actionCreator: messagesServerToClientActions.messageWithMediaReceived},
	[ACTION.CMDP_SETCHANNELINFO]: {actionCreator: channelsServerToClientActions.channelFlagsUpdated},
	[ACTION.CMDP_INIT]: {actionCreator: sessionServerToClientActions.initialized},
	[ACTION.CMDP_SINIT]: {actionCreator: sessionServerToClientActions.initialized},
	[ACTION.CMDP_SLOGIN]: {actionCreator: sessionServerToClientActions.loggedIn},
	[ACTION.CMDP_LOGOUT]: {actionCreator: sessionServerToClientActions.loggedOut},
	[ACTION.CMDP_FSK16]: {actionCreator: sessionServerToClientActions.fsk16Applied},
	[ACTION.CMDP_SERVICE0900]: {actionCreator: sessionServerToClientActions.service0900StatusChanged},
	[ACTION.CMDP_SUPDATE]: {actionCreator: sessionServerToClientActions.sessionCMDPUpdated},
	[ACTION.CMDP_GETONLINESUMMARY]: {actionCreator: onlineSummaryServerToClientActions.getOnlineSummary},
	[ACTION.CMDP_OPEN_LOBBY]: {actionCreator: sessionServerToClientActions.enableLobbyModeResult},
	[ACTION.CMDP_SDELETE_USER]: {actionCreator: sessionServerToClientActions.accountDeleted},
	[ACTION.CMDP_SMEDIAGETPRICES]: {actionCreator: mediaPricesServerToClientActions.receivedByChannelId},
	[ACTION.CMDP_GETSETTINGS]: {actionCreator: pushNotificationsServerToClientActions.settingsReceived},
	[ACTION.CMDP_SETSETTINGS]: {actionCreator: pushNotificationsServerToClientActions.settingsSaved},
	[ACTION.CMDP_SIGNUP]: {actionCreator: sessionServerToClientActions.signedUp},
	[ACTION.CMDP_RESETPASSWORD]: {actionCreator: sessionServerToClientActions.resetPasswordResponseReceived},
	[ACTION.CMDP_SSTARTTESTVIDEOCHAT]: {actionCreator: testVideoChatServerToClientActions.testVideoChatStarted},
	[ACTION.CMDP_SCHECKUPLOADEDMEDIA]: {actionCreator: mediaLinksServerToClientActions.checkUploadedMediaReceived},
	[ACTION.CMDP_SGETMSETTINGS]: {actionCreator: modelSettingsServerToClientActions.getModelSettings},
	[ACTION.CMDP_SSETMSETTING]: {actionCreator: modelSettingsServerToClientActions.setModelSettingResponseReceived},
	[ACTION.CMDP_SSETMSETTINGS]: {actionCreator: modelSettingsServerToClientActions.setModelSettingsResponseReceived},
	[ACTION.CMDP_SUPSERTTICKETSHOW]: {actionCreator: ticketShowServerToClientActions.ticketShowEdited},
	[ACTION.CMDP_SDELETETICKETSHOW]: {actionCreator: ticketShowServerToClientActions.ticketShowDeleted},
	[ACTION.CMDP_START_STREAM_PREVIEW]: {actionCreator: streamPreviewServerToClientActions.previewStarted},
	[ACTION.CMDP_STOPSEND]: {actionCreator: streamServerToClientActions.stopSendResponseReceived},
	[ACTION.CMDP_STARTSEND]: {actionCreator: streamServerToClientActions.startSendResponseReceived},
	[ACTION.CMDP_STARTBANDWIDTHTEST]: {actionCreator: speedTestServerToClientActions.startTestReceived},
	[ACTION.CMDP_STOPBANDWIDTHTEST]: {actionCreator: speedTestServerToClientActions.stopTestReceived},
	[ACTION.CMDP_SGETCHATSSUMMARY]: {actionCreator: chatSummaryServerToClientActions.chatSummaryReceived},
	[ACTION.CMDP_SUPDATEGROUP]: {actionCreator: groupServerToClientActions.groupUpdated},
	[ACTION.CMDP_SADDCHANNELTOGROUP]: {actionCreator: channelGroupsServerToClientActions.addedChannelsToGroup},
	[ACTION.CMDP_SDELCHANNELFROMGROUP]: {actionCreator: channelGroupsServerToClientActions.removedChannelsFromGroup},
	[ACTION.CMDP_SGETUPLOADEDMEDIA2]: {
		actionCreator: mediaServerToClientActions.mediaHistoryReceived,
		batchedSubCommandToActionMap: {
			[ACTION.CMDC_CMEDIA]: {actionCreator: mediaClientOnlyActions.addMany, isMultiEntityAction: true},
		},
	},
	[ACTION.CMDP_SMSGESTIMATE]: {
		actionCreator: channelGroupsPurchaseAbilityServerToClientActions.checkCanReceiveAttachmentReceived,
	},
	[ACTION.CMDP_SGETSTREAMPARAMS]: {actionCreator: streamServerToClientActions.configUpdated},
	[ACTION.CMDP_STARTMOBILEVIDEOCALL]: {
		actionCreator: videoCallServerToClientActions.callStarted,
	},
	[ACTION.CMDP_UPDATEMOBILEVIDEOCALL]: {actionCreator: videoCallServerToClientActions.callUpdated},
	[ACTION.CMDP_SGETTICKETSHOWS]: {actionCreator: ticketShowServerToClientActions.ticketShowsReceived},

	[ACTION.CMDP_NOOP]: {actionCreator: undefined},
	[ACTION.CMDP_SGETCONTACTNOTE]: {actionCreator: undefined},
	[ACTION.CMDP_SSETCONTACTNOTE]: {actionCreator: undefined},
	[ACTION.CMDP_SGETGROUPS]: {actionCreator: undefined},
	[ACTION.CMDP_SDEVICESTATUS]: {actionCreator: undefined},
	[ACTION.CMDP_SSTATUS]: {actionCreator: undefined},
	[ACTION.CMDP_CLOSE_LOBBY]: {actionCreator: undefined},
	[ACTION.CMDP_SGETCHANNELDMEDIASUMMARY]: {actionCreator: undefined},
	[ACTION.CMDP_SETEXPOTOKEN]: {actionCreator: undefined},
	[ACTION.CMDP_SETMOBILETOKEN]: {actionCreator: undefined},
	[ACTION.CMDP_MSGDELETE]: {actionCreator: undefined},
	[ACTION.CMDP_KICK]: {actionCreator: undefined},
	[ACTION.CMDP_LOGIN]: {actionCreator: undefined},
	[ACTION.CMDP_SLOGOUT]: {actionCreator: sessionServerToClientActions.loggedOutAllDevices},
	[ACTION.CMDP_SMSGREACTION]: {actionCreator: undefined},
	[ACTION.CMDP_GETONLINESTATE]: {actionCreator: undefined},
	[ACTION.CMDP_GETCHANNEL]: {actionCreator: undefined},
	[ACTION.CMDP_SGETUPLOADEDMEDIA]: {actionCreator: undefined},
};

export const subCommandToActionMap: TSubCommandToActionMap = {
	[ACTION.CMDC_CSTARTVIDEOPULL]: {actionCreator: cam2camPreviewServerToClientActions.guestStartsCam2Cam},
	[ACTION.CMDC_CSTARTVOYEUR]: {actionCreator: chatsServerToClientActions.guestStartsVoyeur},
	[ACTION.CMDC_CSTOPVIDEOPULL]: {actionCreator: cam2camPreviewServerToClientActions.guestStopsCam2Cam},
	[ACTION.CMDC_CSTOPVOYEUR]: {actionCreator: chatsServerToClientActions.guestStopsVoyeur},
	[ACTION.CMDC_QUERYUSER]: {actionCreator: queryServerToClientActions.queryReceived},
	[ACTION.CMDC_QUERYANSWERED]: {actionCreator: queryServerToClientActions.queryAnswered},
	[ACTION.CMDC_CHANNELSSUMMARY]: {actionCreator: channelsServerToClientActions.channelSummaryReceived},
	[ACTION.CMDC_CSTARTSTREAMPUSH]: {actionCreator: streamServerToClientActions.configReceived},
	[ACTION.CMDC_SLOGOUT]: {actionCreator: sessionServerToClientActions.sessionRemotelyTerminated},
	[ACTION.CMDC_FEEDBACK]: {actionCreator: sessionServerToClientActions.feedbackRequested},
	[ACTION.CMDC_UPDATE]: {actionCreator: sessionServerToClientActions.sessionUpdated},
	[ACTION.CMDC_CSHOWADMIN]: {actionCreator: chatsServerToClientActions.showAdminChat},
	[ACTION.CMDC_TOYACTION]: {actionCreator: toyServerToClientActions.queueVibration},
	[ACTION.CMDC_STREAMSTATE]: {actionCreator: streamServerToClientActions.stateChanged},
	[ACTION.CMDC_CSTREAMRESTART]: {actionCreator: streamServerToClientActions.streamRestartReceived},
	[ACTION.CMDC_MEDIAPURCHASED]: {actionCreator: mediaLinksServerToClientActions.mediaPurchased},
	[ACTION.CMDC_MSETTINGS]: {actionCreator: modelSettingsServerToClientActions.modelSettingsReceived},
	[ACTION.CMDC_CSTOPSTREAMPUSH]: {actionCreator: streamServerToClientActions.stopStreamReceived},
	[ACTION.CMDC_CHANNELMEDIASUMMARY]: {
		actionCreator: channelMediaSummaryServerToClientActions.channelMediaSummaryReceived,
	},
	[ACTION.CMDC_SSTATUS]: {actionCreator: streamServerToClientActions.statusReceived},

	[ACTION.CMDC_CHANNELINFO]: {
		actionCreator: channelInfosClientOnlyActions.upsertMany,
		isMultiEntityAction: true,
	},
	[ACTION.CMDC_CONTACTNOTE]: {actionCreator: contactNoteClientOnlyActions.upsertMany, isMultiEntityAction: true},
	[ACTION.CMDC_ONLINESTATE]: {actionCreator: onlineStateClientOnlyActions.upsertMany, isMultiEntityAction: true},
	[ACTION.CMDC_CMEDIA]: {actionCreator: mediaClientOnlyActions.upsertMany, isMultiEntityAction: true},
	[ACTION.CMDC_CMSG]: {actionCreator: messagesClientOnlyActions.processMany, isMultiEntityAction: true},
	[ACTION.CMDC_CHANNEL]: {actionCreator: channelsClientOnlyActions.upsertMany, isMultiEntityAction: true},
	[ACTION.CMDC_CTICKETSHOW]: {actionCreator: ticketShowClientOnlyActions.upsertMany, isMultiEntityAction: true},
	[ACTION.CMDC_GROUP]: {actionCreator: groupsClientOnlyActions.upsertMany, isMultiEntityAction: true},
	[ACTION.CMDC_CHANNELGROUP]: {
		actionCreator: channelGroupsClientOnlyActions.upsertMany,
		isMultiEntityAction: true,
	},
	[ACTION.CMDC_LIVESESSION]: {actionCreator: liveSessionsClientOnlyActions.addMany, isMultiEntityAction: true},
	[ACTION.CMDC_LIVECHAT]: {actionCreator: liveChatsClientOnlyActions.addMany, isMultiEntityAction: true},
	[ACTION.CMDC_SEDCARDPICTURE]: {
		actionCreator: sedCardPictureClientOnlyActions.addMany,
		isMultiEntityAction: true,
	},
	[ACTION.CMDC_CINIT]: {actionCreator: chatsClientOnlyActions.upsertMany, isMultiEntityAction: true},
	[ACTION.CMDC_UPDATE_CHAT]: {
		actionCreatorGetter: (command) =>
			command?.params?.videoTimeString && command?.params?.videoTime
				? chatsClientOnlyActions.updateChatVideoTimesMany
				: chatsClientOnlyActions.upsertMany,

		isMultiEntityAction: true,
	},
	[ACTION.CMDC_CEXIT]: {actionCreator: chatsClientOnlyActions.upsertMany, isMultiEntityAction: true},

	[ACTION.CMDC_CSTARTTEXT]: {actionCreator: undefined},
	[ACTION.CMDC_CSTARTAUDIOPUSH]: {actionCreator: undefined},
	[ACTION.CMDC_CSTOPAUDIOPUSH]: {actionCreator: undefined},
	[ACTION.CMDC_CSTARTVIDEOPUSH]: {actionCreator: undefined},
	[ACTION.CMDC_CSTOPVIDEOPUSH]: {actionCreator: undefined},
	[ACTION.CMDC_USERINFO]: {actionCreator: undefined},
	[ACTION.CMDC_CONTACTINFO]: {actionCreator: undefined},
	[ACTION.CMDC_VALUE]: {actionCreator: undefined},
	[ACTION.CMDC_DSRELOAD]: {actionCreator: undefined},
	[ACTION.CMDC_CQUERYUSER]: {actionCreator: undefined},
	[ACTION.CMDC_BACKENDPAYLOAD]: {actionCreator: undefined},
};

type TMultiEntityActionCreator<Command extends keyof TServerSubCommands> = ActionCreatorWithPreparedPayload<
	[
		TServerSubCommands[Command]['response']['params'][],
		TMultiEntityActionMeta<TServerSubCommands[Command]['response']>,
	],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	any,
	`${string}/${string}_MANY/${EnumActionRole.CLIENT_ONLY}`
>;

type TMultiEntityActionCreatorGetter<Command extends keyof TServerSubCommands> = (
	command: TServerSubCommands[Command]['response'],
) => TMultiEntityActionCreator<Command>;

export type TSubCommandToActionMap = {
	[Command in keyof TServerSubCommands]:
		| {
				actionCreator: TMultiEntityActionCreator<Command>;
				actionCreatorGetter?: undefined;
				isMultiEntityAction: true;
		  }
		| {
				actionCreatorGetter: TMultiEntityActionCreatorGetter<Command>;
				actionCreator?: undefined;
				isMultiEntityAction: true;
		  }
		| {
				actionCreator: ActionCreatorWithPreparedPayload<
					[
						TServerSubCommands[Command]['response']['params'],
						TSingleEntityActionMeta<TServerSubCommands[Command]['response']>,
					],
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					any,
					`${string}/${string}/${EnumActionRole.SERVER_TO_CLIENT}`
				>;
		  }
		| {
				actionCreator: undefined;
		  };
};

export type TCommandToActionMap = {
	[Command in keyof TServerCommands]: {
		actionCreator?: ActionCreatorWithPreparedPayload<
			[TServerCommands[Command]['response']['values'], TServerCommands[Command]['response']],
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			any,
			//ACTION.CMDP_INIT and ACTION.CMDP_SINIT are mapped the same action sessionServerToClientActions.initialized
			`${string}/${Command extends ACTION.CMDP_SINIT ? ACTION.CMDP_INIT : Command}/${EnumActionRole.SERVER_TO_CLIENT}`
		>;
		batchedSubCommandToActionMap?: Partial<TSubCommandToActionMap>;
	};
};
