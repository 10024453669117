import {ACTION, CMDC_CHANNELMEDIASUMMARY} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class ChannelMediaSummaryServerToClientActions extends ServerToClientActions<EnumStore.CHANNEL_MEDIA_SUMMARY> {
	readonly scope = EnumStore.CHANNEL_MEDIA_SUMMARY;

	channelMediaSummaryReceived = this.createAction(
		ACTION.CMDC_CHANNELMEDIASUMMARY,
		this.getPrepareAction<CMDC_CHANNELMEDIASUMMARY['params'], CMDC_CHANNELMEDIASUMMARY>(),
	);
}

export const channelMediaSummaryServerToClientActions = new ChannelMediaSummaryServerToClientActions();
