import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import EnumPreviewType from '@messenger/core/src/BusinessLogic/EnumPreviewType';

enum EnumClientOnlyActions {
	RESET = 'RESET',
	RESET_SOURCES = 'RESET_SOURCES',
	SET_PREVIEW_TYPE = 'SET_PREVIEW_TYPE',
}

/**
 * @NOTE Most of the actions are in src/Actions/Client/* files
 */
class StreamPreviewClientOnlyActions extends ClientOnlyActions<EnumStore.STREAM_PREVIEW> {
	readonly scope = EnumStore.STREAM_PREVIEW;

	resetStore = this.createAction(EnumClientOnlyActions.RESET);

	resetSources = this.createAction(EnumClientOnlyActions.RESET_SOURCES);

	setPreviewType = this.createAction(EnumClientOnlyActions.SET_PREVIEW_TYPE, this.getPrepareAction<EnumPreviewType>());
}

export const streamPreviewClientOnlyActions = new StreamPreviewClientOnlyActions();
