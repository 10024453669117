import _ from 'lodash';
import {ACTION, CMDP_SET_DISCOUNT_RESPONSE, CMDP_SGETCHATS} from 'cmd-control-client-lib';

import {ServerToClientActions, TSingleEntityActionMeta} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import IChat from '@messenger/core/src/Redux/Chats/Model';

import {prepareChatUpdatePayload} from './prepareChatUpdatePayload';

/**
 * @WARNING
 * After adding new Server Action add it to "serverToClientActionsMap" object
 * @see serverToClientActionsMap
 */
class ChatsServerToClientActions extends ServerToClientActions<EnumStore.CHATS> {
	readonly scope = EnumStore.CHATS;

	setDiscount = this.createAction(
		ACTION.CMDP_SET_DISCOUNT,
		this.getPrepareAction<CMDP_SET_DISCOUNT_RESPONSE['values'], CMDP_SET_DISCOUNT_RESPONSE, false>(),
	);

	/**
	 * @SEE rollbar item 592
	 */
	guestStartsVoyeur = this.createAction(
		ACTION.CMDC_CSTARTVOYEUR,
		(payload: IChat, meta: TSingleEntityActionMeta<{params: IChat}>, error?: string | boolean) => {
			if (!_.isUndefined(payload)) {
				return prepareChatUpdatePayload(
					{
						chatID: payload.chatID,
						isVoyeur: true,
						login: 'Voyeur',
					},
					meta,
					error,
				);
			} else {
				// add some more info for debugging
				throw new Error(`Failed to start voyeur: undefined payload. Meta: ${JSON.stringify(meta)}`);
			}
		},
	);

	guestStopsVoyeur = this.createAction(
		ACTION.CMDC_CSTOPVOYEUR,
		(payload: IChat, meta: TSingleEntityActionMeta<{params: IChat}>, error?: string | boolean) => {
			return prepareChatUpdatePayload({chatID: payload.chatID, isVoyeur: false}, meta, error);
		},
	);

	get = this.createAction(
		ACTION.CMDP_SGETCHATS,
		this.getPrepareAction<CMDP_SGETCHATS['params'], CMDP_SGETCHATS, false>(),
	);

	showAdminChat = this.createAction(ACTION.CMDC_CSHOWADMIN, (payload: {chatID: string}) => ({
		payload: payload.chatID,
	}));
}

export const chatsServerToClientActions = new ChatsServerToClientActions();
