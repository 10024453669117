import EnumResponseCode from './EnumResponseCode';

export default class RequestFailedResponse {
	readonly _code: EnumResponseCode;

	constructor(code: EnumResponseCode = EnumResponseCode.NOT_STARTED) {
		this._code = code;
	}

	get code() {
		return this._code;
	}
}
