import _ from 'lodash';

import {chatAdapter} from '@messenger/core/src/Redux/Chats/entityAdapter';
import IRootState from '@messenger/core/src/Redux/IRootState';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {TDefaultSelectors} from '@messenger/core/src/Redux/TDefaultSelectors';
import IChat from '@messenger/core/src/Redux/Chats/Model';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectChannelEntityVMs} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';
import {selectSessionAvatar} from '@messenger/core/src/Redux/Session/Selectors/selectSessionAvatar';
import {getChatVmCached} from '@messenger/core/src/Redux/Chats/getChatVmCached';
import {ChatViewModel} from '@messenger/core/src/Redux/Chats/ViewModel';

// see typescript nominal typing https://www.typescriptlang.org/play#example/nominal-typing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TChatEntityId = string & {__chatEntityIdBrand: any};

export const getChatIdForSelector = (chatId?: string) => chatId as TChatEntityId;

const {
	selectEntities: _selectEntities,
	selectAll: _selectAll,
	selectById: _selectById,
} = chatAdapter.getSelectors<IRootState>((state) => state[EnumStore.CHATS]) as TDefaultSelectors<IChat, TChatEntityId>;

export const selectChatVmById = createSelector(
	[_selectById, selectSessionAvatar, selectChannelEntityVMs],
	(entity, sessionAvatar, channelVms) =>
		getChatVmCached(entity, [sessionAvatar, _.get(channelVms, `${entity?.channelId}`)]),
);

export const selectChatEntityVms = createSelector(
	[_selectEntities, selectSessionAvatar, selectChannelEntityVMs],
	(entities, sessionAvatar, channelVms) =>
		_.reduce(
			entities,
			(result: Record<string, ChatViewModel>, entity) => {
				if (!_.isUndefined(entity)) {
					const vm = getChatVmCached(entity, [
						sessionAvatar,
						entity.channelId ? _.get(channelVms, entity.channelId) : undefined,
					]);

					if (!_.isUndefined(vm)) {
						result[entity.chatID] = vm;
					}
				}

				return result;
			},
			{},
		),
);

export const selectChatVms = createSelector(
	[_selectAll, selectSessionAvatar, selectChannelEntityVMs],
	(entities, sessionAvatar, channelVms) =>
		_.compact(
			_.map(entities, (entity) =>
				getChatVmCached(entity, [sessionAvatar, entity.channelId ? _.get(channelVms, entity.channelId) : undefined]),
			),
		),
);
