import { EnumBooleanStringified } from "../../@types";

export enum SupportedLanguage {
  DE = "de",
  EN = "en",
  ES = "es",
}

export enum CmdClientAgent {
  WEB = "we", // javascript browser app
  MC = "mc",
}

/** the format, use 'json', 'plain' is default, but depricated */
export enum EnumFormatValues {
  JSON = "json",
  PLAIN = "plain",
}
export type deviceIdType = {
  /* device id*/
  deviceId?: string;
};

export type baseParamsType = {
  /** your app instance id, internal */
  _iid?: string;
  /** unique command id, internal */
  _uniq?: string;
  /** your app type */
  agent?: CmdClientAgent;
  /** your sessionID from CMDP_LOGIN_RESPONSE, Ignored in CMDP_LOGIN, CMDP_INIT */
  sessionID?: string;
  /** the format, use 'json', 'plain' is default, but deprecated */
  format?: EnumFormatValues;
  /** short json, use '1' , default is '0' and force delivery of plain-text non-json format response */
  strip?: EnumBooleanStringified;
  /** version info */
  version?: string;
  /** language, CmdControl has only de and en implemented */
  language?: SupportedLanguage;
  /** webtoken for call into Backend
   * https://jwt.io/
   * \{
   * usrId -  your content partner usrId
   * usrKey - your customer key
   * \}
   */
  webtoken?: string; // optional
} & deviceIdType;
