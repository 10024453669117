import {ACTION} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class ToyClientToServerActions extends ClientToServerActions<EnumStore.TOY> {
	readonly scope = EnumStore.TOY;

	sendToyState = this.createAction(ACTION.CMDP_TOYSTATE, (online: boolean) => ({payload: {online}}));
}

export const toyClientToServerActions = new ToyClientToServerActions();
