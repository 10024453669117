import _ from 'lodash';

import {selectMessageVms} from '@messenger/core/src/Redux/Messages/Selectors/messagesDefaultSelectors';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import {getPropsChannelId} from '@messenger/core/src/Redux/Channels/Selectors/getPropsChannelId';
import {selectMessagesHistoryStatuses} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/selectMessagesHistoryStatuses';
import exactChannelFilter from '@messenger/core/src/BusinessLogic/MessagesDisplayFilters/ExactChannelFilter';
import {sliceMessageHistoryByMessageId} from '@messenger/core/src/Utils/Messages/sliceMessageHistoryByMessageId';

export const selectChannelMessages = createSelector(
	[selectMessageVms, getPropsChannelId, selectMessagesHistoryStatuses],
	(messages, channelId, messagesHistoryStatuses) => {
		if (!channelId || !messagesHistoryStatuses[channelId]?.isReady) {
			return [];
		}

		const filteredMessages = _.filter(messages, (messageVM) => exactChannelFilter(messageVM, channelId));

		return sliceMessageHistoryByMessageId(
			filteredMessages,
			messagesHistoryStatuses[channelId]?.searchAfter?.searchAfterId,
		);
	},
);
