import {put} from 'typed-redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';
import {EnumSetTp} from 'cmd-control-client-lib';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {streamPreviewClientToServerActions} from '@messenger/core/src/Redux/StreamPreview/Actions/streamPreviewClientToServerActions';

export function* requestPreviewOnSetTpChangesSaga(action: PayloadAction<TSessionWithSetTp>) {
	try {
		if (_.get(action.payload, 'setTp') === EnumSetTp.ONLINE) {
			yield* put(streamPreviewClientToServerActions.requestPreview());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'requestPreviewOnSetTpChangesSaga'});
	}
}

type TSessionWithSetTp = {
	setTp?: EnumSetTp;
};
