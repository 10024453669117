import {channelIdType, ChannelMediaLinkState, MediaPrice, UploadedMediaDirection} from 'cmd-control-client-lib';

import {createEntityAdapter} from '@messenger/core/src/Utils/Redux/createEntityAdapter';
import {descSortComparerByField} from '@messenger/core/src/Utils/descSortComparerByField';

export const getMediaLinkId = (channelId: string, mediaMd5: string) => `${channelId}-${mediaMd5}`;

export const mediaLinkEntityAdapter = createEntityAdapter<TMediaLink>({
	selectId: ({channelId, mediaMd5}) => getMediaLinkId(channelId, mediaMd5),
	sortComparer: (mediaLink1, mediaLink2) => descSortComparerByField(mediaLink1, mediaLink2, 'linkDate'),
});

export type TMediaLink = channelIdType &
	MediaPrice & {
		mediaMd5: string;
		linkState: ChannelMediaLinkState;
		linkDate: string;
		mediaType?: string;
		direction?: UploadedMediaDirection;
	};
