import {ACTION, CMDP_SSTARTTESTVIDEOCHAT_RESPONSE} from 'cmd-control-client-lib';
import _ from 'lodash';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class TestVideoChatServerToClientActions extends ServerToClientActions<EnumStore.TEST_VIDEO_CHAT> {
	readonly scope = EnumStore.TEST_VIDEO_CHAT;
	testVideoChatStarted = this.createAction(
		ACTION.CMDP_SSTARTTESTVIDEOCHAT,
		(
			_payload: CMDP_SSTARTTESTVIDEOCHAT_RESPONSE['values'],
			meta: CMDP_SSTARTTESTVIDEOCHAT_RESPONSE,
			error?: string | boolean,
		) => {
			if (!_.isUndefined(_payload)) {
				return {
					payload: {
						clientID: _payload.clientID,
						server: _payload.server,
					},
					meta,
					error,
				};
			} else {
				throw new Error(`Failed to start test video chat: undefined payload. Meta: ${JSON.stringify(meta)}`);
			}
		},
	);
}

export const testVideoChatServerToClientActions = new TestVideoChatServerToClientActions();
