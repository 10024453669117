import {takeEvery} from 'typed-redux-saga';

import {streamPreviewServerToClientActions} from '@messenger/core/src/Redux/StreamPreview/Actions/streamPreviewServerToClientActions';
import {streamServerToClientActions} from '@messenger/core/src/Redux/Stream/Actions';

import {openMainPreviewSocketSaga} from 'src/Redux/Preview/Sagas/openMainPreviewSocketSaga';
import {closeMainPreviewSocketSaga} from 'src/Redux/Preview/Sagas/closeMainPreviewSocketSaga';

function* previewSagaWatcher() {
	yield* takeEvery(streamPreviewServerToClientActions.previewStarted, openMainPreviewSocketSaga);
	yield* takeEvery(streamServerToClientActions.stopStreamReceived, closeMainPreviewSocketSaga);
}

export default previewSagaWatcher;
