import {Action, Middleware} from 'redux';
import {configureStore, StoreEnhancer} from '@reduxjs/toolkit';

import IRootState from '@messenger/core/src/Redux/IRootState';
import rootReducer from '@messenger/core/src/Redux/Reducers';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

const createStore = ({middleware, enhancers}: {middleware: Middleware[]; enhancers?: [StoreEnhancer]}) => {
	const store = configureStore<IRootState, Action, Middleware[]>({
		reducer: rootReducer,
		middleware,
		enhancers,
		devTools: !ServiceFactory.env.reduxDevToolsEnabled()
			? false
			: {
					name: ServiceFactory.env.getName(),
					actionsDenylist: ServiceFactory.env.getReduxIgnoredActions(),
					maxAge: 1000,
			  },
	});

	if (module?.hot) {
		const path = '../core/src/Redux/Reducers/index.ts';

		module.hot.accept(path, () => {
			// eslint-disable-next-line @typescript-eslint/no-var-requires
			store.replaceReducer(require('@messenger/core/src/Redux/Reducers').default);
		});
	}

	return store;
};

export default createStore;
