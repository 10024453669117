/**
 * known values for RESULT.code
 *
 * for B2B : Use for your custom ERROR_CODE the range 6001-6999
 */
export enum ResultCode {
  OK = 2000, // successuful
  WRONG_USER_NAME_OR_PASSWORD = 4002,
  ACCESS_DENIED = 4003,
  SESSION_NOT_FOUND = 4004,
  LOGIN_FAILED = 4006,
  LOGIN_DOUBLE = 4007,
  SESSION_ERROR = 4008,
  PROFIL_NOT_COMPLETE = 4009,
  TOO_MANY_SESSIONS = 4010,
  TOO_MANY_SESSIONS2 = 4011,
  TOO_MANY_SESSIONS3 = 4012,
  VERSION_NOT_SUPPORTED = 4013,
  MAIL_NOT_VERYFIED = 4014,
  AUDIO_NOT_AVAILABLE = 4015,
  SINGLE_NOT_STARTED = 4016,
  GTC_NOT_CONFIRMED = 4017,
  CHAT_NOT_CLOSED = 4020,
  CHAT_NOT_FOUND = 4104,
  NOT_ENOUGH_MONEY = 4141,
  CANT_UPDATE_CHAT = 4150,
  CANT_UPDATE_CHAT2 = 4151,
  INVALID_PARAMETER = 5001,
  FEATURE_NOT_AVAILABLE = 5002,
  UNEXPECTED = 5004,
  NOT_IMPLEMENTED = 5005,
  NO_CONNECTION = 5096,
  SCRIPT_ERROR = 5097,
  TIMEOUT = 5098,
  NETWORK_ERROR = 5099,
  /** B2B error-codes start with 6000 */
  B2B_INTERFACE_ERROR = 6000,
  UNKNOWN_ERROR = 6001,
  INVALID_WEB_TOKEN = 6002,
  NO_CREDIT_VIDEOCHAT = 6106,
  ACCESS_DENIED_BAN = 6201,
  MODEL_IS_OFFLINE = 6403,
  SOFT_CHAT = 6601,
  PREVIEW_LIMIT_EXCEEDED = 6401,
  MODEL_IS_IN_PRIVATE_CHAT = 6906,
  MISSING_SHOW_TICKET = 6010,
  SHOW_NOT_STARTED = 6011,
}
