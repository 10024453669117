import {put, select} from 'typed-redux-saga';

import {selectSpeedTestReport} from '@messenger/core/src/Redux/SpeedTest/Selectors/selectSpeedTestReport';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {speedTestClientToServerActions} from '@messenger/core/src/Redux/SpeedTest/Actions/speedTestClientToServerActions';

export const sendSpeedTestReportSaga = function* () {
	try {
		const report = yield* select(selectSpeedTestReport);

		yield* put(speedTestClientToServerActions.stopTest(report));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'SendSpeedTestReport'});
	}
};
