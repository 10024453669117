export type TLogEventOptions = {category: string};

export enum EnumAnalyticsCategories {
	USER_ACTIVITY = 'User activity',
	FEATURES = 'Features',
	SCREEN_VIEW = 'Screen view',
}

export enum EnumAnalyticsEvents {
	CLICK_ON_CHAT_BUTTON = 'Click on only chat button',
	CLICK_ON_MESSENGER_BUTTON = 'Click on only messenger button',
	RECORDING_AUDIO_WHILE_STREAMING = 'Recording audio while streaming',
	REGULAR_AUDIO_RECORDING = 'Regular audio recording',
	MICROPHONE_THROW = 'Microphone throw',
	SCREEN_VIEW = 'Screen view',
	FCM_TOKEN_REFRESH = 'FCM token refresh',
}

abstract class AbstractAnalyticsService {
	abstract logEvent(action: string, options: TLogEventOptions): void;

	abstract logPage(pageName: string, pageTitle?: string): void;

	abstract logSignUp(method: string): void;
	abstract logSignIn(method: string): void;

	protected abstract initialize(): void;
}

export default AbstractAnalyticsService;
