import {PayloadAction} from '@reduxjs/toolkit';
import {call, select} from 'typed-redux-saga';

import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';
import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TSendFeedbackPayload} from '@messenger/core/src/Redux/Feedback/Actions/feedbackClientOnlyActions';
import {selectSessionUsrKey} from '@messenger/core/src/Redux/Session/Selectors/selectSessionUsrKey';

export const setFeedbackDataToStorageSaga = function* (action: PayloadAction<TSendFeedbackPayload>) {
	try {
		const usrKey = yield* select(selectSessionUsrKey);
		const storageKey = `${EnumLocalStorageKeys.FEEDBACK_DATA}:${usrKey}`;

		yield* call(
			[ServiceFactory.store, ServiceFactory.store.set],
			storageKey,
			{
				login: action.payload.login,
				data: {name: action.payload.name, phone: action.payload.phone, email: action.payload.email},
			},
			{
				storeType: EnumStoreType.LOCAL,
			},
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'setFeedbackDataToStorageSaga'});
	}
};
